export default {
  g: {
    flag: "🇯🇵",
    langName: "Japanese",
    langNameNative: "日本語",
    continue: "続ける",
    save: "保存",
    retry: "再試行",
    loading: "読み込み中...",
    genderMany: {
      all: "すべて",
      male: "男性",
      female: "女性",
    },
  },
  CreateAccount: {
    slogan: "夢が勝利に変わる場所！",
    gender: {
      title: "こんにちは {{name}}、あなたは...",
      male: "男性",
      female: "女性",
    },
  },
  Settings: {
    title: "設定",
    language: "言語",
    auto: "自動",
    country: "国",
    hideProfile: {
      label: "プロファイルを非表示にする",
      description:
        "あなたのプロファイルは削除されたかのように、すべての人から非表示になります。",
    },
    profileHidden:
      "あなたのプロファイルは非表示になっており、他のユーザーには表示されません。",
  },
  Tabs: {
    vote: "投票",
    contest: "コンテスト",
    friends: "友達",
    earn: "稼ぐ",
    wallet: "ウォレット",
  },
  Vote: {
    whoIsBetter: "誰が好きですか？",
    votingForLeader: "リーダーのために投票",
    noUsers: {
      title: "🚀 今日はこれでおしまい",
      description: "友達を招待して投票とコインを獲得し続けよう",
      inviteFriends: "友達を招待する",
    },
  },
  Leaders: {
    rewards: "報酬",
    comingSoon: "近日公開",
    prizePool: "賞金プール",
    moreInfo: "詳細情報",
    contest: "コンテスト",
    richest: "最も裕福な",
    sponsors: "スポンサー",
    description: {
      likes:
        "友達を招待し、プロフィールを共有して、より多くの投票を集め、勝つチャンスを増やしましょう。",
      coins:
        "タスクを完了し、他の参加者に投票し、友達を招待して、より多くのコインを獲得し、ポジションを強化しましょう。",
      stars:
        "プロフィールページでプロフィールに投票して、星を獲得し、ランキングを上げましょう。",
    },
  },
  PrizePool: {
    text: `<b>受賞場所</b>:
<b>1位</b>: 50% USD と10,000コイン
<b>2位</b>: 30% USD と3,000コイン
<b>3位</b>: 20% USD と2,000コイン
<b>4位-10位</b>: 各1,000コイン
<b>11位-50位</b>: 各500コイン
<b>51位-100位</b>: 各250コイン

<b>賞金プール</b>は、誰かがあなたのプロフィールページであなたに投票すると増加します。

<b>条件</b>:
写真をアップロードしてタスクを完了したすべてのプロフィールは、コンテストに参加する資格があります。

<b>終了日</b>:
{{endDate}}

<b>報酬の支払い</b>:
報酬は、Telegramが開発した暗号通貨<b>TON</b>で受け取ることができます。`,
  },
  LeadersItem: {
    join: "参加",
  },
  Friends: {
    title: "友達を招待しましょう！",
    description: "あなたと友達はボーナスを受け取ります",
    bonus: {
      profit: "<coins>+{{count}}</coins> あなたと友達のために",
      invite: {
        title: "友達を招待",
      },
      inviteWithPremium: {
        title: "友達を招待",
        description: "Telegramプレミアム付きで",
      },
    },
    list: {
      title: "あなたの友達リスト",
    },
    inviteFriend: "友達を招待",
    inviteText: `私に参加して、最も美しい男の子と女の子のために投票し、貴重な賞品を獲得しましょう！
  
  🌟 最初のギフトとして+{{coins}} コイン
  🔥 Telegramプレミアムをお持ちの場合は+{{coinsPremium}} コイン
  
  🏆 すべての投票でコインを獲得し、最も魅力的な参加者を選びましょう。もっと欲しいですか？自分で参加して貴重な賞品を競い合いましょう！
  
  チャンスを逃さず、私たちのスターコミュニティの一員になって勝ちましょう！`,
    linkCopied: "リンクがコピーされました",
  },
  Earn: {
    title: "もっとコインを稼ぐ",
    tasksList: "タスクリスト",
    check: "チェック",
    inReview: "審査中",
    subscribe: "購読",
    participate: "参加する",
    invite: "招待する",
    verify: "確認",
    taskCompleted: "タスク完了",
  },
  Wallet: {
    title: "TONで報酬を得る",
    description:
      "Telegramが開発した暗号通貨TONを稼ぐためにチャレンジを完了しましょう",
    rewards: "報酬",
    withdrawal: "引き出し",
    walletConnected: "あなたのTONウォレットは接続されています",
    walletDescription: "さらなる機会がすぐに利用可能になります",
  },
  VerificationRequired: {
    title: "確認が必要です",
    description:
      "コンテストに参加するには、アカウントを確認する必要があります。",
    bdate: {
      title: "生年月日",
    },
  },
  Verification: {
    title: "確認",
    hint: "顔を楕円の中に収めてください",
    secureHint: "この写真は誰にも見られません",
    success: "確認が成功しました",
    goToApp: "アプリに進む",
    verification: "確認中...",
    cameraAccessRequired: "続行するにはカメラアクセスを許可してください",
    gettingAccessToCamer: "カメラアクセスを取得中...",
    cameraAccessNotAllowed: "ユーザーによってカメラアクセスが拒否されました。",
    cameraAccessError: "カメラアクセスエラー",
    loadingHint: "これには時間がかかる場合があります。しばらくお待ちください。",
  },
  Profile: {
    vote: "投票する",
    askVote: "投票を依頼する",
    inviteText: `こんにちは！ 😊 TON Starsコンテストであなたの投票をサポートしてください！ すべての投票が重要で、貴重な賞品を獲得するための一歩に役立ちます。

🌟 最初のギフトとして+{{coins}}コイン
🔥 Telegram Premiumをお持ちの場合は+{{coinsPremium}}コイン

私のプロフィールに投票してください: {{url}}

ご支援ありがとうございます！ 🌟`,
    joinContest: "コンテストに参加する",
    gender: {
      male: "男性",
      female: "女性",
    },
    photos: {
      inReview:
        "新しい写真は審査中です。\nしばらく時間がかかる場合があります。",
      rejected:
        "一枚以上の写真が次の理由で拒否されました:\n{{reason}}。\n\n他の写真をアップロードして再試行してください。",
    },
  },
  ProfileEdit: {
    title: "あなたのプロフィール",
    name: {
      label: "あなたの名前",
      placeholder: "あなたの名前を入力してください",
    },
    instagram: {
      description: "Instagramプロフィールは公開されている必要があります",
    },
    photos: {
      verificationPhotoUploaded:
        "確認用の写真がアップロードされました。誰にも見られません。",
      label: "あなたの写真",
      mainPhoto: "メイン写真",
      error: "一枚以上の写真が適切ではありません。他の選択をしてください。",
      hint: "他の人がいない、顔がはっきりと表現豊かに見える最も印象的な写真を選んでください。",
      inReview:
        "新しい写真は審査中です。\nしばらく時間がかかる場合があります。",
      rejected:
        "一枚以上の写真が次の理由で拒否されました:\n{{reason}}。\n\n他の写真をアップロードして再試行してください。",
    },
    submit: "コンテストに参加する",
    photosUploading: "写真をアップロード中...",
    success: "あなたのプロフィールが更新されました",
    errors: {
      name: "名前を入力してください",
      photos: "写真を3枚選択してください",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "メイン写真",
    noFace: "顔が検出されません",
    otherPeople: "写真にはあなたの顔のみが表示されるべきです",
  },
};
