import { Button } from "@telegram-apps/telegram-ui";
import "./SplashScreen.css";
import StarIcon from "./icons/star.svg?react";
import WebApp from "@twa-dev/sdk";

export const SplashScreen = () => {
  return (
    <div className={`splashscreen${!WebApp.initData ? " once" : ""}`}>
      <div className="splashscreen-container">
        <StarIcon
          className="splashscreen-icon"
          color="rgb(245, 186, 38)"
          width={64}
          height={64}
        />
        <div className="splashscreen-text">TON STARS</div>
      </div>
      {!WebApp.initData && (
        <a href="https://t.me/ton_stars_app_bot/tonstars">
          <Button className="splashscreen-button">Start</Button>
        </a>
      )}
    </div>
  );
};
