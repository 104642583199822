export default {
  g: {
    flag: "🇳🇱",
    langName: "Dutch",
    langNameNative: "Nederlands",
    continue: "Doorgaan",
    save: "Opslaan",
    retry: "Opnieuw proberen",
    loading: "Laden...",
    genderMany: {
      all: "Alle",
      male: "Mannen",
      female: "Vrouwen",
    },
  },
  CreateAccount: {
    slogan: "Plaats waar dromen veranderen in overwinningen!",
    gender: {
      title: "Hallo {{name}}, Jij bent...",
      male: "Man",
      female: "Vrouw",
    },
  },
  Settings: {
    title: "Instellingen",
    language: "Taal",
    auto: "Automatisch",
    country: "Land",
    hideProfile: {
      label: "Profiel verbergen",
      description:
        "Je profiel wordt voor iedereen verborgen, alsof je het hebt verwijderd.",
    },
    profileHidden:
      "Je profiel is verborgen en niet zichtbaar voor andere gebruikers.",
  },
  Tabs: {
    vote: "Stemmen",
    contest: "Wedstrijd",
    friends: "Vrienden",
    earn: "Verdienen",
    wallet: "Portemonnee",
  },
  Vote: {
    whoIsBetter: "Wie vind je leuker?",
    votingForLeader: "Stemmen op de leider",
    noUsers: {
      title: "🚀 Dat is alles voor vandaag",
      description:
        "Nodig vrienden uit om door te gaan met stemmen en munten te verdienen",
      inviteFriends: "Vrienden uitnodigen",
    },
  },
  Leaders: {
    rewards: "Beloningen",
    comingSoon: "Binnenkort beschikbaar",
    prizePool: "Prijzenpot",
    moreInfo: "Meer informatie",
    contest: "Wedstrijd",
    richest: "Rijkste",
    sponsors: "Sponsors",
    description: {
      likes:
        "Nodig vrienden uit en deel je profiel om meer stemmen te verzamelen en je kansen om te winnen te vergroten.",
      coins:
        "Voltooi taken, stem op andere deelnemers en nodig vrienden uit om meer munten te verdienen en je positie te versterken.",
      stars:
        "Stem op profielen op de profielpagina om sterren te ontvangen en stijgen in de ranglijsten.",
    },
  },
  PrizePool: {
    text: `<b>Prijswinnende plaatsen</b>:
<b>1e plaats</b>: 50% USD en 10,000 munten
<b>2e plaats</b>: 30% USD en 3,000 munten
<b>3e plaats</b>: 20% USD en 2,000 munten
<b>4e-10e plaatsen</b>: 1,000 munten elk
<b>11e-50e plaatsen</b>: 500 munten elk
<b>51e-100e plaatsen</b>: 250 munten elk

<b>De prijzenpot</b> wordt groter wanneer iemand op je stemt op je profielpagina.

<b>Voorwaarden</b>:
Alle profielen die een foto uploaden en de taken voltooien komen in aanmerking om deel te nemen aan de wedstrijd.

<b>Einddatum</b>:
{{endDate}}

<b>Uitbetaling van beloningen</b>:
Je kunt je beloning ontvangen in <b>TON</b> — de cryptocurrency ontwikkeld door Telegram.`,
  },
  LeadersItem: {
    join: "Deelnemen",
  },
  Friends: {
    title: "Nodig vrienden uit!",
    description: "Jij en je vriend ontvangen bonussen",
    bonus: {
      profit: "<coins>+{{count}}</coins> voor jou en je vriend",
      invite: {
        title: "Nodig een vriend uit",
      },
      inviteWithPremium: {
        title: "Nodig een vriend uit",
        description: "met Telegram Premium",
      },
    },
    list: {
      title: "Lijst van je vrienden",
    },
    inviteFriend: "Nodig een vriend uit",
    inviteText: `Doe mee met mij, stem op de mooiste jongens en meisjes en ontvang waardevolle prijzen!
  
  🌟 +{{coins}} munten als eerste cadeau
  🔥 +{{coinsPremium}} munten als je Telegram Premium hebt
  
  🏆 Verdien munten voor elke stem en kies de meest aantrekkelijke deelnemers. Wil je meer? Doe zelf mee en strijd om waardevolle prijzen!
  
  Mis je kans niet, word deel van onze sterrencommunity en win!`,
    linkCopied: "Link gekopieerd",
  },
  Earn: {
    title: "Verdien meer munten",
    tasksList: "Takenlijst",
    check: "Controleren",
    inReview: "In beoordeling",
    subscribe: "Abonneren",
    participate: "Deelnemen",
    invite: "Uitnodigen",
    verify: "Verifiëren",
    taskCompleted: "Taak voltooid",
  },
  Wallet: {
    title: "Ontvang beloningen in TON",
    description:
      "Voltooi uitdagingen om TON te verdienen, een cryptomunt ontwikkeld door Telegram",
    rewards: "Beloningen",
    withdrawal: "Opname",
    walletConnected: "Je TON-portemonnee is verbonden",
    walletDescription: "Meer mogelijkheden worden binnenkort beschikbaar",
  },
  VerificationRequired: {
    title: "Verificatie Vereist",
    description:
      "Om deel te nemen aan de wedstrijd, moet je je account verifiëren.",
    bdate: {
      title: "Je geboortedatum",
    },
  },
  Verification: {
    title: "Verificatie",
    hint: "Houd je gezicht binnen de ovaal",
    secureHint: "Niemand zal deze foto zien",
    success: "Je hebt de verificatie succesvol voltooid",
    goToApp: "Ga naar de applicatie",
    verification: "Bezig met verifiëren...",
    cameraAccessRequired:
      "Geef alstublieft toegang tot de camera om door te gaan",
    gettingAccessToCamer: "Toegang tot de camera verkrijgen...",
    cameraAccessNotAllowed:
      "Toegang tot de camera geweigerd door de gebruiker.",
    cameraAccessError: "Fout bij toegang tot de camera",
    loadingHint: "Dit kan even duren. Gelieve geduld te hebben.",
  },
  Profile: {
    vote: "Stem",
    askVote: "Vraag om te Stemmen",
    inviteText: `Hallo! 😊 Steun me alsjeblieft met je stem in de TON Stars-wedstrijd! Elke stem is cruciaal en helpt me dichter bij het winnen van waardevolle prijzen.

🌟 +{{coins}} munten als eerste cadeau
🔥 +{{coinsPremium}} munten als je Telegram Premium hebt

Stem hier op mijn profiel: {{url}}

Bedankt voor je steun! 🌟`,
    joinContest: "Doe mee aan wedstrijd",
    gender: {
      male: "Man",
      female: "Vrouw",
    },
    photos: {
      inReview:
        "Uw nieuwe foto's worden beoordeeld.\nDit kan enige tijd duren.",
      rejected:
        "Een of meer foto's zijn om de volgende reden afgewezen:\n{{reason}}.\n\nUpload alstublieft andere foto's en probeer het opnieuw.",
    },
  },
  ProfileEdit: {
    title: "Jouw profiel",
    name: {
      label: "Jouw naam",
      placeholder: "Vul je naam in",
    },
    instagram: {
      description: "Instagram-profiel moet openbaar zijn",
    },
    photos: {
      verificationPhotoUploaded:
        "Je verificatiefoto is geüpload, niemand zal het zien.",
      label: "Jouw foto's",
      mainPhoto: "Hoofdfoto",
      error: "Een of meer foto's zijn niet geschikt, maak een andere keuze.",
      hint: "Kies je meest indrukwekkende foto's, waar geen andere mensen op staan en je gezicht duidelijk en expressief zichtbaar is.",
      inReview:
        "Uw nieuwe foto's worden beoordeeld.\nDit kan enige tijd duren.",
      rejected:
        "Een of meer foto's zijn om de volgende reden afgewezen:\n{{reason}}.\n\nUpload alstublieft andere foto's en probeer het opnieuw.",
    },
    submit: "Doe mee aan wedstrijd",
    photosUploading: "Foto wordt geüpload...",
    success: "Je profiel is bijgewerkt",
    errors: {
      name: "Voer je naam in",
      photos: "Selecteer 3 foto's",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Hoofdfoto",
    noFace: "Je gezicht is niet gedetecteerd",
    otherPeople: "De foto moet alleen je gezicht tonen",
  },
};
