/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query App_Query($userId: BigInt) {\n    ...Tabs_QueryFragment\n    ...CreateAccount_QueryFragment\n    user {\n      id\n      gender\n      countryCode\n      appLang\n      language_code\n    }\n    voteConfig {\n      ...ProfileVote_QueryFragment\n    }\n    bonus {\n      ...ProfileBonus_QueryFragment\n    }\n  }\n": types.App_QueryDocument,
    "\n  mutation CreateAccount_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      gender\n    }\n  }\n": types.CreateAccount_MutationDocument,
    "\n  fragment CreateAccount_QueryFragment on Query {\n    user {\n      id\n      first_name\n      gender\n    }\n  }\n": types.CreateAccount_QueryFragmentFragmentDoc,
    "\n  query Earn_Query {\n    tasks {\n      id\n      image\n      localizedTitle\n      localizedDescription\n      buttonType\n      buttonUrl\n      reward\n      startedReviewAt\n      completedAt\n      inReview\n    }\n  }\n": types.Earn_QueryDocument,
    "\n  mutation Earn_Mutation(\n    $taskId: String!\n  ) {\n    taskComplete(taskId: $taskId) {\n      id\n      startedReviewAt\n      completedAt\n      inReview\n      updatedUser {\n        id\n        coins\n      }\n    }\n  }\n": types.Earn_MutationDocument,
    "\n  query Friends_Query {\n    friends {\n      ...ProfileUser_QueryFragment\n      ...FriendsUser_QueryFragment\n      id\n    }\n  }\n": types.Friends_QueryDocument,
    "\n    fragment FriendsUser_QueryFragment on User {\n        id\n        name\n        coins\n        coinsForRef\n        uploads {\n            id\n            faceUrl: url(size: 200, face: true)\n        }\n        ...ProfileUser_QueryFragment\n    }\n": types.FriendsUser_QueryFragmentFragmentDoc,
    "\n    fragment Header_QueryFragment on Query {\n      ...Settings_QueryFragment\n      user {\n        ...ProfileUser_QueryFragment\n        id\n        name\n        photo_url\n        coins\n        age\n        verified\n        hidden\n        uploads {\n          id\n          faceUrl: url(size: 200, face: true)\n        }\n        uploadsPending {\n          id\n        }\n        photoStatus {\n          rejectReasonText\n        }\n      }\n    }\n  ": types.Header_QueryFragmentFragmentDoc,
    "\n  fragment LeadersUser_QueryFragment on User {\n    id\n    gender\n  }\n": types.LeadersUser_QueryFragmentFragmentDoc,
    "\n  query Leaders_Query($gender: UserGender, $orderBy: LeaderOrderBy!) {\n   leaders(gender: $gender, orderBy: $orderBy) {\n     id\n     isMe\n     ...LeaderItem_QueryFragment\n   }\n   user {\n    id\n    gender\n    starsCount\n    place\n    genderPlace\n    placeCoins\n    genderPlaceCoins\n    placeStars\n    genderPlaceStars\n    hidden\n    haveVerificationPhoto\n    ...LeaderItem_QueryFragment\n   }\n  }\n": types.Leaders_QueryDocument,
    "\n    fragment LeaderItem_QueryFragment on User {\n        ...ProfileUser_QueryFragment\n        ...VerificationRequiredUser_QueryFragment\n        ...ProfileEditUser_QueryFragment\n        id\n        photo_url\n        name\n        age\n        verified\n        countryCode\n        likesCount\n        coins\n        starsCount\n        isMe\n        uploads {\n          id\n          faceUrl: url(size: 200, face: true)\n        }\n        uploadsPending {\n          id\n        }\n        photoStatus {\n          rejectReasonText\n        }\n    }\n": types.LeaderItem_QueryFragmentFragmentDoc,
    "\n  fragment ProfileUser_QueryFragment on User {\n    ...ProfileEditUser_QueryFragment\n    ...VerificationRequiredUser_QueryFragment\n    id\n    name\n    photo_url\n    age\n    countryCode\n    gender\n    instagram\n    verified\n    isMe\n    likesCount\n    starsCount\n    haveVerificationPhoto\n    uploads {\n      id\n      previewUrl: url(width: 477, height: 848)\n      faceUrl: url(size: 200, face: true)\n    }\n    uploadsPending {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n    photoStatus {\n      rejectReasonText\n    }\n  }\n": types.ProfileUser_QueryFragmentFragmentDoc,
    "\n  fragment ProfileBonus_QueryFragment on Bonus {\n    id\n    invite\n    invitePremium\n  }\n": types.ProfileBonus_QueryFragmentFragmentDoc,
    "\n  mutation ProfileEdit_Mutation($name: String!, $uploadIds: [String!]!, $instagram: String) {\n    userProfileSave(name: $name, uploadIds: $uploadIds, instagram: $instagram) {\n      id\n      name\n      instagram\n      uploads {\n        id\n        previewUrl: url(width: 477, height: 848)\n      }\n      uploadsPending {\n        id\n        previewUrl: url(width: 477, height: 848)\n      }\n      photoStatus {\n        rejectReasonText\n      }\n    }\n  }\n": types.ProfileEdit_MutationDocument,
    "\n    fragment ProfileEditUser_QueryFragment on User {\n        ...VerificationRequiredUser_QueryFragment\n        id\n        name\n        instagram\n        haveVerificationPhoto\n        verified\n        uploads {\n          id\n          ...Upload_QueryFragment\n          previewUrl: url(width: 477, height: 848)\n        }\n        uploadsPending {\n          id\n          ...Upload_QueryFragment\n          previewUrl: url(width: 477, height: 848)\n        }\n        photoStatus {\n          rejectReasonText\n        }\n    }\n": types.ProfileEditUser_QueryFragmentFragmentDoc,
    "\n  query ProfileVotePaid_Query {\n    invoiceUsersLast {\n      id\n      likesCount\n      starsCount\n      coins\n    }\n  }\n": types.ProfileVotePaid_QueryDocument,
    "\n  mutation ProfileInvoiceCreate_Mutation(\n    $userId: BigInt!\n    $likes: Int!\n  ) {\n    invoiceCreate(userId: $userId likes: $likes) {\n      link\n    }\n  }\n": types.ProfileInvoiceCreate_MutationDocument,
    "\n  fragment ProfileVote_QueryFragment on VoteConfig {\n    likePrice\n    likeAmounts\n  }\n": types.ProfileVote_QueryFragmentFragmentDoc,
    "\n  mutation Settings_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      appLang\n      hidden\n      countryCode\n      countryCodeAuto\n      detectedCountry\n    }\n  }\n": types.Settings_MutationDocument,
    "\n  fragment Settings_QueryFragment on Query {\n    user {\n      id\n      appLang\n      language_code\n      hidden\n      countryCode\n      countryCodeAuto\n      detectedCountry\n    }\n  }\n": types.Settings_QueryFragmentFragmentDoc,
    "\n  fragment Tabs_QueryFragment on Query {\n    ...Vote_QueryFragment\n    profile: user(id: $userId) {\n      id\n      hideProfileByRefLink\n      ...ProfileUser_QueryFragment\n    }\n    user {\n      id\n      ...ProfileEditUser_QueryFragment\n      ...WalletUser_QueryFragment\n      ...LeadersUser_QueryFragment\n    }\n  }\n": types.Tabs_QueryFragmentFragmentDoc,
    "\n  mutation Verification_Mutation(\n    $verificationUploadId: String!\n  ) {\n    userVerify(verificationUploadId: $verificationUploadId) {\n      id\n      haveVerificationPhoto\n      verified\n    }\n  }\n": types.Verification_MutationDocument,
    "\n  mutation VerificationRequired_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      gender\n      bdate\n    }\n  }\n": types.VerificationRequired_MutationDocument,
    "\n  fragment VerificationRequiredUser_QueryFragment on User {\n      id\n      gender\n      bdate\n  }\n": types.VerificationRequiredUser_QueryFragmentFragmentDoc,
    "\n  query Vote_Query($gender: UserGender) {\n    usersCompare(gender: $gender) {\n      ...VoteUser_QueryFragment\n    }\n    user {\n      id\n      voteGender\n    }\n  }\n": types.Vote_QueryDocument,
    "\n  fragment Vote_QueryFragment on Query {\n    ...Header_QueryFragment\n    user {\n      id\n      voteGender\n    }\n  }\n": types.Vote_QueryFragmentFragmentDoc,
    "\n  fragment VoteUser_QueryFragment on User {\n    ...ProfileUser_QueryFragment\n    id\n    name\n    age\n    verified\n    countryCode\n    uploads {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n  }\n": types.VoteUser_QueryFragmentFragmentDoc,
    "\n  mutation Vote_Mutation(\n    $userIds: [BigInt!]!\n  ) {\n    vote(userIds: $userIds) {\n      user {\n        id\n        coins\n      }\n      users {\n        ...VoteUser_QueryFragment\n        voteGender\n      }\n    }\n  }\n": types.Vote_MutationDocument,
    "\n  fragment WalletUser_QueryFragment on User {\n    ...ProfileUser_QueryFragment\n    id\n    starsCount\n    coins\n    tonAddress\n  }\n": types.WalletUser_QueryFragmentFragmentDoc,
    "\n  mutation Wallet_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      tonAddress\n    }\n  }\n": types.Wallet_MutationDocument,
    "\n    mutation UploadCreateMutation($data: [UploadCreateInput!]!) {\n      uploadsCreate(data: $data) {\n        id\n        signedUrl\n      }\n    }\n": types.UploadCreateMutationDocument,
    "\n  mutation UploadUpdateMutation($id: ObjectID! $data: UploadUpdateInput!) {\n    uploadUpdate(id: $id data: $data) {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n  }\n": types.UploadUpdateMutationDocument,
    "\n  fragment Upload_QueryFragment on Upload {\n      id\n      previewUrl: url(width: 477, height: 848)\n  }\n": types.Upload_QueryFragmentFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query App_Query($userId: BigInt) {\n    ...Tabs_QueryFragment\n    ...CreateAccount_QueryFragment\n    user {\n      id\n      gender\n      countryCode\n      appLang\n      language_code\n    }\n    voteConfig {\n      ...ProfileVote_QueryFragment\n    }\n    bonus {\n      ...ProfileBonus_QueryFragment\n    }\n  }\n"): (typeof documents)["\n  query App_Query($userId: BigInt) {\n    ...Tabs_QueryFragment\n    ...CreateAccount_QueryFragment\n    user {\n      id\n      gender\n      countryCode\n      appLang\n      language_code\n    }\n    voteConfig {\n      ...ProfileVote_QueryFragment\n    }\n    bonus {\n      ...ProfileBonus_QueryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAccount_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      gender\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAccount_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      gender\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CreateAccount_QueryFragment on Query {\n    user {\n      id\n      first_name\n      gender\n    }\n  }\n"): (typeof documents)["\n  fragment CreateAccount_QueryFragment on Query {\n    user {\n      id\n      first_name\n      gender\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Earn_Query {\n    tasks {\n      id\n      image\n      localizedTitle\n      localizedDescription\n      buttonType\n      buttonUrl\n      reward\n      startedReviewAt\n      completedAt\n      inReview\n    }\n  }\n"): (typeof documents)["\n  query Earn_Query {\n    tasks {\n      id\n      image\n      localizedTitle\n      localizedDescription\n      buttonType\n      buttonUrl\n      reward\n      startedReviewAt\n      completedAt\n      inReview\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Earn_Mutation(\n    $taskId: String!\n  ) {\n    taskComplete(taskId: $taskId) {\n      id\n      startedReviewAt\n      completedAt\n      inReview\n      updatedUser {\n        id\n        coins\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Earn_Mutation(\n    $taskId: String!\n  ) {\n    taskComplete(taskId: $taskId) {\n      id\n      startedReviewAt\n      completedAt\n      inReview\n      updatedUser {\n        id\n        coins\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Friends_Query {\n    friends {\n      ...ProfileUser_QueryFragment\n      ...FriendsUser_QueryFragment\n      id\n    }\n  }\n"): (typeof documents)["\n  query Friends_Query {\n    friends {\n      ...ProfileUser_QueryFragment\n      ...FriendsUser_QueryFragment\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FriendsUser_QueryFragment on User {\n        id\n        name\n        coins\n        coinsForRef\n        uploads {\n            id\n            faceUrl: url(size: 200, face: true)\n        }\n        ...ProfileUser_QueryFragment\n    }\n"): (typeof documents)["\n    fragment FriendsUser_QueryFragment on User {\n        id\n        name\n        coins\n        coinsForRef\n        uploads {\n            id\n            faceUrl: url(size: 200, face: true)\n        }\n        ...ProfileUser_QueryFragment\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment Header_QueryFragment on Query {\n      ...Settings_QueryFragment\n      user {\n        ...ProfileUser_QueryFragment\n        id\n        name\n        photo_url\n        coins\n        age\n        verified\n        hidden\n        uploads {\n          id\n          faceUrl: url(size: 200, face: true)\n        }\n        uploadsPending {\n          id\n        }\n        photoStatus {\n          rejectReasonText\n        }\n      }\n    }\n  "): (typeof documents)["\n    fragment Header_QueryFragment on Query {\n      ...Settings_QueryFragment\n      user {\n        ...ProfileUser_QueryFragment\n        id\n        name\n        photo_url\n        coins\n        age\n        verified\n        hidden\n        uploads {\n          id\n          faceUrl: url(size: 200, face: true)\n        }\n        uploadsPending {\n          id\n        }\n        photoStatus {\n          rejectReasonText\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment LeadersUser_QueryFragment on User {\n    id\n    gender\n  }\n"): (typeof documents)["\n  fragment LeadersUser_QueryFragment on User {\n    id\n    gender\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Leaders_Query($gender: UserGender, $orderBy: LeaderOrderBy!) {\n   leaders(gender: $gender, orderBy: $orderBy) {\n     id\n     isMe\n     ...LeaderItem_QueryFragment\n   }\n   user {\n    id\n    gender\n    starsCount\n    place\n    genderPlace\n    placeCoins\n    genderPlaceCoins\n    placeStars\n    genderPlaceStars\n    hidden\n    haveVerificationPhoto\n    ...LeaderItem_QueryFragment\n   }\n  }\n"): (typeof documents)["\n  query Leaders_Query($gender: UserGender, $orderBy: LeaderOrderBy!) {\n   leaders(gender: $gender, orderBy: $orderBy) {\n     id\n     isMe\n     ...LeaderItem_QueryFragment\n   }\n   user {\n    id\n    gender\n    starsCount\n    place\n    genderPlace\n    placeCoins\n    genderPlaceCoins\n    placeStars\n    genderPlaceStars\n    hidden\n    haveVerificationPhoto\n    ...LeaderItem_QueryFragment\n   }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment LeaderItem_QueryFragment on User {\n        ...ProfileUser_QueryFragment\n        ...VerificationRequiredUser_QueryFragment\n        ...ProfileEditUser_QueryFragment\n        id\n        photo_url\n        name\n        age\n        verified\n        countryCode\n        likesCount\n        coins\n        starsCount\n        isMe\n        uploads {\n          id\n          faceUrl: url(size: 200, face: true)\n        }\n        uploadsPending {\n          id\n        }\n        photoStatus {\n          rejectReasonText\n        }\n    }\n"): (typeof documents)["\n    fragment LeaderItem_QueryFragment on User {\n        ...ProfileUser_QueryFragment\n        ...VerificationRequiredUser_QueryFragment\n        ...ProfileEditUser_QueryFragment\n        id\n        photo_url\n        name\n        age\n        verified\n        countryCode\n        likesCount\n        coins\n        starsCount\n        isMe\n        uploads {\n          id\n          faceUrl: url(size: 200, face: true)\n        }\n        uploadsPending {\n          id\n        }\n        photoStatus {\n          rejectReasonText\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProfileUser_QueryFragment on User {\n    ...ProfileEditUser_QueryFragment\n    ...VerificationRequiredUser_QueryFragment\n    id\n    name\n    photo_url\n    age\n    countryCode\n    gender\n    instagram\n    verified\n    isMe\n    likesCount\n    starsCount\n    haveVerificationPhoto\n    uploads {\n      id\n      previewUrl: url(width: 477, height: 848)\n      faceUrl: url(size: 200, face: true)\n    }\n    uploadsPending {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n    photoStatus {\n      rejectReasonText\n    }\n  }\n"): (typeof documents)["\n  fragment ProfileUser_QueryFragment on User {\n    ...ProfileEditUser_QueryFragment\n    ...VerificationRequiredUser_QueryFragment\n    id\n    name\n    photo_url\n    age\n    countryCode\n    gender\n    instagram\n    verified\n    isMe\n    likesCount\n    starsCount\n    haveVerificationPhoto\n    uploads {\n      id\n      previewUrl: url(width: 477, height: 848)\n      faceUrl: url(size: 200, face: true)\n    }\n    uploadsPending {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n    photoStatus {\n      rejectReasonText\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProfileBonus_QueryFragment on Bonus {\n    id\n    invite\n    invitePremium\n  }\n"): (typeof documents)["\n  fragment ProfileBonus_QueryFragment on Bonus {\n    id\n    invite\n    invitePremium\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ProfileEdit_Mutation($name: String!, $uploadIds: [String!]!, $instagram: String) {\n    userProfileSave(name: $name, uploadIds: $uploadIds, instagram: $instagram) {\n      id\n      name\n      instagram\n      uploads {\n        id\n        previewUrl: url(width: 477, height: 848)\n      }\n      uploadsPending {\n        id\n        previewUrl: url(width: 477, height: 848)\n      }\n      photoStatus {\n        rejectReasonText\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ProfileEdit_Mutation($name: String!, $uploadIds: [String!]!, $instagram: String) {\n    userProfileSave(name: $name, uploadIds: $uploadIds, instagram: $instagram) {\n      id\n      name\n      instagram\n      uploads {\n        id\n        previewUrl: url(width: 477, height: 848)\n      }\n      uploadsPending {\n        id\n        previewUrl: url(width: 477, height: 848)\n      }\n      photoStatus {\n        rejectReasonText\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment ProfileEditUser_QueryFragment on User {\n        ...VerificationRequiredUser_QueryFragment\n        id\n        name\n        instagram\n        haveVerificationPhoto\n        verified\n        uploads {\n          id\n          ...Upload_QueryFragment\n          previewUrl: url(width: 477, height: 848)\n        }\n        uploadsPending {\n          id\n          ...Upload_QueryFragment\n          previewUrl: url(width: 477, height: 848)\n        }\n        photoStatus {\n          rejectReasonText\n        }\n    }\n"): (typeof documents)["\n    fragment ProfileEditUser_QueryFragment on User {\n        ...VerificationRequiredUser_QueryFragment\n        id\n        name\n        instagram\n        haveVerificationPhoto\n        verified\n        uploads {\n          id\n          ...Upload_QueryFragment\n          previewUrl: url(width: 477, height: 848)\n        }\n        uploadsPending {\n          id\n          ...Upload_QueryFragment\n          previewUrl: url(width: 477, height: 848)\n        }\n        photoStatus {\n          rejectReasonText\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ProfileVotePaid_Query {\n    invoiceUsersLast {\n      id\n      likesCount\n      starsCount\n      coins\n    }\n  }\n"): (typeof documents)["\n  query ProfileVotePaid_Query {\n    invoiceUsersLast {\n      id\n      likesCount\n      starsCount\n      coins\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ProfileInvoiceCreate_Mutation(\n    $userId: BigInt!\n    $likes: Int!\n  ) {\n    invoiceCreate(userId: $userId likes: $likes) {\n      link\n    }\n  }\n"): (typeof documents)["\n  mutation ProfileInvoiceCreate_Mutation(\n    $userId: BigInt!\n    $likes: Int!\n  ) {\n    invoiceCreate(userId: $userId likes: $likes) {\n      link\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ProfileVote_QueryFragment on VoteConfig {\n    likePrice\n    likeAmounts\n  }\n"): (typeof documents)["\n  fragment ProfileVote_QueryFragment on VoteConfig {\n    likePrice\n    likeAmounts\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Settings_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      appLang\n      hidden\n      countryCode\n      countryCodeAuto\n      detectedCountry\n    }\n  }\n"): (typeof documents)["\n  mutation Settings_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      appLang\n      hidden\n      countryCode\n      countryCodeAuto\n      detectedCountry\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Settings_QueryFragment on Query {\n    user {\n      id\n      appLang\n      language_code\n      hidden\n      countryCode\n      countryCodeAuto\n      detectedCountry\n    }\n  }\n"): (typeof documents)["\n  fragment Settings_QueryFragment on Query {\n    user {\n      id\n      appLang\n      language_code\n      hidden\n      countryCode\n      countryCodeAuto\n      detectedCountry\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Tabs_QueryFragment on Query {\n    ...Vote_QueryFragment\n    profile: user(id: $userId) {\n      id\n      hideProfileByRefLink\n      ...ProfileUser_QueryFragment\n    }\n    user {\n      id\n      ...ProfileEditUser_QueryFragment\n      ...WalletUser_QueryFragment\n      ...LeadersUser_QueryFragment\n    }\n  }\n"): (typeof documents)["\n  fragment Tabs_QueryFragment on Query {\n    ...Vote_QueryFragment\n    profile: user(id: $userId) {\n      id\n      hideProfileByRefLink\n      ...ProfileUser_QueryFragment\n    }\n    user {\n      id\n      ...ProfileEditUser_QueryFragment\n      ...WalletUser_QueryFragment\n      ...LeadersUser_QueryFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Verification_Mutation(\n    $verificationUploadId: String!\n  ) {\n    userVerify(verificationUploadId: $verificationUploadId) {\n      id\n      haveVerificationPhoto\n      verified\n    }\n  }\n"): (typeof documents)["\n  mutation Verification_Mutation(\n    $verificationUploadId: String!\n  ) {\n    userVerify(verificationUploadId: $verificationUploadId) {\n      id\n      haveVerificationPhoto\n      verified\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VerificationRequired_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      gender\n      bdate\n    }\n  }\n"): (typeof documents)["\n  mutation VerificationRequired_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      gender\n      bdate\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment VerificationRequiredUser_QueryFragment on User {\n      id\n      gender\n      bdate\n  }\n"): (typeof documents)["\n  fragment VerificationRequiredUser_QueryFragment on User {\n      id\n      gender\n      bdate\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Vote_Query($gender: UserGender) {\n    usersCompare(gender: $gender) {\n      ...VoteUser_QueryFragment\n    }\n    user {\n      id\n      voteGender\n    }\n  }\n"): (typeof documents)["\n  query Vote_Query($gender: UserGender) {\n    usersCompare(gender: $gender) {\n      ...VoteUser_QueryFragment\n    }\n    user {\n      id\n      voteGender\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Vote_QueryFragment on Query {\n    ...Header_QueryFragment\n    user {\n      id\n      voteGender\n    }\n  }\n"): (typeof documents)["\n  fragment Vote_QueryFragment on Query {\n    ...Header_QueryFragment\n    user {\n      id\n      voteGender\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment VoteUser_QueryFragment on User {\n    ...ProfileUser_QueryFragment\n    id\n    name\n    age\n    verified\n    countryCode\n    uploads {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n  }\n"): (typeof documents)["\n  fragment VoteUser_QueryFragment on User {\n    ...ProfileUser_QueryFragment\n    id\n    name\n    age\n    verified\n    countryCode\n    uploads {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Vote_Mutation(\n    $userIds: [BigInt!]!\n  ) {\n    vote(userIds: $userIds) {\n      user {\n        id\n        coins\n      }\n      users {\n        ...VoteUser_QueryFragment\n        voteGender\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Vote_Mutation(\n    $userIds: [BigInt!]!\n  ) {\n    vote(userIds: $userIds) {\n      user {\n        id\n        coins\n      }\n      users {\n        ...VoteUser_QueryFragment\n        voteGender\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment WalletUser_QueryFragment on User {\n    ...ProfileUser_QueryFragment\n    id\n    starsCount\n    coins\n    tonAddress\n  }\n"): (typeof documents)["\n  fragment WalletUser_QueryFragment on User {\n    ...ProfileUser_QueryFragment\n    id\n    starsCount\n    coins\n    tonAddress\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Wallet_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      tonAddress\n    }\n  }\n"): (typeof documents)["\n  mutation Wallet_Mutation(\n    $data: UserSaveInput!\n  ) {\n    userSave(data: $data) {\n      id\n      tonAddress\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UploadCreateMutation($data: [UploadCreateInput!]!) {\n      uploadsCreate(data: $data) {\n        id\n        signedUrl\n      }\n    }\n"): (typeof documents)["\n    mutation UploadCreateMutation($data: [UploadCreateInput!]!) {\n      uploadsCreate(data: $data) {\n        id\n        signedUrl\n      }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UploadUpdateMutation($id: ObjectID! $data: UploadUpdateInput!) {\n    uploadUpdate(id: $id data: $data) {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n  }\n"): (typeof documents)["\n  mutation UploadUpdateMutation($id: ObjectID! $data: UploadUpdateInput!) {\n    uploadUpdate(id: $id data: $data) {\n      id\n      previewUrl: url(width: 477, height: 848)\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment Upload_QueryFragment on Upload {\n      id\n      previewUrl: url(width: 477, height: 848)\n  }\n"): (typeof documents)["\n  fragment Upload_QueryFragment on Upload {\n      id\n      previewUrl: url(width: 477, height: 848)\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;