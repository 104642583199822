export default {
  g: {
    flag: "🇿🇦",
    langName: "Afrikaans",
    langNameNative: "Afrikaans",
    continue: "Gaan voort",
    save: "Stoor",
    retry: "Probeer weer",
    loading: "Laai...",
    genderMany: {
      all: "Almal",
      male: "Manne",
      female: "Vroue",
    },
  },
  CreateAccount: {
    slogan: "Plek waar drome in oorwinnings verander!",
    gender: {
      title: "Hallo {{name}}, Jy is...",
      male: "Manlik",
      female: "Vroulik",
    },
  },
  Settings: {
    title: "Instellings",
    language: "Taal",
    auto: "Outomaties",
    country: "Land",
    hideProfile: {
      label: "Versteek profiel",
      description:
        "Jou profiel sal vir almal versteek word, asof jy dit uitgevee het.",
    },
    profileHidden:
      "Jou profiel is versteek en nie sigbaar vir ander gebruikers nie.",
  },
  Tabs: {
    vote: "Stem",
    contest: "Kompetisie",
    friends: "Vriende",
    earn: "Verdien",
    wallet: "Beursie",
  },
  Vote: {
    whoIsBetter: "Wie hou jy meer van?",
    votingForLeader: "Stem vir die leier",
    noUsers: {
      title: "🚀 Dit is al vir vandag",
      description:
        "Nooi vriende om voort te gaan met stemme en munte te verdien",
      inviteFriends: "Nooi Vriende",
    },
  },
  Leaders: {
    rewards: "Belonings",
    comingSoon: "Binnekort beskikbaar",
    prizePool: "Prys poel",
    moreInfo: "Meer inligting",
    contest: "Kompetisie",
    richest: "Rykste",
    sponsors: "Borge",
    description: {
      likes:
        "Nooi vriende en deel jou profiel om meer stemme te kry en jou kanse om te wen te verhoog.",
      coins:
        "Voltooi take, stem vir ander deelnemers en nooi vriende om meer munte te verdien en jou posisie te versterk.",
      stars:
        "Stem vir profiele op die profielbladsy om sterre te ontvang en in die ranglys te styg.",
    },
  },
  PrizePool: {
    text: `<b>Pryswenner plekke</b>:
<b>1ste plek</b>: 50% USD en 10,000 munte
<b>2de plek</b>: 30% USD en 3,000 munte
<b>3de plek</b>: 20% USD en 2,000 munte
<b>4de-10de plekke</b>: 1,000 munte elk
<b>11de-50ste plekke</b>: 500 munte elk
<b>51ste-100ste plekke</b>: 250 munte elk

<b>Die prys poel</b> verhoog wanneer iemand vir jou op jou profielbladsy stem.

<b>Voorwaardes</b>:
Alle profiele wat 'n foto oplaai en die take voltooi, kom in aanmerking om aan die kompetisie deel te neem.

<b>Sluitingsdatum</b>:
{{endDate}}

<b>Beloningsuitbetaling</b>:
Jy kan jou beloning in <b>TON</b> ontvang — die kripto-geldeenheid ontwikkel deur Telegram.`,
  },
  LeadersItem: {
    join: "Sluit aan",
  },
  Friends: {
    title: "Nooi vriende!",
    description: "Jy en jou vriend sal bonusse ontvang",
    bonus: {
      profit: "<coins>+{{count}}</coins> vir jou en jou vriend",
      invite: {
        title: "Nooi vriend",
      },
      inviteWithPremium: {
        title: "Nooi vriend",
        description: "met Telegram Premium",
      },
    },
    list: {
      title: "Lys van jou vriende",
    },
    inviteFriend: "Nooi vriend",
    inviteText: `Sluit by my aan, neem deel aan die stem vir die mooiste ouens en meisies, en ontvang waardevolle pryse!

🌟 +{{coins}} munte as 'n eerste geskenk
🔥 +{{coinsPremium}} munte as jy Telegram Premium het

🏆 Verdien munte vir elke stem en kies die aantreklikste deelnemers. Wil jy meer hê? Neem self deel en kompeteer vir waardevolle pryse!

Moet dit nie misloop nie, word deel van ons sterre-gemeenskap en wen!`,
    linkCopied: "Skakel gekopieer",
  },
  Earn: {
    title: "Verdien meer munte",
    tasksList: "Take lys",
    check: "Kontroleer",
    inReview: "In hersiening",
    subscribe: "Teken in",
    participate: "Neem deel",
    invite: "Nooi",
    verify: "Verifieer",
    taskCompleted: "Taak voltooi",
  },
  Wallet: {
    title: "Kry belonings in TON",
    description:
      "Voltooi uitdagings om TON te verdien, 'n kripto-muntstuk ontwikkel deur Telegram",
    rewards: "Belonings",
    withdrawal: "Onttrekking",
    walletConnected: "Jou TON-beursie is gekoppel",
    walletDescription: "Meer geleenthede sal binnekort beskikbaar wees",
  },
  VerificationRequired: {
    title: "Verifikasie Vereis",
    description:
      "Om aan die kompetisie deel te neem, moet jy jou rekening verifieer.",
    bdate: {
      title: "Jou geboortedatum",
    },
  },
  Verification: {
    title: "Verifikasie",
    hint: "Hou jou gesig binne die ovaal",
    secureHint: "Niemand sal hierdie foto sien nie",
    success: "Jy het die verifikasie suksesvol voltooi",
    goToApp: "Gaan na toepassing",
    verification: "Verifikasie...",
    cameraAccessRequired: "Gee asseblief kamera toegang om voort te gaan",
    gettingAccessToCamer: "Kry toegang tot die kamera...",
    cameraAccessNotAllowed: "Kamera toegang deur die gebruiker geweier.",
    cameraAccessError: "Kamera toegang fout",
    loadingHint: "Dit kan 'n rukkie neem. Wees asseblief geduldig.",
  },
  Profile: {
    vote: "Stem",
    askVote: "Vra om te Stem",
    inviteText: `Hallo! 😊 Ondersteun my asseblief met jou stem in die TON Stars-kompetisie! Elke stem is van kardinale belang en help my om nader aan die wen van waardevolle pryse te kom.

🌟 +{{coins}} munte as 'n eerste geskenk
🔥 +{{coinsPremium}} munte as jy Telegram Premium het

Stem vir my profiel hier: {{url}}

Dankie vir jou ondersteuning! 🌟`,
    joinContest: "Neem deel aan kompetisie",
    gender: {
      male: "Manlik",
      female: "Vroulik",
    },
    photos: {
      inReview: "Jou nuwe foto's is onder hersiening.\nDit kan 'n rukkie neem.",
      rejected:
        "Een of meer foto's is afgekeur vir die volgende rede:\n{{reason}}.\n\nLaai asseblief ander foto's op en probeer weer.",
    },
  },
  ProfileEdit: {
    title: "Jou Profiel",
    name: {
      label: "Jou Naam",
      placeholder: "Voer jou naam in",
    },
    instagram: {
      description: "Instagram-profiel moet openbaar wees",
    },
    photos: {
      verificationPhotoUploaded:
        "Jou verifikasiefoto is opgelaai, niemand sal dit sien nie.",
      label: "Jou Foto's",
      mainPhoto: "Hooffoto",
      error:
        "Een of meer foto's is nie geskik nie, maak asseblief 'n ander keuse.",
      hint: "Kies jou indrukwekkendste foto's, waar daar geen ander mense is nie, en jou gesig duidelik en uitdrukkingsvol sigbaar is.",
      inReview: "Jou nuwe foto's is onder hersiening.\nDit kan 'n rukkie neem.",
      rejected:
        "Een of meer foto's is afgekeur vir die volgende rede:\n{{reason}}.\n\nLaai asseblief ander foto's op en probeer weer.",
    },
    submit: "Neem Deel",
    photosUploading: "Foto word opgelaai...",
    success: "Jou profiel is opgedateer",
    errors: {
      name: "Verskaf jou naam",
      photos: "Kies asseblief 3 foto's",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Hooffoto",
    noFace: "Jou gesig word nie opgespoor nie",
    otherPeople: "Die foto moet net jou gesig bevat",
  },
};
