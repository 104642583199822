export default {
  g: {
    flag: "🇨🇿",
    langName: "Czech",
    langNameNative: "Čeština",
    continue: "Pokračovat",
    save: "Uložit",
    retry: "Zkusit znovu",
    loading: "Načítání...",
    genderMany: {
      all: "Všichni",
      male: "Muži",
      female: "Ženy",
    },
  },
  CreateAccount: {
    slogan: "Místo, kde se sny mění ve vítězství!",
    gender: {
      title: "Ahoj {{name}}, Ty jsi...",
      male: "Muž",
      female: "Žena",
    },
  },
  Settings: {
    title: "Nastavení",
    language: "Jazyk",
    auto: "Automaticky",
    country: "Země",
    hideProfile: {
      label: "Skrýt profil",
      description: "Váš profil bude skrytý před všemi, jako byste ho smazali.",
    },
    profileHidden:
      "Váš profil je skrytý a není viditelný pro ostatní uživatele.",
  },
  Tabs: {
    vote: "Hlasovat",
    contest: "Soutěž",
    friends: "Přátelé",
    earn: "Vydělat",
    wallet: "Peněženka",
  },
  Vote: {
    whoIsBetter: "Kdo se vám líbí víc?",
    votingForLeader: "Hlasování pro lídra",
    noUsers: {
      title: "🚀 To je pro dnešek vše",
      description:
        "Pozvěte přátele, aby mohli pokračovat v hlasování a vydělávat mince",
      inviteFriends: "Pozvat přátele",
    },
  },
  Leaders: {
    rewards: "Odměny",
    comingSoon: "Již brzy",
    prizePool: "Cenový fond",
    moreInfo: "Více informací",
    contest: "Soutěž",
    richest: "Nejbohatší",
    sponsors: "Sponzoři",
    description: {
      likes:
        "Pozvěte přátele a sdílejte svůj profil, abyste získali více hlasů a zvýšili své šance na výhru.",
      coins:
        "Plňte úkoly, hlasujte pro ostatní účastníky a zvěte přátele, abyste získali více mincí a posílili své postavení.",
      stars:
        "Hlasujte pro profily na stránce profilu, abyste získali hvězdy a stoupali v žebříčku.",
    },
  },
  PrizePool: {
    text: `<b>Cenová místa</b>:
<b>1. místo</b>: 50% USD a 10,000 mincí
<b>2. místo</b>: 30% USD a 3,000 mincí
<b>3. místo</b>: 20% USD a 2,000 mincí
<b>4.-10. místa</b>: každý po 1,000 mincí
<b>11.-50. místa</b>: každý po 500 mincí
<b>51.-100. místa</b>: každý po 250 mincí

<b>Cenový fond</b> se zvyšuje, když někdo hlasuje pro vás na vaší stránce profilu.

<b>Podmínky</b>:
Všechny profily, které nahrají fotografii a splní úkoly, jsou způsobilé k účasti v soutěži.

<b>Datum ukončení</b>:
{{endDate}}

<b>Výplata odměn</b>:
Odměnu můžete obdržet v <b>TON</b> — kryptoměně vyvinuté společností Telegram.`,
  },
  LeadersItem: {
    join: "Připojit se",
  },
  Friends: {
    title: "Pozvěte přátele!",
    description: "Vy a váš přítel získáte bonusy",
    bonus: {
      profit: "<coins>+{{count}}</coins> pro vás a vašeho přítele",
      invite: {
        title: "Pozvat přítele",
      },
      inviteWithPremium: {
        title: "Pozvat přítele",
        description: "s Telegram Premium",
      },
    },
    list: {
      title: "Seznam vašich přátel",
    },
    inviteFriend: "Pozvat přítele",
    inviteText: `Přidejte se ke mně, hlasujte pro nejkrásnější chlapce a dívky a získejte hodnotné ceny!
  
  🌟 +{{coins}} mincí jako první dárek
  🔥 +{{coinsPremium}} mincí pokud máte Telegram Premium
  
  🏆 Získejte mince za každý hlas a vyberte si nejatraktivnější účastníky. Chcete více? Zúčastněte se sami a soutěžte o hodnotné ceny!
  
  Nepropásněte svou šanci, staňte se součástí naší hvězdné komunity a vyhrajte!`,
    linkCopied: "Odkaz zkopírován",
  },
  Earn: {
    title: "Vydělejte více mincí",
    tasksList: "Seznam úkolů",
    check: "Zkontrolovat",
    inReview: "V přezkoumání",
    subscribe: "Přihlásit se k odběru",
    participate: "Zúčastnit se",
    invite: "Pozvat",
    verify: "Ověřit",
    taskCompleted: "Úkol dokončen",
  },
  Wallet: {
    title: "Získejte odměny v TON",
    description: "Splňte výzvy a získejte TON, kryptoměnu vyvinutou Telegramem",
    rewards: "Odměny",
    withdrawal: "Výběr",
    walletConnected: "Vaše peněženka TON je připojena",
    walletDescription: "Brzy budou k dispozici další příležitosti",
  },
  VerificationRequired: {
    title: "Je vyžadováno ověření",
    description: "Pro účast v soutěži musíte ověřit svůj účet.",
    bdate: {
      title: "Datum narození",
    },
  },
  Verification: {
    title: "Ověření",
    hint: "Držte obličej uvnitř oválu",
    secureHint: "Nikdo tuto fotku neuvidí",
    success: "Úspěšně jste dokončili ověření",
    goToApp: "Přejít do aplikace",
    verification: "Ověřování...",
    cameraAccessRequired:
      "Prosím, udělte přístup ke kameře, aby bylo možné pokračovat",
    gettingAccessToCamer: "Získávání přístupu ke kameře...",
    cameraAccessNotAllowed: "Přístup ke kameře byl uživatelem zamítnut.",
    cameraAccessError: "Chyba při přístupu ke kameře",
    loadingHint: "To může chvíli trvat. Prosím, buďte trpěliví.",
  },
  Profile: {
    vote: "Hlasovat",
    askVote: "Požádat o hlasování",
    inviteText: `Ahoj! 😊 Podpoř mě prosím svým hlasem v soutěži TON Stars! Každý hlas je důležitý a pomáhá mi přiblížit se k výhře cenných cen.

🌟 +{{coins}} mincí jako první dárek
🔥 +{{coinsPremium}} mincí, pokud máte Telegram Premium

Hlasujte pro můj profil zde: {{url}}

Děkuji za vaši podporu! 🌟`,
    joinContest: "Připojit se ke konkursu",
    gender: {
      male: "Muž",
      female: "Žena",
    },
    photos: {
      inReview:
        "Vaše nové fotografie jsou v recenzním řízení.\nTo může nějakou dobu trvat.",
      rejected:
        "Jedna nebo více fotografií byly zamítnuty z následujícího důvodu:\n{{reason}}.\n\nNahrajte prosím jiné fotografie a zkuste to znovu.",
    },
  },
  ProfileEdit: {
    title: "Váš profil",
    name: {
      label: "Vaše jméno",
      placeholder: "Zadejte své jméno",
    },
    instagram: {
      description: "Profil na Instagramu musí být veřejný",
    },
    photos: {
      verificationPhotoUploaded:
        "Vaše ověřovací fotografie byla nahrána, nikdo ji neuvidí.",
      label: "Vaše fotografie",
      mainPhoto: "Hlavní fotografie",
      error: "Jedna nebo více fotografií není vhodná, prosím, vyberte jiné.",
      hint: "Vyberte své nejpůsobivější fotografie, kde nejsou žádní jiní lidé a váš obličej je jasně a výrazeně viditelný.",
      inReview:
        "Vaše nové fotografie jsou v recenzním řízení.\nTo může nějakou dobu trvat.",
      rejected:
        "Jedna nebo více fotografií byly zamítnuty z následujícího důvodu:\n{{reason}}.\n\nNahrajte prosím jiné fotografie a zkuste to znovu.",
    },
    submit: "Připojit se ke konkursu",
    photosUploading: "Nahrávání fotografie...",
    success: "Váš profil byl aktualizován",
    errors: {
      name: "Uveďte své jméno",
      photos: "Vyberte prosím 3 fotografie",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Hlavní fotografie",
    noFace: "Váš obličej nebyl rozpoznán",
    otherPeople: "Fotografie by měla obsahovat pouze váš obličej",
  },
};
