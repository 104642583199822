export default {
  g: {
    flag: "🇵🇱",
    langName: "Polish",
    langNameNative: "Polski",
    continue: "Kontynuuj",
    save: "Zapisz",
    retry: "Spróbuj ponownie",
    loading: "Ładowanie...",
    genderMany: {
      all: "Wszyscy",
      male: "Mężczyźni",
      female: "Kobiety",
    },
  },
  CreateAccount: {
    slogan: "Miejsce, gdzie marzenia zmieniają się w zwycięstwa!",
    gender: {
      title: "Cześć {{name}}, Ty jesteś...",
      male: "Mężczyzna",
      female: "Kobieta",
    },
  },
  Settings: {
    title: "Ustawienia",
    language: "Język",
    auto: "Automatycznie",
    country: "Kraj",
    hideProfile: {
      label: "Ukryj profil",
      description:
        "Twój profil zostanie ukryty przed wszystkimi, jakbyś go usunął.",
    },
    profileHidden:
      "Twój profil jest ukryty i niewidoczny dla innych użytkowników.",
  },
  Tabs: {
    vote: "Głosuj",
    contest: "Konkurs",
    friends: "Przyjaciele",
    earn: "Zarabiaj",
    wallet: "Portfel",
  },
  Vote: {
    whoIsBetter: "Kogo lubisz bardziej?",
    votingForLeader: "Głosowanie na lidera",
    noUsers: {
      title: "🚀 To wszystko na dziś",
      description:
        "Zaproś znajomych, aby kontynuować głosowanie i zdobywać monety",
      inviteFriends: "Zaproś Znajomych",
    },
  },
  Leaders: {
    rewards: "Nagrody",
    comingSoon: "Wkrótce",
    prizePool: "Pula Nagród",
    moreInfo: "Więcej informacji",
    contest: "Konkurs",
    richest: "Najbogatsi",
    sponsors: "Sponsorzy",
    description: {
      likes:
        "Zapraszaj znajomych i udostępniaj swój profil, aby zdobyć więcej głosów i zwiększyć swoje szanse na wygraną.",
      coins:
        "Wykonuj zadania, głosuj na innych uczestników i zapraszaj znajomych, aby zdobywać więcej monet i wzmacniać swoją pozycję.",
      stars:
        "Głosuj na profile na stronie profilu, aby otrzymywać gwiazdki i wspinać się w rankingu.",
    },
  },
  PrizePool: {
    text: `<b>Miejsca nagrodzone</b>:
<b>1. miejsce</b>: 50% USD i 10,000 monet
<b>2. miejsce</b>: 30% USD i 3,000 monet
<b>3. miejsce</b>: 20% USD i 2,000 monet
<b>4.-10. miejsca</b>: 1,000 monet każde
<b>11.-50. miejsca</b>: 500 monet każde
<b>51.-100. miejsca</b>: 250 monet każde

<b>Pula nagród</b> rośnie, gdy ktoś odda głos na Twój profil.

<b>Warunki</b>:
Wszystkie profile, które dodają zdjęcie i wykonują zadania, są uprawnione do udziału w konkursie.

<b>Data zakończenia</b>:
{{endDate}}

<b>Wypłata nagród</b>:
Możesz otrzymać swoją nagrodę w <b>TON</b> — kryptowalucie opracowanej przez Telegram.`,
  },
  LeadersItem: {
    join: "Dołącz",
  },
  Friends: {
    title: "Zaproś przyjaciół!",
    description: "Ty i twój przyjaciel otrzymacie bonusy",
    bonus: {
      profit: "<coins>+{{count}}</coins> dla ciebie i twojego przyjaciela",
      invite: {
        title: "Zaproś przyjaciela",
      },
      inviteWithPremium: {
        title: "Zaproś przyjaciela",
        description: "z Telegram Premium",
      },
    },
    list: {
      title: "Lista twoich przyjaciół",
    },
    inviteFriend: "Zaproś przyjaciela",
    inviteText: `Dołącz do mnie, głosuj na najpiękniejszych chłopców i dziewczyny i wygraj cenne nagrody!
  
  🌟 +{{coins}} monety jako pierwszy prezent
  🔥 +{{coinsPremium}} monety, jeśli masz Telegram Premium
  
  🏆 Zarabiaj monety za każdy głos i wybieraj najbardziej atrakcyjnych uczestników. Chcesz więcej? Weź udział sam i rywalizuj o cenne nagrody!
  
  Nie przegap swojej szansy, dołącz do naszej społeczności gwiazd i wygraj!`,
    linkCopied: "Link skopiowany",
  },
  Earn: {
    title: "Zarabiaj więcej monet",
    tasksList: "Lista zadań",
    check: "Sprawdź",
    inReview: "Weryfikacja",
    subscribe: "Subskrybuj",
    participate: "Weź udział",
    invite: "Zaproś",
    verify: "Zweryfikuj",
    taskCompleted: "Zadanie ukończone",
  },
  Wallet: {
    title: "Odbierz nagrody w TON",
    description:
      "Ukończ wyzwania, aby zdobyć TON, kryptowalutę opracowaną przez Telegram",
    rewards: "Nagrody",
    withdrawal: "Wypłata",
    walletConnected: "Twoj portfel TON jest podłączony",
    walletDescription: "Wkrótce dostępnych będzie więcej możliwości",
  },
  VerificationRequired: {
    title: "Wymagana weryfikacja",
    description:
      "Aby wziąć udział w konkursie, musisz zweryfikować swoje konto.",
    bdate: {
      title: "Data urodzenia",
    },
  },
  Verification: {
    title: "Weryfikacja",
    hint: "Trzymaj twarz wewnątrz owalu",
    secureHint: "Nikt nie zobaczy tego zdjęcia",
    success: "Pomyślnie ukończyłeś weryfikację",
    goToApp: "Przejdź do aplikacji",
    verification: "Weryfikacja...",
    cameraAccessRequired:
      "Proszę pozwolić na dostęp do kamery, aby kontynuować",
    gettingAccessToCamer: "Uzyskiwanie dostępu do kamery...",
    cameraAccessNotAllowed:
      "Dostęp do kamery został odmówiony przez użytkownika.",
    cameraAccessError: "Błąd dostępu do kamery",
    loadingHint: "To może potrwać trochę czasu. Proszę być cierpliwym.",
  },
  Profile: {
    vote: "Głosuj",
    askVote: "Poproś o Głos",
    inviteText: `Cześć! 😊 Proszę, wesprzyj mnie swoim głosem w konkursie TON Stars! Każdy głos jest kluczowy i pomaga mi zbliżyć się do wygrania cennych nagród.

🌟 +{{coins}} monet jako pierwszy prezent
🔥 +{{coinsPremium}} monet, jeśli masz Telegram Premium

Głosuj na mój profil tutaj: {{url}}

Dziękuję za wsparcie! 🌟`,
    joinContest: "Dołącz do konkursu",
    gender: {
      male: "Męski",
      female: "Żeński",
    },
    photos: {
      inReview:
        "Twoje nowe zdjęcia są w trakcie przeglądu.\nTo może potrwać trochę czasu.",
      rejected:
        "Jedno lub więcej zdjęć zostało odrzuconych z następującego powodu:\n{{reason}}.\n\nProszę wgrać inne zdjęcia i spróbować ponownie.",
    },
  },
  ProfileEdit: {
    title: "Twój Profil",
    name: {
      label: "Twoje Imię",
      placeholder: "Wpisz swoje imię",
    },
    instagram: {
      description: "Profil na Instagramie musi być publiczny",
    },
    photos: {
      verificationPhotoUploaded:
        "Twoje zdjęcie weryfikacyjne zostało przesłane, nikt go nie zobaczy.",
      label: "Twoje zdjęcia",
      mainPhoto: "Główne zdjęcie",
      error: "Jedno lub więcej zdjęć nie jest odpowiednie, proszę wybrać inne.",
      hint: "Wybierz swoje najbardziej imponujące zdjęcia, na których nie ma innych ludzi, a twoja twarz jest wyraźnie widoczna.",
      inReview:
        "Twoje nowe zdjęcia są w trakcie przeglądu.\nTo może potrwać trochę czasu.",
      rejected:
        "Jedno lub więcej zdjęć zostało odrzuconych z następującego powodu:\n{{reason}}.\n\nProszę wgrać inne zdjęcia i spróbować ponownie.",
    },
    submit: "Dołącz do konkursu",
    photosUploading: "Wgrywanie zdjęcia...",
    success: "Twój profil został zaktualizowany",
    errors: {
      name: "Podaj swoje imię",
      photos: "Proszę wybrać 3 zdjęcia",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Główne zdjęcie",
    noFace: "Twoja twarz nie została rozpoznana",
    otherPeople: "Zdjęcie powinno przedstawiać tylko twoją twarz",
  },
};
