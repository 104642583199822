export default {
  g: {
    flag: "🇫🇷",
    langName: "French",
    langNameNative: "Français",
    continue: "Continuer",
    save: "Enregistrer",
    retry: "Réessayer",
    loading: "Chargement...",
    genderMany: {
      all: "Tous",
      male: "Hommes",
      female: "Femmes",
    },
  },
  CreateAccount: {
    slogan: "L'endroit où les rêves deviennent des victoires !",
    gender: {
      title: "Bonjour {{name}}, Vous êtes...",
      male: "Homme",
      female: "Femme",
    },
  },
  Settings: {
    title: "Paramètres",
    language: "Langue",
    auto: "Automatique",
    country: "Pays",
    hideProfile: {
      label: "Masquer le profil",
      description:
        "Votre profil sera caché de tous, comme si vous l'aviez supprimé.",
    },
    profileHidden:
      "Votre profil est masqué et n'est pas visible par les autres utilisateurs.",
  },
  Tabs: {
    vote: "Vote",
    contest: "Concours",
    friends: "Amis",
    earn: "Gagner",
    wallet: "Portefeuille",
  },
  Vote: {
    whoIsBetter: "Qui préfères-tu?",
    votingForLeader: "Vote pour le leader",
    noUsers: {
      title: "🚀 C'est tout pour aujourd'hui",
      description:
        "Invitez des amis pour continuer à voter et gagner des pièces",
      inviteFriends: "Inviter des amis",
    },
  },
  Leaders: {
    rewards: "Récompenses",
    comingSoon: "Bientôt disponible",
    prizePool: "Cagnotte",
    moreInfo: "Plus d'infos",
    contest: "Concours",
    richest: "Les plus riches",
    sponsors: "Sponsors",
    description: {
      likes:
        "Invitez des amis et partagez votre profil pour obtenir plus de votes et augmenter vos chances de gagner.",
      coins:
        "Complétez des tâches, votez pour d'autres participants et invitez des amis pour gagner plus de pièces et renforcer votre position.",
      stars:
        "Votez pour des profils sur la page de profil pour recevoir des étoiles et monter dans les classements.",
    },
  },
  PrizePool: {
    text: `<b>Places gagnantes</b>:
<b>1ère place</b>: 50% USD et 10,000 pièces
<b>2ème place</b>: 30% USD et 3,000 pièces
<b>3ème place</b>: 20% USD et 2,000 pièces
<b>4ème-10ème places</b>: 1,000 pièces chacune
<b>11ème-50ème places</b>: 500 pièces chacune
<b>51ème-100ème places</b>: 250 pièces chacune

<b>La cagnotte</b> augmente lorsque quelqu'un vote pour vous sur votre page de profil.

<b>Conditions</b>:
Tous les profils qui téléchargent une photo et complètent les tâches sont éligibles pour participer au concours.

<b>Date de fin</b>:
{{endDate}}

<b>Versement des récompenses</b>:
Vous pouvez recevoir votre récompense en <b>TON</b> — la cryptomonnaie développée par Telegram.`,
  },
  LeadersItem: {
    join: "Rejoindre",
  },
  Friends: {
    title: "Invitez des amis!",
    description: "Vous et votre ami recevrez des bonus",
    bonus: {
      profit: "<coins>+{{count}}</coins> pour vous et votre ami",
      invite: {
        title: "Invitez un ami",
      },
      inviteWithPremium: {
        title: "Invitez un ami",
        description: "avec Telegram Premium",
      },
    },
    list: {
      title: "Liste de vos amis",
    },
    inviteFriend: "Invitez un ami",
    inviteText: `Rejoignez-moi, participez au vote pour les plus beaux garçons et filles, et recevez des prix précieux!
  
  🌟 +{{coins}} pièces en cadeau de bienvenue
  🔥 +{{coinsPremium}} pièces si vous avez Telegram Premium
  
  🏆 Gagnez des pièces pour chaque vote et choisissez les participants les plus attractifs. Vous en voulez plus? Participez vous-même et concourez pour des prix précieux!
  
  Ne manquez pas votre chance, devenez membre de notre communauté d'étoiles et gagnez!`,
    linkCopied: "Lien copié",
  },
  Earn: {
    title: "Gagnez plus de pièces",
    tasksList: "Liste des tâches",
    check: "Vérifier",
    inReview: "En révision",
    subscribe: "Abonnez-vous",
    participate: "Participez",
    invite: "Invitez",
    verify: "Vérifiez",
    taskCompleted: "Tâche terminée",
  },
  Wallet: {
    title: "Obtenez des récompenses en TON",
    description:
      "Complétez des défis pour gagner TON, une cryptomonnaie développée par Telegram",
    rewards: "Récompenses",
    withdrawal: "Retrait",
    walletConnected: "Votre portefeuille TON est connecté",
    walletDescription: "Plus d'opportunités seront bientôt disponibles",
  },
  VerificationRequired: {
    title: "Vérification Requise",
    description:
      "Pour participer au concours, vous devez vérifier votre compte.",
    bdate: {
      title: "Votre date de naissance",
    },
  },
  Verification: {
    title: "Vérification",
    hint: "Gardez votre visage à l'intérieur de l'ovale",
    secureHint: "Personne ne verra cette photo",
    success: "Vous avez réussi la vérification",
    goToApp: "Allez à l'application",
    verification: "Vérification...",
    cameraAccessRequired:
      "Veuillez autoriser l'accès à la caméra pour continuer",
    gettingAccessToCamer: "Obtention de l'accès à la caméra...",
    cameraAccessNotAllowed: "Accès à la caméra refusé par l'utilisateur.",
    cameraAccessError: "Erreur d'accès à la caméra",
    loadingHint: "Cela peut prendre un certain temps. Veuillez être patient.",
  },
  Profile: {
    vote: "Voter",
    askVote: "Demander un vote",
    inviteText: `Salut! 😊 S'il vous plaît, soutenez-moi avec votre vote dans le concours TON Stars! Chaque vote est crucial et m'aide à me rapprocher de gagner des prix précieux.

🌟 +{{coins}} pièces en cadeau de bienvenue
🔥 +{{coinsPremium}} pièces si vous avez Telegram Premium

Votez pour mon profil ici : {{url}}

Merci pour votre soutien ! 🌟`,
    joinContest: "Participer au concours",
    gender: {
      male: "Homme",
      female: "Femme",
    },
    photos: {
      inReview:
        "Vos nouvelles photos sont en cours de révision.\nCela peut prendre un certain temps.",
      rejected:
        "Une ou plusieurs photos ont été rejetées pour la raison suivante:\n{{reason}}.\n\nVeuillez télécharger d'autres photos et réessayer.",
    },
  },
  ProfileEdit: {
    title: "Votre Profil",
    name: {
      label: "Votre nom",
      placeholder: "Entrez votre nom",
    },
    instagram: {
      description: "Le profil Instagram doit être public",
    },
    photos: {
      verificationPhotoUploaded:
        "Votre photo de vérification a été téléchargée, personne ne la verra.",
      label: "Vos photos",
      mainPhoto: "Photo principale",
      error:
        "Une ou plusieurs photos ne sont pas appropriées, veuillez en choisir d'autres.",
      hint: "Choisissez vos photos les plus impressionnantes, où il n'y a pas d'autres personnes, et où votre visage est clairement et expressivement visible.",
      inReview:
        "Vos nouvelles photos sont en cours de révision.\nCela peut prendre un certain temps.",
      rejected:
        "Une ou plusieurs photos ont été rejetées pour la raison suivante:\n{{reason}}.\n\nVeuillez télécharger d'autres photos et réessayer.",
    },
    submit: "Participer au concours",
    photosUploading: "Téléchargement de la photo...",
    success: "Votre profil a été mis à jour",
    errors: {
      name: "Veuillez indiquer votre nom",
      photos: "Veuillez sélectionner 3 photos",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Photo principale",
    noFace: "Votre visage n'est pas détecté",
    otherPeople: "La photo doit montrer uniquement votre visage",
  },
};
