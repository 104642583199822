export default {
  g: {
    flag: "🇵🇰",
    langName: "Urdu",
    langNameNative: "اردو",
    continue: "جاری رکھیں",
    save: "محفوظ کریں",
    retry: "دوبارہ کوشش کریں",
    loading: "لوڈ ہو رہا ہے...",
    genderMany: {
      all: "سب",
      male: "مرد",
      female: "عورتیں",
    },
  },
  CreateAccount: {
    slogan: "جہاں خواب کامیابیوں میں بدلتے ہیں!",
    gender: {
      title: "ہیلو {{name}}، آپ...",
      male: "مرد",
      female: "عورت",
    },
  },
  Settings: {
    title: "ترتیبات",
    language: "زبان",
    auto: "خودکار",
    country: "ملک",
    hideProfile: {
      label: "پروفائل چھپائیں",
      description:
        "آپ کا پروفائل ہر کسی سے چھپایا جائے گا، جیسے آپ نے اسے حذف کر دیا ہو۔",
    },
    profileHidden:
      "آپ کا پروفائل چھپا ہوا ہے اور دوسرے صارفین کو نظر نہیں آتا۔",
  },
  Tabs: {
    vote: "ووٹ دیں",
    contest: "مقابلہ",
    friends: "دوست",
    earn: "کمائیں",
    wallet: "پرس",
  },
  Vote: {
    whoIsBetter: "آپ کو کون زیادہ پسند ہے؟",
    votingForLeader: "لیڈر کے لیے ووٹنگ",
    noUsers: {
      title: "🚀 آج کے لیے یہ سب ہے",
      description: "ووٹنگ جاری رکھنے اور سکے کمانے کے لیے دوستوں کو مدعو کریں",
      inviteFriends: "دوستوں کو مدعو کریں",
    },
  },
  Leaders: {
    rewards: "انعامات",
    comingSoon: "جلد آرہا ہے",
    prizePool: "انعامی پول",
    moreInfo: "مزید معلومات",
    contest: "مقابلہ",
    richest: "سب سے امیر",
    sponsors: "سپانسرز",
    description: {
      likes:
        "دوستوں کو مدعو کریں اور اپنے پروفائل کا اشتراک کریں تاکہ مزید ووٹ حاصل ہوں اور جیتنے کے امکانات بڑھ سکیں۔",
      coins:
        "کام مکمل کریں، دوسرے شرکاء کے لئے ووٹ دیں اور مزید سکے کمانے اور اپنی پوزیشن کو مضبوط کرنے کے لئے دوستوں کو مدعو کریں۔",
      stars:
        "پروفائل کے صفحے پر پروفائلز کے لئے ووٹ دیں، ستارے حاصل کریں اور رینکنگ میں اوپر جائیں۔",
    },
  },
  PrizePool: {
    text: `<b>انعام جیتنے کی جگہیں</b>:
<b>پہلی جگہ</b>: 50% USD اور 10,000 سکے
<b>دوسری جگہ</b>: 30% USD اور 3,000 سکے
<b>تیسری جگہ</b>: 20% USD اور 2,000 سکے
<b>چوتھی-10ویں جگہ</b>: ہر ایک کے 1,000 سکے
<b>11ویں-50ویں جگہ</b>: ہر ایک کے 500 سکے
<b>51ویں-100ویں جگہ</b>: ہر ایک کے 250 سکے

<b>انعامی پول</b> اس وقت بڑھتا ہے جب کوئی آپ کے پروفائل پیج پر آپ کے لئے ووٹ دیتا ہے۔

<b>شرائط</b>:
تمام پروفائلز جو فوٹو اپلوڈ کرتے ہیں اور کام مکمل کرتے ہیں مقابلے میں شرکت کے اہل ہیں۔

<b>اختتامی تاریخ</b>:
{{endDate}}

<b>انعام کی ادائیگی</b>:
آپ اپنا انعام <b>TON</b> میں وصول کر سکتے ہیں — یہ کرپٹوکرنسی ہے جو ٹیلیگرام نے تیار کی ہے۔`,
  },
  LeadersItem: {
    join: "شامل ہوں",
  },
  Friends: {
    title: "دوستوں کو مدعو کریں!",
    description: "آپ اور آپ کا دوست بونس حاصل کریں گے",
    bonus: {
      profit: "<coins>+{{count}}</coins> آپ اور آپ کے دوست کے لئے",
      invite: {
        title: "دوست کو مدعو کریں",
      },
      inviteWithPremium: {
        title: "دوست کو مدعو کریں",
        description: "ٹیلیگرام پریمیم کے ساتھ",
      },
    },
    list: {
      title: "آپ کے دوستوں کی فہرست",
    },
    inviteFriend: "دوست کو مدعو کریں",
    inviteText: `میرے ساتھ شامل ہوں، سب سے خوبصورت لڑکوں اور لڑکیوں کو ووٹ دیں اور قیمتی انعامات جیتیں!
  
  🌟 پہلا تحفہ کے طور پر +{{coins}} سکے
  🔥 اگر آپ کے پاس ٹیلیگرام پریمیم ہے تو +{{coinsPremium}} سکے
  
  🏆 ہر ووٹ کے لئے سکے کمائیں اور سب سے زیادہ دلکش شرکاء کا انتخاب کریں۔ مزید چاہتے ہیں؟ خود حصہ لیں اور قیمتی انعامات کے لئے مقابلہ کریں!
  
  اپنا موقع ضائع نہ کریں، ہمارے ستاروں کی کمیونٹی کا حصہ بنیں اور جیتیں!`,
    linkCopied: "لنک کاپی ہو گیا ہے",
  },
  Earn: {
    title: "مزید سکے کمائیں",
    tasksList: "کاموں کی فہرست",
    check: "چیک کریں",
    inReview: "جائزے میں",
    subscribe: "سبسکرائب کریں",
    participate: "حصہ لیں",
    invite: "مدعو کریں",
    verify: "تصدیق کریں",
    taskCompleted: "کام مکمل",
  },
  Wallet: {
    title: "TON میں انعامات حاصل کریں",
    description:
      "Telegram کی تیار کردہ ایک کرپٹو کوائن، TON کمانے کے لیے چیلنجز کو مکمل کریں",
    rewards: "انعامات",
    withdrawal: "نکالنا",
    walletConnected: "آپ کا TON والیٹ منسلک ہے",
    walletDescription: "جلد ہی مزید مواقع دستیاب ہوں گے",
  },
  VerificationRequired: {
    title: "تصدیق ضروری ہے",
    description:
      "مقابلے میں حصہ لینے کے لئے، آپ کو اپنے اکاؤنٹ کی تصدیق کرنی ہوگی۔",
    bdate: {
      title: "آپ کی پیدائش کی تاریخ",
    },
  },
  Verification: {
    title: "تصدیق",
    hint: "اپنا چہرہ اوول کے اندر رکھیں",
    secureHint: "یہ تصویر کوئی نہیں دیکھے گا",
    success: "آپ نے کامیابی کے ساتھ تصدیق مکمل کر لی ہے",
    goToApp: "ایپ پر جائیں",
    verification: "تصدیق ہو رہی ہے...",
    cameraAccessRequired:
      "جاری رکھنے کے لئے براہ کرم کیمرے تک رسائی کی اجازت دیں",
    gettingAccessToCamer: "کیمرے تک رسائی حاصل ہو رہی ہے...",
    cameraAccessNotAllowed:
      "صارف کی جانب سے کیمرے تک رسائی کی اجازت نہیں دی گئی۔",
    cameraAccessError: "کیمرے تک رسائی میں خرابی",
    loadingHint: "اس میں کچھ وقت لگ سکتا ہے۔ براہ کرم صبر کریں۔",
  },
  Profile: {
    vote: "ووٹ",
    askVote: "ووٹ کی درخواست کریں",
    inviteText: `سلام! 😊 براہ کرم TON Stars مقابلے میں اپنے ووٹ سے میری حمایت کریں! ہر ووٹ اہم ہے اور مجھے قیمتی انعامات جیتنے کے قریب تر کرتا ہے۔

🌟 پہلا تحفہ کے طور پر +{{coins}} سکے
🔥 اگر آپ کے پاس Telegram Premium ہے تو +{{coinsPremium}} سکے

میرے پروفائل کے لئے یہاں ووٹ کریں: {{url}}

آپ کے تعاون کا شکریہ! 🌟`,
    joinContest: "مقابلے میں شامل ہوں",
    gender: {
      male: "مرد",
      female: "عورت",
    },
    photos: {
      inReview: "آپ کی نئی تصاویر جائزے میں ہیں۔\nاس میں کچھ وقت لگ سکتا ہے۔",
      rejected:
        "ایک یا زیادہ تصاویر درج ذیل وجہ سے مسترد کر دی گئیں:\n{{reason}}.\n\nبراہ کرم مختلف تصاویر اپ لوڈ کریں اور دوبارہ کوشش کریں۔",
    },
  },
  ProfileEdit: {
    title: "آپ کی پروفائل",
    name: {
      label: "آپ کا نام",
      placeholder: "اپنا نام درج کریں",
    },
    instagram: {
      description: "Instagram پروفائل عوامی ہونا چاہئے",
    },
    photos: {
      verificationPhotoUploaded:
        "آپ کی تصدیقی تصویر اپ لوڈ ہو گئی ہے، اسے کوئی نہیں دیکھے گا۔",
      label: "آپ کی تصاویر",
      mainPhoto: "مرکزی تصویر",
      error:
        "ایک یا زیادہ تصاویر موزوں نہیں ہیں، براہ کرم کوئی اور انتخاب کریں۔",
      hint: "اپنی سب سے متاثر کن تصاویر منتخب کریں، جہاں کوئی اور لوگ نہ ہوں، اور آپ کا چہرہ واضح اور صاف نظر آ رہا ہو۔",
      inReview: "آپ کی نئی تصاویر جائزے میں ہیں۔\nاس میں کچھ وقت لگ سکتا ہے۔",
      rejected:
        "ایک یا زیادہ تصاویر درج ذیل وجہ سے مسترد کر دی گئیں:\n{{reason}}.\n\nبراہ کرم مختلف تصاویر اپ لوڈ کریں اور دوبارہ کوشش کریں۔",
    },
    submit: "مقابلے میں شامل ہوں",
    photosUploading: "تصویر اپ لوڈ ہو رہی ہے...",
    success: "آپ کی پروفائل کو اپ ڈیٹ کر دیا گیا ہے",
    errors: {
      name: "براہ کرم اپنا نام درج کریں",
      photos: "براہ کرم 3 تصاویر منتخب کریں",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "مرکزی تصویر",
    noFace: "آپ کا چہرہ معلوم نہیں ہوا",
    otherPeople: "تصویر میں صرف آپ کا چہرہ نظر آنا چاہئے",
  },
};
