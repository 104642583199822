export default {
  g: {
    flag: "🇷🇸",
    langName: "Serbian",
    langNameNative: "Српски",
    continue: "Настави",
    save: "Сачувај",
    retry: "Покушај поново",
    loading: "Учитавање...",
    genderMany: {
      all: "Сви",
      male: "Мушкарци",
      female: "Жене",
    },
  },
  CreateAccount: {
    slogan: "Место, где снови постају победе!",
    gender: {
      title: "Здраво {{name}}, Ви сте...",
      male: "Мушкарац",
      female: "Жена",
    },
  },
  Settings: {
    title: "Подешавања",
    language: "Језик",
    auto: "Аутоматски",
    country: "Земља",
    hideProfile: {
      label: "Сакриј профил",
      description:
        "Ваш профил ће бити сакривен од свих, као да сте га избрисали.",
    },
    profileHidden: "Ваш профил је сакривен и није видљив другим корисницима.",
  },
  Tabs: {
    vote: "Гласај",
    contest: "Такмичење",
    friends: "Пријатељи",
    earn: "Заради",
    wallet: "Новчаник",
  },
  Vote: {
    whoIsBetter: "Кога више волиш?",
    votingForLeader: "Гласање за лидера",
    noUsers: {
      title: "🚀 То је све за данас",
      description:
        "Позовите пријатеље да наставе са гласањем и зарађују новчиће",
      inviteFriends: "Позовите пријатеље",
    },
  },
  Leaders: {
    rewards: "Награде",
    comingSoon: "Ускоро",
    prizePool: "Наградни фонд",
    moreInfo: "Више информација",
    contest: "Такмичење",
    richest: "Најбогатији",
    sponsors: "Спонзори",
    description: {
      likes:
        "Позовите пријатеље и поделите свој профил како бисте сакупили више гласова и повећали своје шансе за победу.",
      coins:
        "Завршите задатке, гласајте за друге учеснике и позовите пријатеље да бисте зарадили више кованица и ојачали своју позицију.",
      stars:
        "Гласајте за профиле на страници профила како бисте добили звездице и попели се на ранг-листи.",
    },
  },
  PrizePool: {
    text: `<b>Награђена места</b>:
<b>1. место</b>: 50% USD и 10.000 кованица
<b>2. место</b>: 30% USD и 3.000 кованица
<b>3. место</b>: 20% USD и 2.000 кованица
<b>4.-10. место</b>: 1.000 кованица сваки
<b>11.-50. место</b>: 500 кованица сваки
<b>51.-100. место</b>: 250 кованица сваки

<b>Наградни фонд</b> се повећава када неко гласа за вас на вашој страници профила.

<b>Услови</b>:
Сви профили који отпреме фотографију и заврше задатке могу учествовати у такмичењу.

<b>Датум завршетка</b>:
{{endDate}}

<b>Исплата награда</b>:
Награду можете добити у <b>TON</b> — криптовалути коју је развио Telegram.`,
  },
  LeadersItem: {
    join: "Придружи се",
  },
  Friends: {
    title: "Позовите пријатеље!",
    description: "Ви и ваш пријатељ добићете бонусе",
    bonus: {
      profit: "<coins>+{{count}}</coins> за вас и вашег пријатеља",
      invite: {
        title: "Позовите пријатеља",
      },
      inviteWithPremium: {
        title: "Позовите пријатеља",
        description: "са Телеграм Премиумом",
      },
    },
    list: {
      title: "Списак ваших пријатеља",
    },
    inviteFriend: "Позовите пријатеља",
    inviteText: `Придружите ми се, гласајте за најлепше момке и девојке и освојите вредне награде!
  
  🌟 +{{coins}} новчића као први поклон
  🔥 +{{coinsPremium}} новчића ако имате Телеграм Премиум
  
  🏆 Зарађујте новчиће за сваки глас и бирајте најатрактивније учеснике. Желите више? Учествујте сами и такмичите се за вредне награде!
  
  Не пропустите своју шансу, постаните део наше заједнице звезда и победите!`,
    linkCopied: "Линк је копиран",
  },
  Earn: {
    title: "Зарадите више новчића",
    tasksList: "Списак задатака",
    check: "Провери",
    inReview: "На прегледу",
    subscribe: "Претплати се",
    participate: "Учествуј",
    invite: "Позови",
    verify: "Верификуј",
    taskCompleted: "Задатак завршен",
  },
  Wallet: {
    title: "Освојите награде у TON-у",
    description:
      "Завршите изазове да зарадите TON, крипто новчић који је развио Telegram",
    rewards: "Награде",
    withdrawal: "Повлачење",
    walletConnected: "Ваш TON новчаник је повезан",
    walletDescription: "Ускоро ће бити доступне додатне могућности",
  },
  VerificationRequired: {
    title: "Потребна је верификација",
    description:
      "Да бисте учествовали у такмичењу, потребно је да верификујете свој налог.",
    bdate: {
      title: "Ваш датум рођења",
    },
  },
  Verification: {
    title: "Верификација",
    hint: "Држите лице унутар овала",
    secureHint: "Нико неће видети ову фотографију",
    success: "Успешно сте завршили верификацију",
    goToApp: "Идите у апликацију",
    verification: "Верификација...",
    cameraAccessRequired: "Молимо дозволите приступ камери да наставите",
    gettingAccessToCamer: "Добијање приступа камери...",
    cameraAccessNotAllowed: "Приступ камери је одбијен од стране корисника.",
    cameraAccessError: "Грешка при приступу камери",
    loadingHint: "Ово може потрајати неко време. Молимо вас будите стрпљиви.",
  },
  Profile: {
    vote: "Гласај",
    askVote: "Тражи Глас",
    inviteText: `Здраво! 😊 Подржи ме својим гласом у ТОН Старс такмичењу! Сваки глас је важан и помаже ми да се приближим освајању вредних награда.

🌟 +{{coins}} новчића као први поклон
🔥 +{{coinsPremium}} новчића ако имаш Telegram Premium

Гласај за мој профил овде: {{url}}

Хвала на подршци! 🌟`,
    joinContest: "Придружите се такмичењу",
    gender: {
      male: "Мушки",
      female: "Женски",
    },
    photos: {
      inReview:
        "Ваше нове фотографије су на прегледу.\nОво може потрајати неко време.",
      rejected:
        "Једна или више фотографија су одбијене из следећег разлога:\n{{reason}}.\n\nМолимо вас, учитајте друге фотографије и покушајте поново.",
    },
  },
  ProfileEdit: {
    title: "Ваш профил",
    name: {
      label: "Ваше име",
      placeholder: "Унесите своје име",
    },
    instagram: {
      description: "Instagram профил мора бити јаван",
    },
    photos: {
      verificationPhotoUploaded:
        "Ваша верификациона фотографија је отпремљена, нико је неће видети.",
      label: "Ваше фотографије",
      mainPhoto: "Главна фотографија",
      error:
        "Једна или више фотографија нису прикладне, молимо вас да направите други избор.",
      hint: "Изаберите своје најимпресивније фотографије, на којима нема других људи, а ваше лице је јасно и изражајно видљиво.",
      inReview:
        "Ваше нове фотографије су на прегледу.\nОво може потрајати неко време.",
      rejected:
        "Једна или више фотографија су одбијене из следећег разлога:\n{{reason}}.\n\nМолимо вас, учитајте друге фотографије и покушајте поново.",
    },
    submit: "Придружите се такмичењу",
    photosUploading: "Учитавање фотографије...",
    success: "Ваш профил је ажуриран",
    errors: {
      name: "Унесите ваше име",
      photos: "Изаберите 3 фотографије",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Главна фотографија",
    noFace: "Ваше лице није откривено",
    otherPeople: "Фотографија треба да приказује само ваше лице",
  },
};
