import CameraIcon from "assets/camera.svg?react";
import "./ProfileEditPhoto.css";
import { CircularProgress } from "@telegram-apps/telegram-ui";
import RemoveIcon from "./icons/remove.svg?react";
import { useTranslation } from "react-i18next";
import RetryIcon from "./icons/retry.svg?react";
import { UploadItem } from "utils/uploads/useUploads";

type ProfileEditPhotoProps = {
  main?: boolean;
  onSelect?: () => void;
  onRemove: (id?: string) => void;
  onSetMain?: (id?: string) => void;
} & UploadItem;

export const ProfileEditPhoto = (props: ProfileEditPhotoProps) => {
  const { t } = useTranslation("ProfileEditPhoto");

  return (
    <div
      style={{
        backgroundImage: props.url ? `url(${props.url})` : undefined,
      }}
      className="profile-edit-photo"
      onClick={
        props.state === "done" || props.state === "uploading"
          ? () => {
              props.onSetMain?.(props.id);
            }
          : props.onSelect
      }
    >
      {props.url ? (
        <>
          <div className="profile-edit-photo-errors">
            {props.state === "uploading" && (
              <div className="profile-edit-photo-error">
                <CircularProgress progress={props.progress} size="medium" />
              </div>
            )}
            {props.state === "error" && (
              <div
                className="profile-edit-photo-error"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  props.uploadRef?.start();
                }}
              >
                <RetryIcon width={20} height={20} />
              </div>
            )}

            <div
              className="profile-edit-photo-remove"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.uploadRef?.abort();
                props.onRemove(props.id);
              }}
            >
              <RemoveIcon className="profile-edit-photo-remove-icon" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="profile-edit-photo-icon">
            <CameraIcon />
          </div>
          {props.main && (
            <div className="profile-edit-photo-text">{t("mainPhoto")}</div>
          )}
        </>
      )}
    </div>
  );
};
