export default {
  g: {
    flag: "🇷🇴",
    langName: "Romanian",
    langNameNative: "Română",
    continue: "Continuă",
    save: "Salvează",
    retry: "Încearcă din nou",
    loading: "Se încarcă...",
    genderMany: {
      all: "Toți",
      male: "Bărbați",
      female: "Femei",
    },
  },
  CreateAccount: {
    slogan: "Locul unde visele devin victorii!",
    gender: {
      title: "Salut {{name}}, Tu ești...",
      male: "Bărbat",
      female: "Femeie",
    },
  },
  Settings: {
    title: "Setări",
    language: "Limbă",
    auto: "Automat",
    country: "Țară",
    hideProfile: {
      label: "Ascunde profilul",
      description:
        "Profilul tău va fi ascuns de toată lumea, ca și cum l-ai fi șters.",
    },
    profileHidden:
      "Profilul tău este ascuns și nu este vizibil pentru alți utilizatori.",
  },
  Tabs: {
    vote: "Votează",
    contest: "Concurs",
    friends: "Prieteni",
    earn: "Câștigă",
    wallet: "Portofel",
  },
  Vote: {
    whoIsBetter: "Cine îți place mai mult?",
    votingForLeader: "Votând pentru lider",
    noUsers: {
      title: "🚀 Asta e tot pentru astăzi",
      description:
        "Invitați prieteni pentru a continua să voteze și să câștige monede",
      inviteFriends: "Invită Prieteni",
    },
  },
  Leaders: {
    rewards: "Recompense",
    comingSoon: "În curând",
    prizePool: "Fond de premii",
    moreInfo: "Mai multe informații",
    contest: "Concurs",
    richest: "Cei mai bogați",
    sponsors: "Sponsori",
    description: {
      likes:
        "Invită prieteni și distribuie profilul tău pentru a aduna mai multe voturi și a-ți crește șansele de a câștiga.",
      coins:
        "Completează sarcinile, votează pentru alți participanți și invită prieteni pentru a câștiga mai multe monede și a-ți consolida poziția.",
      stars:
        "Votează pentru profile pe pagina de profil pentru a primi stele și a urca în clasamente.",
    },
  },
  PrizePool: {
    text: `<b>Locuri premiate</b>:
<b>Locul 1</b>: 50% USD și 10.000 monede
<b>Locul 2</b>: 30% USD și 3.000 monede
<b>Locul 3</b>: 20% USD și 2.000 monede
<b>Locurile 4-10</b>: câte 1.000 monede fiecare
<b>Locurile 11-50</b>: câte 500 monede fiecare
<b>Locurile 51-100</b>: câte 250 monede fiecare

<b>Fondul de premii</b> crește atunci când cineva votează pentru tine pe pagina ta de profil.

<b>Condiții</b>:
Toate profilele care încarcă o fotografie și finalizează sarcinile sunt eligibile pentru a participa la concurs.

<b>Data de încheiere</b>:
{{endDate}}

<b>Plata recompensei</b>:
Poți primi recompensa ta în <b>TON</b> — criptomoneda dezvoltată de Telegram.`,
  },
  LeadersItem: {
    join: "Alăturați-vă",
  },
  Friends: {
    title: "Invită-ți prietenii!",
    description: "Tu și prietenul tău veți primi bonusuri",
    bonus: {
      profit: "<coins>+{{count}}</coins> pentru tine și prietenul tău",
      invite: {
        title: "Invită un prieten",
      },
      inviteWithPremium: {
        title: "Invită un prieten",
        description: "cu Telegram Premium",
      },
    },
    list: {
      title: "Lista prietenilor tăi",
    },
    inviteFriend: "Invită un prieten",
    inviteText: `Alătură-te mie, votează pentru cei mai frumoși băieți și fete și câștigă premii valoroase!
  
  🌟 +{{coins}} monede ca primul cadou
  🔥 +{{coinsPremium}} monede dacă ai Telegram Premium
  
  🏆 Câștigă monede pentru fiecare vot și alege cei mai atrăgători participanți. Vrei mai mult? Participă tu însuți și concurează pentru premii valoroase!
  
  Nu rata șansa ta, devino parte din comunitatea noastră de stele și câștigă!`,
    linkCopied: "Link copiat",
  },
  Earn: {
    title: "Câștigă mai multe monede",
    tasksList: "Lista de sarcini",
    check: "Verifică",
    inReview: "În revizuire",
    subscribe: "Abonează-te",
    participate: "Participă",
    invite: "Invită",
    verify: "Verifică",
    taskCompleted: "Sarcină finalizată",
  },
  Wallet: {
    title: "Obțineți recompense în TON",
    description:
      "Finalizați provocările pentru a câștiga TON, o monedă cripto dezvoltată de Telegram",
    rewards: "Recompense",
    withdrawal: "Retragere",
    walletConnected: "Portofelul dvs. TON este conectat",
    walletDescription: "Mai multe oportunități vor fi disponibile în curând",
  },
  VerificationRequired: {
    title: "Verificare necesară",
    description:
      "Pentru a participa la concurs, trebuie să-ți verifici contul.",
    bdate: {
      title: "Data nașterii tale",
    },
  },
  Verification: {
    title: "Verificare",
    hint: "Mențineți fața în interiorul ovalului",
    secureHint: "Nimeni nu va vedea această fotografie",
    success: "Ai finalizat verificarea cu succes",
    goToApp: "Mergi la aplicație",
    verification: "Verificare...",
    cameraAccessRequired: "Permite accesul la cameră pentru a continua",
    gettingAccessToCamer: "Obținerea accesului la cameră...",
    cameraAccessNotAllowed: "Accesul la cameră a fost refuzat de utilizator.",
    cameraAccessError: "Eroare de acces la cameră",
    loadingHint: "Acest proces poate dura ceva timp. Te rog să ai răbdare.",
  },
  Profile: {
    vote: "Votează",
    askVote: "Cere Vot",
    inviteText: `Bună! 😊 Vă rog, susțineți-mă cu votul dumneavoastră în concursul TON Stars! Fiecare vot este crucial și mă ajută să mă apropii de câștigarea unor premii valoroase.

🌟 +{{coins}} monede ca prim cadou
🔥 +{{coinsPremium}} monede dacă aveți Telegram Premium

Votează pentru profilul meu aici: {{url}}

Vă mulțumesc pentru sprijin! 🌟`,
    joinContest: "Participă la concurs",
    gender: {
      male: "Masculin",
      female: "Feminin",
    },
    photos: {
      inReview:
        "Fotografiile tale noi sunt în curs de revizuire.\nAcest lucru poate dura ceva timp.",
      rejected:
        "Una sau mai multe fotografii au fost respinse din următorul motiv:\n{{reason}}.\n\nTe rugăm să încarci alte fotografii și să încerci din nou.",
    },
  },
  ProfileEdit: {
    title: "Profilul Tău",
    name: {
      label: "Numele Tău",
      placeholder: "Introdu numele tău",
    },
    instagram: {
      description: "Profilul de Instagram trebuie să fie public",
    },
    photos: {
      verificationPhotoUploaded:
        "Fotografia dvs. de verificare a fost încărcată, nimeni nu o va vedea.",
      label: "Fotografiile Tale",
      mainPhoto: "Fotografia Principală",
      error:
        "Una sau mai multe fotografii nu sunt adecvate, te rugăm să alegi altele.",
      hint: "Alege cele mai impresionante fotografii ale tale, unde nu sunt alte persoane și fața ta este clar vizibilă.",
      inReview:
        "Fotografiile tale noi sunt în curs de revizuire.\nAcest lucru poate dura ceva timp.",
      rejected:
        "Una sau mai multe fotografii au fost respinse din următorul motiv:\n{{reason}}.\n\nTe rugăm să încarci alte fotografii și să încerci din nou.",
    },
    submit: "Participă la Concurs",
    photosUploading: "Încărcarea fotografiei...",
    success: "Profilul tău a fost actualizat",
    errors: {
      name: "Introduceți numele dvs.",
      photos: "Vă rugăm să selectați 3 fotografii",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Fotografia principală",
    noFace: "Fața ta nu a fost detectată",
    otherPeople: "Fotografia ar trebui să arate doar fața ta",
  },
};
