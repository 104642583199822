import {
  Placeholder,
  SegmentedControl,
  Spinner,
} from "@telegram-apps/telegram-ui";
import TonIcon from "assets/ton.svg?react";
import StarIcon from "assets/star.svg?react";
import CoinIcon from "assets/coin.svg?react";
import { useTranslation } from "react-i18next";
import "./Wallet.css";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import { useEffect, useState } from "react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useTonAddress, TonConnectButton } from "@tonconnect/ui-react";
import { useMutation } from "@apollo/client";

const WalletUser_QueryFragment = gql(`
  fragment WalletUser_QueryFragment on User {
    ...ProfileUser_QueryFragment
    id
    starsCount
    coins
    tonAddress
  }
`);

const Wallet_Mutation = gql(`
  mutation Wallet_Mutation(
    $data: UserSaveInput!
  ) {
    userSave(data: $data) {
      id
      tonAddress
    }
  }
`);

type WalletProps = {
  user?: FragmentType<typeof WalletUser_QueryFragment> | null;
};

export const Wallet = (props: WalletProps) => {
  const user = getFragmentData(WalletUser_QueryFragment, props.user);
  const [userSave, { loading }] = useMutation(Wallet_Mutation);
  const { t, i18n } = useTranslation("Wallet");

  const [tab, setTab] = useState("rewards");

  const tonAddress = useTonAddress() || null;

  useEffect(() => {
    if (user?.tonAddress === tonAddress) return;

    userSave({ variables: { data: { tonAddress } } });
  }, [tonAddress, user?.tonAddress, userSave]);

  return (
    <div className="scroll">
      <Placeholder header={t("title")} description={t("description")}>
        <TonIcon width={90} height={90} />
      </Placeholder>
      <div className="wallet-tabs-container">
        <SegmentedControl className="wallet-tabs">
          <SegmentedControlItem
            onClick={() => setTab("rewards")}
            selected={tab === "rewards"}
            className={tab === "rewards" ? "selected" : ""}
          >
            {t("rewards")}
          </SegmentedControlItem>
          <SegmentedControlItem
            onClick={() => setTab("withdrawal")}
            selected={tab === "withdrawal"}
            className={tab === "withdrawal" ? "selected" : ""}
          >
            {t("withdrawal")}
          </SegmentedControlItem>
        </SegmentedControl>
      </div>
      {tab === "rewards" && (
        <div className="wallet-items">
          <div className="wallet-item">
            <div className="wallet-item-wrap">
              <div className="wallet-item-text-primary">
                {Intl.NumberFormat(i18n.language, {
                  style: "currency",
                  currency: "TON",
                }).format(0)}
              </div>
              <div className="wallet-item-text-secondary">
                ≈{" "}
                {Intl.NumberFormat(i18n.language, {
                  style: "currency",
                  currency: "USD",
                }).format(0)}
              </div>
            </div>
          </div>
          <div className="wallet-items-row">
            <div className="wallet-item">
              <StarIcon color="rgb(245, 186, 38)" width={24} height={24} />
              <div className="wallet-item-text">
                {Intl.NumberFormat(i18n.language).format(user?.starsCount)}
              </div>
            </div>
            <div className="wallet-item">
              <CoinIcon width={24} height={24} />
              <div className="wallet-item-text">
                {Intl.NumberFormat(i18n.language).format(user?.coins)}
              </div>
            </div>
          </div>
        </div>
      )}
      {tab === "withdrawal" && (
        <div className="wallet-withdrawal">
          {tonAddress && (
            <div className="wallet-withdrawal-title">
              {loading && (
                <Spinner className="wallet-withdrawal-title-spinner" size="m" />
              )}
              <div>{t("walletConnected")}</div>
            </div>
          )}
          <TonConnectButton className="wallet-withdrawal-button" />
          <div className="wallet-withdrawal-description">
            {t("walletDescription")}
          </div>
          {!tonAddress && loading && (
            <div className="wallet-withdrawal-title">
              <Spinner className="wallet-withdrawal-title-spinner" size="m" />
              <div>-</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
