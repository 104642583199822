import { useMemo, useRef } from "react";
import "./Select.css";
import ArrowDownIcon from "assets/arrow-down.svg?react";
import { Spinner } from "@telegram-apps/telegram-ui";

type SelectProps<T> = {
  value?: T;
  options?: { value: T; text: string }[];
  onChange?: (value: T) => void;
  loading?: boolean;
};
export function Select<T extends string>(props: SelectProps<T>) {
  const option = useMemo(
    () => props.options?.find((_) => _.value === props.value),
    [props.options, props.value]
  );

  const ref = useRef<HTMLSelectElement>(null);

  return (
    <div className="select">
      <span>{option?.text}</span>
      {props.loading ? (
        <Spinner className="select-spinner" size="s" />
      ) : (
        <ArrowDownIcon width={12} height={12} />
      )}
      <select
        ref={ref}
        className="select-element"
        onChange={(e) => {
          const value = e.target.value as T;
          props.onChange?.(value);
        }}
        value={props.value}
      >
        {props.options?.map((_) => (
          <option key={_.value} value={_.value}>
            {_.text}
          </option>
        ))}
      </select>
    </div>
  );
}
