import { ApolloError } from "@apollo/client";
import { Button, Spinner } from "@telegram-apps/telegram-ui";
import { ReactNode } from "react";
import "./LoadingPlaceholder.css";
import { useTranslation } from "react-i18next";
import ErrorIcon from "./error.svg?react";

type LoadingPlaceholderProps = {
  children?: ReactNode;
  data?: unknown;
  loading?: boolean;
  error?: ApolloError;
  refetch?: () => void;
  fullscreen?: boolean;
  hideIndicator?: boolean;
};
export const LoadingPlaceholder = (props: LoadingPlaceholderProps) => {
  const { t } = useTranslation();

  if (props.error)
    return (
      <div
        style={
          props.fullscreen ? { flex: 1, justifyContent: "center" } : undefined
        }
        className="loading-placeholder-error"
      >
        <div className="loading-placeholder-text-wrap">
          <ErrorIcon width={16} height={16} />
          <div className="loading-placeholder-text">{props.error.message}</div>
        </div>
        <Button
          size="s"
          onClick={() => {
            props.refetch?.();
          }}
          loading={props.loading}
        >
          {t("retry")}
        </Button>
      </div>
    );
  if (props.hideIndicator) return props.children;

  if (props.data) return props.children;

  if (props.loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spinner size="m" />
      </div>
    );

  return props.children;
};
