export default {
  g: {
    flag: "🇮🇹",
    langName: "Italian",
    langNameNative: "Italiano",
    continue: "Continua",
    save: "Salva",
    retry: "Riprova",
    loading: "Caricamento...",
    genderMany: {
      all: "Tutti",
      male: "Uomini",
      female: "Donne",
    },
  },
  CreateAccount: {
    slogan: "Il luogo dove i sogni diventano vittorie!",
    gender: {
      title: "Ciao {{name}}, Tu sei...",
      male: "Uomo",
      female: "Donna",
    },
  },
  Settings: {
    title: "Impostazioni",
    language: "Lingua",
    auto: "Automatico",
    country: "Paese",
    hideProfile: {
      label: "Nascondi Profilo",
      description:
        "Il tuo profilo sarà nascosto a tutti, come se lo avessi eliminato.",
    },
    profileHidden:
      "Il tuo profilo è nascosto e non visibile agli altri utenti.",
  },
  Tabs: {
    vote: "Vota",
    contest: "Concorso",
    friends: "Amici",
    earn: "Guadagna",
    wallet: "Portafoglio",
  },
  Vote: {
    whoIsBetter: "Chi ti piace di più?",
    votingForLeader: "Votazione per il leader",
    noUsers: {
      title: "🚀 Questo è tutto per oggi",
      description: "Invita amici a continuare a votare e guadagnare monete",
      inviteFriends: "Invita amici",
    },
  },
  Leaders: {
    rewards: "Ricompense",
    comingSoon: "Prossimamente",
    prizePool: "Monte premi",
    moreInfo: "Maggiori informazioni",
    contest: "Concorso",
    richest: "I più ricchi",
    sponsors: "Sponsor",
    description: {
      likes:
        "Invita amici e condividi il tuo profilo per raccogliere più voti e aumentare le tue possibilità di vincere.",
      coins:
        "Completa le attività, vota per altri partecipanti e invita amici per guadagnare più monete e rafforzare la tua posizione.",
      stars:
        "Vota per i profili nella pagina del profilo per ricevere stelle e salire nella classifica.",
    },
  },
  PrizePool: {
    text: `<b>Posti vincenti</b>:
<b>1° posto</b>: 50% USD e 10.000 monete
<b>2° posto</b>: 30% USD e 3.000 monete
<b>3° posto</b>: 20% USD e 2.000 monete
<b>4°-10° posti</b>: 1.000 monete ciascuno
<b>11°-50° posti</b>: 500 monete ciascuno
<b>51°-100° posti</b>: 250 monete ciascuno

<b>Il montepremi</b> aumenta quando qualcuno vota per te sulla tua pagina del profilo.

<b>Condizioni</b>:
Tutti i profili che caricano una foto e completano le attività sono idonei a partecipare al concorso.

<b>Data di fine</b>:
{{endDate}}

<b>Pagamento delle ricompense</b>:
Puoi ricevere la tua ricompensa in <b>TON</b> — la criptovaluta sviluppata da Telegram.`,
  },
  LeadersItem: {
    join: "Unisciti",
  },
  Friends: {
    title: "Invita gli amici!",
    description: "Tu e il tuo amico riceverete bonus",
    bonus: {
      profit: "<coins>+{{count}}</coins> per te e il tuo amico",
      invite: {
        title: "Invita un amico",
      },
      inviteWithPremium: {
        title: "Invita un amico",
        description: "con Telegram Premium",
      },
    },
    list: {
      title: "La tua lista di amici",
    },
    inviteFriend: "Invita un amico",
    inviteText: `Unisciti a me, partecipa al voto per i ragazzi e le ragazze più belli e ricevi premi di valore!
  
  🌟 +{{coins}} monete come primo regalo
  🔥 +{{coinsPremium}} monete se hai Telegram Premium
  
  🏆 Guadagna monete per ogni voto e scegli i partecipanti più attraenti. Vuoi di più? Partecipa tu stesso e competi per premi di valore!
  
  Non perdere la tua occasione, diventa parte della nostra comunità di stelle e vinci!`,
    linkCopied: "Link copiato",
  },
  Earn: {
    title: "Guadagna più monete",
    tasksList: "Elenco dei compiti",
    check: "Controlla",
    inReview: "In revisione",
    subscribe: "Iscriviti",
    participate: "Partecipa",
    invite: "Invita",
    verify: "Verifica",
    taskCompleted: "Compito completato",
  },
  Wallet: {
    title: "Ottieni ricompense in TON",
    description:
      "Completa sfide per guadagnare TON, una criptovaluta sviluppata da Telegram",
    rewards: "Ricompense",
    withdrawal: "Prelievo",
    walletConnected: "Il tuo wallet TON è connesso",
    walletDescription: "Presto saranno disponibili più opportunità",
  },
  VerificationRequired: {
    title: "Verifica Richiesta",
    description: "Per partecipare al concorso, devi verificare il tuo account.",
    bdate: {
      title: "La tua data di nascita",
    },
  },
  Verification: {
    title: "Verifica",
    hint: "Mantieni il viso all'interno dell'ovale",
    secureHint: "Nessuno vedrà questa foto",
    success: "Hai completato la verifica con successo",
    goToApp: "Vai all'applicazione",
    verification: "Verifica in corso...",
    cameraAccessRequired:
      "Per favore, consenti l'accesso alla fotocamera per continuare",
    gettingAccessToCamer: "Ottenendo accesso alla fotocamera...",
    cameraAccessNotAllowed: "Accesso alla fotocamera negato dall'utente.",
    cameraAccessError: "Errore di accesso alla fotocamera",
    loadingHint:
      "Questo potrebbe richiedere del tempo. Per favore, sii paziente.",
  },
  Profile: {
    vote: "Vota",
    askVote: "Chiedi di Votare",
    inviteText: `Ciao! 😊 Per favore, supportami con il tuo voto nel concorso TON Stars! Ogni voto è cruciale e mi aiuta ad avvicinarmi alla vittoria di premi preziosi.

🌟 +{{coins}} monete come primo regalo
🔥 +{{coinsPremium}} monete se hai Telegram Premium

Vota per il mio profilo qui: {{url}}

Grazie per il tuo supporto! 🌟`,
    joinContest: "Partecipa al concorso",
    gender: {
      male: "Maschile",
      female: "Femminile",
    },
    photos: {
      inReview:
        "Le tue nuove foto sono in fase di revisione.\nQuesto potrebbe richiedere del tempo.",
      rejected:
        "Una o più foto sono state rifiutate per il seguente motivo:\n{{reason}}.\n\nSi prega di caricare foto diverse e riprovare.",
    },
  },
  ProfileEdit: {
    title: "Il tuo profilo",
    name: {
      label: "Il tuo nome",
      placeholder: "Inserisci il tuo nome",
    },
    instagram: {
      description: "Il profilo Instagram deve essere pubblico",
    },
    photos: {
      verificationPhotoUploaded:
        "La tua foto di verifica è stata caricata, nessuno la vedrà.",
      label: "Le tue foto",
      mainPhoto: "Foto principale",
      error:
        "Una o più foto non sono adatte, si prega di fare un'altra selezione.",
      hint: "Scegli le tue foto più impressionanti, dove non ci sono altre persone e il tuo viso è chiaramente e visibilmente visibile.",
      inReview:
        "Le tue nuove foto sono in fase di revisione.\nQuesto potrebbe richiedere del tempo.",
      rejected:
        "Una o più foto sono state rifiutate per il seguente motivo:\n{{reason}}.\n\nSi prega di caricare foto diverse e riprovare.",
    },
    submit: "Partecipa al concorso",
    photosUploading: "Caricamento foto in corso...",
    success: "Il tuo profilo è stato aggiornato",
    errors: {
      name: "Indica il tuo nome",
      photos: "Seleziona 3 foto",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto principale",
    noFace: "Il tuo viso non è stato rilevato",
    otherPeople: "La foto deve mostrare solo il tuo viso",
  },
};
