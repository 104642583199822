export default {
  g: {
    flag: "🇪🇹",
    langName: "Amharic",
    langNameNative: "አማርኛ",
    continue: "ቀጥል",
    save: "አስቀምጥ",
    retry: "እንደገና ይሞክሩ",
    loading: "በመጫን ላይ...",
    genderMany: {
      all: "ሁሉም",
      male: "ወንዶች",
      female: "ሴቶች",
    },
  },
  CreateAccount: {
    slogan: "ቦታ ሕልም ወደ ማሸነፍ ይተላለፋል!",
    gender: {
      title: "ሰላም {{name}}, አንተ/አንቺ...",
      male: "ወንድ",
      female: "ሴት",
    },
  },
  Settings: {
    title: "ቅንብሮች",
    language: "ቋንቋ",
    auto: "በራስ-ሰር",
    country: "አገር",
    hideProfile: {
      label: "መገለጫን ደብቅ",
      description: "መገለጫህ እንደ ሰርዘህ እንደሆነ ለሁሉም ተደብቆ ይኖራል።",
    },
    profileHidden: "መገለጫህ ተደብቆ ስለሆነ ለሌሎች ተጠቃሚዎች አይታይም።",
  },
  Tabs: {
    vote: "እንዲሁም",
    contest: "ውድድር",
    friends: "ጓደኞች",
    earn: "ተጣብዬ",
    wallet: "ቦርሳ",
  },
  Vote: {
    whoIsBetter: "ማንን በጣም ወድጃለህ?",
    votingForLeader: "ለመሪው ድምፅ ሰጥቶታል",
    noUsers: {
      title: "🚀 ዛሬ እስከዚህ ነው",
      description: "ጓደኞችን እንዲቀጥሉ እና ለመያዝ ጥሬ እንዲቀጥሉ ጋብዝ",
      inviteFriends: "ወዳጆችን ይጋብዙ",
    },
  },
  Leaders: {
    rewards: "እድል",
    comingSoon: "በቅርቡ",
    prizePool: "ሽልማት መያዣ",
    moreInfo: "ተጨማሪ መረጃ",
    contest: "ውድድር",
    richest: "አብዝቶ ያለ ሀብት",
    sponsors: "አስማሚዎች",
    description: {
      likes:
        "ጓደኞችን ይጋብዙ እና ይለዋወጡ ይህንን ማጠቃለያ ይተግብሩ ድምጽ ያግኙ እና ሽልማት የማገኘት እድል ያሳድጉ.",
      coins:
        "ተግባሮችን ያቅርቡ ለማንኛውም ተሳታፊ ድምጽ ይስጡ እና ጓደኞችን እንድቀጥሉ ጋብዙ እና ቦታዎችን እንድታድሩ ያግኙ.",
      stars: "ለመያዝ ስታር ያግኙ በግለ ገጽ ይህንን ማጠቃለያ ይተግብሩ እና በተለያዩ ቦታዎች ውስጥ ይወጡ.",
    },
  },
  PrizePool: {
    text: `<b>ሽልማት የሚከተሉ ቦታዎች</b>:
<b>1ኛ ቦታ</b>: 50% USD እና 10,000 ኮይኖች
<b>2ኛ ቦታ</b>: 30% USD እና 3,000 ኮይኖች
<b>3ኛ ቦታ</b>: 20% USD እና 2,000 ኮይኖች
<b>4ኛ-10ኛ ቦታዎች</b>: እያንዳንዱ 1,000 ኮይኖች
<b>11ኛ-50ኛ ቦታዎች</b>: እያንዳንዱ 500 ኮይኖች
<b>51ኛ-100ኛ ቦታዎች</b>: እያንዳንዱ 250 ኮይኖች

<b>የሽልማት መያዣ</b> ይጨምራል በግለ ገጽ ላይ ማንኛውም ተሳታፊ ሲድምጹ ለእርስዎ ይጨምራል.

<b>ሁኔታዎች</b>:
እንዲገባ ለሁሉም ተሳታፊዎች ያስገባ እና አስፈላጊ ተግባሮችን ያከናውናል.

<b>ለመጠናቀቅ ቀን</b>:
{{endDate}}

<b>የሽልማት እንዲሰጠው እንደሚገባ ዝግጅት</b>:
እርስዎ በ<b>TON</b> እንደተጠቀም ሽልማት ማግኘት ይችላሉ።`,
  },
  LeadersItem: {
    join: "ተቀላቀል",
  },
  Friends: {
    title: "ጓደኞችን ይጋብዙ!",
    description: "እርስዎና ጓደኛዎ ብዙ ብዙ ብቅዶች ያገኛሉ",
    bonus: {
      profit: "<coins>+{{count}}</coins> ለእርስዎና ለጓደኛዎ",
      invite: {
        title: "ጓደኛ ይጋብዙ",
      },
      inviteWithPremium: {
        title: "ጓደኛ ይጋብዙ",
        description: "ከተለጋም ፕሪሚየም ጋር",
      },
    },
    list: {
      title: "የጓደኞችዎ ዝርዝር",
    },
    inviteFriend: "ጓደኛ ይጋብዙ",
    inviteText: `እኔን ተከተሉ, ለቆንጆ ወንዶችና ሴቶች ተመልከትና እና እኩል ዋጋዎችን እንግደው!
  
  🌟 +{{coins}} ኮይኖች እንደ የመጀመሪያ ስጦታ
  🔥 +{{coinsPremium}} ኮይኖች እንደ የቴሌግራም ፕሪሚየም ተጠቃሚ
  
  🏆 ለእያንዳንዱ ተመልካቾች ኮይኖችን ይካፍሉ እና ተሳታፊዎችን ይምረጡ። በተጨማሪም እርስዎም ተሳትፊ ይሁኑና ለእኩል ዋጋዎች ይከተሉ!
  
  ዕድሉን አታጣም, ወደ ኮከብችን ክህሎት እና ምንጊዜ እንድሆን!`,
    linkCopied: "አገናኝ ተቀድቷል",
  },
  Earn: {
    title: "ተጨማሪ ኮይኖችን እንድታገኝ",
    tasksList: "ተግባሮች ዝርዝር",
    check: "አረጋግጥ",
    inReview: "እየተገመገሙ ነው",
    subscribe: "ይመዝገቡ",
    participate: "ተሳተፍ",
    invite: "ይጋብዙ",
    verify: "ያረጋግጡ",
    taskCompleted: "ተግባሩ ተጠናቋል",
  },
  Wallet: {
    title: "በTON ውስጥ ሽልማት ያግኙ",
    description: "ተግባሮችን ያቅርቡ እና በTelegram የተነደፈ የክሪፕቶ ኮይን TON ያግኙ",
    rewards: "ሽልማቶች",
    withdrawal: "ወጪ",
    walletConnected: "የእርስዎ የTON ቦርሳ ተገናኝቷል",
    walletDescription: "በቅርቡ ብዙ እድሎች ይገኛሉ",
  },
  VerificationRequired: {
    title: "ማረጋገጫ ያስፈልጋል",
    description: "በውድድር ለመሳተፍ መለያዎን ማረጋገጥ አለብዎት።",
    bdate: {
      title: "የትውልድ ቀንህ",
    },
  },
  Verification: {
    title: "ማረጋገጫ",
    hint: "ፊትዎን በኦቬል ውስጥ ያስቀምጡ",
    secureHint: "ማንም ይህን ፎቶ አይረካም",
    success: "በተሳካ ሁኔታ አረጋግጠዋል",
    goToApp: "ወደ መተግበሪያው ሂድ",
    verification: "በማረጋገጥ ላይ…",
    cameraAccessRequired: "ለመቀጠል ካሜራ መጠቀም እባኮትን ይሁኑ",
    gettingAccessToCamer: "ካሜራ መጠቀም…",
    cameraAccessNotAllowed: "እርስዎ በካሜራ ገለል እንደተከለክለት።",
    cameraAccessError: "በካሜራ ግልጋሎት ላይ ስህተት ተከስቷል",
    loadingHint: "ይህ ጥቂት ጊዜ ሊወስድ ይችላል። እባኮትን ትንሽ ትዕግሥት ያድርጉ።",
  },
  Profile: {
    vote: "ድምጽ ስጥ",
    askVote: "ድምጽ እንዲሰጡ ጠይቅ",
    inviteText: `ሰላም! 😊 እባክህ በTON Stars ውድድር ውስጥ በድምጽህ ደግፈኝ! እያንዳንዱ ድምጽ አስፈላጊ ነው እና ዋጋ ያላቸውን ሽልማቶች ለማሸነፍ ወደቀረበ ያደርሰኛል።

🌟 +{{coins}} እንደ መጀመሪያ ስጦታ አንድ እቃ
🔥 +{{coinsPremium}} ኮይኖች ካለህ Telegram Premium ካለው

በዚህ ላይ የግል መገለጫዬን ድምጽ ስጥ: {{url}}

ስለ ደግፍህ እናመሰግናለን! 🌟`,
    joinContest: "ወደ ውድድር ተጠቃሚ ይሁኑ",
    gender: {
      male: "ወንድ",
      female: "ሴት",
    },
    photos: {
      inReview: "አዳዲስ ፎቶዎችዎ በማጽናት ላይ ናቸው።\nይህ ጥቂት ጊዜ ሊወስድ ይችላል።",
      rejected:
        "አንድ ወይም ከዚህ በላይ ፎቶዎች በዚህ ምክንያት ተቀባይነት አላገኙም፦\n{{reason}}.\n\nበእባክዎ ሌላ ፎቶ ስቀሉና እንደገና ይሞክሩ።",
    },
  },
  ProfileEdit: {
    title: "የእርስዎ መገለጫ",
    name: {
      label: "ስምዎ",
      placeholder: "ስምዎን ያስገቡ",
    },
    instagram: {
      description: "የInstagram መገለጫ መክፈት አለበት",
    },
    photos: {
      verificationPhotoUploaded: "የማረጋገጫ ፎቶዎ ተሰቅሏል፣ ማንም አያይውም።",
      label: "የእርስዎ ፎቶዎች",
      mainPhoto: "ዋና ፎቶ",
      error: "አንድ ወይም ከዚህ በላይ ፎቶዎች ተስማማ የማይሆኑ ናቸው፣ እባክዎ ሌላ ምርጫ ይድረጉ።",
      hint: "በሌላ ሰው እንዳልሆነው የተገለጸና በግልፅ የሚታይ የቆምዎትን ፎቶዎች ይምረጡ።",
      inReview: "አዳዲስ ፎቶዎችዎ በማጽናት ላይ ናቸው።\nይህ ጥቂት ጊዜ ሊወስድ ይችላል።",
      rejected:
        "አንድ ወይም ከዚህ በላይ ፎቶዎች በዚህ ምክንያት ተቀባይነት አላገኙም፦\n{{reason}}.\n\nበእባክዎ ሌላ ፎቶ ስቀሉና እንደገና ይሞክሩ።",
    },
    submit: "ወደ ውድድር ተጠቃሚ ይሁኑ",
    photosUploading: "ፎቶ በመስቀል ላይ...",
    success: "መገለጫዎ ተዘመነ",
    errors: {
      name: "እባክዎን ስምዎን ያስገቡ",
      photos: "እባክዎን 3 ፎቶዎችን ይምረጡ",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "ዋና ፎቶ",
    noFace: "ፊትዎ አልተገኘም",
    otherPeople: "የፎቶው ውስጥ እንዲሆኑ ያልተፈለጉ ሰዎች መኖር የለበትም",
  },
};
