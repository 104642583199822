export default {
  g: {
    flag: "🇹🇲",
    langName: "Turkmen",
    langNameNative: "Türkmençe",
    continue: "Dowam et",
    save: "Ýatda sakla",
    retry: "Gaýtadan synanyş",
    loading: "Ýüklenýär...",
    genderMany: {
      all: "Ähli",
      male: "Erkekler",
      female: "Aýallar",
    },
  },
  CreateAccount: {
    slogan: "Hayallerin zaferlere dönüştüğü yer!",
    gender: {
      title: "Salam {{name}}, Sen...",
      male: "Erkek",
      female: "Kadın",
    },
  },
  Settings: {
    title: "Sazlamalar",
    language: "Dil",
    auto: "Awto",
    country: "Ýurt",
    hideProfile: {
      label: "Profili gizle",
      description: "Profiliňiz hemmelerden gizlener, ony öçüren ýaly.",
    },
    profileHidden: "Profiliňiz gizlendi we beýleki ulanyjylar üçin görünmeýär.",
  },
  Tabs: {
    vote: "Ses ber",
    contest: "Bäsleşik",
    friends: "Dostlar",
    earn: "Gazanç et",
    wallet: "Gapjy",
  },
  Vote: {
    whoIsBetter: "Kimi has oňat görýärsiňiz?",
    votingForLeader: "Lider üçin ses berişlik",
    noUsers: {
      title: "🚀 Ine, bu günlik şeýle",
      description:
        "Ses bermäge we teňňeleri gazanmaga dowam etmek üçin dostlaryňyzy çagyryň",
      inviteFriends: "Dostlary Çagyryň",
    },
  },
  Leaders: {
    rewards: "Baýraklar",
    comingSoon: "Garaşyň",
    prizePool: "Baýrak gaznasy",
    moreInfo: "Köpräk maglumat",
    contest: "Bäsleşik",
    richest: "Baýlar",
    sponsors: "Howandarlyk edýänler",
    description: {
      likes:
        "Dostlaryňyzy çagyryň we has köp ses almak we ýeňiş gazanmak mümkinçiligiňizi ýokarlandyrmak üçin profilňizi paýlaşyň.",
      coins:
        "Wezipeleri tamamlaň, beýleki gatnaşyjylara ses beriň we has köp teňňe gazanmak we öz ýagdaýyňyzy berkitmek üçin dostlaryňyzy çagyrň.",
      stars:
        "Ýyldyz almak we derejelerde ýokary galmak üçin profil sahypasynda profiller üçin ses beriň.",
    },
  },
  PrizePool: {
    text: `<b>Baýrakly ýerler</b>:
<b>1-nji ýer</b>: 50% USD we 10,000 teňňe
<b>2-nji ýer</b>: 30% USD we 3,000 teňňe
<b>3-nji ýer</b>: 20% USD we 2,000 teňňe
<b>4-nji-10-njy ýerler</b>: hersi 1,000 teňňe
<b>11-nji-50-nji ýerler</b>: hersi 500 teňňe
<b>51-nji-100-nji ýerler</b>: hersi 250 teňňe

<b>Baýrak gaznasy</b> kimdir biri profil sahypaňyzda size ses berse artýar.

<b>Şertler</b>:
Suraty ýüklän we wezipeleri tamamlan ähli profiller bäsleşige gatnaşmaga hukukly.

<b>Gutarýan senesi</b>:
{{endDate}}

<b>Baýragyň tölegi</b>:
Baýragyňyzy <b>TON</b> - Telegram tarapyndan işlenip düzülen kriptowalýutada alyp bilersiňiz.`,
  },
  LeadersItem: {
    join: "Goşul",
  },
  Friends: {
    title: "Dostlaryňyzy Çagyryň!",
    description: "Siz we siziň dostuňyz bonus alarsyňyz",
    bonus: {
      profit: "<coins>+{{count}}</coins> siz we siziň dostuňyz üçin",
      invite: {
        title: "Dosty Çagyryň",
      },
      inviteWithPremium: {
        title: "Dosty Çagyryň",
        description: "Telegram Premium bilen",
      },
    },
    list: {
      title: "Siziň dostlaryňyzyň sanawy",
    },
    inviteFriend: "Dosty Çagyryň",
    inviteText: `Maňa goşulyň, iň owadan oglanlara we gyzlara ses beriň we gymmatly baýraklary alyň!
  
  🌟 Ilkinji sowgat hökmünde +{{coins}} teňňe
  🔥 Telegram Premium bolsa +{{coinsPremium}} teňňe
  
  🏆 Her ses üçin teňňe gazanyň we iň özüne çekiji gatnaşyjylary saýlaň. Köp zat isleýärsiňizmi? Özüňiz gatnaşyp gymmatly baýraklar üçin bäsleşiň!
  
  Şansyňyzy sypdyrmaň, ýyldyzlar jemgyýetimiziň bir bölegi boluň we ýeňiň!`,
    linkCopied: "Baglanyşyk göçürildi",
  },
  Earn: {
    title: "Köp teňňe gazanyň",
    tasksList: "Wezipeler sanawy",
    check: "Barla",
    inReview: "Gözden geçirýär",
    subscribe: "Abuna bol",
    participate: "Gatnaş",
    invite: "Çagyryň",
    verify: "Tassyklaň",
    taskCompleted: "Wazyp tamamlady",
  },
  Wallet: {
    title: "TON-da sylaglar alyň",
    description:
      "Telegram tarapyndan işlenip düzülen kripto pul TON gazanmak üçin wezipeleri ýerine ýetiriň",
    rewards: "Sylaglar",
    withdrawal: "Yzyňyzy almak",
    walletConnected: "TON gapjygyňyz baglandy",
    walletDescription: "Has köp mümkinçilikler ýakyn wagtda elýeterli bolar",
  },
  VerificationRequired: {
    title: "Tassyklaýyş Zerur",
    description: "Bäsleşige gatnaşmak üçin hasabyňyzy tassyklaň.",
    bdate: {
      title: "Doglan günüňiz",
    },
  },
  Verification: {
    title: "Tassyklaýyş",
    hint: "Ýüzüňizi owalda saklaň",
    secureHint: "Bu suraty hiç kim görmez",
    success: "Siz tassyklaýşy üstünlikli tamamladyňyz",
    goToApp: "Programma git",
    verification: "Tassyklaýar...",
    cameraAccessRequired: "Dowam etmek üçin kamera girmäge rugsat beriň",
    gettingAccessToCamer: "Kamera girmegi alýar...",
    cameraAccessNotAllowed: "Ulanyjy tarapyndan kamera girmegi inkär edildi.",
    cameraAccessError: "Kamera girmäge säwlik",
    loadingHint: "Bu belli bir wagt alýar. Sabyr ediň.",
  },
  Profile: {
    vote: "Ses beriň",
    askVote: "Ses Soramak",
    inviteText: `Salam! 😊 TON Stars bäsleşiginde maňa ses bermek bilen goldaw bermegiňizi haýyş edýärin! Her bir ses möhümdir we meni gymmatly baýraklary gazanmaga has ýakynlaşdyrýar.

🌟 Ilkinji sowgat hökmünde +{{coins}} teňňe
🔥 Telegram Premium bar bolsa +{{coinsPremium}} teňňe

Meniň profilime şu ýerde ses beriň: {{url}}

Goldawyňyz üçin sag boluň! 🌟`,
    joinContest: "Bäsleşige goşul",
    gender: {
      male: "Erkek",
      female: "Aýal",
    },
    photos: {
      inReview:
        "Täze suratlaryňyz gözden geçirilýär.\nBu biraz wagt almagy mümkin.",
      rejected:
        "Bir ýa-da birnäçe surat şu sebäpden kabul edilmedi:\n{{reason}}.\n\nBaşga suratlary ýükläň we täzeden synanyşyň.",
    },
  },
  ProfileEdit: {
    title: "Siziň Profil",
    name: {
      label: "Siziň Adyňyz",
      placeholder: "Adyňyzy giriziň",
    },
    instagram: {
      description: "Instagram profiliniň açyk bolmagy gerek",
    },
    photos: {
      verificationPhotoUploaded:
        "Tassyklaýyş suratyňyz ýüklenildi, ony hiç kim görmez.",
      label: "Siziň Suratlaryňyz",
      mainPhoto: "Baş Surat",
      error: "Bir ýa-da birnäçe surat laýyk däl, başga saýlaw ediň.",
      hint: "Başga adamlaryň bolmadyk, ýüziňiz aç-açan we aýdyň görünýän iň täsirli suratlaryňyzy saýlaň.",
      inReview:
        "Täze suratlaryňyz gözden geçirilýär.\nBu biraz wagt almagy mümkin.",
      rejected:
        "Bir ýa-da birnäçe surat şu sebäpden kabul edilmedi:\n{{reason}}.\n\nBaşga suratlary ýükläň we täzeden synanyşyň.",
    },
    submit: "Bäsleşige Goşul",
    photosUploading: "Surat ýükläp...",
    success: "Profilňiz täzelendi",
    errors: {
      name: "Adyňyzy giriziň",
      photos: "3 surat saýlaň",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Baş surat",
    noFace: "Ýüziňiz anyklanmady",
    otherPeople: "Surat diňe ýüziňizi görkezmeli",
  },
};
