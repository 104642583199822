import { nanoid } from "@reduxjs/toolkit";
import WebApp from "@twa-dev/sdk";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "store";
import {
  addMainButton,
  removeMainButton,
  selectMainButtonStack,
} from "store/settings";

type UseMainButtonProps = {
  text: string;
  loading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  visible?: boolean;
};
export const useMainButton = (props: UseMainButtonProps) => {
  const visible = props.visible === undefined ? true : props.visible;

  const id = useMemo(() => nanoid(), []);
  const stack = useSelector(selectMainButtonStack);
  const lastButton = useMemo(() => stack[stack.length - 1], [stack]);

  const isActive = useMemo(() => id === lastButton, [id, lastButton]);

  useEffect(() => {
    dispatch(addMainButton(id));
    return () => {
      dispatch(removeMainButton(id));
    };
  }, [id]);

  useEffect(() => {
    if (!isActive) return;

    WebApp.MainButton.onClick(props.onClick);

    return () => {
      WebApp.MainButton.offClick(props.onClick);
    };
  }, [isActive, props.onClick]);

  useEffect(() => {
    if (!isActive) return;

    WebApp.MainButton.text = props.text;

    props.loading
      ? WebApp.MainButton.showProgress()
      : WebApp.MainButton.hideProgress();
    return () => {
      WebApp.MainButton.hideProgress();
    };
  }, [isActive, props.loading, props.text]);

  useEffect(() => {
    if (!isActive) return;

    WebApp.MainButton.isVisible = visible;
    return () => {
      WebApp.MainButton.isVisible = false;
    };
  }, [isActive, visible]);

  useEffect(() => {
    props.disabled ? WebApp.MainButton.disable() : WebApp.MainButton.enable();
  }, [props.disabled]);
};
