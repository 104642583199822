export default {
  g: {
    flag: "🇲🇲",
    langName: "Burmese",
    langNameNative: "မြန်မာ",
    continue: "ဆက်လက်လုပ်ဆောင်ရန်",
    save: "သိမ်းမည်",
    retry: "နောက်တစ်ကြိမ် ပြန်လည်ကြိုးစားမည်",
    loading: "ဖွင့်လှစ်နေသည်...",
    genderMany: {
      all: "အားလုံး",
      male: "ယောကျာ်းလေးတွေ",
      female: "မိန်းကလေးတွေ",
    },
  },
  CreateAccount: {
    slogan: "အိပ်မက်တွေ အောင်မြင်မှုတွေအဖြစ် ပြောင်းလဲတဲ့ နေရာ!",
    gender: {
      title: "ဟယ်လို {{name}}၊ သင်က...",
      male: "အမျိုးသား",
      female: "အမျိုးသမီး",
    },
  },
  Settings: {
    title: "ဆက်တင်များ",
    language: "ဘာသာစကား",
    auto: "အော်တို",
    country: "နိုင်ငံ",
    hideProfile: {
      label: "ပရိုဖိုင်ကိုဖျောက်ပါ",
      description:
        "သင့်ပရိုဖိုင်ကိုဖျက်သိမ်းထားသလို၊ တစ်စက်မကျန်လျက် ဖျောက်ထားမည်။",
    },
    profileHidden:
      "သင့်ပရိုဖိုင်သည်ဖျောက်ထားပြီး၊ အခြားအသုံးပြုသူများ၏ကြည့်ရှုမှုအတွက် မရှိပါ။",
  },
  Tabs: {
    vote: "မဲပေးပါ",
    contest: "ပြိုင်ပွဲ",
    friends: "မိတ်ဆွေများ",
    earn: "ဝင်ငွေရှာမည်",
    wallet: "ပိုက်ဆံအိတ်",
  },
  Vote: {
    whoIsBetter: "သင်ဘယ်သူကို ပိုကြိုက်လဲ?",
    votingForLeader: "ခေါင်းဆောင်အတွက် မဲပေးခြင်း",
    noUsers: {
      title: "🚀 ဒီနေ့အတွက် အကုန်ပြီးပါပြီ",
      description:
        "မဲပေးဆွဲခြင်းနှင့် မြေပိုင်းရရှိခြင်းကို ဆက်လက်လုပ်ဆောင်ရန် သူငယ်ချင်းများကို ဖိတ်ကြားပါ",
      inviteFriends: "သူငယ်ချင်းများကို ဖိတ်ကြားပါ",
    },
  },
  Leaders: {
    rewards: "ဆုများ",
    comingSoon: "မကြာမီ",
    prizePool: "ဆုငွေအလောင်း",
    moreInfo: "ပိုမိုသိရှိရန်",
    contest: "ပြိုင်ပွဲ",
    richest: "အင်မတန်ချမ်းသာသူများ",
    sponsors: "အထောက်အပံ့ပေးသူများ",
    description: {
      likes:
        "အမြဲတမ်းပြိုင်ပွဲများတွင်ပါဝင်ရန် သင့်မိတ်ဆွေများကို ဖိတ်ကြားပြီး သင့်ရဲ့ပရိုဖိုင်ကိုမျှဝေပေးပါ။",
      coins:
        "တာဝန်များကိုပြီးမြောက်ပါ၊ အခြားပါဝင်သူများကိုမဲပေးပါ၊ သင့်မိတ်ဆွေများကို ဖိတ်ကြားပါ၊ အပိုဆုငွေများကိုစုဆောင်းပါ၊ သင့်ရဲ့အနေအထားကိုပြင်းထန်စေပါ။",
      stars:
        "အဆင့်များကိုအဆင့်မြင့်စေခြင်းနှင့် မိမိစရင်မှာမဲပေးခြင်းအားဖြင့် ရွေးချယ်မှုအဆင့်တက်ရန် မဲပေးပါ။",
    },
  },
  PrizePool: {
    text: `<b>ဆုရသည့်နေရာများ</b>:
<b>1st place</b>: 50% USD နှင့် 10,000 ကွင်းရိယာ
<b>2nd place</b>: 30% USD နှင့် 3,000 ကွင်းရိယာ
<b>3rd place</b>: 20% USD နှင့် 2,000 ကွင်းရိယာ
<b>4th-10th place</b>: 1,000 ကွင်းရိယာ
<b>11th-50th place</b>: 500 ကွင်းရိယာ
<b>51st-100th place</b>: 250 ကွင်းရိယာ

<b>ဆုငွေ</b> သင်၏ပရိုဖိုင်စာမျက်နှာတွင်သူတစ်ပါးက မဲပေးလျှင်တိုးပွားလာသည်။

<b>လိုအပ်ချက်များ</b>:
ဓာတ်ပုံတင်၍တာဝန်များကိုဖြည့်စွက်သော အမျိုးသားများအားလုံးပြိုင်ပွဲတွင်ပါဝင်ရန် အရည်အချင်းပြည့်မီသည်။

<b>ပြီးဆုံးရက်စွဲ</b>:
{{endDate}}

<b>ဆုငွေပေးချေခြင်း</b>:
သင်၏ဆုကို Telegram မှဖန်တီးသော <b>TON</b> - crypto- currency တွင်လက်ခံနိုင်ပါသည်။`,
  },
  LeadersItem: {
    join: "ပါဝင်ရန်",
  },
  Friends: {
    title: "မိတ်ဆွေများကို ဖိတ်ခေါ်ပါ!",
    description: "သင်နှင့် သင့်မိတ်ဆွေသည် ဘောနပ်စ်များရရှိမည်",
    bonus: {
      profit: "<coins>+{{count}}</coins> သင့်နှင့် သင့်မိတ်ဆွေအတွက်",
      invite: {
        title: "မိတ်ဆွေကို ဖိတ်ခေါ်ပါ",
      },
      inviteWithPremium: {
        title: "မိတ်ဆွေကို ဖိတ်ခေါ်ပါ",
        description: "Telegram Premium နှင့်",
      },
    },
    list: {
      title: "သင့်မိတ်ဆွေများ၏ စာရင်း",
    },
    inviteFriend: "မိတ်ဆွေကို ဖိတ်ခေါ်ပါ",
    inviteText: `ကျွန်ုပ်နှင့်အတူပါဝင်ပါ၊ အကောင်းဆုံးအမျိုးသားများနှင့် အမျိုးသမီးများကို မဲပေးပြီး တန်ဖိုးရှိသော ဆုများကို ရယူပါ!
  
  🌟 +{{coins}} ပထမဆုံးလက်ဆောင်အဖြစ် ရတနာ
  🔥 +{{coinsPremium}} Telegram Premium ရှိပါက
  
  🏆 မဲပေးတိုင်း ရတနာများရှာပြီး အဆွေကြည်သောပါဝင်သူများကို ရွေးချယ်ပါ။ ပိုပြီးစိတ်ဝင်စားပါသလား? ကိုယ်တိုင် ပါဝင်ပြီး တန်ဖိုးရှိသော ဆုများအတွက် ယှဉ်ပြိုင်ပါ!
  
  သင့်အခွင့်အရေးကို လက်လွတ်မခံပါနဲ့၊ ကျွန်ုပ်တို့၏ ကြယ်အဖွဲ့အစည်း၏ အစိတ်အပိုင်းဖြစ်ပြီး အောင်မြင်ပါ!`,
    linkCopied: "လင့်ကိုကူးယူပြီးပါပြီ",
  },
  Earn: {
    title: "ပိုပြီး ရတနာများ ရပါ",
    tasksList: "အလုပ်များစာရင်း",
    check: "စစ်ဆေးပါ",
    inReview: "ပြန်လည်သုံးသပ်နေသည်",
    subscribe: "Subscribe လုပ်ပါ",
    participate: "ပါဝင်ပါ",
    invite: "ဖိတ်ခေါ်ပါ",
    verify: "အတည်ပြုပါ",
    taskCompleted: "အလုပ်ကို ပြီးစီးပါပြီ",
  },
  Wallet: {
    title: "TON တွင်ဆုများရယူပါ",
    description:
      "Telegram ဖွံ့ဖြိုးတီထွင်ထားသော TON ချိန်းဖြစ် coin ရရှိရန် မျှော်လင့်ချက်များကို ပြီးမြောက်စေပါ",
    rewards: "ဆုများ",
    withdrawal: "ထုတ်ယူမှု",
    walletConnected: "သင့် TON ရင်းနှီးမြှုပ်နှံထားသည်",
    walletDescription: "အခြားအခွင့်အလမ်းများ မကြာခင် ရရှိနိုင်ပါလိမ့်မည်",
  },
  VerificationRequired: {
    title: "အတည်ပြုချက် လိုအပ်သည်",
    description: "ပြိုင်ပွဲတွင် ပါဝင်ရန် သင့်အကောင့်ကို အတည်ပြုရမည်။",
    bdate: {
      title: "သင့်မွေးနေ့",
    },
  },
  Verification: {
    title: "အတည်ပြုချက်",
    hint: "မျက်နှာကို သရေစာထဲမှာ ထိန်းထားပါ",
    secureHint: "ဤဓာတ်ပုံကို မည်သူမျှ မမြင်ရပါ",
    success: "သင်အတည်ပြုပြီးပါပြီ",
    goToApp: "အပလီကေးရှင်းသို့ သွားပါ",
    verification: "အတည်ပြုနေသည်...",
    cameraAccessRequired: "ဆက်လုပ်ရန် camera ခွင့်ပြုပါ",
    gettingAccessToCamer: "camera ခွင့်ပြုချက်ရယူနေသည်...",
    cameraAccessNotAllowed: "အသုံးပြုသူက camera ခွင့်ပြုချက် ငြင်းဆိုခဲ့သည်။",
    cameraAccessError: "camera ခွင့်ပြုချက်အမှား",
    loadingHint: "ဤအရာအချိန်အနည်းငယ်ယူနိုင်သည်။ ကျေးဇူးပြု၍ စောင့်ရှောက်ပါ။",
  },
  Profile: {
    vote: "မဲပေးပါ",
    askVote: "မဲပေးရန်တောင်းပါ",
    inviteText: `ဟယ်လို! 😊 TON Stars ပြိုင်ပွဲတွင် မင်းရဲ့ မဲနှင့် ကျွန်တော့်ကို အထောက်အပံ့ပြုပါ။ မဲတိုင်းသည် အရေးကြီးပြီး တန်ဖိုးရှိသော ဆုများကို ရယူရန် ကျွန်တော့်ကို ပိုမိုနီးစပ်စေပါသည်။

🌟 ပထမဆုံးလက်ဆောင်အဖြစ် +{{coins}} ကွိုင်
🔥 Telegram Premium ရှိလျှင် +{{coinsPremium}} ကွိုင်

ကျွန်တော့် ပရိုဖိုင်အတွက် ဒီမှာ မဲပေးပါ: {{url}}

သင့်ထောက်ခံမှုအတွက် ကျေးဇူးတင်ပါတယ်! 🌟`,
    joinContest: "ပြိုင်ပွဲဝင်ပါ",
    gender: {
      male: "အမျိုးသား",
      female: "အမျိုးသမီး",
    },
    photos: {
      inReview:
        "သင့်ရဲ့ဓာတ်ပုံအသစ်တွေကိုပြန်လည်သုံးသပ်နေပါတယ်။\nဒါကိုအချိန်အနည်းငယ်ယူနိုင်ပါတယ်။",
      rejected:
        "ဓာတ်ပုံတစ်ပုံ သို့မဟုတ်ပို၍ နောက်ပါသော အကြောင်းကြောင့် ငြင်းပယ်ခံရပါတယ်:\n{{reason}}.\n\nကွဲပြားတဲ့ဓာတ်ပုံတွေတင်ပြီး ထပ်စမ်းကြည့်ပါ။",
    },
  },
  ProfileEdit: {
    title: "သင့်ရဲ့ကိုယ်ရေးအကျဉ်း",
    name: {
      label: "နာမည်",
      placeholder: "သင့်နာမည်ကိုရိုက်ထည့်ပါ",
    },
    instagram: {
      description: "Instagram ကိုယ်ရေးအကျဉ်းကိုအများသိနိုင်ရမည်",
    },
    photos: {
      verificationPhotoUploaded:
        "သင်၏အတည်ပြုချက်ဓာတ်ပုံကို တင်ပြီးဖြစ်သည်၊ မည်သူမဆို ၎င်းကို မမြင်ရပါ။",
      label: "သင့်ဓာတ်ပုံများ",
      mainPhoto: "အဓိကဓာတ်ပုံ",
      error: "ဓာတ်ပုံတစ်ပုံ သို့မဟုတ်ပို၍ မသင့်တော်ပါ၊ တစ်ခုခုရွေးပါ။",
      hint: "အခြားလူမပါဘဲ သင့်ရဲ့မျက်နှာအလွန်ရှင်းလင်းပြီး ထင်ထင်ရှားရှားမြင်နိုင်တဲ့ အဓိကဓာတ်ပုံတွေရွေးပါ။",
      inReview:
        "သင့်ရဲ့ဓာတ်ပုံအသစ်တွေကိုပြန်လည်သုံးသပ်နေပါတယ်။\nဒါကိုအချိန်အနည်းငယ်ယူနိုင်ပါတယ်။",
      rejected:
        "ဓာတ်ပုံတစ်ပုံ သို့မဟုတ်ပို၍ နောက်ပါသော အကြောင်းကြောင့် ငြင်းပယ်ခံရပါတယ်:\n{{reason}}.\n\nကွဲပြားတဲ့ဓာတ်ပုံတွေတင်ပြီး ထပ်စမ်းကြည့်ပါ။",
    },
    submit: "ပြိုင်ပွဲဝင်ပါ",
    photosUploading: "ဓာတ်ပုံတင်နေသည်...",
    success: "သင့်ရဲ့ကိုယ်ရေးအကျဉ်းကိုအပ်ဒိတ်လုပ်ပြီးပါပြီ",
    errors: {
      name: "သင့်နာမည်ကိုရိုက်ထည့်ပါ",
      photos: "ဓာတ်ပုံ ၃ ပုံကို ရွေးပါ",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "အဓိကဓာတ်ပုံ",
    noFace: "သင့်မျက်နှာကိုမတွေ့ပါ",
    otherPeople: "ဓာတ်ပုံမှာသင့်မျက်နှာသာရှိရမည်",
  },
};
