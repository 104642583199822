import af from "./af";
import bg from "./bg";
import de from "./de";
import et from "./et";
import fr from "./fr";
import hi from "./hi";
import it from "./it";
import ko from "./ko";
import ms from "./ms";
import or from "./or";
import ru from "./ru";
import sv from "./sv";
import th from "./th";
import uz from "./uz";
import am from "./am";
import bn from "./bn";
import el from "./el";
import eu from "./eu";
import ga from "./ga";
import hr from "./hr";
import ja from "./ja";
import lt from "./lt";
import mt from "./mt";
import pl from "./pl";
import sk from "./sk";
import sw from "./sw";
import tk from "./tk";
import vi from "./vi";
import ar from "./ar";
import ca from "./ca";
import en from "./en";
import fa from "./fa";
import gl from "./gl";
import hu from "./hu";
import kk from "./kk";
import lv from "./lv";
import my from "./my";
import ptBr from "./ptBR";
import sl from "./sl";
import ta from "./ta";
import tr from "./tr";
import zhHans from "./zhHans";
import az from "./az";
import cs from "./cs";
import eo from "./eo";
import fi from "./fi";
import gu from "./gu";
import id from "./id";
import km from "./km";
import ml from "./ml";
import nb from "./nb";
import ptPt from "./ptPT";
import sq from "./sq";
import te from "./te";
import uk from "./uk";
import zhHant from "./zhHant";
import be from "./be";
import da from "./da";
import es from "./es";
import fil from "./fil";
import he from "./he";
import kn from "./kn";
import mr from "./mr";
import nl from "./nl";
import ro from "./ro";
import sr from "./sr";
import tg from "./tg";
import ur from "./ur";

const resources = {
  af,
  bg,
  de,
  et,
  fr,
  hi,
  it,
  ko,
  ms,
  or,
  ru,
  sv,
  th,
  uz,
  am,
  bn,
  el,
  eu,
  ga,
  hr,
  ja,
  lt,
  mt,
  pl,
  sk,
  sw,
  tk,
  vi,
  ar,
  ca,
  en,
  fa,
  gl,
  hu,
  kk,
  lv,
  my,
  "pt-BR": ptBr,
  sl,
  ta,
  tr,
  "zh-Hans": zhHans,
  az,
  cs,
  eo,
  fi,
  gu,
  id,
  km,
  ml,
  nb,
  "pt-PT": ptPt,
  sq,
  te,
  uk,
  "zh-Hant": zhHant,
  be,
  da,
  es,
  fil,
  he,
  kn,
  mr,
  nl,
  ro,
  sr,
  tg,
  ur,
} as const;

export type LangCodes = keyof typeof resources;
export const langsList = Object.entries(resources).map(([code, _]) => ({
  code: code as LangCodes,
  flag: _.g.flag,
  name: _.g.langName,
  nameNative: _.g.langNameNative,
}));
export type LangsList = (typeof langsList)[0];

export type SupportedLanguages = keyof typeof resources;
export const supportedLanguages = Object.keys(
  resources
) as SupportedLanguages[];

export default resources;
