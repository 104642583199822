export default {
  g: {
    flag: "🇹🇷",
    langName: "Turkish",
    langNameNative: "Türkçe",
    continue: "Devam et",
    save: "Kaydet",
    retry: "Tekrar dene",
    loading: "Yükleniyor...",
    genderMany: {
      all: "Hepsi",
      male: "Erkekler",
      female: "Kadınlar",
    },
  },
  CreateAccount: {
    slogan: "Hayallerin zaferlere dönüştüğü yer!",
    gender: {
      title: "Merhaba {{name}}, Sen...",
      male: "Erkek",
      female: "Kadın",
    },
  },
  Settings: {
    title: "Ayarlar",
    language: "Dil",
    auto: "Otomatik",
    country: "Ülke",
    hideProfile: {
      label: "Profili Gizle",
      description:
        "Profiliniz herkesin önünden gizlenecek, sanki onu silmişsiniz gibi.",
    },
    profileHidden:
      "Profiliniz gizli ve diğer kullanıcılar tarafından görünmüyor.",
  },
  Tabs: {
    vote: "Oy ver",
    contest: "Yarışma",
    friends: "Arkadaşlar",
    earn: "Kazan",
    wallet: "Cüzdan",
  },
  Vote: {
    whoIsBetter: "Kimi daha çok seversin?",
    votingForLeader: "Lider için oy ver",
    noUsers: {
      title: "🚀 Bugünlük bu kadar",
      description:
        "Oy vermeye devam etmek ve jeton kazanmak için arkadaşlarını davet et",
      inviteFriends: "Arkadaşları Davet Et",
    },
  },
  Leaders: {
    rewards: "Ödüller",
    comingSoon: "Çok yakında",
    prizePool: "Ödül Havuzu",
    moreInfo: "Daha fazla bilgi",
    contest: "Yarışma",
    richest: "En Zenginler",
    sponsors: "Sponsorlar",
    description: {
      likes:
        "Daha fazla oy toplamak ve kazanma şansınızı artırmak için arkadaşlarınızı davet edin ve profilinizi paylaşın.",
      coins:
        "Görevleri tamamlayın, diğer katılımcılara oy verin ve daha fazla madeni para kazanmak ve konumunuzu güçlendirmek için arkadaşlarınızı davet edin.",
      stars:
        "Profil sayfasında profillere oy verin, yıldız kazanın ve sıralamada yükselin.",
    },
  },
  PrizePool: {
    text: `<b>Ödül kazanan yerler</b>:
<b>1. yer</b>: %50 USD ve 10.000 madeni para
<b>2. yer</b>: %30 USD ve 3.000 madeni para
<b>3. yer</b>: %20 USD ve 2.000 madeni para
<b>4.-10. yerler</b>: her biri 1.000 madeni para
<b>11.-50. yerler</b>: her biri 500 madeni para
<b>51.-100. yerler</b>: her biri 250 madeni para

<b>Ödül havuzu</b> profil sayfanızda biri size oy verdiğinde artar.

<b>Koşullar</b>:
Fotoğraf yükleyen ve görevleri tamamlayan tüm profiller yarışmaya katılmaya hak kazanır.

<b>Bitiş tarihi</b>:
{{endDate}}

<b>Ödül ödemesi</b>:
Ödülünüzü Telegram tarafından geliştirilen kripto para birimi <b>TON</b> olarak alabilirsiniz.`,
  },
  LeadersItem: {
    join: "Katıl",
  },
  Friends: {
    title: "Arkadaşlarınızı Davet Edin!",
    description: "Siz ve arkadaşınız bonuslar alacaksınız",
    bonus: {
      profit: "<coins>+{{count}}</coins> sizin ve arkadaşınız için",
      invite: {
        title: "Arkadaşınızı Davet Edin",
      },
      inviteWithPremium: {
        title: "Arkadaşınızı Davet Edin",
        description: "Telegram Premium ile",
      },
    },
    list: {
      title: "Arkadaşlarınızın Listesi",
    },
    inviteFriend: "Arkadaşınızı Davet Edin",
    inviteText: `Bana katılın, en güzel erkekler ve kızlar için oy verin ve değerli ödüller kazanın!
  
  🌟 İlk hediye olarak +{{coins}} jeton
  🔥 Telegram Premium'unuz varsa +{{coinsPremium}} jeton
  
  🏆 Her oy için jeton kazanın ve en çekici katılımcıları seçin. Daha fazlasını mı istiyorsunuz? Kendiniz katılın ve değerli ödüller için yarışın!
  
  Fırsatınızı kaçırmayın, yıldızlar topluluğumuzun bir parçası olun ve kazanın!`,
    linkCopied: "Bağlantı kopyalandı",
  },
  Earn: {
    title: "Daha Fazla Jeton Kazanın",
    tasksList: "Görev Listesi",
    check: "Kontrol Et",
    inReview: "İnceleniyor",
    subscribe: "Abone Ol",
    participate: "Katıl",
    invite: "Davet Et",
    verify: "Doğrula",
    taskCompleted: "Görev Tamamlandı",
  },
  Wallet: {
    title: "TON'da ödüller kazanın",
    description:
      "Telegram tarafından geliştirilen bir kripto para birimi olan TON'u kazanmak için görevleri tamamlayın",
    rewards: "Ödüller",
    withdrawal: "Para Çekme",
    walletConnected: "TON cüzdanınız bağlı",
    walletDescription: "Daha fazla fırsat yakında sunulacak",
  },
  VerificationRequired: {
    title: "Doğrulama Gerekiyor",
    description: "Yarışmaya katılmak için hesabınızı doğrulamanız gerekiyor.",
    bdate: {
      title: "Doğum tarihiniz",
    },
  },
  Verification: {
    title: "Doğrulama",
    hint: "Yüzünüzü oval içinde tutun",
    secureHint: "Bu fotoğrafı kimse görmeyecek",
    success: "Doğrulamayı başarıyla tamamladınız",
    goToApp: "Uygulamaya Git",
    verification: "Doğrulanıyor...",
    cameraAccessRequired: "Devam etmek için lütfen kamera erişimine izin verin",
    gettingAccessToCamer: "Kamera erişimi alınıyor...",
    cameraAccessNotAllowed: "Kamera erişimi kullanıcı tarafından reddedildi.",
    cameraAccessError: "Kamera erişim hatası",
    loadingHint: "Bu biraz zaman alabilir. Lütfen sabırlı olun.",
  },
  Profile: {
    vote: "Oy Ver",
    askVote: "Oy İste",
    inviteText: `Merhaba! 😊 Lütfen TON Stars yarışmasında bana oy vererek destek olun! Her oy çok önemlidir ve değerli ödülleri kazanmama yardımcı olur.

🌟 İlk hediye olarak +{{coins}} jeton
🔥 Telegram Premium'unuz varsa +{{coinsPremium}} jeton

Profilim için buradan oy verin: {{url}}

Desteğiniz için teşekkürler! 🌟`,
    joinContest: "Yarışmaya katıl",
    gender: {
      male: "Erkek",
      female: "Kadın",
    },
    photos: {
      inReview: "Yeni fotoğraflarınız inceleniyor.\nBu biraz zaman alabilir.",
      rejected:
        "Bir veya daha fazla fotoğraf şu nedenden dolayı reddedildi:\n{{reason}}.\n\nLütfen farklı fotoğraflar yükleyin ve tekrar deneyin.",
    },
  },
  ProfileEdit: {
    title: "Profiliniz",
    name: {
      label: "Adınız",
      placeholder: "Adınızı girin",
    },
    instagram: {
      description: "Instagram profili herkese açık olmalıdır",
    },
    photos: {
      verificationPhotoUploaded:
        "Doğrulama fotoğrafınız yüklendi, kimse görmeyecek.",
      label: "Fotoğraflarınız",
      mainPhoto: "Ana Fotoğraf",
      error:
        "Bir veya daha fazla fotoğraf uygun değil, lütfen başka bir seçim yapın.",
      hint: "Başka kimsenin olmadığı, yüzünüzün net ve ifade dolu göründüğü en etkileyici fotoğraflarınızı seçin.",
      inReview: "Yeni fotoğraflarınız inceleniyor.\nBu biraz zaman alabilir.",
      rejected:
        "Bir veya daha fazla fotoğraf şu nedenden dolayı reddedildi:\n{{reason}}.\n\nLütfen farklı fotoğraflar yükleyin ve tekrar deneyin.",
    },
    submit: "Yarışmaya Katıl",
    photosUploading: "Fotoğraf yükleniyor...",
    success: "Profiliniz güncellendi",
    errors: {
      name: "Adınızı girin",
      photos: "Lütfen 3 fotoğraf seçin",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Ana fotoğraf",
    noFace: "Yüzünüz algılanmadı",
    otherPeople: "Fotoğraf sadece yüzünüzü göstermelidir",
  },
};
