export default {
  g: {
    flag: "🇹🇯",
    langName: "Tajik",
    langNameNative: "Тоҷикӣ",
    continue: "Идома диҳед",
    save: "Захира кунед",
    retry: "Боз кӯшиш кунед",
    loading: "Боргузорӣ...",
    genderMany: {
      all: "Ҳама",
      male: "Мардон",
      female: "Занон",
    },
  },
  CreateAccount: {
    slogan: "Ҷое, ки орзуҳо ба пирӯзиҳо табдил меёбанд!",
    gender: {
      title: "Салом {{name}}, Ту...",
      male: "Мард",
      female: "Зан",
    },
  },
  Settings: {
    title: "Танзимот",
    language: "Забон",
    auto: "Автоматӣ",
    country: "Кишвар",
    hideProfile: {
      label: "Пинҳон кардани Профил",
      description:
        "Профили шумо аз ҳама пинҳон карда мешавад, гӯё ки шумо онро ҳазф кардаед.",
    },
    profileHidden:
      "Профили шумо пинҳон аст ва барои дигар корбарон намоён нест.",
  },
  Tabs: {
    vote: "Райъ диҳед",
    contest: "Мусобиқа",
    friends: "Дӯстон",
    earn: "Пул кор кунед",
    wallet: "Ҳамён",
  },
  Vote: {
    whoIsBetter: "Киро бештар дӯст медоред?",
    votingForLeader: "Овоздиҳӣ барои роҳбар",
    noUsers: {
      title: "🚀 Ин ҳама барои имрӯз",
      description:
        "Дӯстонро даъват кунед, то овоздиҳиро идома диҳанд ва тангаҳо ба даст оранд",
      inviteFriends: "Дӯстони худро даъват кунед",
    },
  },
  Leaders: {
    rewards: "Мукофотҳо",
    comingSoon: "Ба наздикӣ",
    prizePool: "Фонди ҷоизаҳо",
    moreInfo: "Маълумоти бештар",
    contest: "Озмун",
    richest: "Байтаринҳо",
    sponsors: "Сарпарастон",
    description: {
      likes:
        "Дӯстонро даъват кунед ва профили худро тақсим кунед, то овозҳои бештар гиред ва имконияти ғолиб шуданро зиёд кунед.",
      coins:
        "Вазифаҳоро иҷро кунед, барои дигар иштирокчиён овоз диҳед ва дӯстонро даъват кунед, то тангаҳои бештар гиред ва мавқеи худро мустаҳкам кунед.",
      stars:
        "Барои гирифтани ситораҳо ва боло рафтан дар раддабандӣ ба профилҳо дар саҳифаи профил овоз диҳед.",
    },
  },
  PrizePool: {
    text: `<b>Ҷойҳои ғолибият</b>:
<b>Ҷои 1</b>: 50% USD ва 10,000 танга
<b>Ҷои 2</b>: 30% USD ва 3,000 танга
<b>Ҷои 3</b>: 20% USD ва 2,000 танга
<b>Ҷойҳои 4-10</b>: ҳар як 1,000 танга
<b>Ҷойҳои 11-50</b>: ҳар як 500 танга
<b>Ҷойҳои 51-100</b>: ҳар як 250 танга

<b>Фонди ҷоизаҳо</b> вақте афзоиш меёбад, ки касе дар саҳифаи профили шумо барои шумо овоз диҳад.

<b>Шартҳо</b>:
Ҳама профилҳое, ки аксро бор мекунанд ва вазифаҳоро иҷро мекунанд, ҳуқуқ доранд дар озмун иштирок кунанд.

<b>Санаи анҷом</b>:
{{endDate}}

<b>Пардохти мукофот</b>:
Шумо метавонед мукофоти худро дар <b>TON</b> — криптовалюте, ки Telegram таҳия кардааст, қабул кунед.`,
  },
  LeadersItem: {
    join: "Ҳамроҳ шавед",
  },
  Friends: {
    title: "Дӯстони худро даъват кунед!",
    description: "Шумо ва дӯстатон бонус хоҳед гирифт",
    bonus: {
      profit: "<coins>+{{count}}</coins> барои шумо ва дӯстатон",
      invite: {
        title: "Дӯстро даъват кунед",
      },
      inviteWithPremium: {
        title: "Дӯстро даъват кунед",
        description: "бо Telegram Premium",
      },
    },
    list: {
      title: "Рӯйхати дӯстони шумо",
    },
    inviteFriend: "Дӯстро даъват кунед",
    inviteText: `Ба ман ҳамроҳ шавед, ба бачаҳо ва духтарони зеботарин райъ диҳед ва ҷоизаҳои арзишманд ба даст оред!
  
  🌟 +{{coins}} танга ҳамчун тӯҳфаи аввалин
  🔥 +{{coinsPremium}} танга агар Telegram Premium дошта бошед
  
  🏆 Барои ҳар як райъ танга ба даст оред ва иштирокчиёни зеботаринро интихоб кунед. Бештар мехоҳед? Худ иштирок кунед ва барои ҷоизаҳои арзишманд рақобат кунед!
  
  Шансатонро аз даст надиҳед, қисми ҷомеаи ситораҳои мо шавед ва ғолиб шавед!`,
    linkCopied: "Истинод нусхабардорӣ шуд",
  },
  Earn: {
    title: "Бештар танга ба даст оред",
    tasksList: "Рӯйхати вазифаҳо",
    check: "Санҷидан",
    inReview: "Дар баррасӣ",
    subscribe: "Обуна шавед",
    participate: "Иштирок кунед",
    invite: "Даъват кунед",
    verify: "Тасдиқ кунед",
    taskCompleted: "Вазифа анҷом ёфт",
  },
  Wallet: {
    title: "Дар TON мукофот гиред",
    description:
      "Тапшиҳоро барои ба даст овардани TON, криптовалют, ки аз ҷониби Telegram таҳия шудааст, иҷро кунед",
    rewards: "Мукофотҳо",
    withdrawal: "Бозпас гирифтани маблағ",
    walletConnected: "Ҳамёни TON-и шумо пайваст шудааст",
    walletDescription: "Ба зудӣ имкониятҳои бештар дастрас мешаванд",
  },
  VerificationRequired: {
    title: "Тасдиқ лозим аст",
    description:
      "Барои иштирок дар мусобиқа, шумо бояд ҳисоби худро тасдиқ кунед.",
    bdate: {
      title: "Санаи таваллуди шумо",
    },
  },
  Verification: {
    title: "Тасдиқ",
    hint: "Рӯи худро дар дохили байзавӣ нигоҳ доред",
    secureHint: "Ҳеҷ кас ин аксро намебинад",
    success: "Шумо тасдиқро муваффақона анҷом додед",
    goToApp: "Ба барнома равед",
    verification: "Тасдиқ...",
    cameraAccessRequired:
      "Лутфан барои идома додан дастрасӣ ба камераро иҷозат диҳед",
    gettingAccessToCamer: "Гирифтани дастрасӣ ба камера...",
    cameraAccessNotAllowed:
      "Дастрасӣ ба камера аз ҷониби корбар рад карда шуд.",
    cameraAccessError: "Хатои дастрасӣ ба камера",
    loadingHint: "Ин метавонад вақт гирад. Лутфан сабр кунед.",
  },
  Profile: {
    vote: "Овоз диҳед",
    askVote: "Дархости овоз",
    inviteText: `Салом! 😊 Лутфан, маро бо овози худ дар озмуни TON Stars дастгирӣ кунед! Ҳар як овоз муҳим аст ва ба ман кӯмак мекунад, ки ба ғолибияти ҷоизаҳои арзишманд наздик шавам.

🌟 +{{coins}} танга ҳамчун тӯҳфаи аввал
🔥 +{{coinsPremium}} танга агар шумо Telegram Premium дошта бошед

Барои профили ман дар ин ҷо овоз диҳед: {{url}}

Барои дастгирии шумо ташаккур! 🌟`,
    joinContest: "Ба озмун ҳамроҳ шавед",
    gender: {
      male: "Мардона",
      female: "Занона",
    },
    photos: {
      inReview:
        "Аксҳои нави шумо дар баррасӣ мебошанд.\nИн метавонад вақт гирад.",
      rejected:
        "Яке ё якчанд аксҳо бо сабаби зерин рад шуданд:\n{{reason}}.\n\nЛутфан аксҳои гуногунро бор кунед ва дубора кӯшиш кунед.",
    },
  },
  ProfileEdit: {
    title: "Профили шумо",
    name: {
      label: "Номи шумо",
      placeholder: "Номи худро ворид кунед",
    },
    instagram: {
      description: "Профили Instagram бояд оммавӣ бошад",
    },
    photos: {
      verificationPhotoUploaded:
        "Акс барои тасдиқи шумо боргузорӣ шуд, ҳеҷ кас онро намебинад.",
      label: "Аксҳои шумо",
      mainPhoto: "Аксҳои асосӣ",
      error: "Яке ё якчанд аксҳо мувофиқ нестанд, лутфан интихоби дигар кунед.",
      hint: "Аксҳои таъсирбахши худро интихоб кунед, ки дар он ҷо дигар одамон набошанд ва рӯи шумо равшан ва возеҳ намоён бошад.",
      inReview:
        "Аксҳои нави шумо дар баррасӣ мебошанд.\nИн метавонад вақт гирад.",
      rejected:
        "Яке ё якчанд аксҳо бо сабаби зерин рад шуданд:\n{{reason}}.\n\nЛутфан аксҳои гуногунро бор кунед ва дубора кӯшиш кунед.",
    },
    submit: "Ба озмун ҳамроҳ шавед",
    photosUploading: "Акс бор карда мешавад...",
    success: "Профили шумо навсозӣ шуд",
    errors: {
      name: "Номи худро ворид кунед",
      photos: "Лутфан, 3 аксро интихоб кунед",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Аксҳои асосӣ",
    noFace: "Рӯи шумо кашф нашуд",
    otherPeople: "Акс бояд танҳо рӯи шуморо нишон диҳад",
  },
};
