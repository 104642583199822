export default {
  g: {
    flag: "🇮🇳",
    langName: "Gujarati",
    langNameNative: "ગુજરાતી",
    continue: "ચાલુ રાખો",
    save: "સાચવો",
    retry: "ફરી પ્રયાસ કરો",
    loading: "લોડ કરી રહ્યું છે...",
    genderMany: {
      all: "બધા",
      male: "પુરુષો",
      female: "સ્ત્રીઓ",
    },
  },
  CreateAccount: {
    slogan: "જ્યાં સપના જીતમાં બદલાય છે!",
    gender: {
      title: "હેલો {{name}}, તમે છો...",
      male: "પુરુષ",
      female: "મહિલા",
    },
  },
  Settings: {
    title: "સેટિંગ્સ",
    language: "ભાષા",
    auto: "આપોઆપ",
    country: "દેશ",
    hideProfile: {
      label: "પ્રોફાઇલ છુપાવો",
      description:
        "તમારું પ્રોફાઇલ દરેક માટે છુપાવવામાં આવશે, જેમ કે તમે તેને કાઢી નાખ્યું છે.",
    },
    profileHidden:
      "તમારું પ્રોફાઇલ છુપાયેલ છે અને અન્ય વપરાશકર્તાઓ માટે દૃશ્યમાન નથી.",
  },
  Tabs: {
    vote: "મત આપો",
    contest: "સ્પર્ધા",
    friends: "મિત્રો",
    earn: "કમાવો",
    wallet: "વોલેટ",
  },
  Vote: {
    whoIsBetter: "તમને વધુ કોણ ગમે છે?",
    votingForLeader: "લીડર માટે મતદાન",
    noUsers: {
      title: "🚀 આજે માટે બધું",
      description:
        "વોટિંગ ચાલુ રાખવા અને સિક્કા કમાવવા માટે મિત્રોનું આમંત્રણ આપો",
      inviteFriends: "મિત્રોને આમંત્રણ આપો",
    },
  },
  Leaders: {
    rewards: "ઇનામ",
    comingSoon: "જલ્દી જ",
    prizePool: "ઇનામ પૂલ",
    moreInfo: "વધુ માહિતી",
    contest: "સ્પર્ધા",
    richest: "સૌથી વધુ ધનવાન",
    sponsors: "પ્રાયોજકો",
    description: {
      likes:
        "મિત્રોને આમંત્રિત કરો અને વધુ મત એકત્ર કરવા અને તમારા જીતવાના ચાન્સને વધારવા માટે તમારી પ્રોફાઇલને શેર કરો.",
      coins:
        "કાર્યો પૂરા કરો, અન્ય ભાગલેનાર માટે મત આપો અને વધુ સિક્કા કમાવા માટે મિત્રો ને આમંત્રણ આપો અને તમારી સ્થિતિ મજબૂત કરો.",
      stars:
        "પ્રોફાઇલ પેજ પર પ્રોફાઇલ માટે મત આપો અને સ્ટાર મેળવો અને રેન્કિંગમાં ઉંચા ચડી જાવ.",
    },
  },
  PrizePool: {
    text: `<b>પ્રાઇઝ જીતવાના સ્થાનો</b>:
<b>1મું સ્થાન</b>: 50% USD અને 10,000 સિક્કા
<b>2મું સ્થાન</b>: 30% USD અને 3,000 સિક્કા
<b>3મું સ્થાન</b>: 20% USD અને 2,000 સિક્કા
<b>4થી-10મું સ્થાન</b>: દરેકને 1,000 સિક્કા
<b>11મું-50મું સ્થાન</b>: દરેકને 500 સિક્કા
<b>51મું-100મું સ્થાન</b>: દરેકને 250 સિક્કા

<b>પ્રાઇઝ પૂલ</b> વધે છે જ્યારે કોઈ તમારા માટે તમારા પ્રોફાઇલ પેજ પર મત આપે છે.

<b>શરતો</b>:
બધા પ્રોફાઇલ્સ જે ફોટો અપલોડ કરે છે અને કાર્યો પૂર્ણ કરે છે તે સ્પર્ધામાં ભાગ લેવા માટે પાત્ર છે.

<b>અંતિમ તારીખ</b>:
{{endDate}}

<b>ઇનામની ચુકવણી</b>:
તમે તમારા ઇનામ <b>TON</b> માં મેળવી શકો છો — જે ક્રિપ્ટોકરન્સી Telegram દ્વારા વિકસાવવામાં આવી છે.`,
  },
  LeadersItem: {
    join: "જોડાઓ",
  },
  Friends: {
    title: "મિત્રોને આમંત્રિત કરો!",
    description: "તમે અને તમારા મિત્રને બોનસ મળશે",
    bonus: {
      profit: "<coins>+{{count}}</coins> તમારા અને તમારા મિત્ર માટે",
      invite: {
        title: "મિત્રને આમંત્રિત કરો",
      },
      inviteWithPremium: {
        title: "મિત્રને આમંત્રિત કરો",
        description: "ટેલિગ્રામ પ્રીમિયમ સાથે",
      },
    },
    list: {
      title: "તમારા મિત્રોની યાદી",
    },
    inviteFriend: "મિત્રને આમંત્રિત કરો",
    inviteText: `મારી સાથે જોડાઓ, સૌથી સુંદર છોકરા અને છોકરીઓ માટે મત આપો અને કિંમતી ઇનામો મેળવો!
  
  🌟 +{{coins}} સિક્કા પ્રથમ ભેટ તરીકે
  🔥 +{{coinsPremium}} સિક્કા જો તમારા પાસે ટેલિગ્રામ પ્રીમિયમ છે
  
  🏆 દરેક મત માટે સિક્કા કમાવો અને સૌથી આકર્ષક ભાગ લેનારા પસંદ કરો. વધુ ઇચ્છો છો? જાતે ભાગ લો અને કિંમતી ઇનામો માટે સ્પર્ધા કરો!
  
  તમારો મોકો ચૂકો નહીં, અમારી તારાઓની સમુદાયનો ભાગ બનો અને જીતો!`,
    linkCopied: "લિંક કૉપિ કરવામાં આવ્યો",
  },
  Earn: {
    title: "વધુ સિક્કા કમાવો",
    tasksList: "ટાસ્ક યાદી",
    check: "તપાસો",
    inReview: "સમીક્ષા હેઠળ",
    subscribe: "સબ્સ્ક્રાઇબ કરો",
    participate: "ભાગ લો",
    invite: "આમંત્રિત કરો",
    verify: "વેરિફાઇ કરો",
    taskCompleted: "ટાસ્ક પૂર્ણ થયું",
  },
  Wallet: {
    title: "TON માં પુરસ્કારો મેળવો",
    description:
      "TON કમાવવા માટે ચેલેન્જ પૂર્ણ કરો, એક ક્રિપ્ટો કોઇન જે Telegram દ્વારા વિકસાવવામાં આવ્યું છે",
    rewards: "પુરસ્કારો",
    withdrawal: "ઉપાડ",
    walletConnected: "તમારો TON વૉલેટ કનેક્ટેડ છે",
    walletDescription: "શીઘ્ર જ વધુ તક ઉપલબ્ધ થશે",
  },
  VerificationRequired: {
    title: "વેરિફિકેશન જરૂરી છે",
    description:
      "સ્પર્ધામાં ભાગ લેવા માટે તમારે તમારું ખાતું વેરિફાઇ કરવું પડશે.",
    bdate: {
      title: "તમારું જન્મતારીખ",
    },
  },
  Verification: {
    title: "વેરિફિકેશન",
    hint: "તમારો ચહેરો ઓવલની અંદર રાખો",
    secureHint: "આ ફોટો કોઈને દેખાશે નહીં",
    success: "તમારા વેરિફિકેશન સફળતાપૂર્વક પૂર્ણ થયું છે",
    goToApp: "એપ્લિકેશનમાં જાઓ",
    verification: "વેરિફાઇ કરી રહ્યું છે...",
    cameraAccessRequired: "આગળ વધવા માટે કૃપા કરીને કેમેરા ઍક્સેસ આપો",
    gettingAccessToCamer: "કેમેરા ઍક્સેસ મેળવી રહ્યું છે...",
    cameraAccessNotAllowed: "યુઝરે કેમેરા ઍક્સેસ નકારી કાઢી.",
    cameraAccessError: "કેમેરા ઍક્સેસ ભૂલ",
    loadingHint: "આમાં થોડો સમય લાગી શકે છે. કૃપા કરીને ધીરજ રાખો.",
  },
  Profile: {
    vote: "વોટ આપો",
    askVote: "વોટ માંગુ",
    inviteText: `હાય! 😊 કૃપા કરીને TON સ્ટાર્સ સ્પર્ધામાં તમારો વોટ આપીને મને ટેકો આપો! દરેક વોટ મહત્વપૂર્ણ છે અને મને મૂલ્યવાન ઇનામ જીતવામાં નજીક પહોંચવામાં મદદ કરે છે.

🌟 +{{coins}} સિક્કો પ્રથમ ભેટ તરીકે
🔥 +{{coinsPremium}} સિક્કો જો તમારા પાસે Telegram Premium છે

મારા પ્રોફાઇલ માટે અહીં વોટ આપો: {{url}}

તમારા ટેકાની માટે આભાર! 🌟`,
    joinContest: "સ્પર્ધામાં જોડાઓ",
    gender: {
      male: "પુરુષ",
      female: "મહિલા",
    },
    photos: {
      inReview: "તમારા નવા ફોટા સમીક્ષા હેઠળ છે.\nઆ થોડો સમય લઈ શકે છે.",
      rejected:
        "એક અથવા વધુ ફોટા નીચેના કારણોસર અસ્વીકૃત થયા છે:\n{{reason}}.\n\nકૃપા કરીને વિવિધ ફોટા અપલોડ કરો અને ફરીથી પ્રયાસ કરો.",
    },
  },
  ProfileEdit: {
    title: "તમારી પ્રોફાઇલ",
    name: {
      label: "તમારું નામ",
      placeholder: "તમારું નામ દાખલ કરો",
    },
    instagram: {
      description: "Instagram પ્રોફાઇલ જાહેર હોવી જોઈએ",
    },
    photos: {
      verificationPhotoUploaded:
        "તમારો વેરિફિકેશન ફોટો અપલોડ કરવામાં આવ્યો છે, તેને કોઈ નહીં જુએ.",
      label: "તમારા ફોટા",
      mainPhoto: "મુખ્ય ફોટો",
      error: "એક અથવા વધુ ફોટા યોગ્ય નથી, કૃપા કરીને અન્ય પસંદગી કરો.",
      hint: "તમારા સૌથી પ્રભાવશાળી ફોટા પસંદ કરો, જ્યાં અન્ય લોકો ન હોય, અને તમારું મોઢું સાફ અને સ્પષ્ટ દેખાય છે.",
      inReview: "તમારા નવા ફોટા સમીક્ષા હેઠળ છે.\nઆ થોડો સમય લઈ શકે છે.",
      rejected:
        "એક અથવા વધુ ફોટા નીચેના કારણોસર અસ્વીકૃત થયા છે:\n{{reason}}.\n\nકૃપા કરીને વિવિધ ફોટા અપલોડ કરો અને ફરીથી પ્રયાસ કરો.",
    },
    submit: "સ્પર્ધામાં જોડાઓ",
    photosUploading: "ફોટો અપલોડ થઈ રહ્યો છે...",
    success: "તમારી પ્રોફાઇલ અપડેટ થઈ છે",
    errors: {
      name: "તમારું નામ દાખલ કરો",
      photos: "કૃપા કરીને 3 તસવીરો પસંદ કરો",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "મુખ્ય ફોટો",
    noFace: "તમારો ચહેરો શોધી શકાયો નથી",
    otherPeople: "ફોટામાં ફક્ત તમારો ચહેરો હોવો જોઈએ",
  },
};
