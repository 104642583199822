export default {
  g: {
    flag: "🇮🇱",
    langName: "Hebrew",
    langNameNative: "עברית",
    continue: "המשך",
    save: "שמור",
    retry: "נסה שוב",
    loading: "טוען...",
    genderMany: {
      all: "הכל",
      male: "גברים",
      female: "נשים",
    },
  },
  CreateAccount: {
    slogan: "המקום שבו חלומות הופכים לניצחונות!",
    gender: {
      title: "שלום {{name}}, אתה...",
      male: "זכר",
      female: "נקבה",
    },
  },
  Settings: {
    title: "הגדרות",
    language: "שפה",
    auto: "אוטומטי",
    country: "מדינה",
    hideProfile: {
      label: "הסתר פרופיל",
      description: "הפרופיל שלך יוסתר מכולם, כאילו מחקת אותו.",
    },
    profileHidden: "הפרופיל שלך מוסתר ואינו גלוי למשתמשים אחרים.",
  },
  Tabs: {
    vote: "הצבע",
    contest: "תחרות",
    friends: "חברים",
    earn: "הרווח",
    wallet: "ארנק",
  },
  Vote: {
    whoIsBetter: "מי אתה אוהב יותר?",
    votingForLeader: "הצבעה עבור המנהיג",
    noUsers: {
      title: "🚀 זה כל מה שיש להיום",
      description: "הזמן חברים כדי להמשיך להצביע ולהרוויח מטבעות",
      inviteFriends: "הזמן חברים",
    },
  },
  Leaders: {
    rewards: "פרסים",
    comingSoon: "בקרוב",
    prizePool: "קרן פרסים",
    moreInfo: "מידע נוסף",
    contest: "תחרות",
    richest: "העשירים ביותר",
    sponsors: "נותני חסות",
    description: {
      likes:
        "הזמינו חברים ושתפו את הפרופיל שלכם כדי לקבל יותר הצבעות ולהגדיל את הסיכויים שלכם לזכות.",
      coins:
        "השלימו משימות, הצביעו למשתתפים אחרים והזמינו חברים כדי להרוויח יותר מטבעות ולחזק את מעמדכם.",
      stars: "הצביעו עבור פרופילים בדף הפרופיל כדי לקבל כוכבים ולטפס בדירוג.",
    },
  },
  PrizePool: {
    text: `<b>מקומות זוכים בפרס</b>:
<b>מקום ראשון</b>: 50% דולר ו-10,000 מטבעות
<b>מקום שני</b>: 30% דולר ו-3,000 מטבעות
<b>מקום שלישי</b>: 20% דולר ו-2,000 מטבעות
<b>מקומות 4-10</b>: 1,000 מטבעות כל אחד
<b>מקומות 11-50</b>: 500 מטבעות כל אחד
<b>מקומות 51-100</b>: 250 מטבעות כל אחד

<b>קרן הפרסים</b> גדלה כאשר מישהו מצביע עבורכם בעמוד הפרופיל שלכם.

<b>תנאים</b>:
כל הפרופילים שמעלים תמונה ומשלימים את המשימות זכאים להשתתף בתחרות.

<b>תאריך סיום</b>:
{{endDate}}

<b>תשלום הפרסים</b>:
תוכל לקבל את הפרס שלך ב-<b>TON</b> — המטבע הקריפטוגרפי שפיתחה Telegram.`,
  },
  LeadersItem: {
    join: "הצטרף",
  },
  Friends: {
    title: "הזמן חברים!",
    description: "אתה וחברך תקבלו בונוסים",
    bonus: {
      profit: "<coins>+{{count}}</coins> בשבילך ובשביל חברך",
      invite: {
        title: "הזמן חבר",
      },
      inviteWithPremium: {
        title: "הזמן חבר",
        description: "עם Telegram Premium",
      },
    },
    list: {
      title: "רשימת החברים שלך",
    },
    inviteFriend: "הזמן חבר",
    inviteText: `הצטרף אלי, השתתף בהצבעה לבחירת הבנים והבנות הכי יפים וקבל פרסים יקרי ערך!
  
  🌟 +{{coins}} מטבעות כמתנת פתיחה
  🔥 +{{coinsPremium}} מטבעות אם יש לך Telegram Premium
  
  🏆 הרווח מטבעות על כל הצבעה ובחר את המשתתפים הכי מושכים. רוצה יותר? השתתף בעצמך ותתחרה על פרסים יקרי ערך!
  
  אל תחמיץ את ההזדמנות שלך, הצטרף לקהילת הכוכבים שלנו וזכה!`,
    linkCopied: "הקישור הועתק",
  },
  Earn: {
    title: "הרווח יותר מטבעות",
    tasksList: "רשימת משימות",
    check: "בדוק",
    inReview: "בבדיקה",
    subscribe: "הרשם",
    participate: "השתתף",
    invite: "הזמן",
    verify: "אמת",
    taskCompleted: "המשימה הושלמה",
  },
  Wallet: {
    title: "קבלו תגמולים ב-TON",
    description:
      "השלימו אתגרים כדי להרוויח TON, מטבע קריפטו שפותח על ידי Telegram",
    rewards: "תגמולים",
    withdrawal: "משיכה",
    walletConnected: "ארנק ה-TON שלך מחובר",
    walletDescription: "הזדמנויות נוספות יהיו זמינות בקרוב",
  },
  VerificationRequired: {
    title: "נדרשת אימות",
    description: "כדי להשתתף בתחרות, עליך לאמת את חשבונך.",
    bdate: {
      title: "תאריך הלידה שלך",
    },
  },
  Verification: {
    title: "אימות",
    hint: "השאר את פניך בתוך הסגלגל",
    secureHint: "אף אחד לא יראה את התמונה הזו",
    success: "האימות הושלם בהצלחה",
    goToApp: "לך לאפליקציה",
    verification: "מאמת...",
    cameraAccessRequired: "אנא אשר גישה למצלמה כדי להמשיך",
    gettingAccessToCamer: "מקבל גישה למצלמה...",
    cameraAccessNotAllowed: "גישה למצלמה נדחתה על ידי המשתמש.",
    cameraAccessError: "שגיאת גישה למצלמה",
    loadingHint: "זה עשוי לקחת זמן מה. אנא המתן בסבלנות.",
  },
  Profile: {
    vote: "הצבע",
    askVote: "בקש הצבעה",
    inviteText: `היי! 😊 אנא תמוך בי עם ההצבעה שלך בתחרות TON Stars! כל הצבעה קריטית ועוזרת לי להתקרב לזכייה בפרסים יקרי ערך.

🌟 +{{coins}} מטבעות כמתנה ראשונה
🔥 +{{coinsPremium}} מטבעות אם יש לך Telegram Premium

הצביעו עבור הפרופיל שלי כאן: {{url}}

תודה על התמיכה! 🌟`,
    joinContest: "הצטרף לתחרות",
    gender: {
      male: "זכר",
      female: "נקבה",
    },
    photos: {
      inReview: "התמונות החדשות שלך בבדיקה.\nזה עשוי לקחת קצת זמן.",
      rejected:
        "תמונה אחת או יותר נדחו מהסיבה הבאה:\n{{reason}}.\n\nאנא העלה תמונות שונות ונסה שוב.",
    },
  },
  ProfileEdit: {
    title: "הפרופיל שלך",
    name: {
      label: "שמך",
      placeholder: "הכנס את שמך",
    },
    instagram: {
      description: "פרופיל ה-Instagram חייב להיות ציבורי",
    },
    photos: {
      verificationPhotoUploaded:
        "תמונת האימות שלך הועלתה, אף אחד לא יראה אותה.",
      label: "התמונות שלך",
      mainPhoto: "תמונה ראשית",
      error: "תמונה אחת או יותר אינן מתאימות, אנא בחר תמונות אחרות.",
      hint: "בחר את התמונות המרשימות ביותר שלך, בהן אין אנשים אחרים, ופניך נראים בבירור ובאופן מובהק.",
      inReview: "התמונות החדשות שלך בבדיקה.\nזה עשוי לקחת קצת זמן.",
      rejected:
        "תמונה אחת או יותר נדחו מהסיבה הבאה:\n{{reason}}.\n\nאנא העלה תמונות שונות ונסה שוב.",
    },
    submit: "הצטרף לתחרות",
    photosUploading: "מעלה תמונה...",
    success: "הפרופיל שלך עודכן",
    errors: {
      name: "הזן את שמך",
      photos: "אנא בחר 3 תמונות",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "תמונה ראשית",
    noFace: "לא זוהה פנים",
    otherPeople: "בתמונה צריך להיות רק הפנים שלך",
  },
};
