import { Avatar, Button, IconButton } from "@telegram-apps/telegram-ui";
import { FragmentType, getFragmentData, gql } from "__generated__";
import CoinIcon from "../../assets/coin.svg?react";
import VerifiedIcon from "../../assets/verified.svg?react";
import GearIcon from "./icons/gear.svg?react";
import { Modal } from "components/Modal/Modal";
import { Settings } from "components/Settings/Settings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Profile, ProfileProps } from "components/Profile/Profile";
import "./Header.css";
import RejectedIcon from "assets/rejected.svg?react";
import ClockIcon from "assets/clock.svg?react";
import { ProfileVoteProps } from "components/ProfileVote/ProfileVote";

const Header_QueryFragment = gql(`
    fragment Header_QueryFragment on Query {
      ...Settings_QueryFragment
      user {
        ...ProfileUser_QueryFragment
        id
        name
        photo_url
        coins
        age
        verified
        hidden
        uploads {
          id
          faceUrl: url(size: 200, face: true)
        }
        uploadsPending {
          id
        }
        photoStatus {
          rejectReasonText
        }
      }
    }
  `);

type HeaderProps = {
  query: FragmentType<typeof Header_QueryFragment>;
  voteConfig: ProfileVoteProps["voteConfig"];
  bonus: ProfileProps["bonus"];
};

export const Header = (props: HeaderProps) => {
  const query = getFragmentData(Header_QueryFragment, props.query);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);

  const { i18n, t: s } = useTranslation("Settings");

  return (
    <>
      <div className="vote-header">
        <div
          className="vote-header-user"
          onClick={() => setProfileVisible(true)}
        >
          <Avatar
            src={query.user?.uploads[0]?.faceUrl || query.user?.photo_url || ""}
            acronym={
              (query.user?.name?.[0]?.toUpperCase() || "") +
              (query.user?.name?.[1]?.toUpperCase() || "")
            }
            size={48}
          />
          <div className="vote-header-user-body">
            <div className="vote-header-user-body-text">
              <div className="vote-header-user-body-name">
                {query.user?.name}
              </div>
              {query.user?.age && (
                <div className="vote-header-user-body-age">
                  , {Intl.NumberFormat(i18n.language).format(query.user?.age)}
                </div>
              )}
            </div>
            <div className="vote-header-user-body-icons">
              {query.user?.verified && (
                <VerifiedIcon
                  style={{ marginTop: -1 }}
                  width={18}
                  height={18}
                />
              )}
              {query.user?.photoStatus?.rejectReasonText ? (
                <RejectedIcon width={22} height={22} />
              ) : (
                !!query.user?.uploadsPending.length && (
                  <ClockIcon width={16} height={16} color="rgb(255, 151, 0)" />
                )
              )}
            </div>
            {/* <div className="vote-header-user-energy">
            <FlashIcon width={18} height={18} />
            <Subheadline level="1" weight="3">
              75/100
            </Subheadline>
          </div> */}
          </div>
        </div>
        <div className="vote-header-coins">
          <CoinIcon width={26} height={26} />{" "}
          {Intl.NumberFormat(i18n.language).format(query.user?.coins)}
        </div>
        <IconButton mode="bezeled" onClick={() => setSettingsVisible(true)}>
          <GearIcon
            className="vote-header-settings-icon"
            width={16}
            height={16}
          />
        </IconButton>
        {settingsVisible && (
          <Modal title={s("title")} onClose={() => setSettingsVisible(false)}>
            <Settings query={query} onClose={() => setSettingsVisible(false)} />
          </Modal>
        )}
        {profileVisible && (
          <Modal fullscreen onClose={() => setProfileVisible(false)}>
            <Profile
              user={query.user}
              voteConfig={props.voteConfig}
              bonus={props.bonus}
            />
          </Modal>
        )}
      </div>
      {query.user?.hidden && (
        <>
          <div className="profile-photos-review">
            <RejectedIcon width={24} height={24} />
            <div>{s("profileHidden")}</div>
          </div>
          <Button
            style={{ alignSelf: "center" }}
            size="m"
            onClick={() => setSettingsVisible(true)}
          >
            {s("title")}
          </Button>
        </>
      )}
    </>
  );
};
