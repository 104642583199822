export default {
  g: {
    flag: "🇭🇺",
    langName: "Hungarian",
    langNameNative: "Magyar",
    continue: "Folytatás",
    save: "Mentés",
    retry: "Újrapróbálás",
    loading: "Betöltés...",
    genderMany: {
      all: "Mindenki",
      male: "Férfiak",
      female: "Nők",
    },
  },
  CreateAccount: {
    slogan: "A hely, ahol az álmok győzelmekké válnak!",
    gender: {
      title: "Helló {{name}}, Te vagy...",
      male: "Férfi",
      female: "Nő",
    },
  },
  Settings: {
    title: "Beállítások",
    language: "Nyelv",
    auto: "Automatikus",
    country: "Ország",
    hideProfile: {
      label: "Profil elrejtése",
      description:
        "A profilod mindenki elől el lesz rejtve, mintha törölted volna.",
    },
    profileHidden:
      "A profilod rejtett, és nem látható más felhasználók számára.",
  },
  Tabs: {
    vote: "Szavazás",
    contest: "Verseny",
    friends: "Barátok",
    earn: "Keresni",
    wallet: "Pénztárca",
  },
  Vote: {
    whoIsBetter: "Kit szeretsz jobban?",
    votingForLeader: "Szavazás a vezetőre",
    noUsers: {
      title: "🚀 Ez minden mára",
      description:
        "Hívd meg a barátaidat, hogy folytassák a szavazást és érméket gyűjtsenek",
      inviteFriends: "Hívd meg a Barátaidat",
    },
  },
  Leaders: {
    rewards: "Jutalmak",
    comingSoon: "Hamarosan",
    prizePool: "Nyereményalap",
    moreInfo: "További információ",
    contest: "Verseny",
    richest: "A leggazdagabbak",
    sponsors: "Szponzorok",
    description: {
      likes:
        "Hívj meg barátokat, és oszd meg profilodat, hogy több szavazatot gyűjts, és növeld esélyeidet a győzelemre.",
      coins:
        "Teljesítsd a feladatokat, szavazz más résztvevőkre, és hívd meg barátaidat, hogy több érmét szerezz és erősítsd pozíciódat.",
      stars:
        "Szavazz a profiloldalon a profilokra, hogy csillagokat kapj és előrébb juss a rangsorban.",
    },
  },
  PrizePool: {
    text: `<b>Díjazott helyek</b>:
<b>1. hely</b>: 50% USD és 10,000 érme
<b>2. hely</b>: 30% USD és 3,000 érme
<b>3. hely</b>: 20% USD és 2,000 érme
<b>4.-10. helyek</b>: mindegyik 1,000 érme
<b>11.-50. helyek</b>: mindegyik 500 érme
<b>51.-100. helyek</b>: mindegyik 250 érme

<b>A nyereményalap</b> növekszik, amikor valaki szavaz rád a profiloldaladon.

<b>Feltételek</b>:
Minden olyan profil, amely feltölt egy fényképet és teljesíti a feladatokat, részt vehet a versenyben.

<b>Befejezési dátum</b>:
{{endDate}}

<b>Jutalom kifizetése</b>:
A jutalmadat <b>TON</b> kriptovalutában veheted fel — amelyet a Telegram fejlesztett.`,
  },
  LeadersItem: {
    join: "Csatlakozás",
  },
  Friends: {
    title: "Hívd meg barátaidat!",
    description: "Te és a barátod bónuszokat kaptok",
    bonus: {
      profit: "<coins>+{{count}}</coins> neked és a barátodnak",
      invite: {
        title: "Hívj meg egy barátot",
      },
      inviteWithPremium: {
        title: "Hívj meg egy barátot",
        description: "Telegram Premium-mal",
      },
    },
    list: {
      title: "Barátaid listája",
    },
    inviteFriend: "Hívj meg egy barátot",
    inviteText: `Csatlakozz hozzám, vegyél részt a legszebb fiúkra és lányokra való szavazásban, és nyerj értékes díjakat!
  
  🌟 +{{coins}} érme ajándékként
  🔥 +{{coinsPremium}} érme, ha van Telegram Premium-od
  
  🏆 Keress érméket minden szavazatért, és válaszd ki a legvonzóbb résztvevőket. Többet szeretnél? Vegyél részt te magad is, és versenyezz értékes díjakért!
  
  Ne hagyd ki a lehetőséget, csatlakozz csillagközösségünkhöz, és nyerj!`,
    linkCopied: "Link másolva",
  },
  Earn: {
    title: "Keress több érmét",
    tasksList: "Feladatlista",
    check: "Ellenőrzés",
    inReview: "Felülvizsgálat alatt",
    subscribe: "Feliratkozás",
    participate: "Részvétel",
    invite: "Meghívás",
    verify: "Ellenőrzés",
    taskCompleted: "Feladat teljesítve",
  },
  Wallet: {
    title: "Szerezz jutalmakat TON-ban",
    description:
      "Teljesítsd a kihívásokat, hogy TON-t szerezz, a Telegram által kifejlesztett kriptovalutát",
    rewards: "Jutalmak",
    withdrawal: "Kivonás",
    walletConnected: "A TON tárcád csatlakoztatva van",
    walletDescription: "Hamarosan több lehetőség lesz elérhető",
  },
  VerificationRequired: {
    title: "Ellenőrzés szükséges",
    description: "A versenyen való részvételhez ellenőrizned kell a fiókodat.",
    bdate: {
      title: "Születési dátumod",
    },
  },
  Verification: {
    title: "Ellenőrzés",
    hint: "Tartsa az arcát az oválison belül",
    secureHint: "Senki sem fogja látni ezt a fényképet",
    success: "Sikeresen befejezted az ellenőrzést",
    goToApp: "Ugrás az alkalmazáshoz",
    verification: "Ellenőrzés...",
    cameraAccessRequired:
      "Kérlek, engedélyezd a kamera hozzáférését a folytatáshoz",
    gettingAccessToCamer: "A kamera hozzáférésének megszerzése...",
    cameraAccessNotAllowed: "A felhasználó megtagadta a kamera hozzáférését.",
    cameraAccessError: "Kamera hozzáférési hiba",
    loadingHint: "Ez eltarthat egy ideig. Kérlek, légy türelmes.",
  },
  Profile: {
    vote: "Szavazás",
    askVote: "Kérjen Szavazatot",
    inviteText: `Szia! 😊 Kérlek, támogass szavazatoddal a TON Stars versenyen! Minden szavazat döntő fontosságú, és segít, hogy közelebb kerüljek az értékes díjak megnyeréséhez.

🌟 +{{coins}} érme első ajándékként
🔥 +{{coinsPremium}} érme, ha van Telegram Premiumod

Szavazz a profilomra itt: {{url}}

Köszönöm a támogatásodat! 🌟`,
    joinContest: "Csatlakozz a versenyhez",
    gender: {
      male: "Férfi",
      female: "Nő",
    },
    photos: {
      inReview:
        "Az új fotóid felülvizsgálat alatt állnak.\nEz eltarthat egy ideig.",
      rejected:
        "Egy vagy több fotó elutasításra került a következő okból:\n{{reason}}.\n\nKérjük, tölts fel különböző fotókat, és próbáld meg újra.",
    },
  },
  ProfileEdit: {
    title: "Profilod",
    name: {
      label: "Neved",
      placeholder: "Írd be a neved",
    },
    instagram: {
      description: "Az Instagram profilnak nyilvánosnak kell lennie",
    },
    photos: {
      verificationPhotoUploaded:
        "Az ellenőrző fotó feltöltve, senki sem fogja látni.",
      label: "Fotóid",
      mainPhoto: "Fő Fotó",
      error: "Egy vagy több fotó nem megfelelő, kérjük válassz másikat.",
      hint: "Válaszd ki a legimpozánsabb fotóidat, ahol nincsenek más emberek, és az arcod tisztán és kifejezően látható.",
      inReview:
        "Az új fotóid felülvizsgálat alatt állnak.\nEz eltarthat egy ideig.",
      rejected:
        "Egy vagy több fotó elutasításra került a következő okból:\n{{reason}}.\n\nKérjük, tölts fel különböző fotókat, és próbáld meg újra.",
    },
    submit: "Csatlakozz a versenyhez",
    photosUploading: "Fotó feltöltése...",
    success: "A profilod frissítve lett",
    errors: {
      name: "Adja meg a nevét",
      photos: "Kérjük, válasszon 3 fényképet",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Fő fotó",
    noFace: "Az arcod nem észlelhető",
    otherPeople: "A fotón csak az arcodnak kell látszódnia",
  },
};
