import { useEffect, useLayoutEffect } from "react";
import "./App.css";
import { CreateAccount } from "./components/CreateAccount/CreateAccount";
import { Tabs } from "./components/Tabs/Tabs";
import WebApp from "@twa-dev/sdk";
import { SplashScreen } from "./components/SplashScreen/SplashScreen";
import { gql } from "__generated__";
import { useQuery } from "@apollo/client";
import i18next from "i18next";
import { LoadingPlaceholder } from "components/LoadingPlaceholder/LoadingPlaceholder";

const App_Query = gql(`
  query App_Query($userId: BigInt) {
    ...Tabs_QueryFragment
    ...CreateAccount_QueryFragment
    user {
      id
      gender
      countryCode
      appLang
      language_code
    }
    voteConfig {
      ...ProfileVote_QueryFragment
    }
    bonus {
      ...ProfileBonus_QueryFragment
    }
  }
`);

function App() {
  const userIdMatch = WebApp.initDataUnsafe.start_param?.match(/id(.+)/);
  const userId = userIdMatch ? Number(userIdMatch[1]) : null;

  const query = useQuery(App_Query, {
    skip: !WebApp.initData,
    variables: { userId },
  });

  useEffect(() => {
    const onThemeChanged = () => {
      const color =
        WebApp.colorScheme == "dark"
          ? WebApp.themeParams.secondary_bg_color
          : WebApp.themeParams.bg_color;

      WebApp.setHeaderColor(color);
      WebApp.setBackgroundColor(color);
    };

    onThemeChanged();

    WebApp.onEvent("themeChanged", onThemeChanged);

    return () => WebApp.offEvent("themeChanged", onThemeChanged);
  }, []);

  useLayoutEffect(() => {
    if (!query) return;

    const user = query.data?.user;
    const lang = user?.appLang === "auto" ? user.language_code : user?.appLang;

    if (lang) {
      i18next.changeLanguage(lang);
      localStorage.setItem("lang", lang);
    }
  }, [query]);

  if (!query.data?.user)
    return (
      <LoadingPlaceholder fullscreen hideIndicator {...query}>
        <SplashScreen />
      </LoadingPlaceholder>
    );

  if (!query.data.user?.gender) return <CreateAccount query={query.data} />;

  return (
    <Tabs
      query={query.data}
      voteConfig={query.data.voteConfig || null}
      bonus={query.data.bonus || null}
    />
  );
}

export default App;
