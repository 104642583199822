export default {
  g: {
    flag: "🇬🇧",
    langName: "English",
    langNameNative: "English",
    continue: "Continue",
    save: "Save",
    retry: "Retry",
    loading: "Loading...",
    genderMany: {
      all: "All",
      male: "Males",
      female: "Females",
    },
  },
  CreateAccount: {
    slogan: "Place where Dreams Turn into Wins!",
    gender: {
      title: "Hello {{name}}, You are...",
      male: "Male",
      female: "Female",
    },
  },
  Settings: {
    title: "Settings",
    language: "Language",
    auto: "Auto",
    country: "Country",
    hideProfile: {
      label: "Hide Profile",
      description:
        "Your profile will be hidden from everyone, as if you had deleted it.",
    },
    profileHidden: "Your profile is hidden and not visible to other users.",
  },
  Tabs: {
    vote: "Vote",
    contest: "Contest",
    friends: "Friends",
    earn: "Earn",
    wallet: "Wallet",
  },
  Vote: {
    whoIsBetter: "Who do you like more?",
    votingForLeader: "Voting for the leader",
    noUsers: {
      title: "🚀 That's all for today",
      description: "Invite friends to continue voting and earning coins",
      inviteFriends: "Invite Friends",
    },
  },
  Leaders: {
    rewards: "Rewards",
    comingSoon: "Coming soon",
    prizePool: "Prize Pool",
    moreInfo: "More info",
    contest: "Contest",
    richest: "Richest",
    sponsors: "Sponsors",
    description: {
      likes:
        "Invite friends and share your profile to gather more votes and increase your chances of winning.",
      coins:
        "Complete tasks, vote for other participants, and invite friends to earn more coins and strengthen your position.",
      stars:
        "Vote for profiles on the profile page to receive stars and climb up the rankings.",
    },
  },
  PrizePool: {
    text: `<b>Prize-winning places</b>:
<b>1st place</b>: 50% USD and 10,000 coins
<b>2nd place</b>: 30% USD and 3,000 coins
<b>3rd place</b>: 20% USD and 2,000 coins
<b>4th-10th places</b>: 1,000 coins each
<b>11th-50th places</b>: 500 coins each
<b>51st-100th places</b>: 250 coins each

<b>The prize pool</b> increases when someone votes for you on your profile page.

<b>Conditions</b>:
All profiles that upload a photo and complete the tasks are eligible to participate in the contest.

<b>End date</b>:
{{endDate}}

<b>Reward payout</b>:
You can receive your reward in <b>TON</b> — the cryptocurrency developed by Telegram.`,
  },
  LeadersItem: {
    join: "Join",
  },
  Friends: {
    title: "Invite friends!",
    description: "You and your friend will receive bonuses",
    bonus: {
      profit: "<coins>+{{count}}</coins> for you and friend",
      invite: {
        title: "Invite friend",
      },
      inviteWithPremium: {
        title: "Invite friend",
        description: "with Telegram Premium",
      },
    },
    list: {
      title: "List of your friends",
    },
    inviteFriend: "Invite friend",
    inviteText: `Join me, participate in voting for the most beautiful guys and girls, and receive valuable prizes!

🌟 +{{coins}} coins as a first gift
🔥 +{{coinsPremium}} coins if you have Telegram Premium

🏆 Earn coins for every vote and choose the most attractive participants. Want more? Participate yourself and compete for valuable prizes!

Don't miss your chance, become part of our stars community and win!`,
    linkCopied: "Link copied",
  },
  Earn: {
    title: "Earn more coins",
    tasksList: "Tasks list",
    check: "Check",
    inReview: "In review",
    subscribe: "Subscribe",
    participate: "Participate",
    invite: "Invite",
    verify: "Verify",
    taskCompleted: "Task completed",
  },
  Wallet: {
    title: "Get rewards in TON",
    description:
      "Complete challenges to earn TON, a crypto coin developed by Telegram",
    rewards: "Rewards",
    withdrawal: "Withdrawal",
    walletConnected: "Your TON wallet is connected",
    walletDescription: "More opportunities will be available soon",
  },
  VerificationRequired: {
    title: "Verification Required",
    description:
      "To participate in the contest, you need to verify your account.",
    bdate: {
      title: "Date of your birth",
    },
  },
  Verification: {
    title: "Verification",
    hint: "Keep your face within the oval",
    secureHint: "No one will see this photo",
    verification: "Verification...",
    cameraAccessRequired: "Please grant camera access to continue",
    gettingAccessToCamer: "Getting access to the camera...",
    cameraAccessNotAllowed: "Camera access denied by the user.",
    cameraAccessError: "Camera access error",
    loadingHint: "This may take some time. Please be patient.",
  },
  Profile: {
    vote: "Vote",
    askVote: "Ask to Vote",
    inviteText: `Hi! 😊 Please support me with your vote in the TON Stars contest! Every vote is crucial and helps me get closer to winning valuable prizes.

🌟 +{{coins}} coins as a first gift
🔥 +{{coinsPremium}} coins if you have Telegram Premium

Vote for my profile here: {{url}}

Thank you for your support! 🌟`,
    joinContest: "Join contest",
    gender: {
      male: "Male",
      female: "Female",
    },
    photos: {
      inReview: "Your new photos are under review.\nThis may take some time.",
      rejected:
        "One or more photos have been rejected for the following reason:\n{{reason}}.\n\nPlease upload different photos and try again.",
    },
  },
  ProfileEdit: {
    title: "Your Profile",
    name: {
      label: "Your Name",
      placeholder: "Enter your name",
    },
    instagram: {
      description: "Instagram profile must be public",
    },
    photos: {
      verificationPhotoUploaded:
        "Your verification photo has been uploaded, no one will see it.",
      label: "Your Photos",
      mainPhoto: "Main Photo",
      error:
        "One or more photos are not suitable, please make another selection.",
      hint: "Choose your most impressive photos, where there are no other people, and your face is clearly and expressively visible.",
      inReview: "Your new photos are under review.\nThis may take some time.",
      rejected:
        "One or more photos have been rejected for the following reason:\n{{reason}}.\n\nPlease upload different photos and try again.",
    },
    submit: "Join Contest",
    photosUploading: "Uploading photo...",
    success: "Your profile has been updated",
    errors: {
      name: "Please enter your name",
      photos: "Please select 3 photos",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Main photo",
    noFace: "Your face is not detected",
    otherPeople: "The photo should only feature your face",
  },
};
