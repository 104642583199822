export default {
  g: {
    flag: "🇪🇸",
    langName: "Galician",
    langNameNative: "Galego",
    continue: "Continuar",
    save: "Gardar",
    retry: "Tentar de novo",
    loading: "Cargando...",
    genderMany: {
      all: "Todos",
      male: "Homes",
      female: "Mulleres",
    },
  },
  CreateAccount: {
    slogan: "Lugar onde os soños se converten en vitorias!",
    gender: {
      title: "Ola {{name}}, Ti es...",
      male: "Home",
      female: "Muller",
    },
  },
  Settings: {
    title: "Configuración",
    language: "Idioma",
    auto: "Automático",
    country: "País",
    hideProfile: {
      label: "Agochar perfil",
      description:
        "O teu perfil estará agochado para todos, como se o eliminases.",
    },
    profileHidden:
      "O teu perfil está agochado e non é visible para outros usuarios.",
  },
  Tabs: {
    vote: "Votar",
    contest: "Concurso",
    friends: "Amigos",
    earn: "Gañar",
    wallet: "Carteira",
  },
  Vote: {
    whoIsBetter: "A quen che gusta máis?",
    votingForLeader: "Votando polo líder",
    noUsers: {
      title: "🚀 Iso é todo por hoxe",
      description: "Convida aos amigos para seguir votando e gañar moedas",
      inviteFriends: "Convidar amigos",
    },
  },
  Leaders: {
    rewards: "Recompensas",
    comingSoon: "Próximamente",
    prizePool: "Fondo de premios",
    moreInfo: "Máis información",
    contest: "Concurso",
    richest: "Máis ricos",
    sponsors: "Patrocinadores",
    description: {
      likes:
        "Convida aos teus amigos e comparte o teu perfil para recoller máis votos e aumentar as túas posibilidades de gañar.",
      coins:
        "Completa tarefas, vota por outros participantes e convida aos teus amigos para gañar máis moedas e fortalecer a túa posición.",
      stars:
        "Vota polos perfís na páxina de perfil para recibir estrelas e subir no ránking.",
    },
  },
  PrizePool: {
    text: `<b>Lugares premiados</b>:
<b>1º lugar</b>: 50% USD e 10,000 moedas
<b>2º lugar</b>: 30% USD e 3,000 moedas
<b>3º lugar</b>: 20% USD e 2,000 moedas
<b>4º-10º lugares</b>: 1,000 moedas cada un
<b>11º-50º lugares</b>: 500 moedas cada un
<b>51º-100º lugares</b>: 250 moedas cada un

<b>O fondo de premios</b> aumenta cando alguén vota por ti na túa páxina de perfil.

<b>Condicións</b>:
Todos os perfís que suban unha foto e completen as tarefas son elixibles para participar no concurso.

<b>Data de finalización</b>:
{{endDate}}

<b>Pago das recompensas</b>:
Podes recibir a túa recompensa en <b>TON</b> — a criptomoeda desenvolvida por Telegram.`,
  },
  LeadersItem: {
    join: "Unirse",
  },
  Friends: {
    title: "Convida aos teus amigos!",
    description: "Ti e o teu amigo recibiredes bonos",
    bonus: {
      profit: "<coins>+{{count}}</coins> para ti e o teu amigo",
      invite: {
        title: "Convidar a un amigo",
      },
      inviteWithPremium: {
        title: "Convidar a un amigo",
        description: "con Telegram Premium",
      },
    },
    list: {
      title: "Lista dos teus amigos",
    },
    inviteFriend: "Convidar a un amigo",
    inviteText: `Únete a min, participa na votación dos rapaces e rapazas máis fermosos, e recibe valiosos premios!
  
  🌟 +{{coins}} moedas como primeiro agasallo
  🔥 +{{coinsPremium}} moedas se tes Telegram Premium
  
  🏆 Gaña moedas por cada voto e escolle aos participantes máis atractivos. Queres máis? Participa ti mesmo e compite por valiosos premios!
  
  Non perdas a túa oportunidade, convértete en parte da nosa comunidade de estrelas e gaña!`,
    linkCopied: "Ligazón copiada",
  },
  Earn: {
    title: "Gaña máis moedas",
    tasksList: "Lista de tarefas",
    check: "Comprobar",
    inReview: "En revisión",
    subscribe: "Suscribirse",
    participate: "Participar",
    invite: "Convidar",
    verify: "Verificar",
    taskCompleted: "Tarefa completada",
  },
  Wallet: {
    title: "Obtén recompensas en TON",
    description:
      "Completa desafíos para gañar TON, unha criptomoeda desenvolvida por Telegram",
    rewards: "Recompensas",
    withdrawal: "Retirada",
    walletConnected: "A túa carteira TON está conectada",
    walletDescription: "Máis oportunidades estarán dispoñibles pronto",
  },
  VerificationRequired: {
    title: "Verificación Requirida",
    description: "Para participar no concurso, debes verificar a túa conta.",
    bdate: {
      title: "Data de nacemento",
    },
  },
  Verification: {
    title: "Verificación",
    hint: "Manteña a súa cara dentro do óvalo",
    secureHint: "Ninguén verá esta foto",
    success: "Completaches a verificación con éxito",
    goToApp: "Ir á aplicación",
    verification: "Verificando...",
    cameraAccessRequired: "Por favor, permite acceso á cámara para continuar",
    gettingAccessToCamer: "Obtendo acceso á cámara...",
    cameraAccessNotAllowed: "O acceso á cámara foi denegado polo usuario.",
    cameraAccessError: "Erro de acceso á cámara",
    loadingHint: "Isto pode levar un tempo. Por favor, sexa paciente.",
  },
  Profile: {
    vote: "Votar",
    askVote: "Pedir voto",
    inviteText: `Ola! 😊 Por favor, apóie-me co teu voto no concurso TON Stars! Cada voto é crucial e axúdame a achegarme a gañar premios valiosos.

🌟 +{{coins}} moedas como primeiro agasallo
🔥 +{{coinsPremium}} moedas se tes Telegram Premium

Vota polo meu perfil aquí: {{url}}

Grazas polo teu apoio! 🌟`,
    joinContest: "Únete ao concurso",
    gender: {
      male: "Masculino",
      female: "Feminino",
    },
    photos: {
      inReview:
        "As túas novas fotos están en revisión.\nIsto pode levar un tempo.",
      rejected:
        "Unha ou máis fotos foron rexeitadas polo seguinte motivo:\n{{reason}}.\n\nCargue outras fotos e inténtao de novo.",
    },
  },
  ProfileEdit: {
    title: "O teu perfil",
    name: {
      label: "O teu nome",
      placeholder: "Introduce o teu nome",
    },
    instagram: {
      description: "O perfil de Instagram debe ser público",
    },
    photos: {
      verificationPhotoUploaded:
        "A túa foto de verificación foi cargada, ninguén a verá.",
      label: "As túas fotos",
      mainPhoto: "Foto principal",
      error: "Unha ou máis fotos non son adecuadas, fai outra selección.",
      hint: "Escolle as túas fotos máis impresionantes, onde non haxa outras persoas, e o teu rostro sexa claramente visible.",
      inReview:
        "As túas novas fotos están en revisión.\nIsto pode levar un tempo.",
      rejected:
        "Unha ou máis fotos foron rexeitadas polo seguinte motivo:\n{{reason}}.\n\nCargue outras fotos e inténtao de novo.",
    },
    submit: "Únete ao concurso",
    photosUploading: "Cargando foto...",
    success: "O teu perfil foi actualizado",
    errors: {
      name: "Indique o seu nome",
      photos: "Seleccione 3 fotos",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto principal",
    noFace: "Non se detectou a túa cara",
    otherPeople: "A foto só debe mostrar a túa cara",
  },
};
