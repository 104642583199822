export default {
  g: {
    flag: "🇸🇪",
    langName: "Swedish",
    langNameNative: "Svenska",
    continue: "Fortsätt",
    save: "Spara",
    retry: "Försök igen",
    loading: "Laddar...",
    genderMany: {
      all: "Alla",
      male: "Män",
      female: "Kvinnor",
    },
  },
  CreateAccount: {
    slogan: "Platsen där drömmar blir vinster!",
    gender: {
      title: "Hej {{name}}, Du är...",
      male: "Man",
      female: "Kvinna",
    },
  },
  Settings: {
    title: "Inställningar",
    language: "Språk",
    auto: "Automatisk",
    country: "Land",
    hideProfile: {
      label: "Dölj profil",
      description:
        "Din profil kommer att döljas för alla, som om du hade raderat den.",
    },
    profileHidden: "Din profil är dold och inte synlig för andra användare.",
  },
  Tabs: {
    vote: "Rösta",
    contest: "Tävling",
    friends: "Vänner",
    earn: "Tjäna",
    wallet: "Plånbok",
  },
  Vote: {
    whoIsBetter: "Vem gillar du mer?",
    votingForLeader: "Röstar på ledaren",
    noUsers: {
      title: "🚀 Det var allt för idag",
      description: "Bjud in vänner för att fortsätta rösta och tjäna mynt",
      inviteFriends: "Bjud in vänner",
    },
  },
  Leaders: {
    rewards: "Belöningar",
    comingSoon: "Kommer snart",
    prizePool: "Prispott",
    moreInfo: "Mer info",
    contest: "Tävling",
    richest: "Rikast",
    sponsors: "Sponsorer",
    description: {
      likes:
        "Bjud in vänner och dela din profil för att samla fler röster och öka dina chanser att vinna.",
      coins:
        "Slutför uppgifter, rösta på andra deltagare och bjud in vänner för att tjäna fler mynt och stärka din position.",
      stars:
        "Rösta på profiler på profilsidan för att få stjärnor och klättra i rankingen.",
    },
  },
  PrizePool: {
    text: `<b>Prisvinnande platser</b>:
<b>1:a plats</b>: 50% USD och 10,000 mynt
<b>2:a plats</b>: 30% USD och 3,000 mynt
<b>3:e plats</b>: 20% USD och 2,000 mynt
<b>4:e-10:e plats</b>: 1,000 mynt vardera
<b>11:e-50:e plats</b>: 500 mynt vardera
<b>51:a-100:e plats</b>: 250 mynt vardera

<b>Prispotten</b> ökar när någon röstar på dig på din profilsida.

<b>Villkor</b>:
Alla profiler som laddar upp ett foto och slutför uppgifterna är kvalificerade att delta i tävlingen.

<b>Slutdatum</b>:
{{endDate}}

<b>Utbetalning av belöning</b>:
Du kan ta emot din belöning i <b>TON</b> — kryptovalutan som utvecklats av Telegram.`,
  },
  LeadersItem: {
    join: "Gå med",
  },
  Friends: {
    title: "Bjud in vänner!",
    description: "Du och din vän kommer att få bonusar",
    bonus: {
      profit: "<coins>+{{count}}</coins> till dig och din vän",
      invite: {
        title: "Bjud in vän",
      },
      inviteWithPremium: {
        title: "Bjud in vän",
        description: "med Telegram Premium",
      },
    },
    list: {
      title: "Din vänlista",
    },
    inviteFriend: "Bjud in vän",
    inviteText: `Gå med mig, rösta på de vackraste killarna och tjejerna och få värdefulla priser!
  
  🌟 +{{coins}} mynt som första gåva
  🔥 +{{coinsPremium}} mynt om du har Telegram Premium
  
  🏆 Tjäna mynt för varje röst och välj de mest attraktiva deltagarna. Vill du ha mer? Delta själv och tävla om värdefulla priser!
  
  Missa inte din chans, bli en del av vår stjärngemenskap och vinn!`,
    linkCopied: "Länk kopierad",
  },
  Earn: {
    title: "Tjäna fler mynt",
    tasksList: "Uppgiftslista",
    check: "Kontrollera",
    inReview: "Under granskning",
    subscribe: "Prenumerera",
    participate: "Delta",
    invite: "Bjud in",
    verify: "Verifiera",
    taskCompleted: "Uppgift slutförd",
  },
  Wallet: {
    title: "Få belöningar i TON",
    description:
      "Slutför utmaningar för att tjäna TON, ett kryptomynt utvecklat av Telegram",
    rewards: "Belöningar",
    withdrawal: "Uttag",
    walletConnected: "Din TON-plånbok är ansluten",
    walletDescription: "Fler möjligheter kommer snart att finnas tillgängliga",
  },
  VerificationRequired: {
    title: "Verifiering Krävs",
    description: "För att delta i tävlingen måste du verifiera ditt konto.",
    bdate: {
      title: "Din födelsedag",
    },
  },
  Verification: {
    title: "Verifiering",
    hint: "Håll ditt ansikte inom ovalen",
    secureHint: "Ingen kommer att se detta foto",
    success: "Du har framgångsrikt slutfört verifieringen",
    goToApp: "Gå till applikationen",
    verification: "Verifierar...",
    cameraAccessRequired: "Tillåt kameraåtkomst för att fortsätta",
    gettingAccessToCamer: "Får åtkomst till kameran...",
    cameraAccessNotAllowed: "Användaren nekade kameraåtkomst.",
    cameraAccessError: "Fel vid kameraåtkomst",
    loadingHint: "Detta kan ta lite tid. Var god ha tålamod.",
  },
  Profile: {
    vote: "Rösta",
    askVote: "Be om Röst",
    inviteText: `Hej! 😊 Vänligen stöd mig med din röst i TON Stars-tävlingen! Varje röst är avgörande och hjälper mig att komma närmare att vinna värdefulla priser.

🌟 +{{coins}} mynt som en första gåva
🔥 +{{coinsPremium}} mynt om du har Telegram Premium

Rösta på min profil här: {{url}}

Tack för ditt stöd! 🌟`,
    joinContest: "Delta i tävlingen",
    gender: {
      male: "Man",
      female: "Kvinna",
    },
    photos: {
      inReview: "Dina nya foton granskas.\nDetta kan ta lite tid.",
      rejected:
        "Ett eller flera foton har avvisats av följande anledning:\n{{reason}}.\n\nLadda upp andra foton och försök igen.",
    },
  },
  ProfileEdit: {
    title: "Din profil",
    name: {
      label: "Ditt namn",
      placeholder: "Ange ditt namn",
    },
    instagram: {
      description: "Instagram-profilen måste vara offentlig",
    },
    photos: {
      verificationPhotoUploaded:
        "Ditt verifieringsfoto har laddats upp, ingen kommer att se det.",
      label: "Dina foton",
      mainPhoto: "Huvudfoto",
      error:
        "Ett eller flera foton är inte lämpliga, vänligen gör ett annat val.",
      hint: "Välj dina mest imponerande foton, där det inte finns andra personer, och ditt ansikte är tydligt och uttrycksfullt synligt.",
      inReview: "Dina nya foton granskas.\nDetta kan ta lite tid.",
      rejected:
        "Ett eller flera foton har avvisats av följande anledning:\n{{reason}}.\n\nLadda upp andra foton och försök igen.",
    },
    submit: "Delta i tävlingen",
    photosUploading: "Laddar upp foto...",
    success: "Din profil har uppdaterats",
    errors: {
      name: "Ange ditt namn",
      photos: "Välj 3 foton",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Huvudfoto",
    noFace: "Ditt ansikte har inte upptäckts",
    otherPeople: "Fotot ska bara visa ditt ansikte",
  },
};
