import { appoloClient } from "utils/appolo";
import { UploadCreateMutation, UploadUpdateMutation } from "./gql";
import axios from "axios";
import { UploadApiResponse } from "utils/cloudinary";
import { UploadCloudinaryResourceType } from "__generated__/graphql";
import Emittery from "emittery";
import toast from "react-hot-toast";
import { nanoid } from "@reduxjs/toolkit";

type UploadState =
  | "pending"
  | "creating"
  | "uploading"
  | "completing"
  | "done"
  | "error";

export class Upload extends Emittery {
  abortController = new AbortController();
  id?: string;
  objectUrl?: string;
  file?: File | Blob;
  fileName?: string;
  state: UploadState = "pending";
  _upload?: {
    __typename?: "Upload";
    id: string;
    signedUrl?: string | null;
    previewUrl?: string | null;
  };
  _uploadResponse?: UploadApiResponse;
  progress = 0;

  constructor(file: File | Blob, fileName?: string) {
    super();
    this.fileName = fileName;
    this.id = nanoid();
    this.file = file;
    this.objectUrl = URL.createObjectURL(file);
  }

  setState(state: UploadState) {
    this.state = state;
    this.emit("update");
  }

  async start() {
    try {
      await this.create();
      await this.upload();
      return await this.complete();
    } catch (e) {
      const err = e as Error;
      toast.error(err.message);
      throw e;
    }
  }

  create() {
    this.setState("creating");
    if (!this.file) return;

    return appoloClient
      .mutate({
        mutation: UploadCreateMutation,
        variables: {
          data: {
            fileName:
              "name" in this.file
                ? this.file.name
                : this.fileName || "file.jpg",
            mime: this.file.type,
          },
        },
      })
      .then(({ data }) => {
        this._upload = data?.uploadsCreate[0];
      })
      .catch((e) => {
        this.setState("error");
        throw e;
      });
  }

  upload() {
    this.setState("uploading");
    if (!this.file || !this._upload) return;

    const { signedUrl } = this._upload;

    if (!signedUrl) return;

    const formData = new FormData();
    formData.append("file", this.file);
    return axios
      .post<UploadApiResponse>(signedUrl, formData, {
        signal: this.abortController.signal,
        onUploadProgress: (progress) => {
          this.progress = progress.progress ? progress.progress * 100 : 50;
        },
      })
      .then(({ data }) => {
        this._uploadResponse = data;
      })
      .catch((e) => {
        this.setState("error");
        throw e;
      });
  }

  complete() {
    this.setState("completing");

    if (!this._uploadResponse || !this._upload) return;

    return appoloClient
      .mutate({
        mutation: UploadUpdateMutation,
        variables: {
          id: this._upload.id,
          data: {
            completed: true,
            cloudinary: {
              public_id: this._uploadResponse.public_id,
              resource_type: this._uploadResponse
                .resource_type as UploadCloudinaryResourceType,
              url: this._uploadResponse.url,
              secure_url: this._uploadResponse.secure_url,
            },
          },
        },
      })
      .then((response) => {
        this._upload = response.data?.uploadUpdate;
        this.setState("done");

        return response;
      })
      .catch((e) => {
        this.setState("error");
        throw e;
      });
  }

  abort() {
    this.abortController.abort();
  }
}
