import "./Profile.css";
import {
  Avatar,
  Button,
  IconButton,
  Subheadline,
  Title,
} from "@telegram-apps/telegram-ui";
import VerifiedIcon from "../../assets/verified.svg?react";
// import PinIcon from "../../assets/pin.svg?react";
import GenderIcon from "./icons/gender.svg?react";
import InstagramIcon from "../../assets/instagram.svg?react";
import HeartIcon from "../../assets/heart.svg?react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import countries from "data/countries.json";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { ProfileEdit } from "components/ProfileEdit/ProfileEdit";
import ClockIcon from "assets/clock.svg?react";
import RejectedIcon from "assets/rejected.svg?react";
import PencilIcon from "assets/pencil.svg?react";
import { VerificationRequired } from "components/Verification/VerificationRequired";
import StarIcon from "assets/star.svg?react";
import {
  ProfileVote,
  ProfileVoteProps,
} from "components/ProfileVote/ProfileVote";
// import WebApp from "@twa-dev/sdk";
// import config from "config";

const ProfileUser_QueryFragment = gql(`
  fragment ProfileUser_QueryFragment on User {
    ...ProfileEditUser_QueryFragment
    ...VerificationRequiredUser_QueryFragment
    id
    name
    photo_url
    age
    countryCode
    gender
    instagram
    verified
    isMe
    likesCount
    starsCount
    haveVerificationPhoto
    uploads {
      id
      previewUrl: url(width: 477, height: 848)
      faceUrl: url(size: 200, face: true)
    }
    uploadsPending {
      id
      previewUrl: url(width: 477, height: 848)
    }
    photoStatus {
      rejectReasonText
    }
  }
`);

// eslint-disable-next-line react-refresh/only-export-components
export const ProfileBonus_QueryFragment = gql(`
  fragment ProfileBonus_QueryFragment on Bonus {
    id
    invite
    invitePremium
  }
`);

export type ProfileProps = {
  user?: FragmentType<typeof ProfileUser_QueryFragment> | null;
  voteConfig: ProfileVoteProps["voteConfig"];
  bonus: FragmentType<typeof ProfileBonus_QueryFragment> | null;
};

export const Profile = (props: ProfileProps) => {
  const user = getFragmentData(ProfileUser_QueryFragment, props.user);
  // const bonus = getFragmentData(ProfileBonus_QueryFragment, props.bonus);

  const { t, i18n } = useTranslation("Profile");

  const country = useMemo(
    () => countries.find((_) => _.code === user?.countryCode),
    [user?.countryCode]
  );

  const [editVisible, setEditVisible] = useState(false);
  const [verificationVisible, setVerificationVisible] = useState(false);
  const [profileVoteVisible, setProfileVoteVisible] = useState(false);

  const [photoOrder, setPhotoOrder] = useState([0, 1, 2]);

  const setFirstPhoto = useCallback((index: number) => {
    setPhotoOrder((_arr) => {
      const arr = [..._arr];
      const idx = arr.findIndex((_) => _ === index);
      if (idx) [arr[0], arr[idx]] = [arr[idx], arr[0]];
      return [...arr];
    });
  }, []);

  // const shareUrl =
  //   `https://t.me/share/url?url=${config.refUrl}&text=` +
  //   encodeURIComponent(
  //     t("inviteText", {
  //       coins: Intl.NumberFormat(i18n.language, {
  //         notation: "compact",
  //       }).format(bonus?.invite),
  //       coinsPremium: Intl.NumberFormat(i18n.language, {
  //         notation: "compact",
  //       }).format(bonus?.invitePremium),
  //       url: config.refUrl,
  //     })
  //   );

  return (
    <div className="profile">
      {user?.photoStatus?.rejectReasonText ? (
        <div className="profile-photos-review">
          <RejectedIcon width={24} height={24} />
          <div>
            {t("photos.rejected", {
              reason: user?.photoStatus.rejectReasonText,
            })}
          </div>
        </div>
      ) : (
        !!user?.uploadsPending.length && (
          <div className="profile-photos-review">
            <ClockIcon width={18} height={18} />
            <div>{t("photos.inReview")}</div>
          </div>
        )
      )}
      <div className="profile-header">
        <Avatar
          className="profile-header-photo"
          src={user?.photo_url || user?.uploads[0]?.faceUrl || ""}
          acronym={
            (user?.name?.[0]?.toUpperCase() || "") +
            user?.name?.[1]?.toUpperCase()
          }
          size={96}
        />
        <div className="profile-header-row">
          <Title
            className="profile-header-body"
            level="2"
            weight="2"
            data-id={user?.id}
          >
            {user?.name}
            {user?.age
              ? `, ${Intl.NumberFormat(i18n.language).format(user.age)}`
              : ""}
          </Title>
          {user?.verified && <VerifiedIcon width={26} height={26} />}
          {user?.isMe && (
            <IconButton
              className="profile-button"
              onClick={() => {
                if (user?.haveVerificationPhoto) setEditVisible(true);
                else setVerificationVisible(true);
              }}
            >
              <PencilIcon width={12} height={12} />
              {editVisible && (
                <Modal fullscreen onClose={() => setEditVisible(false)}>
                  <ProfileEdit
                    user={user}
                    onClose={() => setEditVisible(false)}
                  />
                </Modal>
              )}
            </IconButton>
          )}
        </div>
        <Button
          className="profile-vote-button"
          before={<StarIcon width={20} height={20} />}
          onClick={() => {
            setProfileVoteVisible(true);
          }}
        >
          {t("vote")}
        </Button>
        <div
          style={{
            justifyContent: "space-evenly",
          }}
          className="profile-header-row"
        >
          {user?.instagram && (
            <a
              href={`https://instagram.com/${user.instagram}`}
              className="profile-header-row-item"
              target="_blank"
            >
              <InstagramIcon width={28} height={28} />
              <Subheadline weight="2">{user.instagram}</Subheadline>
            </a>
          )}
          <div className="profile-header-row-item">
            <HeartIcon width={28} height={28} />
            <Subheadline>
              {Intl.NumberFormat(i18n.language).format(user?.likesCount)}
            </Subheadline>
          </div>
          <div className="profile-header-row-item">
            <StarIcon color="rgb(252, 210, 39)" width={24} height={24} />
            <Subheadline>
              {Intl.NumberFormat(i18n.language).format(user?.starsCount)}
            </Subheadline>
          </div>
        </div>
      </div>
      {!!user?.uploads.length && (
        <div className="profile-photos">
          <div
            className={`profile-photo profile-photo-${photoOrder.findIndex(
              (_) => _ === 0
            )}`}
            style={{
              backgroundImage: `url(${user?.uploads[0].previewUrl})`,
            }}
            onClick={() => setFirstPhoto(0)}
          ></div>
          <div
            className={`profile-photo profile-photo-${photoOrder.findIndex(
              (_) => _ === 1
            )}`}
            style={{
              backgroundImage: `url(${user?.uploads[1].previewUrl})`,
            }}
            onClick={() => setFirstPhoto(1)}
          ></div>
          <div
            className={`profile-photo profile-photo-${photoOrder.findIndex(
              (_) => _ === 2
            )}`}
            style={{
              backgroundImage: `url(${user?.uploads[2].previewUrl})`,
            }}
            onClick={() => setFirstPhoto(2)}
          ></div>
        </div>
      )}
      <div className="profile-info">
        <div className="profile-info-item">
          {/* <PinIcon style={{ minWidth: 18 }} width={18} height={18} /> */}
          <Subheadline className="profile-info-item-body" level="1" weight="3">
            {country?.flag} {country?.name}
          </Subheadline>
        </div>
        {user?.gender && (
          <div className="profile-info-item">
            <GenderIcon style={{ minWidth: 18 }} width={18} height={18} />
            <Subheadline
              className="profile-info-item-body"
              level="1"
              weight="3"
            >
              {t(`gender.${user.gender}`)}
            </Subheadline>
          </div>
        )}
      </div>
      {verificationVisible && (
        <VerificationRequired
          user={user}
          onClose={() => setVerificationVisible(false)}
          onComplete={() => setEditVisible(true)}
        />
      )}
      {profileVoteVisible && (
        <Modal onClose={() => setProfileVoteVisible(false)}>
          <ProfileVote
            userId={user?.id}
            voteConfig={props.voteConfig}
            onClose={() => setProfileVoteVisible(false)}
          />
        </Modal>
      )}
    </div>
  );
};
