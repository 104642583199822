import * as React from "react";
const SvgInvite = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: 512, height: 512, x: 0, y: 0, viewBox: "0 0 682.667 682.667", style: {
  enableBackground: "new 0 0 512 512"
}, xmlSpace: "preserve", className: "", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "b", clipPathUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("path", { d: "M0 512h512V0H0Z", fill: "currentColor", opacity: 1, "data-original": "currentColor" }))), /* @__PURE__ */ React.createElement("mask", { id: "a" }, /* @__PURE__ */ React.createElement("rect", { width: "100%", height: "100%", fill: "#ffffff", opacity: 1, "data-original": "#ffffff" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#a)" }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#b)", transform: "matrix(1.33333 0 0 -1.33333 0 682.667)" }, /* @__PURE__ */ React.createElement("path", { d: "M0 0c0 45.287 36.713 82 82 82s82-36.713 82-82-36.713-82-82-82S0-45.287 0 0Z", style: {
  strokeWidth: 40,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "translate(106 273.125)", fill: "none", stroke: "currentColor", strokeWidth: 40, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, strokeDasharray: "none", strokeOpacity: "", "data-original": "currentColor", className: "" }), /* @__PURE__ */ React.createElement("path", { d: "M0 0h-174c-27.129 0-46.39 26.435-38.083 52.261 22.067 68.611 89.699 118.26 165.645 118.26 36.229 0 69.818-11.298 97.438-30.563", style: {
  strokeWidth: 40,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "translate(234 20)", fill: "none", stroke: "currentColor", strokeWidth: 40, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, strokeDasharray: "none", strokeOpacity: "", "data-original": "currentColor", className: "" }), /* @__PURE__ */ React.createElement("path", { d: "M0 0c0 45.287 36.713 82 82 82s82-36.713 82-82-36.713-82-82-82S0-45.287 0 0Z", style: {
  strokeWidth: 40,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "translate(313 410)", fill: "none", stroke: "currentColor", strokeWidth: 40, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, strokeDasharray: "none", strokeOpacity: "", "data-original": "currentColor", className: "" }), /* @__PURE__ */ React.createElement("path", { d: "M0 0c31.085 32.88 75.113 53.396 123.933 53.396 36.228 0 69.817-11.298 97.437-30.563", style: {
  strokeWidth: 40,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "translate(270.63 274)", fill: "none", stroke: "currentColor", strokeWidth: 40, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, strokeDasharray: "none", strokeOpacity: "", "data-original": "currentColor", className: "" }), /* @__PURE__ */ React.createElement("path", { d: "M0 0v150", style: {
  strokeWidth: 40,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "translate(417 20)", fill: "none", stroke: "currentColor", strokeWidth: 40, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, strokeDasharray: "none", strokeOpacity: "", "data-original": "currentColor", className: "" }), /* @__PURE__ */ React.createElement("path", { d: "M0 0h-150", style: {
  strokeWidth: 40,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
  strokeDasharray: "none",
  strokeOpacity: 1
}, transform: "translate(492 95)", fill: "none", stroke: "currentColor", strokeWidth: 40, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: 10, strokeDasharray: "none", strokeOpacity: "", "data-original": "currentColor", className: "" })))));
export default SvgInvite;
