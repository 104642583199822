export default {
  g: {
    flag: "🇱🇻",
    langName: "Latvian",
    langNameNative: "Latviešu",
    continue: "Turpināt",
    save: "Saglabāt",
    retry: "Mēģināt vēlreiz",
    loading: "Ielādē...",
    genderMany: {
      all: "Visi",
      male: "Vīrieši",
      female: "Sievietes",
    },
  },
  CreateAccount: {
    slogan: "Vieta, kur sapņi kļūst par uzvarām!",
    gender: {
      title: "Sveiki {{name}}, Jūs esat...",
      male: "Vīrietis",
      female: "Sieviete",
    },
  },
  Settings: {
    title: "Iestatījumi",
    language: "Valoda",
    auto: "Automātiski",
    country: "Valsts",
    hideProfile: {
      label: "Slēpt profilu",
      description:
        "Jūsu profils būs paslēpts no visiem, it kā jūs to būtu izdzēsis.",
    },
    profileHidden:
      "Jūsu profils ir paslēpts un nav redzams citiem lietotājiem.",
  },
  Tabs: {
    vote: "Balsot",
    contest: "Konkurss",
    friends: "Draugi",
    earn: "Nopelnīt",
    wallet: "Maciņš",
  },
  Vote: {
    whoIsBetter: "Kurš jums patīk vairāk?",
    votingForLeader: "Balsošana par līderi",
    noUsers: {
      title: "🚀 Tas ir viss šodienai",
      description: "Aiciniet draugus turpināt balsot un pelnīt monētas",
      inviteFriends: "Aicināt draugus",
    },
  },
  Leaders: {
    rewards: "Atlīdzības",
    comingSoon: "Drīzumā",
    prizePool: "Balvu fonds",
    moreInfo: "Vairāk informācijas",
    contest: "Konkurss",
    richest: "Bagātākie",
    sponsors: "Sponsori",
    description: {
      likes:
        "Aiciniet draugus un dalieties ar savu profilu, lai iegūtu vairāk balsu un palielinātu savas izredzes uzvarēt.",
      coins:
        "Pabeidziet uzdevumus, balsojiet par citiem dalībniekiem un aiciniet draugus, lai nopelnītu vairāk monētu un nostiprinātu savu pozīciju.",
      stars:
        "Balsojiet par profiliem profila lapā, lai saņemtu zvaigznes un kāptu augstāk reitingos.",
    },
  },
  PrizePool: {
    text: `<b>Balvu vietas</b>:
<b>1. vieta</b>: 50% USD un 10,000 monētu
<b>2. vieta</b>: 30% USD un 3,000 monētu
<b>3. vieta</b>: 20% USD un 2,000 monētu
<b>4.-10. vietas</b>: katram pa 1,000 monētām
<b>11.-50. vietas</b>: katram pa 500 monētām
<b>51.-100. vietas</b>: katram pa 250 monētām

<b>Balvu fonds</b> palielinās, kad kāds balso par jums jūsu profila lapā.

<b>Nosacījumi</b>:
Visi profili, kas augšupielādē fotoattēlu un izpilda uzdevumus, var piedalīties konkursā.

<b>Beigu datums</b>:
{{endDate}}

<b>Atlīdzības izmaksa</b>:
Jūs varat saņemt savu atlīdzību <b>TON</b> — kriptovalūtā, kuru izstrādāja Telegram.`,
  },
  LeadersItem: {
    join: "Pievienoties",
  },
  Friends: {
    title: "Ielūdz draugus!",
    description: "Tu un tavs draugs saņemsiet bonusus",
    bonus: {
      profit: "<coins>+{{count}}</coins> tev un tavam draugam",
      invite: {
        title: "Ielūdz draugu",
      },
      inviteWithPremium: {
        title: "Ielūdz draugu",
        description: "ar Telegram Premium",
      },
    },
    list: {
      title: "Tavu draugu saraksts",
    },
    inviteFriend: "Ielūdz draugu",
    inviteText: `Pievienojies man, piedalies balsošanā par skaistākajiem puišiem un meitenēm un saņem vērtīgas balvas!
  
  🌟 +{{coins}} monētas kā pirmā dāvana
  🔥 +{{coinsPremium}} monētas, ja tev ir Telegram Premium
  
  🏆 Nopelni monētas par katru balsi un izvēlies pievilcīgākos dalībniekus. Vēlies vairāk? Piedalies pats un sacenties par vērtīgām balvām!
  
  Nepalaid garām savu iespēju, kļūsti par mūsu zvaigžņu kopienas dalībnieku un uzvari!`,
    linkCopied: "Saite nokopēta",
  },
  Earn: {
    title: "Nopelni vairāk monētu",
    tasksList: "Uzdevumu saraksts",
    check: "Pārbaudīt",
    inReview: "Pārskatē",
    subscribe: "Abonēt",
    participate: "Piedalīties",
    invite: "Ielūgt",
    verify: "Verificēt",
    taskCompleted: "Uzdevums pabeigts",
  },
  Wallet: {
    title: "Saņemiet atlīdzību TON",
    description:
      "Izpildiet izaicinājumus, lai nopelnītu TON, kriptovalūtu, kuru izstrādājusi Telegram",
    rewards: "Atlīdzības",
    withdrawal: "Izņemšana",
    walletConnected: "Jūsu TON maks ir pievienots",
    walletDescription: "Drīzumā būs pieejamas vairāk iespēju",
  },
  VerificationRequired: {
    title: "Nepieciešama verifikācija",
    description: "Lai piedalītos konkursā, tev jāverificē savs konts.",
    bdate: {
      title: "Tava dzimšanas diena",
    },
  },
  Verification: {
    title: "Verifikācija",
    hint: "Turiet savu seju ovāla iekšpusē",
    secureHint: "Neviens neredzēs šo fotoattēlu",
    success: "Tu veiksmīgi pabeidzi verifikāciju",
    goToApp: "Doties uz lietotni",
    verification: "Verificē...",
    cameraAccessRequired: "Lūdzu, atļauj piekļuvi kamerai, lai turpinātu",
    gettingAccessToCamer: "Iegūst piekļuvi kamerai...",
    cameraAccessNotAllowed: "Lietotājs atteicis piekļuvi kamerai.",
    cameraAccessError: "Kļūda piekļūstot kamerai",
    loadingHint: "Tas var aizņemt kādu laiku. Lūdzu, esi pacietīgs.",
  },
  Profile: {
    vote: "Balsot",
    askVote: "Lūgt Balsot",
    inviteText: `Sveiki! 😊 Lūdzu, atbalstiet mani ar savu balsi TON Stars konkursā! Katrs balsojums ir izšķirošs un palīdz man tuvāk tikt pie vērtīgām balvām.

🌟 +{{coins}} monētas kā pirmo dāvanu
🔥 +{{coinsPremium}} monētas, ja jums ir Telegram Premium

Balsojiet par manu profilu šeit: {{url}}

Paldies par jūsu atbalstu! 🌟`,
    joinContest: "Pievienoties konkursam",
    gender: {
      male: "Vīrietis",
      female: "Sieviete",
    },
    photos: {
      inReview:
        "Jūsu jaunās fotogrāfijas tiek pārskatītas.\nTas var aizņemt kādu laiku.",
      rejected:
        "Viena vai vairākas fotogrāfijas ir noraidītas šāda iemesla dēļ:\n{{reason}}.\n\nLūdzu, augšupielādējiet citas fotogrāfijas un mēģiniet vēlreiz.",
    },
  },
  ProfileEdit: {
    title: "Jūsu profils",
    name: {
      label: "Jūsu vārds",
      placeholder: "Ievadiet savu vārdu",
    },
    instagram: {
      description: "Instagram profilam jābūt publiskam",
    },
    photos: {
      verificationPhotoUploaded:
        "Jūsu verifikācijas fotoattēls ir augšupielādēts, neviens to neredzēs.",
      label: "Jūsu fotogrāfijas",
      mainPhoto: "Galvenā fotogrāfija",
      error:
        "Viena vai vairākas fotogrāfijas nav piemērotas, lūdzu, izvēlieties citas.",
      hint: "Izvēlieties savas iespaidīgākās fotogrāfijas, kurās nav citu cilvēku un jūsu seja ir skaidri redzama.",
      inReview:
        "Jūsu jaunās fotogrāfijas tiek pārskatītas.\nTas var aizņemt kādu laiku.",
      rejected:
        "Viena vai vairākas fotogrāfijas ir noraidītas šāda iemesla dēļ:\n{{reason}}.\n\nLūdzu, augšupielādējiet citas fotogrāfijas un mēģiniet vēlreiz.",
    },
    submit: "Pievienoties konkursam",
    photosUploading: "Fotogrāfija tiek augšupielādēta...",
    success: "Jūsu profils ir atjaunināts",
    errors: {
      name: "Lūdzu, norādiet savu vārdu",
      photos: "Lūdzu, atlasiet 3 fotogrāfijas",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Galvenā fotogrāfija",
    noFace: "Jūsu seja netika atpazīta",
    otherPeople: "Fotogrāfijā jābūt redzamai tikai jūsu sejai",
  },
};
