export default {
  g: {
    flag: "🇮🇪",
    langName: "Irish",
    langNameNative: "Gaeilge",
    continue: "Lean ar aghaidh",
    save: "Sábháil",
    retry: "Bain triail eile as",
    loading: "Ag luchtú...",
    genderMany: {
      all: "Gach Duine",
      male: "Fir",
      female: "Mná",
    },
  },
  CreateAccount: {
    slogan: "Áit ina n-athraíonn Aislingí go Bua!",
    gender: {
      title: "Dia dhuit {{name}}, Is tusa...",
      male: "Fireann",
      female: "Baineann",
    },
  },
  Settings: {
    title: "Socruithe",
    language: "Teanga",
    auto: "Uathoibríoch",
    country: "Tír",
    hideProfile: {
      label: "Folaigh Próifíl",
      description:
        "Beidh do phróifíl i bhfolach ó gach duine, amhail is dá mbeadh sé scriosta agat.",
    },
    profileHidden:
      "Tá do phróifíl i bhfolach agus ní fheictear d’úsáideoirí eile é.",
  },
  Tabs: {
    vote: "Vótáil",
    contest: "Comórtas",
    friends: "Cairde",
    earn: "Tuilleamh",
    wallet: "Sparán",
  },
  Vote: {
    whoIsBetter: "Cé acu is fearr leat?",
    votingForLeader: "Ag vótáil don cheannaire",
    noUsers: {
      title: "🚀 Sin é sin do inniu",
      description:
        "Tabhair cuireadh do chairde leanúint ar aghaidh ag vótáil agus ag saothrú boinn",
      inviteFriends: "Tabhair cuireadh do Chairde",
    },
  },
  Leaders: {
    rewards: "Luaíochtaí",
    comingSoon: "Ag teacht go luath",
    prizePool: "Linn Duaiseanna",
    moreInfo: "Tuilleadh eolais",
    contest: "Comórtas",
    richest: "Na Daoine is Saibhre",
    sponsors: "Urraitheoirí",
    description: {
      likes:
        "Tabhair cuireadh do chairde agus roinn do phróifíl chun níos mó vótaí a bhailiú agus do sheans a mhéadú le buachan.",
      coins:
        "Críochnaigh tascanna, vótáil do rannpháirtithe eile, agus tabhair cuireadh do chairde níos mó boinn a thuilleamh agus do sheasamh a neartú.",
      stars:
        "Vótáil do phróifílí ar an leathanach próifíle chun réaltaí a fháil agus dul suas sna rangú.",
    },
  },
  PrizePool: {
    text: `<b>Áiteanna buaite duaiseanna</b>:
<b>1ú háit</b>: 50% USD agus 10,000 bonn
<b>2ú háit</b>: 30% USD agus 3,000 bonn
<b>3ú háit</b>: 20% USD agus 2,000 bonn
<b>4ú-10ú háiteanna</b>: 1,000 bonn gach ceann
<b>11ú-50ú háiteanna</b>: 500 bonn gach ceann
<b>51ú-100ú háiteanna</b>: 250 bonn gach ceann

<b>Méadaíonn an linn duaiseanna</b> nuair a vótálann duine éigin ar do shon ar do leathanach próifíle.

<b>Coinníollacha</b>:
Tá gach próifíl a uaslódálann grianghraf agus a chríochnaíonn na tascanna incháilithe le páirt a ghlacadh sa chomórtas.

<b>Dáta deiridh</b>:
{{endDate}}

<b>Íocaíocht luaíochtaí</b>:
Is féidir leat do luach saothair a fháil i <b>TON</b> — an cript-airgeadra forbartha ag Telegram.`,
  },
  LeadersItem: {
    join: "Bí páirteach",
  },
  Friends: {
    title: "Tabhair cuireadh do chairde!",
    description: "Gheobhaidh tú féin agus do chara bónais",
    bonus: {
      profit: "<coins>+{{count}}</coins> duit féin agus do chara",
      invite: {
        title: "Tabhair cuireadh do chara",
      },
      inviteWithPremium: {
        title: "Tabhair cuireadh do chara",
        description: "le Telegram Premium",
      },
    },
    list: {
      title: "Do liosta cairde",
    },
    inviteFriend: "Tabhair cuireadh do chara",
    inviteText: `Glac páirt liomsa, vótáil ar son na mbuachaillí agus na gcailíní is áille, agus faigh duaiseanna luachmhara!
  
  🌟 +{{coins}} bonn mar bhronntanas tosaigh
  🔥 +{{coinsPremium}} bonn má tá Telegram Premium agat
  
  🏆 Tuilleamh boinn le gach vóta agus roghnaigh na rannpháirtithe is tarraingtí. Níos mó ag teastáil? Glac páirt féin agus déan comórtas ar son duaiseanna luachmhara!
  
  Ná caill do sheans, bí páirteach inár bpobal réaltaí agus buail!`,
    linkCopied: "Nasc cóipeáilte",
  },
  Earn: {
    title: "Tuilleamh níos mó boinn",
    tasksList: "Liosta tascanna",
    check: "Seiceáil",
    inReview: "Faoi athbhreithniú",
    subscribe: "Liostáil",
    participate: "Glac páirt",
    invite: "Tabhair cuireadh",
    verify: "Deimhnigh",
    taskCompleted: "Tasc críochnaithe",
  },
  Wallet: {
    title: "Faigh luach saothair i TON",
    description:
      "Comhlánaigh dúshláin chun TON a thuilleamh, bonn cripte a d'fhorbair Telegram",
    rewards: "Luaíochtaí",
    withdrawal: "Aistarraingt",
    walletConnected: "Tá do sparán TON ceangailte",
    walletDescription: "Beidh tuilleadh deiseanna ar fáil go luath",
  },
  VerificationRequired: {
    title: "Teastaíonn Deimhniú",
    description:
      "Chun páirt a ghlacadh sa chomórtas, ní mór duit do chuntas a dheimhniú.",
    bdate: {
      title: "Dáta breithe",
    },
  },
  Verification: {
    title: "Deimhniú",
    hint: "Coinnigh d’aghaidh laistigh den ubhchruthach",
    secureHint: "Ní fheicfidh aon duine an grianghraf seo",
    success: "D’éirigh leat an deimhniú a chríochnú",
    goToApp: "Téigh chuig an aip",
    verification: "Ag deimhniú...",
    cameraAccessRequired:
      "Tabhair rochtain ar an gceamara le do thoil chun leanúint ar aghaidh",
    gettingAccessToCamer: "Ag fáil rochtana ar an gceamara...",
    cameraAccessNotAllowed:
      "Diúltaíodh rochtain ar an gceamara ag an úsáideoir.",
    cameraAccessError: "Earráid rochtana ceamara",
    loadingHint:
      "D’fhéadfadh sé seo tamall a thógáil. Bí foighneach le do thoil.",
  },
  Profile: {
    vote: "Vótáil",
    askVote: "Iarr Vóta",
    inviteText: `Dia dhuit! 😊 Tabhair tacaíocht dom le do vóta sa chomórtas TON Stars! Tá gach vóta ríthábhachtach agus cabhraíonn sé liom dul níos gaire do dhuaiseanna luachmhara a bhuachan.

🌟 +{{coins}} boinn mar chéad bhronntanas
🔥 +{{coinsPremium}} boinn má tá Telegram Premium agat

Vótáil ar mo phróifíl anseo: {{url}}

Go raibh maith agat as do thacaíocht! 🌟`,
    joinContest: "Bí páirteach sa chomórtas",
    gender: {
      male: "Fireann",
      female: "Baineann",
    },
    photos: {
      inReview:
        "Tá do ghrianghraif nua á n-athbhreithniú.\nFéadfaidh sé seo tamall a thógáil.",
      rejected:
        "Diúltaíodh ceann nó níos mó de do ghrianghraif ar na cúiseanna seo a leanas:\n{{reason}}.\n\nUaslódáil grianghraif eile le do thoil agus bain triail eile as.",
    },
  },
  ProfileEdit: {
    title: "Do Phróifíl",
    name: {
      label: "Do ainm",
      placeholder: "Cuir isteach d'ainm",
    },
    instagram: {
      description: "Caithfidh próifíl Instagram a bheith poiblí",
    },
    photos: {
      verificationPhotoUploaded:
        "Tá do ghrianghraf fíoraithe uaslódáilte, ní fheicfidh éinne é.",
      label: "Do Grianghraif",
      mainPhoto: "Príomh-Grianghraf",
      error:
        "Níl ceann nó níos mó de na grianghraif oiriúnach, roghnaigh ceann eile le do thoil.",
      hint: "Roghnaigh na grianghraif is suntasaí atá agat, áit nach bhfuil aon daoine eile, agus go bhfuil d'aghaidh le feiceáil go soiléir agus go sainráiteach.",
      inReview:
        "Tá do ghrianghraif nua á n-athbhreithniú.\nFéadfaidh sé seo tamall a thógáil.",
      rejected:
        "Diúltaíodh ceann nó níos mó de do ghrianghraif ar na cúiseanna seo a leanas:\n{{reason}}.\n\nUaslódáil grianghraif eile le do thoil agus bain triail eile as.",
    },
    submit: "Bí páirteach sa chomórtas",
    photosUploading: "Uaslódáil grianghraf...",
    success: "Tá do phróifíl cothrom le dáta",
    errors: {
      name: "Cuir isteach d'ainm",
      photos: "Roghnaigh 3 ghrianghraf le do thoil",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Príomh-Grianghraf",
    noFace: "Níor aimsíodh d'aghaidh",
    otherPeople: "Níor cheart don ghrianghraf ach d'aghaidh a thaispeáint",
  },
};
