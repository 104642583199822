export default {
  g: {
    flag: "🇧🇬",
    langName: "Bulgarian",
    langNameNative: "Български",
    continue: "Продължи",
    save: "Запази",
    retry: "Опитай отново",
    loading: "Зареждане...",
    genderMany: {
      all: "Всички",
      male: "Мъже",
      female: "Жени",
    },
  },
  CreateAccount: {
    slogan: "Мястото, където мечтите се превръщат в победи!",
    gender: {
      title: "Здравей {{name}}, Ти си...",
      male: "Мъж",
      female: "Жена",
    },
  },
  Settings: {
    title: "Настройки",
    language: "Език",
    auto: "Автоматично",
    country: "Държава",
    hideProfile: {
      label: "Скрий профила",
      description:
        "Вашият профил ще бъде скрит от всички, сякаш сте го изтрили.",
    },
    profileHidden: "Вашият профил е скрит и не се вижда от други потребители.",
  },
  Tabs: {
    vote: "Гласувай",
    contest: "Конкурс",
    friends: "Приятели",
    earn: "Спечели",
    wallet: "Портфейл",
  },
  Vote: {
    whoIsBetter: "Кой ти харесва повече?",
    votingForLeader: "Гласуване за лидера",
    noUsers: {
      title: "🚀 Това е всичко за днес",
      description:
        "Поканете приятели, за да продължите да гласувате и да печелите монети",
      inviteFriends: "Поканете приятели",
    },
  },
  Leaders: {
    rewards: "Награди",
    comingSoon: "Очаквайте скоро",
    prizePool: "Награден фонд",
    moreInfo: "Повече информация",
    contest: "Конкурс",
    richest: "Най-богати",
    sponsors: "Спонсори",
    description: {
      likes:
        "Поканете приятели и споделете своя профил, за да съберете повече гласове и да увеличите шансовете си за победа.",
      coins:
        "Изпълнете задачи, гласувайте за други участници и поканете приятели, за да спечелите повече монети и да укрепите позицията си.",
      stars:
        "Гласувайте за профили на страницата на профила, за да получите звезди и да се изкачите в класациите.",
    },
  },
  PrizePool: {
    text: `<b>Печеливши места</b>:
<b>1-во място</b>: 50% USD и 10,000 монети
<b>2-ро място</b>: 30% USD и 3,000 монети
<b>3-то място</b>: 20% USD и 2,000 монети
<b>4-10-то място</b>: по 1,000 монети всяко
<b>11-50-то място</b>: по 500 монети всяко
<b>51-100-то място</b>: по 250 монети всяко

<b>Наградният фонд</b> се увеличава, когато някой гласува за вас на страницата на вашия профил.

<b>Условия</b>:
Всички профили, които качат снимка и изпълнят задачите, могат да участват в конкурса.

<b>Краен срок</b>:
{{endDate}}

<b>Изплащане на награди</b>:
Можете да получите наградата си в <b>TON</b> — криптовалутата, разработена от Telegram.`,
  },
  LeadersItem: {
    join: "Присъединете се",
  },
  Friends: {
    title: "Поканете приятели!",
    description: "Вие и вашият приятел ще получите бонуси",
    bonus: {
      profit: "<coins>+{{count}}</coins> за вас и вашият приятел",
      invite: {
        title: "Поканете приятел",
      },
      inviteWithPremium: {
        title: "Поканете приятел",
        description: "с Telegram Premium",
      },
    },
    list: {
      title: "Списък на вашите приятели",
    },
    inviteFriend: "Поканете приятел",
    inviteText: `Присъединете се към мен, участвайте в гласуването за най-красивите момчета и момичета и получете ценни награди!
  
  🌟 +{{coins}} монети като първи подарък
  🔥 +{{coinsPremium}} монети, ако имате Telegram Premium
  
  🏆 Спечелете монети за всеки глас и изберете най-привлекателните участници. Искате повече? Участвайте сами и се състезавайте за ценни награди!
  
  Не пропускайте своя шанс, станете част от нашата звездна общност и спечелете!`,
    linkCopied: "Връзката е копирана",
  },
  Earn: {
    title: "Спечелете повече монети",
    tasksList: "Списък със задачи",
    check: "Проверете",
    inReview: "В преглед",
    subscribe: "Абонирайте се",
    participate: "Участвайте",
    invite: "Поканете",
    verify: "Потвърдете",
    taskCompleted: "Задачата е завършена",
  },
  Wallet: {
    title: "Получавайте награди в TON",
    description:
      "Изпълнявайте предизвикателства, за да спечелите TON, криптовалута, разработена от Telegram",
    rewards: "Награди",
    withdrawal: "Теглене",
    walletConnected: "Вашият TON портфейл е свързан",
    walletDescription: "Скоро ще има повече възможности",
  },
  VerificationRequired: {
    title: "Необходимо е потвърждение",
    description: "За да участвате в конкурса, трябва да потвърдите акаунта си.",
    bdate: {
      title: "Дата на раждане",
    },
  },
  Verification: {
    title: "Потвърждение",
    hint: "Дръжте лицето си в овала",
    secureHint: "Никой няма да види тази снимка",
    success: "Успешно завършихте потвърждението",
    goToApp: "Отидете в приложението",
    verification: "Потвърждаване...",
    cameraAccessRequired: "Моля, дайте достъп до камерата, за да продължите",
    gettingAccessToCamer: "Получаване на достъп до камерата...",
    cameraAccessNotAllowed: "Достъпът до камерата е отказан от потребителя.",
    cameraAccessError: "Грешка при достъпа до камерата",
    loadingHint: "Това може да отнеме известно време. Моля, бъдете търпеливи.",
  },
  Profile: {
    vote: "Гласувай",
    askVote: "Помоли за гласуване",
    inviteText: `Здравей! 😊 Моля, подкрепи ме с гласа си в конкурса TON Stars! Всеки глас е от значение и ми помага да се приближа към спечелването на ценни награди.

🌟 +{{coins}} монети като първи подарък
🔥 +{{coinsPremium}} монети, ако имаш Telegram Premium

Гласувай за моя профил тук: {{url}}

Благодаря за подкрепата ти! 🌟`,
    joinContest: "Присъединете се към конкурса",
    gender: {
      male: "Мъжки",
      female: "Женски",
    },
    photos: {
      inReview:
        "Новите ви снимки са в процес на преглед.\nТова може да отнеме известно време.",
      rejected:
        "Една или повече снимки бяха отхвърлени поради следната причина:\n{{reason}}.\n\nМоля, качете различни снимки и опитайте отново.",
    },
  },
  ProfileEdit: {
    title: "Вашият профил",
    name: {
      label: "Вашето име",
      placeholder: "Въведете вашето име",
    },
    instagram: {
      description: "Профилът в Instagram трябва да бъде публичен",
    },
    photos: {
      verificationPhotoUploaded:
        "Вашата верификационна снимка е качена, никой няма да я види.",
      label: "Вашите снимки",
      mainPhoto: "Основна снимка",
      error:
        "Една или повече снимки не са подходящи, моля, направете друг избор.",
      hint: "Изберете най-впечатляващите си снимки, на които няма други хора и лицето ви е ясно и изразително видимо.",
      inReview:
        "Новите ви снимки са в процес на преглед.\nТова може да отнеме известно време.",
      rejected:
        "Една или повече снимки бяха отхвърлени поради следната причина:\n{{reason}}.\n\nМоля, качете различни снимки и опитайте отново.",
    },
    submit: "Присъединете се към конкурса",
    photosUploading: "Качване на снимка...",
    success: "Вашият профил беше обновен",
    errors: {
      name: "Моля, въведете вашето име",
      photos: "Моля, изберете 3 снимки",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Основна снимка",
    noFace: "Лицето ви не е открито",
    otherPeople: "Снимката трябва да показва само вашето лице",
  },
};
