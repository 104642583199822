import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTimePeriodFromNow } from "utils/time";
import TimerIcon from "./icons/timer.svg?react";

export const LeadersTimer = () => {
  const { i18n } = useTranslation();

  const [i, setI] = useState(0);
  i;
  useEffect(() => {
    const tmr = setInterval(() => setI((i) => i + 1), 1000);
    return () => {
      clearInterval(tmr);
    };
  }, []);
  return (
    <div className="leaders-timer">
      <TimerIcon width={14} height={14} />
      {formatTimePeriodFromNow(
        moment("2024-12-31 23:59:59").toDate(),
        i18n.language
      )}
    </div>
  );
};
