export default {
  g: {
    flag: "🇪🇸",
    langName: "Spanish",
    langNameNative: "Español",
    continue: "Continuar",
    save: "Guardar",
    retry: "Intentar de nuevo",
    loading: "Cargando...",
    genderMany: {
      all: "Todos",
      male: "Hombres",
      female: "Mujeres",
    },
  },
  CreateAccount: {
    slogan: "¡Lugar donde los sueños se convierten en victorias!",
    gender: {
      title: "Hola {{name}}, Tú eres...",
      male: "Hombre",
      female: "Mujer",
    },
  },
  Settings: {
    title: "Configuraciones",
    language: "Idioma",
    auto: "Automático",
    country: "País",
    hideProfile: {
      label: "Ocultar perfil",
      description:
        "Tu perfil estará oculto para todos, como si lo hubieras eliminado.",
    },
    profileHidden: "Tu perfil está oculto y no es visible para otros usuarios.",
  },
  Tabs: {
    vote: "Votar",
    contest: "Concurso",
    friends: "Amigos",
    earn: "Ganar",
    wallet: "Cartera",
  },
  Vote: {
    whoIsBetter: "¿Quién te gusta más?",
    votingForLeader: "Votando por el líder",
    noUsers: {
      title: "🚀 Eso es todo por hoy",
      description: "Invita a amigos para continuar votando y ganando monedas",
      inviteFriends: "Invitar amigos",
    },
  },
  Leaders: {
    rewards: "Recompensas",
    comingSoon: "Próximamente",
    prizePool: "Fondo de premios",
    moreInfo: "Más información",
    contest: "Concurso",
    richest: "Más ricos",
    sponsors: "Patrocinadores",
    description: {
      likes:
        "Invita a tus amigos y comparte tu perfil para obtener más votos y aumentar tus posibilidades de ganar.",
      coins:
        "Completa tareas, vota por otros participantes e invita a tus amigos para ganar más monedas y fortalecer tu posición.",
      stars:
        "Vota por perfiles en la página de perfil para recibir estrellas y subir en la clasificación.",
    },
  },
  PrizePool: {
    text: `<b>Lugares premiados</b>:
<b>1er lugar</b>: 50% USD y 10,000 monedas
<b>2do lugar</b>: 30% USD y 3,000 monedas
<b>3er lugar</b>: 20% USD y 2,000 monedas
<b>4to-10mo lugar</b>: 1,000 monedas cada uno
<b>11mo-50mo lugar</b>: 500 monedas cada uno
<b>51mo-100mo lugar</b>: 250 monedas cada uno

<b>El fondo de premios</b> aumenta cuando alguien vota por ti en tu página de perfil.

<b>Condiciones</b>:
Todos los perfiles que suban una foto y completen las tareas son elegibles para participar en el concurso.

<b>Fecha de finalización</b>:
{{endDate}}

<b>Pago de recompensas</b>:
Puedes recibir tu recompensa en <b>TON</b> — la criptomoneda desarrollada por Telegram.`,
  },
  LeadersItem: {
    join: "Unirse",
  },
  Friends: {
    title: "¡Invita a tus amigos!",
    description: "Tú y tu amigo recibirán bonos",
    bonus: {
      profit: "<coins>+{{count}}</coins> para ti y tu amigo",
      invite: {
        title: "Invitar a un amigo",
      },
      inviteWithPremium: {
        title: "Invitar a un amigo",
        description: "con Telegram Premium",
      },
    },
    list: {
      title: "Lista de tus amigos",
    },
    inviteFriend: "Invitar a un amigo",
    inviteText: `¡Únete a mí, vota por los chicos y chicas más guapos y recibe premios valiosos!
  
  🌟 +{{coins}} monedas como primer regalo
  🔥 +{{coinsPremium}} monedas si tienes Telegram Premium
  
  🏆 Gana monedas por cada voto y elige a los participantes más atractivos. ¿Quieres más? ¡Participa tú mismo y compite por premios valiosos!
  
  ¡No pierdas tu oportunidad, únete a nuestra comunidad de estrellas y gana!`,
    linkCopied: "Enlace copiado",
  },
  Earn: {
    title: "Gana más monedas",
    tasksList: "Lista de tareas",
    check: "Verificar",
    inReview: "En revisión",
    subscribe: "Suscribirse",
    participate: "Participar",
    invite: "Invitar",
    verify: "Verificar",
    taskCompleted: "Tarea completada",
  },
  Wallet: {
    title: "Obtén recompensas en TON",
    description:
      "Completa desafíos para ganar TON, una criptomoneda desarrollada por Telegram",
    rewards: "Recompensas",
    withdrawal: "Retiro",
    walletConnected: "Tu billetera TON está conectada",
    walletDescription: "Pronto habrá más oportunidades disponibles",
  },
  VerificationRequired: {
    title: "Verificación Requerida",
    description:
      "Para participar en el concurso, necesitas verificar tu cuenta.",
    bdate: {
      title: "Tu fecha de nacimiento",
    },
  },
  Verification: {
    title: "Verificación",
    hint: "Mantén tu cara dentro del óvalo",
    secureHint: "Nadie verá esta foto",
    success: "Has completado la verificación con éxito",
    goToApp: "Ir a la aplicación",
    verification: "Verificando...",
    cameraAccessRequired:
      "Por favor, permite el acceso a la cámara para continuar",
    gettingAccessToCamer: "Obteniendo acceso a la cámara...",
    cameraAccessNotAllowed: "Acceso a la cámara denegado por el usuario.",
    cameraAccessError: "Error de acceso a la cámara",
    loadingHint: "Esto puede tomar un tiempo. Por favor, ten paciencia.",
  },
  Profile: {
    vote: "Votar",
    askVote: "Pedir Voto",
    inviteText: `¡Hola! 😊 Por favor, apóyame con tu voto en el concurso TON Stars! Cada voto es crucial y me ayuda a acercarme a ganar valiosos premios.

🌟 +{{coins}} monedas como primer regalo
🔥 +{{coinsPremium}} monedas si tienes Telegram Premium

Vota por mi perfil aquí: {{url}}

¡Gracias por tu apoyo! 🌟`,
    joinContest: "Unirse al concurso",
    gender: {
      male: "Masculino",
      female: "Femenino",
    },
    photos: {
      inReview:
        "Tus nuevas fotos están en revisión.\nEsto puede tardar un poco.",
      rejected:
        "Una o más fotos han sido rechazadas por la siguiente razón:\n{{reason}}.\n\nPor favor, sube fotos diferentes y vuelve a intentarlo.",
    },
  },
  ProfileEdit: {
    title: "Tu perfil",
    name: {
      label: "Tu nombre",
      placeholder: "Introduce tu nombre",
    },
    instagram: {
      description: "El perfil de Instagram debe ser público",
    },
    photos: {
      verificationPhotoUploaded:
        "Tu foto de verificación se ha subido, nadie la verá.",
      label: "Tus fotos",
      mainPhoto: "Foto principal",
      error: "Una o más fotos no son adecuadas, por favor, selecciona otras.",
      hint: "Elige tus fotos más impresionantes, donde no haya otras personas, y tu rostro sea claramente y expresivamente visible.",
      inReview:
        "Tus nuevas fotos están en revisión.\nEsto puede tardar un poco.",
      rejected:
        "Una o más fotos han sido rechazadas por la siguiente razón:\n{{reason}}.\n\nPor favor, sube fotos diferentes y vuelve a intentarlo.",
    },
    submit: "Unirse al concurso",
    photosUploading: "Subiendo foto...",
    success: "Tu perfil ha sido actualizado",
    errors: {
      name: "Indique su nombre",
      photos: "Por favor, seleccione 3 fotos",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto principal",
    noFace: "Tu cara no ha sido detectada",
    otherPeople: "La foto debe mostrar solo tu cara",
  },
};
