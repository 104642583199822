export default {
  g: {
    flag: "🇪🇪",
    langName: "Estonian",
    langNameNative: "Eesti",
    continue: "Jätka",
    save: "Salvesta",
    retry: "Proovi uuesti",
    loading: "Laadimine...",
    genderMany: {
      all: "Kõik",
      male: "Mehed",
      female: "Naised",
    },
  },
  CreateAccount: {
    slogan: "Koht, kus unistused muutuvad võitudeks!",
    gender: {
      title: "Tere {{name}}, Sina oled...",
      male: "Mees",
      female: "Naine",
    },
  },
  Settings: {
    title: "Seaded",
    language: "Keel",
    auto: "Automaatne",
    country: "Riik",
    hideProfile: {
      label: "Peida profiil",
      description:
        "Teie profiil peidetakse kõigi eest, justkui oleksite selle kustutanud.",
    },
    profileHidden:
      "Teie profiil on peidetud ja pole teistele kasutajatele nähtav.",
  },
  Tabs: {
    vote: "Hääleta",
    contest: "Võistlus",
    friends: "Sõbrad",
    earn: "Teeni",
    wallet: "Rahakott",
  },
  Vote: {
    whoIsBetter: "Keda sa rohkem armastad?",
    votingForLeader: "Hääletamine juhi eest",
    noUsers: {
      title: "🚀 See on tänaseks kõik",
      description: "Kutsu sõpru jätkama hääletamist ja teenima münte",
      inviteFriends: "Kutsu sõpru",
    },
  },
  Leaders: {
    rewards: "Preemiad",
    comingSoon: "Tulekul",
    prizePool: "Auhinnafond",
    moreInfo: "Rohkem infot",
    contest: "Konkurss",
    richest: "Rikkamad",
    sponsors: "Sponsorid",
    description: {
      likes:
        "Kutsu sõpru ja jaga oma profiili, et koguda rohkem hääli ja suurendada oma võiduvõimalusi.",
      coins:
        "Täida ülesandeid, hääleta teiste osalejate poolt ja kutsu sõpru, et teenida rohkem münte ja tugevdada oma positsiooni.",
      stars:
        "Hääleta profiilide poolt profiililehel, et saada tähti ja tõusta edetabelites.",
    },
  },
  PrizePool: {
    text: `<b>Auhinnalised kohad</b>:
<b>1. koht</b>: 50% USD ja 10,000 münti
<b>2. koht</b>: 30% USD ja 3,000 münti
<b>3. koht</b>: 20% USD ja 2,000 münti
<b>4.-10. kohad</b>: igaüks 1,000 münti
<b>11.-50. kohad</b>: igaüks 500 münti
<b>51.-100. kohad</b>: igaüks 250 münti

<b>Auhinnafond</b> suureneb, kui keegi hääletab teie profiili lehel teie poolt.

<b>Tingimused</b>:
Kõik profiilid, mis laadivad üles foto ja täidavad ülesanded, võivad konkursist osa võtta.

<b>Lõppkuupäev</b>:
{{endDate}}

<b>Preemiate väljamakse</b>:
Te saate oma preemia kätte <b>TON</b>-is — krüptoraha, mille on välja töötanud Telegram.`,
  },
  LeadersItem: {
    join: "Liitu",
  },
  Friends: {
    title: "Kutsu sõpru!",
    description: "Sina ja sinu sõber saavad boonuseid",
    bonus: {
      profit: "<coins>+{{count}}</coins> sulle ja sinu sõbrale",
      invite: {
        title: "Kutsu sõber",
      },
      inviteWithPremium: {
        title: "Kutsu sõber",
        description: "koos Telegram Premiumiga",
      },
    },
    list: {
      title: "Sinu sõprade nimekiri",
    },
    inviteFriend: "Kutsu sõber",
    inviteText: `Liitu minuga, osale kõige ilusamate poiste ja tüdrukute hääletamisel ning saa väärtuslikke auhindu!
  
  🌟 +{{coins}} münti esimese kingitusena
  🔥 +{{coinsPremium}} münti, kui sul on Telegram Premium
  
  🏆 Teeni mündid iga hääle eest ja vali kõige atraktiivsemad osalejad. Tahad rohkem? Osale ise ja võistle väärtuslike auhindade nimel!
  
  Ära jäta oma võimalust kasutamata, saa osa meie tähekogukonnast ja võida!`,
    linkCopied: "Link kopeeritud",
  },
  Earn: {
    title: "Teeni rohkem münte",
    tasksList: "Ülesannete loetelu",
    check: "Kontrolli",
    inReview: "Läbivaatamisel",
    subscribe: "Telli",
    participate: "Osale",
    invite: "Kutsu",
    verify: "Kinnita",
    taskCompleted: "Ülesanne täidetud",
  },
  Wallet: {
    title: "Hankige TON-is preemiaid",
    description:
      "Teenige TON, krüptoraha, mille on välja töötanud Telegram, täites ülesandeid",
    rewards: "Auhinnad",
    withdrawal: "Väljamakse",
    walletConnected: "Teie TON rahakott on ühendatud",
    walletDescription: "Varsti on saadaval rohkem võimalusi",
  },
  VerificationRequired: {
    title: "Kinnitus Nõutav",
    description: "Võistlusel osalemiseks peate kinnitama oma konto.",
    bdate: {
      title: "Sinu sünnikuupäev",
    },
  },
  Verification: {
    title: "Kinnitus",
    hint: "Hoia oma nägu ovaali sees",
    secureHint: "Keegi ei näe seda fotot",
    success: "Olete edukalt kinnitanud",
    goToApp: "Mine rakendusse",
    verification: "Kinnitamine...",
    cameraAccessRequired: "Palun võimaldage kaamera juurdepääsu jätkamiseks",
    gettingAccessToCamer: "Kaamera juurdepääsu saamine...",
    cameraAccessNotAllowed: "Kasutaja keelas kaamera juurdepääsu.",
    cameraAccessError: "Kaamera juurdepääsu viga",
    loadingHint: "See võib võtta natuke aega. Palun ole kannatlik.",
  },
  Profile: {
    vote: "Hääleta",
    askVote: "Küsi Häält",
    inviteText: `Tere! 😊 Palun toeta mind oma häälega TON Stars konkursil! Iga hääl on ülioluline ja aitab mind väärtuslike auhindade võitmisele lähemale.

🌟 +{{coins}} münti kui esimene kingitus
🔥 +{{coinsPremium}} münti, kui teil on Telegram Premium

Hääleta minu profiili eest siin: {{url}}

Tänan teid toetuse eest! 🌟`,
    joinContest: "Liitu konkursiga",
    gender: {
      male: "Mees",
      female: "Naine",
    },
    photos: {
      inReview: "Teie uued fotod on ülevaatamisel.\nSee võib veidi aega võtta.",
      rejected:
        "Üks või mitu fotot on tagasi lükatud järgmisel põhjusel:\n{{reason}}.\n\nPalun laadige üles teistsugused fotod ja proovige uuesti.",
    },
  },
  ProfileEdit: {
    title: "Teie profiil",
    name: {
      label: "Teie nimi",
      placeholder: "Sisestage oma nimi",
    },
    instagram: {
      description: "Instagrami profiil peab olema avalik",
    },
    photos: {
      verificationPhotoUploaded:
        "Teie kinnitamise foto on üles laaditud, keegi seda ei näe.",
      label: "Teie fotod",
      mainPhoto: "Peamine foto",
      error: "Üks või mitu fotot ei sobi, palun tehke teine ​​valik.",
      hint: "Valige oma kõige muljetavaldavamad fotod, kus pole teisi inimesi ja teie nägu on selgelt ja väljendusrikkalt nähtav.",
      inReview: "Teie uued fotod on ülevaatamisel.\nSee võib veidi aega võtta.",
      rejected:
        "Üks või mitu fotot on tagasi lükatud järgmisel põhjusel:\n{{reason}}.\n\nPalun laadige üles teistsugused fotod ja proovige uuesti.",
    },
    submit: "Liitu konkursiga",
    photosUploading: "Foto üleslaadimine...",
    success: "Teie profiil on uuendatud",
    errors: {
      name: "Palun sisestage oma nimi",
      photos: "Palun valige 3 fotot",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Peamine foto",
    noFace: "Teie nägu pole tuvastatud",
    otherPeople: "Foto peaks näitama ainult teie nägu",
  },
};
