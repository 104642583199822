export default {
  g: {
    flag: "🇧🇩",
    langName: "Bengali",
    langNameNative: "বাংলা",
    continue: "চালিয়ে যান",
    save: "সংরক্ষণ করুন",
    retry: "পুনরায় চেষ্টা করুন",
    loading: "লোড হচ্ছে...",
    genderMany: {
      all: "সব",
      male: "পুরুষ",
      female: "মহিলা",
    },
  },
  CreateAccount: {
    slogan: "যেখানে স্বপ্নগুলি জয়ে পরিণত হয়!",
    gender: {
      title: "হ্যালো {{name}}, আপনি...",
      male: "পুরুষ",
      female: "মহিলা",
    },
  },
  Settings: {
    title: "সেটিংস",
    language: "ভাষা",
    auto: "স্বয়ংক্রিয়",
    country: "দেশ",
    hideProfile: {
      label: "প্রোফাইল লুকান",
      description:
        "আপনার প্রোফাইল সবার কাছ থেকে লুকানো হবে, যেন আপনি এটি মুছে ফেলেছেন।",
    },
    profileHidden:
      "আপনার প্রোফাইল লুকানো আছে এবং অন্য ব্যবহারকারীদের জন্য দৃশ্যমান নয়।",
  },
  Tabs: {
    vote: "ভোট দিন",
    contest: "প্রতিযোগিতা",
    friends: "বন্ধুরা",
    earn: "উপার্জন করুন",
    wallet: "ওয়ালেট",
  },
  Vote: {
    whoIsBetter: "তুমি কাকে বেশি পছন্দ কর?",
    votingForLeader: "নেতার জন্য ভোটদান",
    noUsers: {
      title: "🚀 আজকের জন্য এতটুকুই",
      description:
        "ভোট দিতে এবং কয়েন অর্জন চালিয়ে যাওয়ার জন্য বন্ধুদের আমন্ত্রণ জানান",
      inviteFriends: "বন্ধুদের আমন্ত্রণ জানান",
    },
  },
  Leaders: {
    rewards: "পুরস্কার",
    comingSoon: "শীঘ্রই আসছে",
    prizePool: "পুরস্কারের পুল",
    moreInfo: "আরও তথ্য",
    contest: "প্রতিযোগিতা",
    richest: "সবচেয়ে ধনী",
    sponsors: "স্পনসর",
    description: {
      likes:
        "আরও ভোট পেতে এবং আপনার জয়ের সম্ভাবনা বাড়ানোর জন্য বন্ধুদের আমন্ত্রণ জানান এবং আপনার প্রোফাইল শেয়ার করুন।",
      coins:
        "কাজ সম্পূর্ণ করুন, অন্যান্য প্রতিযোগীদের জন্য ভোট দিন, এবং আরও কয়েন উপার্জন করতে এবং আপনার অবস্থান শক্তিশালী করতে বন্ধুদের আমন্ত্রণ জানান।",
      stars:
        "তারকা পেতে এবং র‌্যাঙ্কিংয়ে উঠতে প্রোফাইল পৃষ্ঠায় প্রোফাইলগুলির জন্য ভোট দিন।",
    },
  },
  PrizePool: {
    text: `<b>পুরস্কার বিজয়ী স্থান</b>:
<b>1ম স্থান</b>: 50% USD এবং 10,000 কয়েন
<b>2য় স্থান</b>: 30% USD এবং 3,000 কয়েন
<b>3য় স্থান</b>: 20% USD এবং 2,000 কয়েন
<b>4-10ম স্থান</b>: প্রতি 1,000 কয়েন
<b>11-50ম স্থান</b>: প্রতি 500 কয়েন
<b>51-100ম স্থান</b>: প্রতি 250 কয়েন

<b>পুরস্কারের পুল</b> বৃদ্ধি পায় যখন কেউ আপনার প্রোফাইল পৃষ্ঠায় আপনার জন্য ভোট দেয়।

<b>শর্তাবলী</b>:
যারা ফটো আপলোড করে এবং কাজগুলি সম্পূর্ণ করে তাদের সকল প্রোফাইল প্রতিযোগিতায় অংশগ্রহণের যোগ্য।

<b>শেষ তারিখ</b>:
{{endDate}}

<b>পুরস্কার প্রদান</b>:
আপনি আপনার পুরস্কার <b>TON</b> -এ পেতে পারেন — টেলিগ্রাম দ্বারা বিকাশিত ক্রিপ্টোকারেন্সি।`,
  },
  LeadersItem: {
    join: "যোগ দিন",
  },
  Friends: {
    title: "বন্ধুদের আমন্ত্রণ জানান!",
    description: "আপনি এবং আপনার বন্ধু বোনাস পাবেন",
    bonus: {
      profit: "<coins>+{{count}}</coins> আপনার এবং আপনার বন্ধুর জন্য",
      invite: {
        title: "বন্ধুকে আমন্ত্রণ জানান",
      },
      inviteWithPremium: {
        title: "বন্ধুকে আমন্ত্রণ জানান",
        description: "টেলিগ্রাম প্রিমিয়াম সহ",
      },
    },
    list: {
      title: "আপনার বন্ধুদের তালিকা",
    },
    inviteFriend: "বন্ধুকে আমন্ত্রণ জানান",
    inviteText: `আমার সাথে যোগ দিন, সবচেয়ে সুন্দর ছেলে এবং মেয়েদের জন্য ভোট দিন এবং মূল্যবান পুরস্কার পান!
  
  🌟 +{{coins}} কয়েন প্রথম উপহার হিসাবে
  🔥 +{{coinsPremium}} কয়েন যদি আপনার টেলিগ্রাম প্রিমিয়াম থাকে
  
  🏆 প্রতিটি ভোটের জন্য কয়েন উপার্জন করুন এবং সবচেয়ে আকর্ষণীয় অংশগ্রহণকারীদের বেছে নিন। আরও চান? নিজেই অংশগ্রহণ করুন এবং মূল্যবান পুরস্কারের জন্য প্রতিদ্বন্দ্বিতা করুন!
  
  আপনার সুযোগ মিস করবেন না, আমাদের তারকা সম্প্রদায়ের অংশ হন এবং জিতুন!`,
    linkCopied: "লিঙ্ক অনুলিপি করা হয়েছে",
  },
  Earn: {
    title: "আরও কয়েন উপার্জন করুন",
    tasksList: "কাজের তালিকা",
    check: "পরীক্ষা করুন",
    inReview: "পর্যালোচনায়",
    subscribe: "সাবস্ক্রাইব করুন",
    participate: "অংশগ্রহণ করুন",
    invite: "আমন্ত্রণ জানান",
    verify: "যাচাই করুন",
    taskCompleted: "কাজ সম্পন্ন হয়েছে",
  },
  Wallet: {
    title: "TON-এ পুরস্কার পান",
    description:
      "TON অর্জনের জন্য চ্যালেঞ্জ সম্পন্ন করুন, একটি ক্রিপ্টো কয়েন যা Telegram দ্বারা বিকশিত হয়েছে",
    rewards: "পুরস্কার",
    withdrawal: "উত্তোলন",
    walletConnected: "আপনার TON ওয়ালেট সংযুক্ত হয়েছে",
    walletDescription: "শীঘ্রই আরও সুযোগ উপলব্ধ হবে",
  },
  VerificationRequired: {
    title: "যাচাই প্রয়োজন",
    description:
      "প্রতিযোগিতায় অংশ নিতে, আপনাকে আপনার অ্যাকাউন্ট যাচাই করতে হবে।",
    bdate: {
      title: "আপনার জন্ম তারিখ",
    },
  },
  Verification: {
    title: "যাচাই",
    hint: "আপনার মুখটি ওভালের মধ্যে রাখুন",
    secureHint: "কেউ এই ছবিটি দেখবে না",
    success: "আপনি সফলভাবে যাচাই সম্পন্ন করেছেন",
    goToApp: "অ্যাপে যান",
    verification: "যাচাই হচ্ছে...",
    cameraAccessRequired: "অ্যাপ চালিয়ে যেতে ক্যামেরা অ্যাক্সেস দিন",
    gettingAccessToCamer: "ক্যামেরা অ্যাক্সেস পাওয়া হচ্ছে...",
    cameraAccessNotAllowed:
      "ব্যবহারকারী দ্বারা ক্যামেরা অ্যাক্সেস অস্বীকৃত হয়েছে।",
    cameraAccessError: "ক্যামেরা অ্যাক্সেস ত্রুটি",
    loadingHint: "এটি কিছুটা সময় নিতে পারে। অনুগ্রহ করে ধৈর্য ধরুন।",
  },
  Profile: {
    vote: "ভোট দিন",
    askVote: "ভোট চাই",
    inviteText: `হাই! 😊 TON Stars প্রতিযোগিতায় আপনার ভোট দিয়ে আমাকে সমর্থন করুন! প্রতিটি ভোট গুরুত্বপূর্ণ এবং আমাকে মূল্যবান পুরস্কার জেতার কাছাকাছি পৌঁছাতে সহায়তা করে।

🌟 প্রথম উপহার হিসাবে +{{coins}} কয়েন
🔥 যদি আপনার কাছে Telegram Premium থাকে তবে +{{coinsPremium}} কয়েন

আমার প্রোফাইলে ভোট দিন এখানে: {{url}}

আপনার সমর্থনের জন্য ধন্যবাদ! 🌟`,
    joinContest: "প্রতিযোগিতায় যোগদান করুন",
    gender: {
      male: "পুরুষ",
      female: "মহিলা",
    },
    photos: {
      inReview:
        "আপনার নতুন ছবিগুলি পর্যালোচনা করা হচ্ছে।\nএটি কিছু সময় নিতে পারে।",
      rejected:
        "এক বা একাধিক ছবি নিম্নলিখিত কারণে প্রত্যাখ্যান করা হয়েছে:\n{{reason}}।\n\nঅনুগ্রহ করে ভিন্ন ছবি আপলোড করুন এবং আবার চেষ্টা করুন।",
    },
  },
  ProfileEdit: {
    title: "আপনার প্রোফাইল",
    name: {
      label: "আপনার নাম",
      placeholder: "আপনার নাম লিখুন",
    },
    instagram: {
      description: "ইনস্টাগ্রাম প্রোফাইলটি পাবলিক হতে হবে",
    },
    photos: {
      verificationPhotoUploaded:
        "আপনার যাচাইকরণ ছবি আপলোড করা হয়েছে, কেউ এটি দেখবে না।",
      label: "আপনার ছবি",
      mainPhoto: "প্রধান ছবি",
      error: "এক বা একাধিক ছবি উপযুক্ত নয়, অনুগ্রহ করে অন্যটি নির্বাচন করুন।",
      hint: "আপনার সবচেয়ে প্রভাবশালী ছবি বাছাই করুন, যেখানে অন্য কেউ নেই এবং আপনার মুখটি পরিষ্কার ও স্পষ্টভাবে দৃশ্যমান।",
      inReview:
        "আপনার নতুন ছবিগুলি পর্যালোচনা করা হচ্ছে।\nএটি কিছু সময় নিতে পারে।",
      rejected:
        "এক বা একাধিক ছবি নিম্নলিখিত কারণে প্রত্যাখ্যান করা হয়েছে:\n{{reason}}।\n\nঅনুগ্রহ করে ভিন্ন ছবি আপলোড করুন এবং আবার চেষ্টা করুন।",
    },
    submit: "প্রতিযোগিতায় যোগদান করুন",
    photosUploading: "ছবি আপলোড করা হচ্ছে...",
    success: "আপনার প্রোফাইল আপডেট করা হয়েছে",
    errors: {
      name: "আপনার নাম লিখুন",
      photos: "অনুগ্রহ করে ৩টি ছবি নির্বাচন করুন",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "প্রধান ছবি",
    noFace: "আপনার মুখ সনাক্ত করা যায়নি",
    otherPeople: "ছবিতে শুধুমাত্র আপনার মুখ থাকা উচিত",
  },
};
