export default {
  g: {
    flag: "🇪🇺",
    langName: "Basque",
    langNameNative: "Euskara",
    continue: "Jarraitu",
    save: "Gorde",
    retry: "Berriro saiatu",
    loading: "Kargatzen...",
    genderMany: {
      all: "Guztiak",
      male: "Gizonezkoak",
      female: "Emakumeak",
    },
  },
  CreateAccount: {
    slogan: "Ametsak irabazietara bihurtzen diren lekua!",
    gender: {
      title: "Kaixo {{name}}, Zu zara...",
      male: "Gizonezkoa",
      female: "Emakumezkoa",
    },
  },
  Settings: {
    title: "Ezarpenak",
    language: "Hizkuntza",
    auto: "Automatikoa",
    country: "Herrialdea",
    hideProfile: {
      label: "Ezkutatu profila",
      description:
        "Zure profila guztien aurrean ezkutatuko da, ezabatu bazenu bezala.",
    },
    profileHidden:
      "Zure profila ezkutatuta dago eta ez da beste erabiltzaileentzat ikusgai.",
  },
  Tabs: {
    vote: "Bozkatu",
    contest: "Lehiaketa",
    friends: "Lagunak",
    earn: "Irabazi",
    wallet: "Zorroa",
  },
  Vote: {
    whoIsBetter: "Zeinek duzu gehiago gustuko?",
    votingForLeader: "Liderra bozkatu",
    noUsers: {
      title: "🚀 Hori da gaurkoz guztia",
      description:
        "Gonbidatu lagunak bozketa jarraitzeko eta txanponak irabazteko",
      inviteFriends: "Gonbidatu Lagunak",
    },
  },
  Leaders: {
    rewards: "Saririk",
    comingSoon: "Laster egongo da",
    prizePool: "Sari poltsa",
    moreInfo: "Informazio gehiago",
    contest: "Lehiaketa",
    richest: "Aberatsenak",
    sponsors: "Babesleak",
    description: {
      likes:
        "Lagunak gonbidatu eta zure profila partekatu, boto gehiago biltzeko eta irabazteko aukerak handitzeko.",
      coins:
        "Egin zereginak, bozkatu beste parte-hartzaileentzat eta gonbidatu lagunak txanpon gehiago irabazteko eta zure posizioa indartzeko.",
      stars:
        "Bozkatu profilen alde profil orrian izarrak jasotzeko eta sailkapenean igotzeko.",
    },
  },
  PrizePool: {
    text: `<b>Sariak irabazteko lekuak</b>:
<b>1. lekua</b>: %50 USD eta 10,000 txanpon
<b>2. lekua</b>: %30 USD eta 3,000 txanpon
<b>3. lekua</b>: %20 USD eta 2,000 txanpon
<b>4.-10. lekuak</b>: bakoitzak 1,000 txanpon
<b>11.-50. lekuak</b>: bakoitzak 500 txanpon
<b>51.-100. lekuak</b>: bakoitzak 250 txanpon

<b>Sari poltsa</b> handitzen da zure profilaren alde bozkatu egiten dutenean.

<b>Baldintzak</b>:
Argazkia igotzen duten eta zereginak betetzen dituzten profil guztiek parte hartzeko eskubidea dute lehiaketan.

<b>Bukaera data</b>:
{{endDate}}

<b>Sarien ordainketa</b>:
Zure saria <b>TON</b> kriptotxanponetan jaso dezakezu — Telegramek garatutako kriptotxanpona.`,
  },
  LeadersItem: {
    join: "Parte hartu",
  },
  Friends: {
    title: "Gonbidatu lagunak!",
    description: "Zu eta zure lagunak bonusak jasoko dituzue",
    bonus: {
      profit: "<coins>+{{count}}</coins> zuretzat eta zure lagunarentzat",
      invite: {
        title: "Gonbidatu laguna",
      },
      inviteWithPremium: {
        title: "Gonbidatu laguna",
        description: "Telegram Premium-ekin",
      },
    },
    list: {
      title: "Zure lagunen zerrenda",
    },
    inviteFriend: "Gonbidatu laguna",
    inviteText: `Batu nirekin, parte hartu mutil eta neska ederrenei botoa ematen eta sari baliotsuak irabazi!
  
  🌟 +{{coins}} txanpon lehen opari gisa
  🔥 +{{coinsPremium}} txanpon Telegram Premium baduzu
  
  🏆 Irabazi txanponak boto bakoitzeko eta hautatu parte-hartzaile erakargarrienak. Gehiago nahi duzu? Parte hartu zeure burua eta lehiatu sari baliotsuak lortzeko!
  
  Ez galdu zure aukera, batu gure izar-komunitatera eta irabazi!`,
    linkCopied: "Esteka kopiatu da",
  },
  Earn: {
    title: "Irabazi txanpon gehiago",
    tasksList: "Zeregin zerrenda",
    check: "Egiaztatu",
    inReview: "Berrikusten",
    subscribe: "Harpidetu",
    participate: "Parte hartu",
    invite: "Gonbidatu",
    verify: "Egiaztatu",
    taskCompleted: "Zeregina osatu da",
  },
  Wallet: {
    title: "Lortu sariak TONen",
    description:
      "Telegram-ek garatutako kriptotxanpon bat den TON irabazteko erronkak bete",
    rewards: "Sariak",
    withdrawal: "Erretiratzea",
    walletConnected: "Zure TON zorroa konektatuta dago",
    walletDescription: "Laster aukera gehiago izango dira eskuragarri",
  },
  VerificationRequired: {
    title: "Egiaztapena Beharrezkoa da",
    description: "Lehiaketan parte hartzeko, zure kontua egiaztatu behar duzu.",
    bdate: {
      title: "Zure jaiotza data",
    },
  },
  Verification: {
    title: "Egiaztapena",
    hint: "Mantendu aurpegia obaloaren barruan",
    secureHint: "Inork ez du argazki hau ikusiko",
    success: "Egiaztapena arrakastaz osatu duzu",
    goToApp: "Joan aplikaziora",
    verification: "Egiaztatzen...",
    cameraAccessRequired: "Mesedez, eman kamera sarbidea jarraitzeko",
    gettingAccessToCamer: "Kamera sarbidea lortzen...",
    cameraAccessNotAllowed: "Erabiltzaileak kamera sarbidea ukatu du.",
    cameraAccessError: "Kamera sarbide errorea",
    loadingHint:
      "Honek denbora pixka bat har dezake. Mesedez, izan pazientzia.",
  },
  Profile: {
    vote: "Bozkatu",
    askVote: "Eskatu bozka",
    inviteText: `Kaixo! 😊 Mesedez, eman zure botoa TON Stars lehiaketan! Boto bakoitza erabakigarria da eta sari baliotsuak irabazteko gertuago laguntzen dit.

🌟 +{{coins}} txanpon lehen opari gisa
🔥 +{{coinsPremium}} txanpon Telegram Premium baduzu

Bozkatu nire profilean hemen: {{url}}

Eskerrik asko zure laguntzagatik! 🌟`,
    joinContest: "Lehiaketara batu",
    gender: {
      male: "Gizonezkoa",
      female: "Emakumezkoa",
    },
    photos: {
      inReview:
        "Zure argazki berriak berrikusten ari dira.\nHonek denbora pixka bat har dezake.",
      rejected:
        "Argazki bat edo gehiago arrazoi hauengatik baztertu dira:\n{{reason}}.\n\nMesedez, igo argazki desberdinak eta saiatu berriro.",
    },
  },
  ProfileEdit: {
    title: "Zure Profila",
    name: {
      label: "Zure izena",
      placeholder: "Sartu zure izena",
    },
    instagram: {
      description: "Instagram profila publiko izan behar da",
    },
    photos: {
      verificationPhotoUploaded:
        "Zure egiaztapen argazkia igo da, inork ez du ikusiko.",
      label: "Zure Argazkiak",
      mainPhoto: "Argazki Nagusia",
      error:
        "Argazki bat edo gehiago ez dira egokiak, mesedez, egin beste aukeraketa bat.",
      hint: "Aukeratu zure argazki ikusgarrienak, non ez dauden beste pertsonarik, eta zure aurpegia argi eta garbi ikus daiteke.",
      inReview:
        "Zure argazki berriak berrikusten ari dira.\nHonek denbora pixka bat har dezake.",
      rejected:
        "Argazki bat edo gehiago arrazoi hauengatik baztertu dira:\n{{reason}}.\n\nMesedez, igo argazki desberdinak eta saiatu berriro.",
    },
    submit: "Lehiaketara Batu",
    photosUploading: "Argazkia igotzen...",
    success: "Zure profila eguneratu da",
    errors: {
      name: "Adierazi zure izena",
      photos: "Hautatu 3 argazki, mesedez",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Argazki nagusia",
    noFace: "Zure aurpegia ez da detektatu",
    otherPeople: "Argazkiak zure aurpegia bakarrik erakutsi behar du",
  },
};
