export default {
  g: {
    flag: "🇦🇿",
    langName: "Azerbaijani",
    langNameNative: "Azərbaycan",
    continue: "Davam et",
    save: "Yadda saxla",
    retry: "Təkrar cəhd et",
    loading: "Yüklənir...",
    genderMany: {
      all: "Hamısı",
      male: "Kişilər",
      female: "Qadınlar",
    },
  },
  CreateAccount: {
    slogan: "Arzuların Qələbəyə Çevrildiyi Yer!",
    gender: {
      title: "Salam {{name}}, Sən...",
      male: "Kişi",
      female: "Qadın",
    },
  },
  Settings: {
    title: "Tənzimləmələr",
    language: "Dil",
    auto: "Avtomatik",
    country: "Ölkə",
    hideProfile: {
      label: "Profilini gizlət",
      description: "Profiliniz hamıdan gizlədiləcək, sanki onu silmisiniz.",
    },
    profileHidden:
      "Profiliniz gizlidir və digər istifadəçilər üçün görünməzdir.",
  },
  Tabs: {
    vote: "Səs ver",
    contest: "Müsabiqə",
    friends: "Dostlar",
    earn: "Qazan",
    wallet: "Cüzdan",
  },
  Vote: {
    whoIsBetter: "Kimi daha çox bəyənirsən?",
    votingForLeader: "Lider üçün səs verin",
    noUsers: {
      title: "🚀 Bu gün üçün bu qədər",
      description:
        "Səs verməyə və pul qazanmağa davam etmək üçün dostları dəvət edin",
      inviteFriends: "Dostları dəvət edin",
    },
  },
  Leaders: {
    rewards: "Mükafatlar",
    comingSoon: "Tezliklə",
    prizePool: "Mükafat Fondu",
    moreInfo: "Daha çox məlumat",
    contest: "Müsabiqə",
    richest: "Ən Zəngin",
    sponsors: "Sponsorlar",
    description: {
      likes:
        "Daha çox səs toplamaq və qələbə şansınızı artırmaq üçün dostlarınızı dəvət edin və profilinizi paylaşın.",
      coins:
        "Vəzifələri yerinə yetirin, digər iştirakçılara səs verin və dostlarınızı dəvət edin ki, daha çox pul qazanasınız və mövqeyinizi gücləndirəsiniz.",
      stars:
        "Ulduzlar əldə etmək və reytinqlərdə irəliləmək üçün profil səhifəsində profillərə səs verin.",
    },
  },
  PrizePool: {
    text: `<b>Mükafat qazanan yerlər</b>:
<b>1-ci yer</b>: 50% USD və 10,000 sikkə
<b>2-ci yer</b>: 30% USD və 3,000 sikkə
<b>3-cü yer</b>: 20% USD və 2,000 sikkə
<b>4-10-cu yerlər</b>: hər biri 1,000 sikkə
<b>11-50-ci yerlər</b>: hər biri 500 sikkə
<b>51-100-cü yerlər</b>: hər biri 250 sikkə

<b>Mükafat fondu</b> sizin profil səhifənizdə sizə səs verən zaman artır.

<b>Şərtlər</b>:
Foto yükləyən və tapşırıqları yerinə yetirən bütün profillər müsabiqədə iştirak etmək hüququna malikdir.

<b>Bitmə tarixi</b>:
{{endDate}}

<b>Mükafatların ödənişi</b>:
Mükafatınızı <b>TON</b> kriptovalyutası ilə ala bilərsiniz — Telegram tərəfindən hazırlanmışdır.`,
  },
  LeadersItem: {
    join: "Qoşul",
  },
  Friends: {
    title: "Dostları dəvət edin!",
    description: "Siz və dostunuz bonuslar alacaqsınız",
    bonus: {
      profit: "<coins>+{{count}}</coins> sizin və dostunuz üçün",
      invite: {
        title: "Dostu dəvət edin",
      },
      inviteWithPremium: {
        title: "Dostu dəvət edin",
        description: "Telegram Premium ilə",
      },
    },
    list: {
      title: "Dostlarınızın siyahısı",
    },
    inviteFriend: "Dostu dəvət edin",
    inviteText: `Mənə qoşul, ən gözəl oğlanlar və qızlar üçün səs ver və dəyərli mükafatlar qazan!

🌟 +{{coins}} sikkə ilk hədiyyə olaraq
🔥 +{{coinsPremium}} sikkə əgər Telegram Premiumunuz varsa

🏆 Hər səs üçün sikkələr qazanın və ən cəlbedici iştirakçıları seçin. Daha çox istəyirsiniz? Özünüz iştirak edin və dəyərli mükafatlar üçün yarışın!

Şansınızı qaçırmayın, ulduzlar cəmiyyətimizin bir hissəsi olun və qazanın!`,
    linkCopied: "Keçid kopyalandı",
  },
  Earn: {
    title: "Daha çox sikkə qazanın",
    tasksList: "Tapşırıqlar siyahısı",
    check: "Yoxla",
    inReview: "Nəzərdən keçirilir",
    subscribe: "Abunə ol",
    participate: "İştirak et",
    invite: "Dəvət et",
    verify: "Təsdiqlə",
    taskCompleted: "Tapşırıq tamamlandı",
  },
  Wallet: {
    title: "TON-da mükafatlar qazanın",
    description:
      "TON, Telegram tərəfindən hazırlanmış kriptovalyuta qazanmaq üçün çağırışları yerinə yetirin",
    rewards: "Mükafatlar",
    withdrawal: "Çıxarış",
    walletConnected: "Sizin TON cüzdanınız qoşulub",
    walletDescription: "Tezliklə daha çox imkanlar əlçatan olacaq",
  },
  VerificationRequired: {
    title: "Təsdiq tələb olunur",
    description: "Müsabiqədə iştirak etmək üçün hesabınızı təsdiqləməlisiniz.",
    bdate: {
      title: "Doğum tarixi",
    },
  },
  Verification: {
    title: "Təsdiq",
    hint: "Üzünüzü ovalın içində saxlayın",
    secureHint: "Heç kim bu şəkli görməyəcək",
    success: "Təsdiqi uğurla tamamladınız",
    goToApp: "Tətbiqə keç",
    verification: "Təsdiq edilir...",
    cameraAccessRequired: "Davam etmək üçün kamera girişinə icazə verin",
    gettingAccessToCamer: "Kameraya giriş əldə edilir...",
    cameraAccessNotAllowed: "Kamera girişi istifadəçi tərəfindən rədd edildi.",
    cameraAccessError: "Kamera giriş xətası",
    loadingHint: "Bu bir az vaxt apara bilər. Xahiş olunur səbrli olun.",
  },
  Profile: {
    vote: "Səs verin",
    askVote: "Səs İstəyin",
    inviteText: `Salam! 😊 TON Stars müsabiqəsində səsinizlə mənə dəstək olun! Hər bir səs vacibdir və mənə dəyərli mükafatlar qazanmaqda kömək edir.

🌟 İlk hədiyyə olaraq +{{coins}} sikkə
🔥 Telegram Premiumunuz varsa +{{coinsPremium}} sikkə

Profilimə buradan səs verin: {{url}}

Dəstəyinizə görə təşəkkür edirik! 🌟`,
    joinContest: "Müsabiqəyə qoşul",
    gender: {
      male: "Kişi",
      female: "Qadın",
    },
    photos: {
      inReview:
        "Yeni fotolarınız nəzərdən keçirilir.\nBu bir az vaxt apara bilər.",
      rejected:
        "Bir və ya bir neçə fotoqrafiya bu səbəbdən rədd edildi:\n{{reason}}.\n\nZəhmət olmasa, fərqli fotolar yükləyin və yenidən cəhd edin.",
    },
  },
  ProfileEdit: {
    title: "Profiliniz",
    name: {
      label: "Adınız",
      placeholder: "Adınızı daxil edin",
    },
    instagram: {
      description: "Instagram profili açıq olmalıdır",
    },
    photos: {
      verificationPhotoUploaded:
        "Təsdiq fotoşəkliniz yükləndi, heç kim onu görməyəcək.",
      label: "Fotolarınız",
      mainPhoto: "Əsas foto",
      error:
        "Bir və ya bir neçə fotoqrafiya uyğun deyil, zəhmət olmasa başqa seçim edin.",
      hint: "Digər insanların olmadığı və üzünüzün aydın və ifadəli göründüyü ən təsirli fotolarınızı seçin.",
      inReview:
        "Yeni fotolarınız nəzərdən keçirilir.\nBu bir az vaxt apara bilər.",
      rejected:
        "Bir və ya bir neçə fotoqrafiya bu səbəbdən rədd edildi:\n{{reason}}.\n\nZəhmət olmasa, fərqli fotolar yükləyin və yenidən cəhd edin.",
    },
    submit: "Müsabiqəyə qoşul",
    photosUploading: "Foto yüklənir...",
    success: "Profiliniz yeniləndi",
    errors: {
      name: "Adınızı daxil edin",
      photos: "Zəhmət olmasa, 3 fotoşəkil seçin",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Əsas foto",
    noFace: "Üzünüz aşkar edilmədi",
    otherPeople: "Foto yalnız üzünüzü göstərməlidir",
  },
};
