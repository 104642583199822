export default {
  g: {
    flag: "🇵🇹",
    langName: "Portuguese (Portugal)",
    langNameNative: "Português (Portugal)",
    continue: "Continuar",
    save: "Guardar",
    retry: "Tentar novamente",
    loading: "A carregar...",
    genderMany: {
      all: "Todos",
      male: "Homens",
      female: "Mulheres",
    },
  },
  CreateAccount: {
    slogan: "O lugar onde os sonhos se tornam vitórias!",
    gender: {
      title: "Olá {{name}}, Você é...",
      male: "Masculino",
      female: "Feminino",
    },
  },
  Settings: {
    title: "Configurações",
    language: "Idioma",
    auto: "Automático",
    country: "País",
    hideProfile: {
      label: "Ocultar Perfil",
      description:
        "O seu perfil ficará oculto para todos, como se o tivesse eliminado.",
    },
    profileHidden:
      "O seu perfil está oculto e não visível para outros usuários.",
  },
  Tabs: {
    vote: "Votar",
    contest: "Concurso",
    friends: "Amigos",
    earn: "Ganhar",
    wallet: "Carteira",
  },
  Vote: {
    whoIsBetter: "Quem gostas mais?",
    votingForLeader: "Votando no líder",
    noUsers: {
      title: "🚀 Isso é tudo por hoje",
      description: "Convide amigos para continuar votando e ganhando moedas",
      inviteFriends: "Convidar Amigos",
    },
  },
  Leaders: {
    rewards: "Recompensas",
    comingSoon: "Em breve",
    prizePool: "Prémio",
    moreInfo: "Mais informações",
    contest: "Concurso",
    richest: "Mais ricos",
    sponsors: "Patrocinadores",
    description: {
      likes:
        "Convide amigos e partilhe o seu perfil para obter mais votos e aumentar as suas hipóteses de ganhar.",
      coins:
        "Complete tarefas, vote noutros participantes e convide amigos para ganhar mais moedas e fortalecer a sua posição.",
      stars:
        "Vote em perfis na página do perfil para receber estrelas e subir nas classificações.",
    },
  },
  PrizePool: {
    text: `<b>Lugares premiados</b>:
<b>1º lugar</b>: 50% USD e 10.000 moedas
<b>2º lugar</b>: 30% USD e 3.000 moedas
<b>3º lugar</b>: 20% USD e 2.000 moedas
<b>4º-10º lugares</b>: 1.000 moedas cada
<b>11º-50º lugares</b>: 500 moedas cada
<b>51º-100º lugares</b>: 250 moedas cada

<b>O prémio</b> aumenta quando alguém vota em si na sua página de perfil.

<b>Condições</b>:
Todos os perfis que fizerem upload de uma foto e completarem as tarefas estão aptos a participar no concurso.

<b>Data de término</b>:
{{endDate}}

<b>Pagamento da recompensa</b>:
Pode receber a sua recompensa em <b>TON</b> — a criptomoeda desenvolvida pelo Telegram.`,
  },
  LeadersItem: {
    join: "Entrar",
  },
  Friends: {
    title: "Convide amigos!",
    description: "Você e o seu amigo vão receber bónus",
    bonus: {
      profit: "<coins>+{{count}}</coins> para você e o seu amigo",
      invite: {
        title: "Convide um amigo",
      },
      inviteWithPremium: {
        title: "Convide um amigo",
        description: "com Telegram Premium",
      },
    },
    list: {
      title: "Lista dos seus amigos",
    },
    inviteFriend: "Convide um amigo",
    inviteText: `Junte-se a mim, participe nas votações para os rapazes e raparigas mais bonitos e ganhe prémios valiosos!
  
  🌟 +{{coins}} moedas como primeiro presente
  🔥 +{{coinsPremium}} moedas se tiver Telegram Premium
  
  🏆 Ganhe moedas por cada voto e escolha os participantes mais atraentes. Quer mais? Participe você mesmo e concorra a prémios valiosos!
  
  Não perca a sua oportunidade, torne-se parte da nossa comunidade de estrelas e ganhe!`,
    linkCopied: "Link copiado",
  },
  Earn: {
    title: "Ganhe mais moedas",
    tasksList: "Lista de tarefas",
    check: "Verificar",
    inReview: "Em revisão",
    subscribe: "Subscrever",
    participate: "Participar",
    invite: "Convidar",
    verify: "Verificar",
    taskCompleted: "Tarefa concluída",
  },
  Wallet: {
    title: "Receba recompensas em TON",
    description:
      "Complete desafios para ganhar TON, uma criptomoeda desenvolvida pelo Telegram",
    rewards: "Recompensas",
    withdrawal: "Retirada",
    walletConnected: "Sua carteira TON está conectada",
    walletDescription: "Mais oportunidades estarão disponíveis em breve",
  },
  VerificationRequired: {
    title: "Verificação Necessária",
    description:
      "Para participar no concurso, precisa de verificar a sua conta.",
    bdate: {
      title: "A sua data de nascimento",
    },
  },
  Verification: {
    title: "Verificação",
    hint: "Mantenha o seu rosto dentro do oval",
    secureHint: "Ninguém verá esta foto",
    success: "Completou a verificação com sucesso",
    goToApp: "Ir para a aplicação",
    verification: "A verificar...",
    cameraAccessRequired: "Por favor, permita o acesso à câmara para continuar",
    gettingAccessToCamer: "A obter acesso à câmara...",
    cameraAccessNotAllowed: "Acesso à câmara negado pelo utilizador.",
    cameraAccessError: "Erro de acesso à câmara",
    loadingHint: "Isto pode demorar algum tempo. Por favor, seja paciente.",
  },
  Profile: {
    vote: "Votar",
    askVote: "Pedir para Votar",
    inviteText: `Oi! 😊 Por favor, apoie-me com seu voto no concurso TON Stars! Cada voto é crucial e me ajuda a ficar mais perto de ganhar prêmios valiosos.

🌟 +{{coins}} moedas como primeiro presente
🔥 +{{coinsPremium}} moedas se você tiver o Telegram Premium

Vote no meu perfil aqui: {{url}}

Obrigado pelo seu apoio! 🌟`,
    joinContest: "Participar no concurso",
    gender: {
      male: "Masculino",
      female: "Feminino",
    },
    photos: {
      inReview:
        "As suas novas fotos estão em revisão.\nIsto pode demorar algum tempo.",
      rejected:
        "Uma ou mais fotos foram rejeitadas pelo seguinte motivo:\n{{reason}}.\n\nPor favor, faça o upload de fotos diferentes e tente novamente.",
    },
  },
  ProfileEdit: {
    title: "O Seu Perfil",
    name: {
      label: "O Seu Nome",
      placeholder: "Digite o seu nome",
    },
    instagram: {
      description: "O perfil do Instagram deve ser público",
    },
    photos: {
      verificationPhotoUploaded:
        "A sua foto de verificação foi carregada, ninguém a verá.",
      label: "As Suas Fotos",
      mainPhoto: "Foto Principal",
      error:
        "Uma ou mais fotos não são adequadas, por favor, faça outra seleção.",
      hint: "Escolha as suas fotos mais impressionantes, onde não há outras pessoas e o seu rosto é claramente visível.",
      inReview:
        "As suas novas fotos estão em revisão.\nIsto pode demorar algum tempo.",
      rejected:
        "Uma ou mais fotos foram rejeitadas pelo seguinte motivo:\n{{reason}}.\n\nPor favor, faça o upload de fotos diferentes e tente novamente.",
    },
    submit: "Participar no Concurso",
    photosUploading: "Carregando foto...",
    success: "O seu perfil foi atualizado",
    errors: {
      name: "Indique o seu nome",
      photos: "Por favor, selecione 3 fotos",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto Principal",
    noFace: "O seu rosto não foi detetado",
    otherPeople: "A foto deve mostrar apenas o seu rosto",
  },
};
