import { Button, Headline, Placeholder } from "@telegram-apps/telegram-ui";
import "./Vote.css";
import InfoIcon from "./icons/info.svg?react";
import VerifiedIcon from "../../assets/verified.svg?react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { Header } from "components/Header/Header";

import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "components/Modal/Modal";
import { Profile, ProfileProps } from "components/Profile/Profile";
import countries from "data/countries.json";
import HeartIcon from "assets/heart.svg?react";
import { useMutation, useQuery } from "@apollo/client";
import { cache } from "utils/appolo";
import { UserGender, Vote_QueryDocument } from "__generated__/graphql";
import { preloadImages } from "utils/image";
import { dispatch } from "store";
import { setTabId } from "store/settings";
import toast from "react-hot-toast";
import { Select } from "components/Select/Select";
import { ProfileVoteProps } from "components/ProfileVote/ProfileVote";
import { LoadingPlaceholder } from "components/LoadingPlaceholder/LoadingPlaceholder";

const genders = [UserGender.Female, UserGender.Male] as const;

const Vote_Query = gql(`
  query Vote_Query($gender: UserGender) {
    usersCompare(gender: $gender) {
      ...VoteUser_QueryFragment
    }
    user {
      id
      voteGender
    }
  }
`);

const Vote_QueryFragment = gql(`
  fragment Vote_QueryFragment on Query {
    ...Header_QueryFragment
    user {
      id
      voteGender
    }
  }
`);

const VoteUser_QueryFragment = gql(`
  fragment VoteUser_QueryFragment on User {
    ...ProfileUser_QueryFragment
    id
    name
    age
    verified
    countryCode
    uploads {
      id
      previewUrl: url(width: 477, height: 848)
    }
  }
`);

const Vote_Mutation = gql(`
  mutation Vote_Mutation(
    $userIds: [BigInt!]!
  ) {
    vote(userIds: $userIds) {
      user {
        id
        coins
      }
      users {
        ...VoteUser_QueryFragment
        voteGender
      }
    }
  }
`);

type VoteProps = {
  query: FragmentType<typeof Vote_QueryFragment>;
  voteConfig: ProfileVoteProps["voteConfig"];
  bonus: ProfileProps["bonus"];
};

export const Vote = (props: VoteProps) => {
  const query = getFragmentData(Vote_QueryFragment, props.query);
  const [vote] = useMutation(Vote_Mutation);

  const [gender, setGender] = useState<(typeof genders)[number]>(
    query.user?.voteGender || genders[0]
  );

  const { data, fetchMore, ...rest } = useQuery(Vote_Query);
  const usersCompare = getFragmentData(
    VoteUser_QueryFragment,
    data?.usersCompare
  );

  const { t } = useTranslation("Vote");
  const { t: g } = useTranslation();

  const [profileId, setProfileId] = useState<bigint>();
  const [likedUserId, setLikedUserId] = useState<bigint>();
  const [users, setUsers] = useState(usersCompare);
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    if (users === undefined && usersCompare) {
      setUsers(usersCompare);
    }
  }, [users, usersCompare]);

  const likedIndex = useMemo(() => {
    const index = users?.findIndex((_) => _.id === likedUserId);

    return index;
  }, [likedUserId, users]);

  useEffect(() => {
    if (!likedUserId) return;

    const index = users?.findIndex((_) => _.id === likedUserId);
    const secondUserId = users?.find((_) => _.id !== likedUserId)?.id;

    const userIds = [likedUserId, secondUserId];

    setLocked(true);
    vote({
      variables: { userIds },
    })
      .then(({ data }) => {
        if (!data?.vote) {
          setProfileId(undefined);
          setLikedUserId(undefined);
          setLocked(false);
          return;
        }

        cache.updateQuery({ query: Vote_QueryDocument }, (d) => {
          return { ...d, usersCompare: data.vote.users };
        });

        const newUsers = getFragmentData(
          VoteUser_QueryFragment,
          data?.vote.users || []
        );

        preloadImages(
          newUsers.map((_) => _.uploads[0].previewUrl) as string[]
        ).finally(() => {
          const ref = index ? item2Ref : item1Ref;
          if (ref.current) {
            ref.current.className += " vote-item-container-remove";
            ref.current.onanimationend = () => {
              setLikedUserId(undefined);
              setUsers(() => {
                return newUsers;
              });
              setProfileId(undefined);
            };
          }
          setTimeout(() => {
            setLocked(false);
          }, 300);
        });
      })
      .catch(() => {
        setLocked(false);
        setProfileId(undefined);
        setLikedUserId(undefined);
      });
  }, [likedUserId, users, vote]);

  const item1Ref = useRef<HTMLDivElement>(null);
  const item2Ref = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);

  return (
    <div className="vote-container scroll">
      <Header query={query} voteConfig={props.voteConfig} bonus={props.bonus} />
      <div className="vote-body">
        <LoadingPlaceholder {...rest} data={usersCompare}>
          <Select
            loading={loading}
            value={gender}
            options={genders.map((_) => ({
              value: _,
              text: g(`genderMany.${_}`),
            }))}
            onChange={(newGender) => {
              const prevGender = gender;
              setGender(newGender);
              setLoading(true);
              fetchMore({ variables: { gender: newGender } })
                .then(({ data }) => {
                  if (!data) {
                    setGender(prevGender);
                    return;
                  }
                  cache.updateQuery({ query: Vote_Query }, (d) => {
                    return {
                      ...d,
                      usersCompare: data.usersCompare,
                      user: d?.user
                        ? { ...d.user, voteGender: newGender }
                        : d?.user,
                    };
                  });
                  setUsers(
                    getFragmentData(VoteUser_QueryFragment, data.usersCompare)
                  );
                })
                .catch((e: Error) => {
                  toast.error(e.message);
                  setGender(prevGender);
                })
                .finally(() => setLoading(false));
            }}
          />
          {users?.length ? (
            <>
              <Headline className="vote-title" weight="1">
                {t("whoIsBetter")}
              </Headline>
              <div
                className="vote-items"
                style={{
                  pointerEvents: locked ? "none" : "auto",
                }}
              >
                {likedIndex !== undefined && likedIndex > -1 && (
                  <div
                    className={`vote-items-liked vote-items-liked-${likedIndex}`}
                  ></div>
                )}
                {users.map((user, index) => {
                  const country = countries.find(
                    (_) => _.code === user.countryCode
                  );

                  return (
                    <div
                      key={user.id}
                      ref={!index ? item1Ref : item2Ref}
                      className="vote-item-container"
                    >
                      {profileId === user.id && (
                        <Modal
                          fullscreen
                          onClose={() => setProfileId(undefined)}
                        >
                          <Profile
                            user={user}
                            voteConfig={props.voteConfig}
                            bonus={props.bonus}
                          />
                        </Modal>
                      )}
                      <div className="vote-item-header">
                        <div className="vote-item-name">{user.name}</div>
                        <div className="vote-item-age">{`, ${user.age}`}</div>
                        <VerifiedIcon
                          style={{ minWidth: 18 }}
                          width={18}
                          height={18}
                        />
                      </div>
                      <div
                        className="vote-item"
                        style={{
                          backgroundImage: `url(${user.uploads[0].previewUrl})`,
                        }}
                        onClick={() => setLikedUserId(user.id)}
                        onScroll={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <div className="vote-item-footer">
                          <div className="vote-item-footer-body">
                            <div className="vote-item-footer-row">
                              <div className="vote-item-location">
                                {country?.flag} {country?.name}
                              </div>
                            </div>
                          </div>
                          <div
                            className="vote-info-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setProfileId(user.id);
                            }}
                          >
                            <InfoIcon width={18} height={18} color="white" />
                          </div>
                        </div>
                        <HeartIcon
                          className="vote-item-heart"
                          width={48}
                          height={48}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <Headline className="vote-title" weight="3">
                {t("votingForLeader")}
              </Headline>
            </>
          ) : (
            <Placeholder
              className="vote-placeholder"
              header={t("noUsers.title")}
              description={t("noUsers.description")}
              action={
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 16 }}
                >
                  <Button
                    size="l"
                    stretched
                    onClick={() => dispatch(setTabId("friends"))}
                  >
                    {t("noUsers.inviteFriends")}
                  </Button>
                </div>
              }
            >
              <span style={{ fontSize: 30 }}>🌟</span>
            </Placeholder>
          )}
        </LoadingPlaceholder>
      </div>
    </div>
  );
};
