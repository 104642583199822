export default {
  g: {
    flag: "🇦🇱",
    langName: "Albanian",
    langNameNative: "Shqip",
    continue: "Vazhdo",
    save: "Ruaj",
    retry: "Provo përsëri",
    loading: "Duke ngarkuar...",
    genderMany: {
      all: "Të gjithë",
      male: "Meshkuj",
      female: "Femra",
    },
  },
  CreateAccount: {
    slogan: "Vendi ku ëndrrat kthehen në fitore!",
    gender: {
      title: "Përshëndetje {{name}}, Ti je...",
      male: "Mashkull",
      female: "Femër",
    },
  },
  Settings: {
    title: "Cilësimet",
    language: "Gjuha",
    auto: "Automatik",
    country: "Vendi",
    hideProfile: {
      label: "Fsheh Profilin",
      description:
        "Profili juaj do të jetë i fshehur për të gjithë, sikur ta keni fshirë.",
    },
    profileHidden:
      "Profili juaj është i fshehur dhe nuk është i dukshëm për përdoruesit e tjerë.",
  },
  Tabs: {
    vote: "Voto",
    contest: "Konkurs",
    friends: "Miqtë",
    earn: "Fito",
    wallet: "Kuletë",
  },
  Vote: {
    whoIsBetter: "Kë e pëlqen më shumë?",
    votingForLeader: "Votimi për liderin",
    noUsers: {
      title: "🚀 Kjo është e gjitha për sot",
      description:
        "Fto miqtë për të vazhduar votimin dhe për të fituar monedha",
      inviteFriends: "Fto Miqtë",
    },
  },
  Leaders: {
    rewards: "Shpërblime",
    comingSoon: "Së shpejti",
    prizePool: "Fond i Çmimeve",
    moreInfo: "Më shumë informacion",
    contest: "Konkurs",
    richest: "Më të pasurit",
    sponsors: "Sponsorë",
    description: {
      likes:
        "Fto miqtë dhe ndaj profilin tënd për të mbledhur më shumë vota dhe për të rritur shanset për të fituar.",
      coins:
        "Përfundo detyrat, voto për pjesëmarrësit e tjerë dhe fto miqtë për të fituar më shumë monedha dhe për të forcuar pozicionin tënd.",
      stars:
        "Voto për profilet në faqen e profilit për të marrë yje dhe për të ngjitur në renditje.",
    },
  },
  PrizePool: {
    text: `<b>Vendndodhjet e fitimit të çmimit</b>:
<b>1 vendi</b>: 50% USD dhe 10.000 monedha
<b>2 vendi</b>: 30% USD dhe 3.000 monedha
<b>3 vendi</b>: 20% USD dhe 2.000 monedha
<b>4.-10. vendet</b>: 1.000 monedha secili
<b>11.-50. vendet</b>: 500 monedha secili
<b>51.-100. vendet</b>: 250 monedha secili

<b>Fondi i çmimeve</b> rritet kur dikush voton për ju në faqen tuaj të profilit.

<b>Kushtet</b>:
Të gjitha profilet që ngarkojnë një foto dhe përfundojnë detyrat janë të kualifikuar për të marrë pjesë në konkurs.

<b>Data e përfundimit</b>:
{{endDate}}

<b>Pagesa e shpërblimit</b>:
Mund ta merrni shpërblimin tuaj në <b>TON</b> — kriptomonedhën e zhvilluar nga Telegram.`,
  },
  LeadersItem: {
    join: "Bashkohuni",
  },
  Friends: {
    title: "Fto miqtë!",
    description: "Ju dhe miku juaj do të merrni bonuse",
    bonus: {
      profit: "<coins>+{{count}}</coins> për ju dhe mikun tuaj",
      invite: {
        title: "Fto mikun",
      },
      inviteWithPremium: {
        title: "Fto mikun",
        description: "me Telegram Premium",
      },
    },
    list: {
      title: "Lista e miqve tuaj",
    },
    inviteFriend: "Fto mikun",
    inviteText: `Bashkohuni me mua, merrni pjesë në votimin për djemtë dhe vajzat më të bukura, dhe fitoni çmime të vlefshme!
  
  🌟 +{{coins}} monedha si dhuratë e parë
  🔥 +{{coinsPremium}} monedha nëse keni Telegram Premium
  
  🏆 Fitoni monedha për çdo votë dhe zgjidhni pjesëmarrësit më tërheqës. Dëshironi më shumë? Merrni pjesë vetë dhe garoni për çmime të vlefshme!
  
  Mos e humbisni shansin tuaj, bëhuni pjesë e komunitetit tonë të yjeve dhe fitoni!`,
    linkCopied: "Lidhja u kopjua",
  },
  Earn: {
    title: "Fitoni më shumë monedha",
    tasksList: "Lista e detyrave",
    check: "Kontrollo",
    inReview: "Në shqyrtim",
    subscribe: "Regjistrohu",
    participate: "Merr pjesë",
    invite: "Fto",
    verify: "Verifiko",
    taskCompleted: "Detyra e përfunduar",
  },
  Wallet: {
    title: "Merrni shpërblime në TON",
    description:
      "Përfundoni sfidat për të fituar TON, një monedhë kripto e zhvilluar nga Telegram",
    rewards: "Shpërblime",
    withdrawal: "Tërheqja",
    walletConnected: "Portofoli juaj TON është i lidhur",
    walletDescription: "Së shpejti do të jenë në dispozicion më shumë mundësi",
  },
  VerificationRequired: {
    title: "Kërkohet Verifikimi",
    description:
      "Për të marrë pjesë në konkurs, duhet të verifikoni llogarinë tuaj.",
    bdate: {
      title: "Data e lindjes tuaj",
    },
  },
  Verification: {
    title: "Verifikimi",
    hint: "Mbajeni fytyrën brenda ovale",
    secureHint: "Askush nuk do ta shohë këtë foto",
    success: "Ju keni përfunduar me sukses verifikimin",
    goToApp: "Shko te aplikacioni",
    verification: "Duke verifikuar...",
    cameraAccessRequired: "Ju lutemi jepni qasje në kamerë për të vazhduar",
    gettingAccessToCamer: "Duke marrë qasje në kamerë...",
    cameraAccessNotAllowed: "Qasja në kamerë u refuzua nga përdoruesi.",
    cameraAccessError: "Gabim në qasjen e kamerës",
    loadingHint: "Kjo mund të zgjasë pak kohë. Ju lutemi, bëni durim.",
  },
  Profile: {
    vote: "Voto",
    askVote: "Kërko Votë",
    inviteText: `Përshëndetje! 😊 Ju lutem, më mbështesni me votën tuaj në konkursin TON Stars! Çdo votë është thelbësore dhe më ndihmon të afrohem më shumë drejt fitimit të çmimeve të vlefshme.

🌟 +{{coins}} monedha si dhuratë e parë
🔥 +{{coinsPremium}} monedha nëse keni Telegram Premium

Voto për profilin tim këtu: {{url}}

Faleminderit për mbështetjen tuaj! 🌟`,
    joinContest: "Bashkohuni në konkurs",
    gender: {
      male: "Mashkull",
      female: "Femër",
    },
    photos: {
      inReview:
        "Fotot tuaja të reja janë në shqyrtim.\nKjo mund të zgjasë pak kohë.",
      rejected:
        "Një ose më shumë foto janë refuzuar për arsyen e mëposhtme:\n{{reason}}.\n\nJu lutemi ngarkoni foto të ndryshme dhe provoni përsëri.",
    },
  },
  ProfileEdit: {
    title: "Profili juaj",
    name: {
      label: "Emri juaj",
      placeholder: "Futni emrin tuaj",
    },
    instagram: {
      description: "Profili në Instagram duhet të jetë publik",
    },
    photos: {
      verificationPhotoUploaded:
        "Fotoja juaj e verifikimit është ngarkuar, askush nuk do ta shohë.",
      label: "Fotot tuaja",
      mainPhoto: "Foto kryesore",
      error:
        "Një ose më shumë foto nuk janë të përshtatshme, ju lutemi bëni një zgjedhje tjetër.",
      hint: "Zgjidhni fotot tuaja më mbresëlënëse, ku nuk ka njerëz të tjerë, dhe fytyra juaj është qartë dhe shprehësisht e dukshme.",
      inReview:
        "Fotot tuaja të reja janë në shqyrtim.\nKjo mund të zgjasë pak kohë.",
      rejected:
        "Një ose më shumë foto janë refuzuar për arsyen e mëposhtme:\n{{reason}}.\n\nJu lutemi ngarkoni foto të ndryshme dhe provoni përsëri.",
    },
    submit: "Bashkohuni në Konkurs",
    photosUploading: "Ngarkimi i fotos...",
    success: "Profili juaj është përditësuar",
    errors: {
      name: "Shkruani emrin tuaj",
      photos: "Ju lutemi, zgjidhni 3 fotografi",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto kryesore",
    noFace: "Fytyra juaj nuk është zbuluar",
    otherPeople: "Foto duhet të tregojë vetëm fytyrën tuaj",
  },
};
