export default {
  g: {
    flag: "🇸🇰",
    langName: "Slovak",
    langNameNative: "Slovenčina",
    continue: "Pokračovať",
    save: "Uložiť",
    retry: "Skúsiť znova",
    loading: "Načítava sa...",
    genderMany: {
      all: "Všetci",
      male: "Muži",
      female: "Ženy",
    },
  },
  CreateAccount: {
    slogan: "Miesto, kde sa sny menia na víťazstvá!",
    gender: {
      title: "Ahoj {{name}}, Ty si...",
      male: "Muž",
      female: "Žena",
    },
  },
  Settings: {
    title: "Nastavenia",
    language: "Jazyk",
    auto: "Automaticky",
    country: "Krajina",
    hideProfile: {
      label: "Skryť profil",
      description:
        "Váš profil bude skrytý pred všetkými, ako keby ste ho odstránili.",
    },
    profileHidden:
      "Váš profil je skrytý a nie je viditeľný pre ostatných používateľov.",
  },
  Tabs: {
    vote: "Hlasovať",
    contest: "Súťaž",
    friends: "Priatelia",
    earn: "Zarobiť",
    wallet: "Peňaženka",
  },
  Vote: {
    whoIsBetter: "Koho máte radšej?",
    votingForLeader: "Hlasovanie za lídra",
    noUsers: {
      title: "🚀 To je všetko na dnes",
      description:
        "Pozvite priateľov, aby pokračovali v hlasovaní a získavaní mincí",
      inviteFriends: "Pozvite priateľov",
    },
  },
  Leaders: {
    rewards: "Odměny",
    comingSoon: "Čoskoro",
    prizePool: "Cenový fond",
    moreInfo: "Viac informácií",
    contest: "Súťaž",
    richest: "Najbohatší",
    sponsors: "Sponzori",
    description: {
      likes:
        "Pozvite priateľov a zdieľajte svoj profil, aby ste získali viac hlasov a zvýšili svoje šance na výhru.",
      coins:
        "Splňte úlohy, hlasujte za ostatných účastníkov a pozvite priateľov, aby ste získali viac mincí a posilnili svoju pozíciu.",
      stars:
        "Hlasujte za profily na stránke profilu, aby ste získali hviezdičky a postúpili v rebríčku.",
    },
  },
  PrizePool: {
    text: `<b>Výherné miesta</b>:
<b>1. miesto</b>: 50% USD a 10.000 mincí
<b>2. miesto</b>: 30% USD a 3.000 mincí
<b>3. miesto</b>: 20% USD a 2.000 mincí
<b>4.-10. miesto</b>: 1.000 mincí každý
<b>11.-50. miesto</b>: 500 mincí každý
<b>51.-100. miesto</b>: 250 mincí každý

<b>Cenový fond</b> sa zvyšuje, keď niekto hlasuje za vás na vašej stránke profilu.

<b>Podmienky</b>:
Všetky profily, ktoré nahrajú fotografiu a splnia úlohy, sú oprávnené zúčastniť sa súťaže.

<b>Dátum ukončenia</b>:
{{endDate}}

<b>Vyplácanie odmien</b>:
Odmenu môžete získať v <b>TON</b> — kryptomene vyvinutej spoločnosťou Telegram.`,
  },
  LeadersItem: {
    join: "Pripojiť sa",
  },
  Friends: {
    title: "Pozvite priateľov!",
    description: "Vy a váš priateľ dostanete bonusy",
    bonus: {
      profit: "<coins>+{{count}}</coins> pre vás a vášho priateľa",
      invite: {
        title: "Pozvite priateľa",
      },
      inviteWithPremium: {
        title: "Pozvite priateľa",
        description: "s Telegram Premium",
      },
    },
    list: {
      title: "Zoznam vašich priateľov",
    },
    inviteFriend: "Pozvite priateľa",
    inviteText: `Pripojte sa ku mne, hlasujte za najkrajších chlapcov a dievčatá a získajte cenné ceny!
  
  🌟 +{{coins}} mincí ako prvý darček
  🔥 +{{coinsPremium}} mincí, ak máte Telegram Premium
  
  🏆 Získajte mince za každý hlas a vyberte si najatraktívnejších účastníkov. Chcete viac? Zúčastnite sa sami a súťažte o cenné ceny!
  
  Nezmeškajte svoju šancu, staňte sa súčasťou našej komunity hviezd a vyhrajte!`,
    linkCopied: "Odkaz skopírovaný",
  },
  Earn: {
    title: "Zarobiť viac mincí",
    tasksList: "Zoznam úloh",
    check: "Skontrolovať",
    inReview: "Na preskúmanie",
    subscribe: "Prihlásiť sa na odber",
    participate: "Zúčastniť sa",
    invite: "Pozvať",
    verify: "Overiť",
    taskCompleted: "Úloha dokončená",
  },
  Wallet: {
    title: "Získajte odmeny v TON",
    description:
      "Dokončite výzvy a získajte TON, kryptomenu vyvinutú spoločnosťou Telegram",
    rewards: "Odměny",
    withdrawal: "Výber",
    walletConnected: "Vaša TON peňaženka je pripojená",
    walletDescription: "Viac príležitostí bude čoskoro k dispozícii",
  },
  VerificationRequired: {
    title: "Vyžaduje sa overenie",
    description: "Aby ste sa mohli zúčastniť súťaže, musíte overiť svoj účet.",
    bdate: {
      title: "Váš dátum narodenia",
    },
  },
  Verification: {
    title: "Overenie",
    hint: "Držte tvár v ováli",
    secureHint: "Nikto neuvidí túto fotku",
    success: "Úspešne ste dokončili overenie",
    goToApp: "Prejsť do aplikácie",
    verification: "Overovanie...",
    cameraAccessRequired: "Prosím, povoľte prístup ku kamere na pokračovanie",
    gettingAccessToCamer: "Získavanie prístupu ku kamere...",
    cameraAccessNotAllowed: "Prístup ku kamere bol zamietnutý používateľom.",
    cameraAccessError: "Chyba prístupu ku kamere",
    loadingHint: "Tento proces môže chvíľu trvať. Prosím, buďte trpezliví.",
  },
  Profile: {
    vote: "Hlasovať",
    askVote: "Požiadať o Hlas",
    inviteText: `Ahoj! 😊 Prosím, podporte ma svojím hlasom v súťaži TON Stars! Každý hlas je kľúčový a pomáha mi priblížiť sa k výhre cenných cien.

🌟 +{{coins}} mincí ako prvý darček
🔥 +{{coinsPremium}} mincí, ak máte Telegram Premium

Hlasujte za môj profil tu: {{url}}

Ďakujem za vašu podporu! 🌟`,
    joinContest: "Pripojiť sa ku konkurzu",
    gender: {
      male: "Muž",
      female: "Žena",
    },
    photos: {
      inReview:
        "Vaše nové fotografie sú na prezeraní.\nMôže to trvať nejaký čas.",
      rejected:
        "Jedna alebo viacero fotografií boli zamietnuté z nasledujúceho dôvodu:\n{{reason}}.\n\nNahrajte prosím iné fotografie a skúste to znova.",
    },
  },
  ProfileEdit: {
    title: "Váš profil",
    name: {
      label: "Vaše meno",
      placeholder: "Zadajte svoje meno",
    },
    instagram: {
      description: "Profil na Instagrame musí byť verejný",
    },
    photos: {
      verificationPhotoUploaded:
        "Vaša overovacia fotografia bola nahratá, nikto ju neuvidí.",
      label: "Vaše fotografie",
      mainPhoto: "Hlavná fotografia",
      error:
        "Jedna alebo viacero fotografií nie je vhodných, prosím, urobte iný výber.",
      hint: "Vyberte svoje najpôsobivejšie fotografie, kde nie sú žiadni iní ľudia a vaša tvár je jasne viditeľná.",
      inReview:
        "Vaše nové fotografie sú na prezeraní.\nMôže to trvať nejaký čas.",
      rejected:
        "Jedna alebo viacero fotografií boli zamietnuté z nasledujúceho dôvodu:\n{{reason}}.\n\nNahrajte prosím iné fotografie a skúste to znova.",
    },
    submit: "Pripojiť sa ku konkurzu",
    photosUploading: "Nahrávanie fotografie...",
    success: "Váš profil bol aktualizovaný",
    errors: {
      name: "Uveďte svoje meno",
      photos: "Vyberte, prosím, 3 fotografie",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Hlavná fotografia",
    noFace: "Vaša tvár nebola zistená",
    otherPeople: "Fotografia by mala ukazovať iba vašu tvár",
  },
};
