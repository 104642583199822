export default {
  g: {
    flag: "🇲🇹",
    langName: "Maltese",
    langNameNative: "Malti",
    continue: "Kompli",
    save: "Ħlief",
    retry: "Erġa' pprova",
    loading: "Tagħbija...",
    genderMany: {
      all: "Kollha",
      male: "Irġiel",
      female: "Nisa",
    },
  },
  CreateAccount: {
    slogan: "Post fejn il-Ħolm isir Rebħiet!",
    gender: {
      title: "Bongu {{name}}, Inti...",
      male: "Raġel",
      female: "Mara",
    },
  },
  Settings: {
    title: "Settings",
    language: "Lingwa",
    auto: "Awto",
    country: "Pajjiż",
    hideProfile: {
      label: "Aħbi l-Profil",
      description:
        "Il-profil tiegħek se jinħeba minn kulħadd, bħallikieku ħażżejtu.",
    },
    profileHidden:
      "Il-profil tiegħek huwa moħbi u mhux viżibbli għall-utenti l-oħra.",
  },
  Tabs: {
    vote: "Ivota",
    contest: "Kompetizzjoni",
    friends: "Ħbieb",
    earn: "Aqla'",
    wallet: "Kartiera",
  },
  Vote: {
    whoIsBetter: "Min tħobb l-aktar?",
    votingForLeader: "Votazzjoni għat-tmexxija",
    noUsers: {
      title: "🚀 Dan hu kollox għal-lum",
      description:
        "Stieden lill-ħbieb biex ikomplu jivvutaw u jaqilgħu l-muniti",
      inviteFriends: "Stieden Ħbieb",
    },
  },
  Leaders: {
    rewards: "Premji",
    comingSoon: "Dalwaqt disponibbli",
    prizePool: "Fondi ta’ premji",
    moreInfo: "Aktar informazzjoni",
    contest: "Konkors",
    richest: "L-aktar sinjur",
    sponsors: "Sponsors",
    description: {
      likes:
        "Stieden lil ħbiebek u aqsam il-profil tiegħek biex tiġbor aktar voti u żżid iċ-ċansijiet tiegħek li tirbaħ.",
      coins:
        "Imla kompiti, ivvota għal parteċipanti oħra, u stieden lil ħbiebek biex taqla' aktar muniti u ssaħħaħ il-pożizzjoni tiegħek.",
      stars:
        "Ivvota għal profili fuq il-paġna tal-profil biex tirċievi stilel u titla’ fil-klassifika.",
    },
  },
  PrizePool: {
    text: `<b>Postijiet rebbieħa tal-premji</b>:
<b>Post l-ewwel</b>: 50% USD u 10,000 muniti
<b>Post it-tieni</b>: 30% USD u 3,000 muniti
<b>Post it-tielet</b>: 20% USD u 2,000 muniti
<b>Postijiet 4-10</b>: 1,000 muniti kull wieħed
<b>Postijiet 11-50</b>: 500 muniti kull wieħed
<b>Postijiet 51-100</b>: 250 muniti kull wieħed

<b>Fondi ta’ premji</b> jiżdied meta xi ħadd jivvota għalik fuq il-paġna tal-profil tiegħek.

<b>Kundizzjonijiet</b>:
Kull profil li jtellgħu ritratt u jlestu l-kompiti huma eliġibbli biex jipparteċipaw fil-kompetizzjoni.

<b>Data tat-tmiem</b>:
{{endDate}}

<b>Ħlas tal-premjijiet</b>:
Tista' tirċievi l-premju tiegħek f’<b>TON</b> — il-kripto-munita żviluppata minn Telegram.`,
  },
  LeadersItem: {
    join: "Ingħaqad",
  },
  Friends: {
    title: "Stieden lill-ħbieb!",
    description: "Int u l-ħabib tiegħek tirċievu bonuses",
    bonus: {
      profit: "<coins>+{{count}}</coins> għalik u għall-ħabib tiegħek",
      invite: {
        title: "Stieden lill-ħabib",
      },
      inviteWithPremium: {
        title: "Stieden lill-ħabib",
        description: "ma' Telegram Premium",
      },
    },
    list: {
      title: "Lista tal-ħbieb tiegħek",
    },
    inviteFriend: "Stieden lill-ħabib",
    inviteText: `Ingħaqad miegħi, ivvota għall-irġiel u n-nisa l-iktar sbieħ, u irbaħ premjijiet ta' valur!
  
  🌟 +{{coins}} muniti bħala rigal inizjali
  🔥 +{{coinsPremium}} muniti jekk għandek Telegram Premium
  
  🏆 Aqla' muniti għal kull vot u agħżel l-iktar parteċipanti attraenti. Trid aktar? Ipparteċipa int stess u ikkompeti għal premjijiet ta' valur!
  
  Tħallix ċ-ċans tiegħek jgħaddi, ssieħeb fil-komunità tal-kewkba tagħna u irbaħ!`,
    linkCopied: "Link ikkopyjat",
  },
  Earn: {
    title: "Aqla' aktar muniti",
    tasksList: "Lista ta' kompiti",
    check: "Iċċekkja",
    inReview: "Għaddej minn reviżjoni",
    subscribe: "Abbona",
    participate: "Ipparteċipa",
    invite: "Stieden",
    verify: "Ivverifika",
    taskCompleted: "Kompitu mitmum",
  },
  Wallet: {
    title: "Ikseb premji f'TON",
    description:
      "Ikkumplimenta sfidi biex taqla' TON, munita kriptografika żviluppata minn Telegram",
    rewards: "Premji",
    withdrawal: "Irtirar",
    walletConnected: "Il-kartiera TON tiegħek hija konnessa",
    walletDescription: "Aktar opportunitajiet se jkunu disponibbli dalwaqt",
  },
  VerificationRequired: {
    title: "Verifika Meħtieġa",
    description:
      "Biex tipparteċipa fil-kompetizzjoni, trid tivverifika l-kont tiegħek.",
    bdate: {
      title: "Data tat-twelid tiegħek",
    },
  },
  Verification: {
    title: "Verifika",
    hint: "Żomm wiċċek ġewwa l-ovali",
    secureHint: "Ħadd ma jara din ir-ritratt",
    success: "Int għalaqt b'suċċess il-verifika",
    goToApp: "Mur għall-applikazzjoni",
    verification: "Verifika...",
    cameraAccessRequired:
      "Jekk jogħġbok ippermetti aċċess għall-kamera biex tkompli",
    gettingAccessToCamer: "Tikseb aċċess għall-kamera...",
    cameraAccessNotAllowed: "L-aċċess għall-kamera ġie miċħud mill-utent.",
    cameraAccessError: "Żball fl-aċċess għall-kamera",
    loadingHint:
      "Dan jista' jieħu ftit żmien. Jekk jogħġbok stenna bil-paċenzja.",
  },
  Profile: {
    vote: "Ivota",
    askVote: "Staqsi biex Tivvota",
    inviteText: `Hi! 😊 Jekk jogħġbok, appoġġani bil-vot tiegħek fil-konkors TON Stars! Kull vot huwa kruċjali u jgħinni nersaq eqreb lejn ir-rebħ ta' premjijiet siewja.

🌟 +{{coins}} muniti bħala l-ewwel rigal
🔥 +{{coinsPremium}} muniti jekk għandek Telegram Premium

Ivota għall-profil tiegħi hawn: {{url}}

Grazzi għall-appoġġ tiegħek! 🌟`,
    joinContest: "Ingħaqad fil-konkors",
    gender: {
      male: "Maskili",
      female: "Femminili",
    },
    photos: {
      inReview:
        "Ir-ritratti ġodda tiegħek qegħdin jiġu riveduti.\nDan jista' jieħu ftit żmien.",
      rejected:
        "Ritratt jew aktar ġew miċħuda għar-raġuni li ġejja:\n{{reason}}.\n\nJekk jogħġbok ittella' ritratti differenti u erġa' pprova.",
    },
  },
  ProfileEdit: {
    title: "Il-Profil Tiegħek",
    name: {
      label: "Isem Tiegħek",
      placeholder: "Daħħal ismek",
    },
    instagram: {
      description: "Il-profil tal-Instagram irid ikun pubbliku",
    },
    photos: {
      verificationPhotoUploaded:
        "Ir-ritratt tal-verifika tiegħek ġie mtella', ħadd ma jarah.",
      label: "Ir-Ritratti Tiegħek",
      mainPhoto: "Ritratt Ewlieni",
      error:
        "Ritratt jew aktar mhumiex xierqa, jekk jogħġbok agħmel għażla oħra.",
      hint: "Agħżel l-aktar ritratti impressjonanti tiegħek, fejn ma hemmx nies oħra, u wiċċek jidher b'mod ċar u espressiv.",
      inReview:
        "Ir-ritratti ġodda tiegħek qegħdin jiġu riveduti.\nDan jista' jieħu ftit żmien.",
      rejected:
        "Ritratt jew aktar ġew miċħuda għar-raġuni li ġejja:\n{{reason}}.\n\nJekk jogħġbok ittella' ritratti differenti u erġa' pprova.",
    },
    submit: "Ingħaqad fil-Konkors",
    photosUploading: "Tella' ritratt...",
    success: "Il-profil tiegħek ġie aġġornat",
    errors: {
      name: "Indika l-isem tiegħek",
      photos: "Jekk jogħġbok agħżel 3 ritratti",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Ritratt ewlieni",
    noFace: "Wiċċek ma nstabx",
    otherPeople: "Ir-ritratt għandu juri biss wiċċek",
  },
};
