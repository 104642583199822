import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  ApolloLink,
} from "@apollo/client";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
// import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { onError } from "@apollo/client/link/error";
import config from "config";
import WebApp from "@twa-dev/sdk";
import toast from "react-hot-toast";
import i18next from "i18next";

// if (__DEV__) {
// loadDevMessages();
// loadErrorMessages();
// }

// const retryLink = new RetryLink({
//   delay: {
//     initial: 500, // Начальная задержка между попытками
//     max: Infinity, // Максимальная задержка между попытками
//     jitter: true, // Случайное распределение задержек для избегания одновременных повторных запросов
//   },
//   attempts: {
//     max: Infinity, // Максимальное количество попыток
//     retryIf: (error) => {
//       // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
//       if (!error?.statusCode) {
//         console.log("retry", error);
//         return true;
//       }

//       return false;
//     },
//   },
// });

// const resetErrorLink = new ApolloLink((operation, forward) => {
//   return forward(operation).map((response) => {
//     return response;
//   });
// });

const errorLink = onError(({ graphQLErrors }) => {
  console.log("errorLink", graphQLErrors);
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      toast.error(message);
    });
    console.log(JSON.stringify(graphQLErrors, null, 2));
  }
});

const httpLink = new HttpLink({
  uri: (operation) => {
    // const state = store.getState();
    const uri =
      (import.meta.env.DEV ? config.api.test : config.api.production) +
      "/" +
      operation.operationName;
    return uri;
  },
});

// const splitLink = split(({ query }) => {
//   const definition = getMainDefinition(query);
//   return (
//     definition.kind === "OperationDefinition" &&
//     definition.operation === "subscription"
//   );
// }, httpLink);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "Init-Data": WebApp.initData,
      lang: i18next.language,
    },
  }));

  return forward(operation);
});

export const cache = new InMemoryCache();

process.env.NODE_ENV !== "development" &&
  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

// const successLink = new ApolloLink((operation, forward) => {
//   return forward(operation).map((response) => {
//     if (!response.errors) {
//       operation.query.definitions.forEach((definition) => {
//         if ("operation" in definition && definition.operation === "mutation") {
//           const defaultValue = t("operations:default", {
//             operationName: operation.operationName,
//           });
//           const successText = i18next.t(
//             `operations:${operation.operationName}.success`,
//             { defaultValue }
//           );
//           const operationText = i18next.t(
//             `operations:${operation.operationName}`,
//             {
//               defaultValue,
//             }
//           );
//           const text = operationText === false ? false : successText;
//           typeof text === "string" && toast(text);
//         }
//       });
//     }
//     return response;
//   });
// });

export const appoloClient = new ApolloClient({
  link: from([
    authLink,
    errorLink,
    // // resetErrorLink,
    // // retryLink,
    // successLink,
    // splitLink,
    httpLink,
  ]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
