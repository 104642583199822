export default {
  g: {
    flag: "🇩🇪",
    langName: "German",
    langNameNative: "Deutsch",
    continue: "Fortsetzen",
    save: "Speichern",
    retry: "Erneut versuchen",
    loading: "Lädt...",
    genderMany: {
      all: "Alle",
      male: "Männer",
      female: "Frauen",
    },
  },
  CreateAccount: {
    slogan: "Der Ort, an dem Träume zu Siegen werden!",
    gender: {
      title: "Hallo {{name}}, Du bist...",
      male: "Männlich",
      female: "Weiblich",
    },
  },
  Settings: {
    title: "Einstellungen",
    language: "Sprache",
    auto: "Automatisch",
    country: "Land",
    hideProfile: {
      label: "Profil verbergen",
      description:
        "Ihr Profil wird für alle verborgen, als ob Sie es gelöscht hätten.",
    },
    profileHidden:
      "Ihr Profil ist verborgen und für andere Benutzer nicht sichtbar.",
  },
  Tabs: {
    vote: "Abstimmen",
    contest: "Wettbewerb",
    friends: "Freunde",
    earn: "Verdienen",
    wallet: "Brieftasche",
  },
  Vote: {
    whoIsBetter: "Wen magst du mehr?",
    votingForLeader: "Stimmen für den Anführer",
    noUsers: {
      title: "🚀 Das war's für heute",
      description:
        "Lade Freunde ein, um weiter abzustimmen und Münzen zu verdienen",
      inviteFriends: "Freunde einladen",
    },
  },
  Leaders: {
    rewards: "Belohnungen",
    comingSoon: "Kommt bald",
    prizePool: "Preispool",
    moreInfo: "Mehr Info",
    contest: "Wettbewerb",
    richest: "Die Reichsten",
    sponsors: "Sponsoren",
    description: {
      likes:
        "Lade Freunde ein und teile dein Profil, um mehr Stimmen zu sammeln und deine Gewinnchancen zu erhöhen.",
      coins:
        "Erledige Aufgaben, stimme für andere Teilnehmer und lade Freunde ein, um mehr Münzen zu verdienen und deine Position zu stärken.",
      stars:
        "Stimme für Profile auf der Profilseite ab, um Sterne zu erhalten und in den Ranglisten aufzusteigen.",
    },
  },
  PrizePool: {
    text: `<b>Preisgekrönte Plätze</b>:
<b>1. Platz</b>: 50% USD und 10,000 Münzen
<b>2. Platz</b>: 30% USD und 3,000 Münzen
<b>3. Platz</b>: 20% USD und 2,000 Münzen
<b>4.-10. Plätze</b>: jeweils 1,000 Münzen
<b>11.-50. Plätze</b>: jeweils 500 Münzen
<b>51.-100. Plätze</b>: jeweils 250 Münzen

<b>Der Preispool</b> erhöht sich, wenn jemand auf deiner Profilseite für dich stimmt.

<b>Bedingungen</b>:
Alle Profile, die ein Foto hochladen und die Aufgaben erfüllen, sind berechtigt, am Wettbewerb teilzunehmen.

<b>Enddatum</b>:
{{endDate}}

<b>Belohnungsauszahlung</b>:
Du kannst deine Belohnung in <b>TON</b> erhalten — die Kryptowährung, die von Telegram entwickelt wurde.`,
  },
  LeadersItem: {
    join: "Beitreten",
  },
  Friends: {
    title: "Lade Freunde ein!",
    description: "Du und dein Freund erhalten Boni",
    bonus: {
      profit: "<coins>+{{count}}</coins> für dich und deinen Freund",
      invite: {
        title: "Freund einladen",
      },
      inviteWithPremium: {
        title: "Freund einladen",
        description: "mit Telegram Premium",
      },
    },
    list: {
      title: "Liste deiner Freunde",
    },
    inviteFriend: "Freund einladen",
    inviteText: `Mach mit, stimme für die schönsten Jungs und Mädchen und gewinne wertvolle Preise!
  
  🌟 +{{coins}} Münzen als erstes Geschenk
  🔥 +{{coinsPremium}} Münzen, wenn du Telegram Premium hast
  
  🏆 Verdiene Münzen für jede Stimme und wähle die attraktivsten Teilnehmer. Willst du mehr? Nimm selbst teil und kämpfe um wertvolle Preise!
  
  Verpasse deine Chance nicht, werde Teil unserer Sternengemeinschaft und gewinne!`,
    linkCopied: "Link kopiert",
  },
  Earn: {
    title: "Verdiene mehr Münzen",
    tasksList: "Aufgabenliste",
    check: "Überprüfen",
    inReview: "Wird überprüft",
    subscribe: "Abonnieren",
    participate: "Teilnehmen",
    invite: "Einladen",
    verify: "Verifizieren",
    taskCompleted: "Aufgabe abgeschlossen",
  },
  Wallet: {
    title: "Erhalte Belohnungen in TON",
    description:
      "Schließe Herausforderungen ab, um TON zu verdienen, eine von Telegram entwickelte Kryptowährung",
    rewards: "Belohnungen",
    withdrawal: "Auszahlung",
    walletConnected: "Dein TON-Wallet ist verbunden",
    walletDescription: "Weitere Möglichkeiten werden bald verfügbar sein",
  },
  VerificationRequired: {
    title: "Verifizierung erforderlich",
    description:
      "Um am Wettbewerb teilzunehmen, musst du dein Konto verifizieren.",
    bdate: {
      title: "Dein Geburtsdatum",
    },
  },
  Verification: {
    title: "Verifizierung",
    hint: "Halten Sie Ihr Gesicht innerhalb des Ovals",
    secureHint: "Niemand wird dieses Foto sehen",
    success: "Du hast die Verifizierung erfolgreich abgeschlossen",
    goToApp: "Zur Anwendung gehen",
    verification: "Verifizierung...",
    cameraAccessRequired:
      "Bitte erlaube den Zugriff auf die Kamera, um fortzufahren",
    gettingAccessToCamer: "Zugriff auf die Kamera wird gewährt...",
    cameraAccessNotAllowed:
      "Zugriff auf die Kamera wurde vom Benutzer verweigert.",
    cameraAccessError: "Kamerazugriffsfehler",
    loadingHint: "Dies kann einige Zeit dauern. Bitte habe Geduld.",
  },
  Profile: {
    vote: "Abstimmen",
    askVote: "Um Abstimmung bitten",
    inviteText: `Hallo! 😊 Bitte unterstütze mich mit deiner Stimme im TON Stars-Wettbewerb! Jede Stimme ist entscheidend und hilft mir, dem Gewinn wertvoller Preise näher zu kommen.

🌟 +{{coins}} Münzen als erstes Geschenk
🔥 +{{coinsPremium}} Münzen, wenn du Telegram Premium hast

Stimme hier für mein Profil ab: {{url}}

Danke für deine Unterstützung! 🌟`,
    joinContest: "Am Wettbewerb teilnehmen",
    gender: {
      male: "Männlich",
      female: "Weiblich",
    },
    photos: {
      inReview:
        "Deine neuen Fotos werden überprüft.\nDies kann einige Zeit in Anspruch nehmen.",
      rejected:
        "Ein oder mehrere Fotos wurden aus folgendem Grund abgelehnt:\n{{reason}}.\n\nBitte lade andere Fotos hoch und versuche es erneut.",
    },
  },
  ProfileEdit: {
    title: "Dein Profil",
    name: {
      label: "Dein Name",
      placeholder: "Gib deinen Namen ein",
    },
    instagram: {
      description: "Instagram-Profil muss öffentlich sein",
    },
    photos: {
      verificationPhotoUploaded:
        "Dein Verifizierungsfoto wurde hochgeladen, niemand wird es sehen.",
      label: "Deine Fotos",
      mainPhoto: "Hauptfoto",
      error:
        "Ein oder mehrere Fotos sind nicht geeignet, bitte wähle andere aus.",
      hint: "Wähle deine beeindruckendsten Fotos aus, auf denen keine anderen Personen zu sehen sind und dein Gesicht klar und ausdrucksstark sichtbar ist.",
      inReview:
        "Deine neuen Fotos werden überprüft.\nDies kann einige Zeit in Anspruch nehmen.",
      rejected:
        "Ein oder mehrere Fotos wurden aus folgendem Grund abgelehnt:\n{{reason}}.\n\nBitte lade andere Fotos hoch und versuche es erneut.",
    },
    submit: "Am Wettbewerb teilnehmen",
    photosUploading: "Foto wird hochgeladen...",
    success: "Dein Profil wurde aktualisiert",
    errors: {
      name: "Geben Sie Ihren Namen an",
      photos: "Bitte wählen Sie 3 Fotos aus",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Hauptfoto",
    noFace: "Dein Gesicht wurde nicht erkannt",
    otherPeople: "Das Foto sollte nur dein Gesicht zeigen",
  },
};
