export default {
  g: {
    flag: "🇰🇪",
    langName: "Swahili",
    langNameNative: "Kiswahili",
    continue: "Endelea",
    save: "Hifadhi",
    retry: "Jaribu tena",
    loading: "Inapakia...",
    genderMany: {
      all: "Wote",
      male: "Wanaume",
      female: "Wanawake",
    },
  },
  CreateAccount: {
    slogan: "Mahali ambapo Ndoto Zinabadilika Kuwa Ushindi!",
    gender: {
      title: "Habari {{name}}, Wewe ni...",
      male: "Mwanaume",
      female: "Mwanamke",
    },
  },
  Settings: {
    title: "Mipangilio",
    language: "Lugha",
    auto: "Kiotomatiki",
    country: "Nchi",
    hideProfile: {
      label: "Ficha Profaili",
      description:
        "Profaili yako itafichwa kutoka kwa kila mtu, kana kwamba umeifuta.",
    },
    profileHidden:
      "Profaili yako imefichwa na haionekani kwa watumiaji wengine.",
  },
  Tabs: {
    vote: "Piga Kura",
    contest: "Mashindano",
    friends: "Marafiki",
    earn: "Pata",
    wallet: "Mkoba",
  },
  Vote: {
    whoIsBetter: "Unapenda nani zaidi?",
    votingForLeader: "Kupiga kura kwa kiongozi",
    noUsers: {
      title: "🚀 Hiyo ni yote kwa leo",
      description: "Waambie marafiki kuendelea kupiga kura na kupata sarafu",
      inviteFriends: "Waambie Marafiki",
    },
  },
  Leaders: {
    rewards: "Tuzo",
    comingSoon: "Inakuja hivi karibuni",
    prizePool: "Mfuko wa Zawadi",
    moreInfo: "Maelezo zaidi",
    contest: "Shindano",
    richest: "Matajiri zaidi",
    sponsors: "Wadhamini",
    description: {
      likes:
        "Waombe marafiki na ushiriki wasifu wako ili kukusanya kura zaidi na kuongeza nafasi zako za kushinda.",
      coins:
        "Kamilisha majukumu, piga kura kwa washiriki wengine, na waalike marafiki ili kupata sarafu zaidi na kuimarisha nafasi yako.",
      stars:
        "Piga kura kwa wasifu kwenye ukurasa wa wasifu ili kupata nyota na kupanda kwenye orodha ya viwango.",
    },
  },
  PrizePool: {
    text: `<b>Nafasi za Ushindi wa Zawadi</b>:
<b>1. nafasi</b>: 50% USD na sarafu 10,000
<b>2. nafasi</b>: 30% USD na sarafu 3,000
<b>3. nafasi</b>: 20% USD na sarafu 2,000
<b>4.-10. nafasi</b>: sarafu 1,000 kila moja
<b>11.-50. nafasi</b>: sarafu 500 kila moja
<b>51.-100. nafasi</b>: sarafu 250 kila moja

<b>Mfuko wa Zawadi</b> unakua unapopigiwa kura kwenye ukurasa wako wa wasifu.

<b>Masharti</b>:
Wasifu wote wanaopakia picha na kukamilisha majukumu wanastahili kushiriki kwenye shindano.

<b>Tarehe ya kumalizika</b>:
{{endDate}}

<b>Malipo ya Tuzo</b>:
Unaweza kupokea tuzo yako katika <b>TON</b> — sarafu ya kidijitali iliyotengenezwa na Telegram.`,
  },
  LeadersItem: {
    join: "Jiunge",
  },
  Friends: {
    title: "Waite marafiki!",
    description: "Wewe na rafiki yako mtapokea bonasi",
    bonus: {
      profit: "<coins>+{{count}}</coins> kwa ajili yako na rafiki yako",
      invite: {
        title: "Mwalike rafiki",
      },
      inviteWithPremium: {
        title: "Mwalike rafiki",
        description: "na Telegram Premium",
      },
    },
    list: {
      title: "Orodha ya marafiki zako",
    },
    inviteFriend: "Mwalike rafiki",
    inviteText: `Jiunge nami, piga kura kwa wavulana na wasichana warembo zaidi na upate zawadi muhimu!
  
  🌟 +{{coins}} sarafu kama zawadi ya kwanza
  🔥 +{{coinsPremium}} sarafu ikiwa una Telegram Premium
  
  🏆 Pata sarafu kwa kila kura na chagua washiriki wenye mvuto zaidi. Unataka zaidi? Shiriki mwenyewe na ushindane kwa zawadi muhimu!
  
  Usikose nafasi yako, kuwa sehemu ya jamii yetu ya nyota na ushinde!`,
    linkCopied: "Kiungo kimenakiliwa",
  },
  Earn: {
    title: "Pata sarafu zaidi",
    tasksList: "Orodha ya kazi",
    check: "Angalia",
    inReview: "Katika mapitio",
    subscribe: "Jiandikishe",
    participate: "Shiriki",
    invite: "Mwalike",
    verify: "Thibitisha",
    taskCompleted: "Kazi imekamilika",
  },
  Wallet: {
    title: "Pata tuzo katika TON",
    description:
      "Kamilisha changamoto kupata TON, sarafu ya crypto iliyotengenezwa na Telegram",
    rewards: "Zawadi",
    withdrawal: "Uondoaji",
    walletConnected: "Mkoba wako wa TON umeunganishwa",
    walletDescription: "Fursa zaidi zitapatikana hivi karibuni",
  },
  VerificationRequired: {
    title: "Uthibitisho Unahitajika",
    description:
      "Ili kushiriki kwenye mashindano, unahitaji kuthibitisha akaunti yako.",
    bdate: {
      title: "Tarehe yako ya kuzaliwa",
    },
  },
  Verification: {
    title: "Uthibitisho",
    hint: "Weka uso wako ndani ya mviringo",
    secureHint: "Hakuna mtu atakayeona picha hii",
    success: "Umefanikiwa kukamilisha uthibitisho",
    goToApp: "Nenda kwenye programu",
    verification: "Inathibitisha...",
    cameraAccessRequired: "Tafadhali ruhusu ufikiaji wa kamera ili kuendelea",
    gettingAccessToCamer: "Kupata ufikiaji wa kamera...",
    cameraAccessNotAllowed: "Ufikiaji wa kamera umekataliwa na mtumiaji.",
    cameraAccessError: "Hitilafu ya ufikiaji wa kamera",
    loadingHint: "Hii inaweza kuchukua muda. Tafadhali, kuwa na subira.",
  },
  Profile: {
    vote: "Piga kura",
    askVote: "Omba Kura",
    inviteText: `Habari! 😊 Tafadhali niunge mkono kwa kura yako katika shindano la TON Stars! Kila kura ni muhimu sana na inanisaidia kukaribia kushinda zawadi za thamani.

🌟 +{{coins}} sarafu kama zawadi ya kwanza
🔥 +{{coinsPremium}} sarafu ikiwa una Telegram Premium

Piga kura kwa wasifu wangu hapa: {{url}}

Asante kwa msaada wako! 🌟`,
    joinContest: "Jiunge na mashindano",
    gender: {
      male: "Mwanaume",
      female: "Mwanamke",
    },
    photos: {
      inReview: "Picha zako mpya zinakaguliwa.\nHii inaweza kuchukua muda.",
      rejected:
        "Picha moja au zaidi zilikataliwa kwa sababu ifuatayo:\n{{reason}}.\n\nTafadhali pakia picha tofauti na ujaribu tena.",
    },
  },
  ProfileEdit: {
    title: "Wasifu wako",
    name: {
      label: "Jina lako",
      placeholder: "Ingiza jina lako",
    },
    instagram: {
      description: "Wasifu wa Instagram lazima uwe wazi",
    },
    photos: {
      verificationPhotoUploaded:
        "Picha yako ya uthibitishaji imepakiwa, hakuna mtu atakayeiona.",
      label: "Picha zako",
      mainPhoto: "Picha Kuu",
      error: "Picha moja au zaidi hazifai, tafadhali chagua nyingine.",
      hint: "Chagua picha zako za kuvutia zaidi, ambapo hakuna watu wengine, na uso wako unaonekana wazi na kwa uwazi.",
      inReview: "Picha zako mpya zinakaguliwa.\nHii inaweza kuchukua muda.",
      rejected:
        "Picha moja au zaidi zilikataliwa kwa sababu ifuatayo:\n{{reason}}.\n\nTafadhali pakia picha tofauti na ujaribu tena.",
    },
    submit: "Jiunge na Mashindano",
    photosUploading: "Picha inapakiwa...",
    success: "Wasifu wako umesasishwa",
    errors: {
      name: "Weka jina lako",
      photos: "Tafadhali chagua picha 3",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Picha kuu",
    noFace: "Uso wako haujapatikana",
    otherPeople: "Picha inapaswa kuonyesha uso wako pekee",
  },
};
