export default {
  g: {
    flag: "🇮🇳",
    langName: "Marathi",
    langNameNative: "मराठी",
    continue: "सुरू ठेवा",
    save: "जतन करा",
    retry: "पुन्हा प्रयत्न करा",
    loading: "लोड करत आहे...",
    genderMany: {
      all: "सर्व",
      male: "पुरुष",
      female: "महिला",
    },
  },
  CreateAccount: {
    slogan: "जिथे स्वप्ने विजयात बदलतात!",
    gender: {
      title: "नमस्कार {{name}}, आपण...",
      male: "पुरुष",
      female: "स्त्री",
    },
  },
  Settings: {
    title: "सेटिंग्ज",
    language: "भाषा",
    auto: "स्वयंचलित",
    country: "देश",
    hideProfile: {
      label: "प्रोफाइल लपवा",
      description:
        "तुमचा प्रोफाइल सर्वांपासून लपवला जाईल, जणू तुम्ही तो हटवला आहे.",
    },
    profileHidden:
      "तुमचा प्रोफाइल लपलेला आहे आणि इतर वापरकर्त्यांना दृश्यमान नाही.",
  },
  Tabs: {
    vote: "मत द्या",
    contest: "स्पर्धा",
    friends: "मित्र",
    earn: "कमवा",
    wallet: "वॉलेट",
  },
  Vote: {
    whoIsBetter: "तुला जास्त कोण आवडतो?",
    votingForLeader: "नेत्यासाठी मतदान",
    noUsers: {
      title: "🚀 आजसाठी एवढेच",
      description:
        "मतदान सुरू ठेवण्यासाठी आणि नाणी मिळवण्यासाठी मित्रांना आमंत्रित करा",
      inviteFriends: "मित्रांना आमंत्रित करा",
    },
  },
  Leaders: {
    rewards: "पुरस्कार",
    comingSoon: "लवकरच येत आहे",
    prizePool: "बक्षिसांचे तळ",
    moreInfo: "अधिक माहिती",
    contest: "स्पर्धा",
    richest: "सर्वात श्रीमंत",
    sponsors: "प्रायोजक",
    description: {
      likes:
        "अधिक मतं मिळविण्यासाठी आणि जिंकण्याच्या शक्यता वाढविण्यासाठी मित्रांना आमंत्रित करा आणि आपला प्रोफाइल सामायिक करा.",
      coins:
        "कार्ये पूर्ण करा, इतर सहभागींसाठी मत द्या, आणि अधिक नाणी मिळविण्यासाठी मित्रांना आमंत्रित करा आणि आपले स्थान मजबूत करा.",
      stars:
        "प्रोफाइल पृष्ठावर प्रोफाइलसाठी मत द्या, स्टार मिळवा, आणि रँकिंगमध्ये वर जा.",
    },
  },
  PrizePool: {
    text: `<b>प्राईज जिंकण्याचे स्थान</b>:
<b>पहिले स्थान</b>: 50% USD आणि 10,000 नाणी
<b>दुसरे स्थान</b>: 30% USD आणि 3,000 नाणी
<b>तिसरे स्थान</b>: 20% USD आणि 2,000 नाणी
<b>चौथे-10वे स्थान</b>: प्रत्येक 1,000 नाणी
<b>11वे-50वे स्थान</b>: प्रत्येक 500 नाणी
<b>51वे-100वे स्थान</b>: प्रत्येक 250 नाणी

<b>प्राईज तळ</b> तुमच्या प्रोफाइल पृष्ठावर कोणी तुमच्यासाठी मतदान केल्यास वाढतो.

<b>अटी</b>:
सर्व प्रोफाइल्स ज्यांनी फोटो अपलोड केले आणि कार्ये पूर्ण केली ती स्पर्धेत भाग घेण्यास पात्र आहेत.

<b>अंतिम तारीख</b>:
{{endDate}}

<b>पुरस्काराचे पेमेंट</b>:
आपण आपले बक्षीस <b>TON</b> मध्ये मिळवू शकता - ही क्रिप्टोकरन्सी आहे जी Telegram द्वारे विकसित करण्यात आली आहे.`,
  },
  LeadersItem: {
    join: "सामील व्हा",
  },
  Friends: {
    title: "मित्रांना आमंत्रित करा!",
    description: "तुम्ही आणि तुमचे मित्र बोनस प्राप्त कराल",
    bonus: {
      profit: "<coins>+{{count}}</coins> तुमच्यासाठी आणि तुमच्या मित्रासाठी",
      invite: {
        title: "मित्रांना आमंत्रित करा",
      },
      inviteWithPremium: {
        title: "मित्रांना आमंत्रित करा",
        description: "Telegram Premium सह",
      },
    },
    list: {
      title: "तुमच्या मित्रांची यादी",
    },
    inviteFriend: "मित्रांना आमंत्रित करा",
    inviteText: `माझ्याबरोबर सामील व्हा, सर्वात सुंदर मुलं आणि मुलींसाठी मत द्या आणि मौल्यवान बक्षिसे मिळवा!
  
  🌟 प्रथम भेट म्हणून +{{coins}} नाणी
  🔥 तुम्हाला Telegram Premium असल्यास +{{coinsPremium}} नाणी
  
  🏆 प्रत्येक मतासाठी नाणी कमवा आणि सर्वात आकर्षक सहभागी निवडा. अधिक इच्छिता? स्वतः भाग घ्या आणि मौल्यवान बक्षिसांसाठी स्पर्धा करा!
  
  तुमची संधी गमावू नका, आमच्या तारा समुदायाचा भाग बना आणि जिंका!`,
    linkCopied: "लिंक कॉपी केले",
  },
  Earn: {
    title: "अधिक नाणी कमवा",
    tasksList: "कार्य सूची",
    check: "तपासा",
    inReview: "पुनरावलोकनात",
    subscribe: "सदस्यता घ्या",
    participate: "सहभागी व्हा",
    invite: "आमंत्रित करा",
    verify: "सत्यापित करा",
    taskCompleted: "कार्य पूर्ण झाले",
  },
  Wallet: {
    title: "TON मध्ये बक्षिसे मिळवा",
    description:
      "TON कमविण्यासाठी आव्हाने पूर्ण करा, एक क्रिप्टो नाणे जे Telegram द्वारे विकसित केले गेले आहे",
    rewards: "बक्षिसे",
    withdrawal: "वापसी",
    walletConnected: "आपले TON वॉलेट कनेक्ट केलेले आहे",
    walletDescription: "लवकरच अधिक संधी उपलब्ध होतील",
  },
  VerificationRequired: {
    title: "सत्यापन आवश्यक आहे",
    description:
      "स्पर्धेत सहभागी होण्यासाठी तुम्हाला तुमचे खाते सत्यापित करावे लागेल.",
    bdate: {
      title: "तुमचा जन्मदिवस",
    },
  },
  Verification: {
    title: "सत्यापन",
    hint: "तुमचा चेहरा ओव्हलच्या आत ठेवा",
    secureHint: "कोणीही हे छायाचित्र पाहणार नाही",
    success: "तुम्ही यशस्वीपणे सत्यापन पूर्ण केले आहे",
    goToApp: "अॅपवर जा",
    verification: "सत्यापित करत आहे...",
    cameraAccessRequired: "कृपया पुढे जाण्यासाठी कॅमेरा प्रवेश मंजूर करा",
    gettingAccessToCamer: "कॅमेरा प्रवेश मिळवत आहे...",
    cameraAccessNotAllowed: "वापरकर्त्याने कॅमेरा प्रवेश नाकारला.",
    cameraAccessError: "कॅमेरा प्रवेश त्रुटी",
    loadingHint: "यात काही वेळ लागू शकतो. कृपया धीर धरा.",
  },
  Profile: {
    vote: "मत",
    askVote: "मत विचारणे",
    inviteText: `हाय! 😊 कृपया TON Stars स्पर्धेत तुमच्या मताने माझे समर्थन करा! प्रत्येक मत महत्वाचे आहे आणि मला मौल्यवान बक्षिसे जिंकण्याच्या जवळ नेण्यास मदत करते.

🌟 पहिल्या भेटवस्तूसाठी +{{coins}} नाणी
🔥 जर तुमच्याकडे Telegram Premium असेल तर +{{coinsPremium}} नाणी

माझ्या प्रोफाइलला येथे मत द्या: {{url}}

तुमच्या समर्थनासाठी धन्यवाद! 🌟`,
    joinContest: "स्पर्धेत सामील व्हा",
    gender: {
      male: "पुरुष",
      female: "महिला",
    },
    photos: {
      inReview: "आपले नवीन फोटो पुनरावलोकनाखाली आहेत.\nयात काही काळ लागू शकतो.",
      rejected:
        "एका किंवा अधिक फोटोंना खालील कारणास्तव नाकारले गेले आहे:\n{{reason}}.\n\nकृपया विविध फोटो अपलोड करा आणि पुन्हा प्रयत्न करा.",
    },
  },
  ProfileEdit: {
    title: "आपले प्रोफाइल",
    name: {
      label: "आपले नाव",
      placeholder: "आपले नाव प्रविष्ट करा",
    },
    instagram: {
      description: "Instagram प्रोफाइल सार्वजनिक असणे आवश्यक आहे",
    },
    photos: {
      verificationPhotoUploaded:
        "तुमचे सत्यापन फोटो अपलोड झाले आहे, ते कोणीही पाहणार नाही.",
      label: "आपले फोटो",
      mainPhoto: "मुख्य फोटो",
      error: "एका किंवा अधिक फोटो योग्य नाहीत, कृपया दुसरे निवडा.",
      hint: "आपले सर्वात प्रभावी फोटो निवडा, जिथे इतर कोणी नाही आणि आपले चेहरा स्पष्टपणे आणि स्पष्टपणे दिसतो.",
      inReview: "आपले नवीन फोटो पुनरावलोकनाखाली आहेत.\nयात काही काळ लागू शकतो.",
      rejected:
        "एका किंवा अधिक फोटोंना खालील कारणास्तव नाकारले गेले आहे:\n{{reason}}.\n\nकृपया विविध फोटो अपलोड करा आणि पुन्हा प्रयत्न करा.",
    },
    submit: "स्पर्धेत सामील व्हा",
    photosUploading: "फोटो अपलोड करत आहे...",
    success: "आपले प्रोफाइल अद्यतनित केले आहे",
    errors: {
      name: "आपले नाव सांगा",
      photos: "कृपया 3 छायाचित्रे निवडा",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "मुख्य फोटो",
    noFace: "आपले चेहरा ओळखले नाही",
    otherPeople: "फोटोमध्ये फक्त आपला चेहरा दिसायला हवा",
  },
};
