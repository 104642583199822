import { gql } from "__generated__";

export const UploadCreateMutation = gql(`
    mutation UploadCreateMutation($data: [UploadCreateInput!]!) {
      uploadsCreate(data: $data) {
        id
        signedUrl
      }
    }
`);

export const UploadUpdateMutation = gql(`
  mutation UploadUpdateMutation($id: ObjectID! $data: UploadUpdateInput!) {
    uploadUpdate(id: $id data: $data) {
      id
      previewUrl: url(width: 477, height: 848)
    }
  }
`);

export const Upload_QueryFragment = gql(`
  fragment Upload_QueryFragment on Upload {
      id
      previewUrl: url(width: 477, height: 848)
  }
`);
