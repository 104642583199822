export default {
  g: {
    flag: "🇱🇹",
    langName: "Lithuanian",
    langNameNative: "Lietuvių",
    continue: "Tęsti",
    save: "Išsaugoti",
    retry: "Bandyti dar kartą",
    loading: "Įkeliama...",
    genderMany: {
      all: "Visi",
      male: "Vyrai",
      female: "Moterys",
    },
  },
  CreateAccount: {
    slogan: "Vieta, kur svajonės virsta pergalėmis!",
    gender: {
      title: "Sveiki {{name}}, Jūs esate...",
      male: "Vyras",
      female: "Moteris",
    },
  },
  Settings: {
    title: "Nustatymai",
    language: "Kalba",
    auto: "Automatinis",
    country: "Šalis",
    hideProfile: {
      label: "Slėpti profilį",
      description:
        "Jūsų profilis bus paslėptas nuo visų, tarsi jį būtumėte ištrynę.",
    },
    profileHidden:
      "Jūsų profilis yra paslėptas ir nematomas kitiems naudotojams.",
  },
  Tabs: {
    vote: "Balsuoti",
    contest: "Konkursas",
    friends: "Draugai",
    earn: "Uždirbti",
    wallet: "Piniginė",
  },
  Vote: {
    whoIsBetter: "Kas jums labiau patinka?",
    votingForLeader: "Balsavimas už lyderį",
    noUsers: {
      title: "🚀 Tai viskas šiandienai",
      description: "Kviečiame draugus toliau balsuoti ir uždirbti monetas",
      inviteFriends: "Kviesti draugus",
    },
  },
  Leaders: {
    rewards: "Atlygis",
    comingSoon: "Netrukus",
    prizePool: "Prizinis fondas",
    moreInfo: "Daugiau informacijos",
    contest: "Konkursas",
    richest: "Turtingiausi",
    sponsors: "Rėmėjai",
    description: {
      likes:
        "Kvietkite draugus ir dalinkitės savo profiliu, kad surinktumėte daugiau balsų ir padidintumėte savo šansus laimėti.",
      coins:
        "Vykdykite užduotis, balsuokite už kitus dalyvius ir kvieskite draugus, kad uždirbtumėte daugiau monetų ir sustiprintumėte savo poziciją.",
      stars:
        "Balsuokite už profilius profilio puslapyje, kad gautumėte žvaigždutes ir pakiltumėte reitinguose.",
    },
  },
  PrizePool: {
    text: `<b>Prizines vietas</b>:
<b>1-oji vieta</b>: 50% USD ir 10,000 monetų
<b>2-oji vieta</b>: 30% USD ir 3,000 monetų
<b>3-oji vieta</b>: 20% USD ir 2,000 monetų
<b>4-oji-10-oji vietos</b>: po 1,000 monetų
<b>11-oji-50-oji vietos</b>: po 500 monetų
<b>51-oji-100-oji vietos</b>: po 250 monetų

<b>Prizinis fondas</b> didėja, kai kas nors balsuoja už jus jūsų profilio puslapyje.

<b>Sąlygos</b>:
Visi profiliai, kurie įkelia nuotrauką ir įvykdo užduotis, gali dalyvauti konkurse.

<b>Pabaigos data</b>:
{{endDate}}

<b>Atlygio išmokėjimas</b>:
Atlygį galite gauti <b>TON</b> — Telegram sukurtu kriptovaliuta.`,
  },
  LeadersItem: {
    join: "Prisijungti",
  },
  Friends: {
    title: "Pakvieskite draugus!",
    description: "Jūs ir jūsų draugas gausite premijas",
    bonus: {
      profit: "<coins>+{{count}}</coins> jums ir jūsų draugui",
      invite: {
        title: "Pakviesti draugą",
      },
      inviteWithPremium: {
        title: "Pakviesti draugą",
        description: "su Telegram Premium",
      },
    },
    list: {
      title: "Jūsų draugų sąrašas",
    },
    inviteFriend: "Pakviesti draugą",
    inviteText: `Prisijunkite prie manęs, balsuokite už gražiausius vaikinus ir merginas ir laimėkite vertingus prizus!
  
  🌟 +{{coins}} monetos kaip pirmoji dovana
  🔥 +{{coinsPremium}} monetos, jei turite Telegram Premium
  
  🏆 Uždirbkite monetas už kiekvieną balsą ir pasirinkite patraukliausius dalyvius. Norite daugiau? Dalyvaukite patys ir varžykitės dėl vertingų prizų!
  
  Nepraleiskite savo šanso, tapkite mūsų žvaigždžių bendruomenės dalimi ir laimėkite!`,
    linkCopied: "Nuoroda nukopijuota",
  },
  Earn: {
    title: "Uždirbkite daugiau monetų",
    tasksList: "Užduočių sąrašas",
    check: "Patikrinti",
    inReview: "Peržiūrima",
    subscribe: "Prenumeruoti",
    participate: "Dalyvauti",
    invite: "Kviesti",
    verify: "Patvirtinti",
    taskCompleted: "Užduotis baigta",
  },
  Wallet: {
    title: "Gaukite atlygį TON",
    description:
      "Atlikite iššūkius, kad uždirbtumėte TON, kriptovaliutą, kurią sukūrė Telegram",
    rewards: "Atlygis",
    withdrawal: "Išsiėmimas",
    walletConnected: "Jūsų TON piniginė prijungta",
    walletDescription: "Netrukus bus pasiekiama daugiau galimybių",
  },
  VerificationRequired: {
    title: "Reikalingas patvirtinimas",
    description:
      "Norint dalyvauti konkurse, jums reikia patvirtinti savo paskyrą.",
    bdate: {
      title: "Jūsų gimimo data",
    },
  },
  Verification: {
    title: "Patvirtinimas",
    hint: "Laikykite veidą ovalo viduje",
    secureHint: "Niekas nematys šios nuotraukos",
    success: "Jūs sėkmingai užbaigėte patvirtinimą",
    goToApp: "Eiti į programą",
    verification: "Patvirtinimas...",
    cameraAccessRequired: "Prašome leisti prieigą prie kameros norint tęsti",
    gettingAccessToCamer: "Gaunama prieiga prie kameros...",
    cameraAccessNotAllowed: "Naudotojas atmetė prieigą prie kameros.",
    cameraAccessError: "Prieigos prie kameros klaida",
    loadingHint: "Tai gali užtrukti. Prašome būti kantrūs.",
  },
  Profile: {
    vote: "Balsuoti",
    askVote: "Prašyti Balsavimo",
    inviteText: `Sveiki! 😊 Prašome palaikyti mane savo balsu TON Stars konkurse! Kiekvienas balsas yra labai svarbus ir padeda man artėti prie vertingų prizų laimėjimo.

🌟 +{{coins}} monetų kaip pirmoji dovana
🔥 +{{coinsPremium}} monetų, jei turite Telegram Premium

Balsuokite už mano profilį čia: {{url}}

Ačiū už jūsų palaikymą! 🌟`,
    joinContest: "Prisijunkite prie konkurso",
    gender: {
      male: "Vyras",
      female: "Moteris",
    },
    photos: {
      inReview: "Jūsų naujos nuotraukos peržiūrimos.\nTai gali užtrukti.",
      rejected:
        "Viena ar daugiau nuotraukų buvo atmestos dėl šios priežasties:\n{{reason}}.\n\nPrašome įkelti kitas nuotraukas ir bandyti dar kartą.",
    },
  },
  ProfileEdit: {
    title: "Jūsų profilis",
    name: {
      label: "Jūsų vardas",
      placeholder: "Įveskite savo vardą",
    },
    instagram: {
      description: "Instagram profilis turi būti viešas",
    },
    photos: {
      verificationPhotoUploaded:
        "Jūsų patvirtinimo nuotrauka įkelta, niekas jos nematys.",
      label: "Jūsų nuotraukos",
      mainPhoto: "Pagrindinė nuotrauka",
      error: "Viena ar daugiau nuotraukų netinkamos, prašome pasirinkti kitas.",
      hint: "Pasirinkite savo įspūdingiausias nuotraukas, kuriose nėra kitų žmonių ir jūsų veidas aiškiai matomas.",
      inReview: "Jūsų naujos nuotraukos peržiūrimos.\nTai gali užtrukti.",
      rejected:
        "Viena ar daugiau nuotraukų buvo atmestos dėl šios priežasties:\n{{reason}}.\n\nPrašome įkelti kitas nuotraukas ir bandyti dar kartą.",
    },
    submit: "Prisijunkite prie konkurso",
    photosUploading: "Nuotrauka įkeliama...",
    success: "Jūsų profilis buvo atnaujintas",
    errors: {
      name: "Nurodykite savo vardą",
      photos: "Pasirinkite 3 nuotraukas",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Pagrindinė nuotrauka",
    noFace: "Jūsų veidas nebuvo aptiktas",
    otherPeople: "Nuotraukoje turi būti matomas tik jūsų veidas",
  },
};
