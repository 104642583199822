export default {
  g: {
    flag: "🇸🇮",
    langName: "Slovenian",
    langNameNative: "Slovenščina",
    continue: "Nadaljuj",
    save: "Shrani",
    retry: "Poskusi znova",
    loading: "Nalaganje...",
    genderMany: {
      all: "Vsi",
      male: "Moški",
      female: "Ženske",
    },
  },
  CreateAccount: {
    slogan: "Kraj, kjer se sanje spremenijo v zmage!",
    gender: {
      title: "Živjo {{name}}, Ti si...",
      male: "Moški",
      female: "Ženska",
    },
  },
  Settings: {
    title: "Nastavitve",
    language: "Jezik",
    auto: "Samodejno",
    country: "Država",
    hideProfile: {
      label: "Skrij profil",
      description: "Vaš profil bo skrit pred vsemi, kot da bi ga izbrisali.",
    },
    profileHidden: "Vaš profil je skrit in ni viden drugim uporabnikom.",
  },
  Tabs: {
    vote: "Glasuj",
    contest: "Tekmovanje",
    friends: "Prijatelji",
    earn: "Zaslužite",
    wallet: "Denarnica",
  },
  Vote: {
    whoIsBetter: "Kdo vam je bolj všeč?",
    votingForLeader: "Glasovanje za vodjo",
    noUsers: {
      title: "🚀 To je vse za danes",
      description:
        "Povabite prijatelje, da nadaljujejo z glasovanjem in zaslužijo kovance",
      inviteFriends: "Povabite prijatelje",
    },
  },
  Leaders: {
    rewards: "Nagrade",
    comingSoon: "Kmalu na voljo",
    prizePool: "Nagradni sklad",
    moreInfo: "Več informacij",
    contest: "Tekmovanje",
    richest: "Najbogatejši",
    sponsors: "Sponzorji",
    description: {
      likes:
        "Povabite prijatelje in delite svoj profil, da zberete več glasov in povečate svoje možnosti za zmago.",
      coins:
        "Izpolnite naloge, glasujte za druge udeležence in povabite prijatelje, da zaslužite več kovancev in okrepite svoj položaj.",
      stars:
        "Glasujte za profile na strani profila, da prejmete zvezdice in se povzpnete v razvrstitvi.",
    },
  },
  PrizePool: {
    text: `<b>Ocenjene nagrade</b>:
<b>1. mesto</b>: 50% USD in 10.000 kovancev
<b>2. mesto</b>: 30% USD in 3.000 kovancev
<b>3. mesto</b>: 20% USD in 2.000 kovancev
<b>4.-10. mesto</b>: 1.000 kovancev vsak
<b>11.-50. mesto</b>: 500 kovancev vsak
<b>51.-100. mesto</b>: 250 kovancev vsak

<b>Nagradni sklad</b> se povečuje, ko nekdo glasuje za vas na vaši strani profila.

<b>Pogoji</b>:
Vsi profili, ki naložijo fotografijo in izpolnijo naloge, so upravičeni do sodelovanja v tekmovanju.

<b>Datum zaključka</b>:
{{endDate}}

<b>Izplačilo nagrad</b>:
Nagrado lahko prejmete v <b>TON</b> — kriptovaluti, ki jo je razvil Telegram.`,
  },
  LeadersItem: {
    join: "Pridruži se",
  },
  Friends: {
    title: "Povabite prijatelje!",
    description: "Vi in vaš prijatelj bosta prejela bonuse",
    bonus: {
      profit: "<coins>+{{count}}</coins> za vas in vašega prijatelja",
      invite: {
        title: "Povabite prijatelja",
      },
      inviteWithPremium: {
        title: "Povabite prijatelja",
        description: "s Telegram Premium",
      },
    },
    list: {
      title: "Seznam vaših prijateljev",
    },
    inviteFriend: "Povabite prijatelja",
    inviteText: `Pridružite se mi, glasujte za najlepše fante in dekleta ter osvojite dragocene nagrade!
  
  🌟 +{{coins}} kovancev kot prvi dar
  🔥 +{{coinsPremium}} kovancev, če imate Telegram Premium
  
  🏆 Zaslužite kovance za vsak glas in izberite najbolj privlačne udeležence. Želite več? Sodelujte sami in tekmujte za dragocene nagrade!
  
  Ne zamudite svoje priložnosti, postanite del naše skupnosti zvezd in zmagajte!`,
    linkCopied: "Povezava kopirana",
  },
  Earn: {
    title: "Zaslužite več kovancev",
    tasksList: "Seznam nalog",
    check: "Preveri",
    inReview: "V pregledu",
    subscribe: "Naročite se",
    participate: "Sodelujte",
    invite: "Povabite",
    verify: "Potrdite",
    taskCompleted: "Naloga zaključena",
  },
  Wallet: {
    title: "Pridobite nagrade v TON",
    description:
      "Izpolnite izzive, da zaslužite TON, kripto kovanec, ki ga je razvil Telegram",
    rewards: "Nagrade",
    withdrawal: "Dvigi",
    walletConnected: "Vaša TON denarnica je povezana",
    walletDescription: "Kmalu bodo na voljo še več možnosti",
  },
  VerificationRequired: {
    title: "Potrebna potrditev",
    description: "Za sodelovanje na tekmovanju morate potrditi svoj račun.",
    bdate: {
      title: "Vaš datum rojstva",
    },
  },
  Verification: {
    title: "Potrditev",
    hint: "Držite obraz znotraj ovalnega",
    secureHint: "Nihče ne bo videl te fotografije",
    success: "Uspešno ste zaključili potrditev",
    goToApp: "Pojdite v aplikacijo",
    verification: "Potrditev...",
    cameraAccessRequired: "Prosim, dovolite dostop do kamere za nadaljevanje",
    gettingAccessToCamer: "Pridobivanje dostopa do kamere...",
    cameraAccessNotAllowed: "Uporabnik je zavrnil dostop do kamere.",
    cameraAccessError: "Napaka pri dostopu do kamere",
    loadingHint: "To lahko traja nekaj časa. Prosim, bodite potrpežljivi.",
  },
  Profile: {
    vote: "Glasuj",
    askVote: "Prosite za Glas",
    inviteText: `Zdravo! 😊 Podprite me s svojim glasom na tekmovanju TON Stars! Vsak glas je ključnega pomena in mi pomaga, da se približam zmagi dragocenih nagrad.

🌟 +{{coins}} kovancev kot prvo darilo
🔥 +{{coinsPremium}} kovancev, če imate Telegram Premium

Glasujte za moj profil tukaj: {{url}}

Hvala za vašo podporo! 🌟`,
    joinContest: "Pridruži se k tekmovanju",
    gender: {
      male: "Moški",
      female: "Ženski",
    },
    photos: {
      inReview:
        "Vaše nove fotografije so v pregledu.\nTo lahko traja nekaj časa.",
      rejected:
        "Ena ali več fotografij je bilo zavrnjenih iz naslednjega razloga:\n{{reason}}.\n\nNaložite druge fotografije in poskusite znova.",
    },
  },
  ProfileEdit: {
    title: "Vaš profil",
    name: {
      label: "Vaše ime",
      placeholder: "Vnesite svoje ime",
    },
    instagram: {
      description: "Instagram profil mora biti javen",
    },
    photos: {
      verificationPhotoUploaded:
        "Vaša potrditvena fotografija je bila naložena, nihče je ne bo videl.",
      label: "Vaše fotografije",
      mainPhoto: "Glavna fotografija",
      error: "Ena ali več fotografij ni primernih, prosimo izberite druge.",
      hint: "Izberite svoje najbolj impresivne fotografije, kjer ni drugih ljudi in je vaš obraz jasno viden.",
      inReview:
        "Vaše nove fotografije so v pregledu.\nTo lahko traja nekaj časa.",
      rejected:
        "Ena ali več fotografij je bilo zavrnjenih iz naslednjega razloga:\n{{reason}}.\n\nNaložite druge fotografije in poskusite znova.",
    },
    submit: "Pridruži se k tekmovanju",
    photosUploading: "Nalaganje fotografije...",
    success: "Vaš profil je bil posodobljen",
    errors: {
      name: "Vnesite svoje ime",
      photos: "Prosimo, izberite 3 fotografije",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Glavna fotografija",
    noFace: "Vaš obraz ni zaznan",
    otherPeople: "Fotografija mora pokazati samo vaš obraz",
  },
};
