import {
  Button,
  Caption,
  Input,
  Text,
  Title,
} from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import "./ProfileEdit.css";
import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { ProfileEditPhoto } from "./ProfileEditPhoto";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import InstagramIcon from "assets/instagram.svg?react";
import ClockIcon from "assets/clock.svg?react";
import RejectedIcon from "assets/rejected.svg?react";
import { useUploads } from "utils/uploads/useUploads";
import WebApp from "@twa-dev/sdk";
import LockIcon from "assets/lock.svg?react";
import { VerificationRequired } from "components/Verification/VerificationRequired";
import { useMainButton } from "hooks/useMainButton";

const ProfileEdit_Mutation = gql(`
  mutation ProfileEdit_Mutation($name: String!, $uploadIds: [String!]!, $instagram: String) {
    userProfileSave(name: $name, uploadIds: $uploadIds, instagram: $instagram) {
      id
      name
      instagram
      uploads {
        id
        previewUrl: url(width: 477, height: 848)
      }
      uploadsPending {
        id
        previewUrl: url(width: 477, height: 848)
      }
      photoStatus {
        rejectReasonText
      }
    }
  }
`);

const ProfileEditUser_QueryFragment = gql(`
    fragment ProfileEditUser_QueryFragment on User {
        ...VerificationRequiredUser_QueryFragment
        id
        name
        instagram
        haveVerificationPhoto
        verified
        uploads {
          id
          ...Upload_QueryFragment
          previewUrl: url(width: 477, height: 848)
        }
        uploadsPending {
          id
          ...Upload_QueryFragment
          previewUrl: url(width: 477, height: 848)
        }
        photoStatus {
          rejectReasonText
        }
    }
`);

type ProfileEditProps = {
  onClose?: () => void;
  user?: FragmentType<typeof ProfileEditUser_QueryFragment> | null;
};

export const ProfileEdit = (props: ProfileEditProps) => {
  const user = getFragmentData(ProfileEditUser_QueryFragment, props.user);

  const [userProfileUpdate, { loading }] = useMutation(ProfileEdit_Mutation);

  const { t: g } = useTranslation();
  const { t } = useTranslation("ProfileEdit");
  const { t: v } = useTranslation("Verification");
  const [name, setName] = useState(user?.name || "");
  const [instagram, setInstagram] = useState(user?.instagram || "");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [uploads, setUploads, uploadFile] = useUploads(
    user?.uploadsPending.length ? user?.uploadsPending : user?.uploads
  );

  const selectPhotos = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const onChangeFiles = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.currentTarget.files
        ? [...e.currentTarget.files].filter((_) => /^image\//.test(_.type))
        : [];

      if (!files?.length) return;

      files.splice(0, 3 - uploads.length).forEach((file) => {
        uploadFile(file);
      });
    },
    [uploadFile, uploads.length]
  );

  const photosUploading = useMemo(() => {
    return uploads.some((_) => _.state === "uploading");
  }, [uploads]);

  const uploadIds = useMemo(() => {
    return uploads.filter((_) => _.uploadId).map((_) => _.uploadId) as string[];
  }, [uploads]);

  const [verificationVisible, setVerificationVisible] = useState(false);

  useMainButton({
    text: photosUploading ? t("photosUploading") : g("save"),
    loading,
    disabled: photosUploading,
    onClick: useCallback(() => {
      const errors: ("name" | "photos")[] = [];
      if (!name.length) errors.push("name");
      if (uploadIds.length < 3) errors.push("photos");
      if (errors.length) {
        errors.forEach((_) => {
          toast.error(t(`errors.${_}`));
        });
        return;
      }
      userProfileUpdate({
        variables: {
          name,
          uploadIds,
          instagram,
        },
      }).then(() => {
        toast.success(t("success"));
        props.onClose?.();
      });
    }, [instagram, name, props, t, uploadIds, userProfileUpdate]),
  });

  return (
    <div className="profile-edit">
      <Title className="profile-edit-title">{t("title")}</Title>
      {!user?.verified && user?.haveVerificationPhoto && (
        <div className="profile-edit-photos-review profile-edit-photos-review-verification">
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  gap: 6,
                }}
              >
                <LockIcon style={{ marginTop: -3 }} width={16} height={16} />{" "}
                {v("title")}
              </div>
              <div>{t("photos.verificationPhotoUploaded")}</div>
            </div>
            <Button
              size="s"
              mode="gray"
              onClick={() => setVerificationVisible(true)}
            >
              {g("retry")}
            </Button>
          </div>
        </div>
      )}
      <div className="profile-edit-section">
        <Caption className="profile-edit-caption" caps level="1" weight="3">
          {t("name.label")}
        </Caption>
        <Input
          placeholder={t("name.placeholder")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="profile-edit-section">
        <Caption className="profile-edit-caption" caps level="1" weight="3">
          Instagram
        </Caption>
        <Input
          className="profile-edit-instagram"
          value={instagram}
          onChange={(e) => {
            setInstagram(extractIgUsername(e.target.value));
          }}
          before={
            <div className="profile-edit-instagram-before">
              <InstagramIcon width={20} height={20} />
              <span>instagram.com/</span>
            </div>
          }
        />
        <Caption style={{ marginTop: 8, paddingLeft: 16 }} level="1" weight="3">
          {t("instagram.description")}
        </Caption>
      </div>

      <div className="profile-edit-section">
        <Caption className="profile-edit-caption" caps level="1" weight="3">
          {t("photos.label")} ({uploads.length}/3)
        </Caption>
        <input
          ref={fileInputRef}
          type="file"
          id="fileInput"
          accept="image/*"
          style={{ display: "none" }}
          multiple={WebApp.platform !== "android"}
          max={3}
          maxLength={3}
          onChange={onChangeFiles}
        />
        {verificationVisible && (
          <VerificationRequired
            user={user}
            onClose={() => setVerificationVisible(false)}
          />
        )}
        {user?.photoStatus?.rejectReasonText ? (
          <div className="profile-edit-photos-review">
            <RejectedIcon width={24} height={24} />
            <div>
              {t("photos.rejected", {
                reason: user?.photoStatus.rejectReasonText,
              })}
            </div>
          </div>
        ) : (
          !!user?.uploadsPending.length && (
            <div className="profile-edit-photos-review">
              <ClockIcon width={18} height={18} />
              <div>{t("photos.inReview")}</div>
            </div>
          )
        )}
        <div className="profile-edit-photos">
          {[0, 1, 2].map((key) => (
            <ProfileEditPhoto
              key={uploads[key]?.id || key}
              onSelect={selectPhotos}
              main={!key}
              onRemove={(id) =>
                setUploads([...uploads.filter((_) => _.id !== id)])
              }
              onSetMain={(id) => {
                const index = uploads.findIndex((_) => _.id === id);
                if (index <= 0) return;

                const [photo] = uploads.splice(index, 1);
                uploads.unshift(photo);

                setUploads([...uploads]);
              }}
              {...uploads[key]}
            />
          ))}
        </div>
      </div>
      <Text className="profile-edit-hint">{t("photos.hint")}</Text>
    </div>
  );
};

const extractIgUsername = (input: string) => {
  const rx =
    /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?([\w\\.]+)\/?/;
  const match = rx.exec(input);

  return match?.[1] || "";
};
