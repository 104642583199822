import { ReactNode, useEffect, useMemo } from "react";
import "./Modal.css";
import WebApp from "@twa-dev/sdk";
import CloseIcon from "./icons/close.svg?react";
import { Button, Text, Title } from "@telegram-apps/telegram-ui";
import CoinIcon from "assets/coin.svg?react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { addModal, removeModal, selectModalStack } from "store/settings";
import { dispatch } from "store";
import { nanoid } from "@reduxjs/toolkit";

type ModalButton = {
  name: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  href?: string;
};

type ModalProps = {
  children?: ReactNode;
  fullscreen?: boolean;
  onClose?: () => void;
  image?: string | null;
  title?: string;
  description?: string | null;
  button?: ModalButton;
  reward?: number;
  mainButton?: ModalButton;
  footerText?: string;
};

export const Modal = (props: ModalProps) => {
  const { i18n } = useTranslation();

  const id = useMemo(() => nanoid(), []);
  const modalStack = useSelector(selectModalStack);
  const lastModal = useMemo(
    () => modalStack[modalStack.length - 1],
    [modalStack]
  );

  const isActive = useMemo(() => id === lastModal, [id, lastModal]);

  useEffect(() => {
    dispatch(addModal(id));
    return () => {
      dispatch(removeModal(id));
    };
  }, [id]);

  useEffect(() => {
    if (!isActive) return;

    WebApp.BackButton.show();

    return () => {
      WebApp.BackButton.hide();
    };
  }, [isActive, modalStack.length]);

  useEffect(() => {
    if (!isActive) return;
    props.onClose && WebApp.BackButton.onClick(props.onClose);
    document.body.style.overflow = "hidden";

    return () => {
      props.onClose && WebApp.BackButton.offClick(props.onClose);
      document.body.style.overflow = "";
    };
  }, [isActive, props.onClose]);

  const modal = (
    <div className="modal">
      <div className="modal-overlay" onClick={props.onClose} />
      {props.fullscreen ? (
        props.children
      ) : (
        <div className="modal-container">
          <CloseIcon
            className="modal-close-icon"
            width={24}
            height={24}
            onClick={props.onClose}
          />
          <div className="modal-wrap">
            {!!props.image && <img className="modal-icon" src={props.image} />}
            {!!props.title && (
              <Title className="modal-title" level="1" weight="2">
                {props.title}
              </Title>
            )}
            <div className="modal-wrap2">
              {!!props.description && (
                <Text className="modal-description">{props.description}</Text>
              )}
              {props.button && (
                <a
                  href={props.button.href || undefined}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button
                    mode="filled"
                    size="m"
                    {...props.button}
                    target="_blank"
                    href={props.button.href || undefined}
                  >
                    {props.button.name}
                  </Button>
                </a>
              )}
            </div>
            {props.reward && (
              <div className="modal-reward">
                <CoinIcon width={24} height={24} /> +
                {Intl.NumberFormat(i18n.language).format(props.reward)}
              </div>
            )}
            {props.children}
            {props.mainButton && (
              <a
                href={props.mainButton.href || undefined}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button
                  style={{ marginTop: 16 }}
                  mode="filled"
                  size="l"
                  stretched
                  target="_blank"
                  {...props.mainButton}
                  href={props.mainButton.href || undefined}
                >
                  {props.mainButton.name}
                </Button>
              </a>
            )}
            {!!props.footerText && (
              <div className="modal-footer-text">{props.footerText}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const el = document.querySelector("#root > div");

  if (!el) return null;

  return createPortal(modal, el);
};
