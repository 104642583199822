import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import WebApp from "@twa-dev/sdk";
import "@telegram-apps/telegram-ui/dist/styles.css";
import { AppRoot } from "@telegram-apps/telegram-ui";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { Provider } from "react-redux";
import { persistor, store } from "store";
import { PersistGate } from "redux-persist/integration/react";
import { ApolloProvider } from "@apollo/client";
import { appoloClient } from "utils/appolo.ts";
import { Toaster } from "react-hot-toast";

WebApp.ready();
WebApp.expand();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ApolloProvider client={appoloClient}>
    <AppRoot platform="ios">
      <TonConnectUIProvider manifestUrl="https://tonstars.app/tonconnect-manifest.json">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
        <Toaster toastOptions={{ className: "toast" }} />
      </TonConnectUIProvider>
    </AppRoot>
  </ApolloProvider>
);
