import { Avatar, Cell } from "@telegram-apps/telegram-ui";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useTranslation } from "react-i18next";
import CoinIcon from "../../assets/coin.svg?react";
import { useState } from "react";
import { Modal } from "components/Modal/Modal";
import { Profile, ProfileProps } from "components/Profile/Profile";
import { ProfileVoteProps } from "components/ProfileVote/ProfileVote";

const FriendsUser_QueryFragment = gql(`
    fragment FriendsUser_QueryFragment on User {
        id
        name
        coins
        coinsForRef
        uploads {
            id
            faceUrl: url(size: 200, face: true)
        }
        ...ProfileUser_QueryFragment
    }
`);

type FriendsUserProps = {
  user: FragmentType<typeof FriendsUser_QueryFragment>;
  shareUrl: string;
  voteConfig: ProfileVoteProps["voteConfig"];
  bonus: ProfileProps["bonus"];
};

export const FriendsUser = (props: FriendsUserProps) => {
  const { i18n } = useTranslation("Friends");
  const user = getFragmentData(FriendsUser_QueryFragment, props.user);

  const [profileVisible, setProfileVisible] = useState(false);

  return (
    <>
      <Cell
        style={{ padding: "8px 16px" }}
        className="friends-cell"
        before={
          <Avatar
            src={user.uploads[0]?.faceUrl || ""}
            size={48}
            acronym={user.name?.[0].toUpperCase() || ""}
          />
        }
        onClick={() => setProfileVisible(true)}
        description={
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              fontSize: 14,
            }}
          >
            <CoinIcon width={14} height={14} />
            <b style={{ color: "var(--tgui--text_color)" }}>
              {Intl.NumberFormat(i18n.language, {
                notation: "compact",
              }).format(user.coins)}
            </b>
          </div>
        }
        after={
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}
          >
            <b style={{ color: "var(--tgui--text_color)" }}>
              +
              {Intl.NumberFormat(i18n.language, {
                notation: "compact",
              }).format(user.coinsForRef)}
            </b>
            <CoinIcon width={18} height={18} />
          </div>
        }
      >
        {user.name}
      </Cell>
      {profileVisible && (
        <Modal fullscreen onClose={() => setProfileVisible(false)}>
          <Profile
            user={user}
            voteConfig={props.voteConfig}
            bonus={props.bonus}
          />
        </Modal>
      )}
    </>
  );
};
