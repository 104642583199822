export default {
  g: {
    flag: "🇬🇷",
    langName: "Greek",
    langNameNative: "Ελληνικά",
    continue: "Συνέχεια",
    save: "Αποθήκευση",
    retry: "Δοκιμάστε ξανά",
    loading: "Φόρτωση...",
    genderMany: {
      all: "Όλοι",
      male: "Άνδρες",
      female: "Γυναίκες",
    },
  },
  CreateAccount: {
    slogan: "Το μέρος όπου τα όνειρα γίνονται νίκες!",
    gender: {
      title: "Γεια σου {{name}}, Είσαι...",
      male: "Άνδρας",
      female: "Γυναίκα",
    },
  },
  Settings: {
    title: "Ρυθμίσεις",
    language: "Γλώσσα",
    auto: "Αυτόματο",
    country: "Χώρα",
    hideProfile: {
      label: "Απόκρυψη προφίλ",
      description:
        "Το προφίλ σας θα είναι κρυφό από όλους, σαν να το είχατε διαγράψει.",
    },
    profileHidden:
      "Το προφίλ σας είναι κρυφό και δεν είναι ορατό σε άλλους χρήστες.",
  },
  Tabs: {
    vote: "Ψήφος",
    contest: "Διαγωνισμός",
    friends: "Φίλοι",
    earn: "Κερδίστε",
    wallet: "Πορτοφόλι",
  },
  Vote: {
    whoIsBetter: "Ποιον συμπαθείς περισσότερο;",
    votingForLeader: "Ψηφίζοντας τον ηγέτη",
    noUsers: {
      title: "🚀 Αυτό ήταν για σήμερα",
      description:
        "Προσκαλέστε φίλους για να συνεχίσετε να ψηφίζετε και να κερδίζετε νομίσματα",
      inviteFriends: "Προσκαλέστε φίλους",
    },
  },
  Leaders: {
    rewards: "Ανταμοιβές",
    comingSoon: "Σύντομα",
    prizePool: "Ταμείο Βραβείων",
    moreInfo: "Περισσότερες πληροφορίες",
    contest: "Διαγωνισμός",
    richest: "Οι πλουσιότεροι",
    sponsors: "Χορηγοί",
    description: {
      likes:
        "Προσκαλέστε φίλους και μοιραστείτε το προφίλ σας για να συγκεντρώσετε περισσότερες ψήφους και να αυξήσετε τις πιθανότητες να κερδίσετε.",
      coins:
        "Ολοκληρώστε τις αποστολές, ψηφίστε για άλλους συμμετέχοντες και προσκαλέστε φίλους για να κερδίσετε περισσότερα νομίσματα και να ενισχύσετε τη θέση σας.",
      stars:
        "Ψηφίστε για προφίλ στη σελίδα προφίλ για να λάβετε αστέρια και να ανεβείτε στις κατατάξεις.",
    },
  },
  PrizePool: {
    text: `<b>Βραβευμένες θέσεις</b>:
<b>1η θέση</b>: 50% USD και 10,000 νομίσματα
<b>2η θέση</b>: 30% USD και 3,000 νομίσματα
<b>3η θέση</b>: 20% USD και 2,000 νομίσματα
<b>4η-10η θέσεις</b>: 1,000 νομίσματα η καθεμία
<b>11η-50η θέσεις</b>: 500 νομίσματα η καθεμία
<b>51η-100η θέσεις</b>: 250 νομίσματα η καθεμία

<b>Το ταμείο βραβείων</b> αυξάνεται όταν κάποιος ψηφίζει για εσάς στη σελίδα του προφίλ σας.

<b>Όροι</b>:
Όλα τα προφίλ που ανεβάζουν φωτογραφία και ολοκληρώνουν τις αποστολές είναι επιλέξιμα για να συμμετάσχουν στον διαγωνισμό.

<b>Ημερομηνία λήξης</b>:
{{endDate}}

<b>Πληρωμή ανταμοιβής</b>:
Μπορείτε να λάβετε την ανταμοιβή σας σε <b>TON</b> — το κρυπτονόμισμα που αναπτύχθηκε από το Telegram.`,
  },
  LeadersItem: {
    join: "Συμμετοχή",
  },
  Friends: {
    title: "Προσκαλέστε φίλους!",
    description: "Εσείς και ο φίλος σας θα λάβετε μπόνους",
    bonus: {
      profit: "<coins>+{{count}}</coins> για εσάς και τον φίλο σας",
      invite: {
        title: "Προσκαλέστε φίλο",
      },
      inviteWithPremium: {
        title: "Προσκαλέστε φίλο",
        description: "με Telegram Premium",
      },
    },
    list: {
      title: "Λίστα των φίλων σας",
    },
    inviteFriend: "Προσκαλέστε φίλο",
    inviteText: `Ελάτε μαζί μου, ψηφίστε για τους πιο όμορφους άντρες και γυναίκες και κερδίστε πολύτιμα βραβεία!
  
  🌟 +{{coins}} νομίσματα ως πρώτο δώρο
  🔥 +{{coinsPremium}} νομίσματα αν έχετε Telegram Premium
  
  🏆 Κερδίστε νομίσματα για κάθε ψήφο και επιλέξτε τους πιο ελκυστικούς συμμετέχοντες. Θέλετε περισσότερα; Συμμετάσχετε κι εσείς και ανταγωνιστείτε για πολύτιμα βραβεία!
  
  Μην χάσετε την ευκαιρία σας, γίνετε μέρος της κοινότητας μας και κερδίστε!`,
    linkCopied: "Ο σύνδεσμος αντιγράφηκε",
  },
  Earn: {
    title: "Κερδίστε περισσότερα νομίσματα",
    tasksList: "Λίστα εργασιών",
    check: "Έλεγχος",
    inReview: "Σε αναθεώρηση",
    subscribe: "Εγγραφείτε",
    participate: "Συμμετοχή",
    invite: "Προσκαλέστε",
    verify: "Επαλήθευση",
    taskCompleted: "Η εργασία ολοκληρώθηκε",
  },
  Wallet: {
    title: "Λάβετε ανταμοιβές σε TON",
    description:
      "Ολοκληρώστε προκλήσεις για να κερδίσετε TON, ένα κρυπτονόμισμα που αναπτύχθηκε από το Telegram",
    rewards: "Ανταμοιβές",
    withdrawal: "Ανάληψη",
    walletConnected: "Το πορτοφόλι σας TON είναι συνδεδεμένο",
    walletDescription: "Περισσότερες ευκαιρίες θα είναι σύντομα διαθέσιμες",
  },
  VerificationRequired: {
    title: "Απαιτείται Επαλήθευση",
    description:
      "Για να συμμετάσχετε στον διαγωνισμό, πρέπει να επαληθεύσετε τον λογαριασμό σας.",
    bdate: {
      title: "Ημερομηνία γέννησης",
    },
  },
  Verification: {
    title: "Επαλήθευση",
    hint: "Κρατήστε το πρόσωπό σας μέσα στο οβάλ",
    secureHint: "Κανείς δεν θα δει αυτή τη φωτογραφία",
    success: "Η επαλήθευση ολοκληρώθηκε με επιτυχία",
    goToApp: "Μετάβαση στην εφαρμογή",
    verification: "Επαλήθευση...",
    cameraAccessRequired:
      "Παρακαλώ επιτρέψτε πρόσβαση στην κάμερα για να συνεχίσετε",
    gettingAccessToCamer: "Απόκτηση πρόσβασης στην κάμερα...",
    cameraAccessNotAllowed: "Ο χρήστης απέρριψε την πρόσβαση στην κάμερα.",
    cameraAccessError: "Σφάλμα πρόσβασης στην κάμερα",
    loadingHint: "Αυτό μπορεί να πάρει λίγο χρόνο. Παρακαλώ περιμένετε.",
  },
  Profile: {
    vote: "Ψηφίστε",
    askVote: "Ζητήστε να ψηφίσουν",
    inviteText: `Γεια! 😊 Παρακαλώ, υποστήριξέ με με την ψήφο σου στον διαγωνισμό TON Stars! Κάθε ψήφος είναι κρίσιμη και με βοηθάει να έρθω πιο κοντά στην κερδοφορία πολύτιμων βραβείων.

🌟 +{{coins}} νομίσματα ως πρώτο δώρο
🔥 +{{coinsPremium}} νομίσματα αν έχεις Telegram Premium

Ψήφισε το προφίλ μου εδώ: {{url}}

Ευχαριστώ για την υποστήριξή σου! 🌟`,
    joinContest: "Συμμετοχή στον διαγωνισμό",
    gender: {
      male: "Άνδρας",
      female: "Γυναίκα",
    },
    photos: {
      inReview:
        "Οι νέες φωτογραφίες σας είναι υπό εξέταση.\nΑυτό μπορεί να πάρει λίγο χρόνο.",
      rejected:
        "Μία ή περισσότερες φωτογραφίες απορρίφθηκαν για τον εξής λόγο:\n{{reason}}.\n\nΠαρακαλούμε ανεβάστε διαφορετικές φωτογραφίες και δοκιμάστε ξανά.",
    },
  },
  ProfileEdit: {
    title: "Το προφίλ σας",
    name: {
      label: "Το όνομά σας",
      placeholder: "Εισάγετε το όνομά σας",
    },
    instagram: {
      description: "Το προφίλ στο Instagram πρέπει να είναι δημόσιο",
    },
    photos: {
      verificationPhotoUploaded:
        "Η φωτογραφία επαλήθευσής σας έχει μεταφορτωθεί, κανείς δεν θα τη δει.",
      label: "Οι φωτογραφίες σας",
      mainPhoto: "Κύρια φωτογραφία",
      error:
        "Μία ή περισσότερες φωτογραφίες δεν είναι κατάλληλες, παρακαλώ επιλέξτε άλλες.",
      hint: "Επιλέξτε τις πιο εντυπωσιακές φωτογραφίες σας, όπου δεν υπάρχουν άλλοι άνθρωποι και το πρόσωπό σας είναι καθαρά και εκφραστικά ορατό.",
      inReview:
        "Οι νέες φωτογραφίες σας είναι υπό εξέταση.\nΑυτό μπορεί να πάρει λίγο χρόνο.",
      rejected:
        "Μία ή περισσότερες φωτογραφίες απορρίφθηκαν για τον εξής λόγο:\n{{reason}}.\n\nΠαρακαλούμε ανεβάστε διαφορετικές φωτογραφίες και δοκιμάστε ξανά.",
    },
    submit: "Συμμετοχή στον διαγωνισμό",
    photosUploading: "Φόρτωση φωτογραφίας...",
    success: "Το προφίλ σας ενημερώθηκε",
    errors: {
      name: "Παρακαλώ δώστε το όνομά σας",
      photos: "Παρακαλώ επιλέξτε 3 φωτογραφίες",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Κύρια φωτογραφία",
    noFace: "Δεν ανιχνεύθηκε το πρόσωπό σας",
    otherPeople: "Η φωτογραφία πρέπει να δείχνει μόνο το πρόσωπό σας",
  },
};
