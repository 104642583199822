import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const startDate = moment("2024-08-15 00:00:00");
const endDate = moment("2024-12-31 23:59:59");
const startAmount = 1000;
const endAmount = 1500;
const amountDiff = endAmount - startAmount;

export const LeadersMoney = () => {
  const { i18n } = useTranslation();
  const [now, setNow] = useState(moment());
  const p = Math.min(
    now.diff(startDate, "s") / endDate.diff(startDate, "s"),
    1
  );

  useEffect(() => {
    const tmr = setInterval(() => {
      setNow(moment());
    }, 1000);
    return () => {
      clearInterval(tmr);
    };
  });

  return (
    <div>
      {Intl.NumberFormat(i18n.language, {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 4,
      }).format(startAmount + amountDiff * p)}
    </div>
  );
};
