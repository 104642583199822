import { Caption, List, Select, Switch } from "@telegram-apps/telegram-ui";
import "./Settings.css";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useCallback, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { langsList } from "utils/i18n/resources";
import config from "config";
import { useMainButton } from "hooks/useMainButton";
import countries from "data/countries.json";

const Settings_Mutation = gql(`
  mutation Settings_Mutation(
    $data: UserSaveInput!
  ) {
    userSave(data: $data) {
      id
      appLang
      hidden
      countryCode
      countryCodeAuto
      detectedCountry
    }
  }
`);

const Settings_QueryFragment = gql(`
  fragment Settings_QueryFragment on Query {
    user {
      id
      appLang
      language_code
      hidden
      countryCode
      countryCodeAuto
      detectedCountry
    }
  }
`);

type CreateAccountProps = {
  query: FragmentType<typeof Settings_QueryFragment>;
  onClose?: () => void;
};

export const Settings = (props: CreateAccountProps) => {
  const query = getFragmentData(Settings_QueryFragment, props.query);
  const [save, { loading }] = useMutation(Settings_Mutation);

  const { t } = useTranslation("Settings");
  const { t: g } = useTranslation();

  const [appLang, setAppLang] = useState(query.user?.appLang);
  const [countryCode, setCountryCode] = useState(query.user?.countryCode);
  const [countryCodeAuto, setCountryCodeAuto] = useState(
    query.user?.countryCodeAuto
  );
  const [hidden, setHidden] = useState(!!query.user?.hidden);

  const autoLang = useMemo(() => {
    return langsList.find(
      (_) => _.code === (query.user?.language_code || config.defaultLocale)
    );
  }, [query.user?.language_code]);

  const langs = useMemo(
    () => langsList.sort((a, b) => a.nameNative.localeCompare(b.nameNative)),
    []
  );

  useMainButton({
    text: g("save"),
    loading,
    onClick: useCallback(() => {
      save({
        variables: {
          data: { appLang, hidden, countryCode, countryCodeAuto },
        },
      }).then(props.onClose);
    }, [appLang, countryCode, countryCodeAuto, hidden, props.onClose, save]),
  });

  const detectedCountry = useMemo(() => {
    return countries.find((_) => _.code === query.user?.detectedCountry);
  }, [query.user?.detectedCountry]);

  return (
    <div className="settings-container">
      <List>
        <Caption className="settings-caption" caps level="1" weight="3">
          {t("language")}
        </Caption>
        <Select
          className="settings-select"
          onChange={(_) => setAppLang(_.target.value)}
          defaultValue={appLang || ""}
          value={appLang || ""}
        >
          <option value="auto">
            {autoLang?.flag || "🌐"} {t("auto")} ({autoLang?.code})
          </option>
          {langs.map((_) => (
            <option key={_.code} value={_.code}>
              {_.flag} {_.nameNative} ({_.code})
            </option>
          ))}
        </Select>

        <Caption className="settings-caption" caps level="1" weight="3">
          {t("country")}
        </Caption>
        <Select
          className="settings-select"
          onChange={(_) => {
            if (_.target.value === "auto") {
              setCountryCode(query.user?.detectedCountry);
              setCountryCodeAuto(true);
            } else {
              setCountryCode(_.target.value);
              setCountryCodeAuto(false);
            }
          }}
          defaultValue={countryCodeAuto ? "auto" : countryCode || ""}
          value={countryCodeAuto ? "auto" : countryCode || ""}
        >
          <option value="auto">
            {detectedCountry?.flag || "🌐"} {t("auto")} (
            {query.user?.detectedCountry})
          </option>
          {countries.map((_) => (
            <option key={_.code} value={_.code}>
              {_.flag} {_.name} ({_.code})
            </option>
          ))}
        </Select>

        <div className="settings-switch">
          <div className="settings-switch-text">{t("hideProfile.label")}</div>
          <Switch checked={hidden} onChange={() => setHidden(!hidden)} />
        </div>
        <div className="settings-item-description">
          {t("hideProfile.description")}
        </div>
      </List>
    </div>
  );
};
