import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Verification.css";
import { Button, Spinner, Text, Title } from "@telegram-apps/telegram-ui";
import { useTranslation } from "react-i18next";
import CameraIcon from "assets/camera.svg?react";
import InfoIcon from "assets/info.svg?react";
import ErrorIcon from "./icons/error.svg?react";
import { useMutation } from "@apollo/client";
import { gql } from "__generated__";
import { Upload } from "utils/uploads/Upload";
import LockIcon from "assets/lock.svg?react";
import { useMainButton } from "hooks/useMainButton";

const Verification_Mutation = gql(`
  mutation Verification_Mutation(
    $verificationUploadId: String!
  ) {
    userVerify(verificationUploadId: $verificationUploadId) {
      id
      haveVerificationPhoto
      verified
    }
  }
`);

type VerificationProps = {
  onComplete: () => void;
};
export const Verification = (props: VerificationProps) => {
  const { t, i18n } = useTranslation("Verification");
  const { t: g } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const [verify] = useMutation(Verification_Mutation);
  const [error, setError] = useState<string>();

  const lang = useMemo(() => {
    return getQueryParams().lang;
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [cameraGranted, setCameraGranted] = useState(false);
  const [cameraLoading, setCameraLoading] = useState(true);
  const streamRef = useRef<MediaStream>();
  const webCam = useCallback(async () => {
    const cameraOptions: MediaStreamConstraints = {
      audio: false,
      video: {
        facingMode: {
          ideal: "user",
        },
      },
    };
    streamRef.current = await navigator.mediaDevices.getUserMedia(
      cameraOptions
    );

    // streamRef.current.getTracks().forEach((track) => track.stop());

    setCameraLoading(false);
    setCameraGranted(true);

    const video = videoRef.current;

    if (!video) return;

    video.srcObject = streamRef.current;
    video.play();
  }, []);

  const [cameraError, setCameraError] = useState<Error>();
  const [loading, setLoading] = useState(true);
  const init = useCallback(async () => {
    setCameraError(undefined);
    setLoading(false);
    await webCam().catch((e: Error) => {
      setCameraLoading(false);
      setCameraError(e);
    });
  }, [webCam]);

  useEffect(() => {
    init();
  }, [init]);

  const uploadRef = useRef<Upload>();

  const upload = useCallback(() => {
    setLoading(true);
    setError(undefined);
    uploadRef.current
      ?.start()
      .then((response) => {
        if (!response?.data?.uploadUpdate) return;

        return verify({
          variables: {
            verificationUploadId: response.data.uploadUpdate.id,
          },
        }).then(({ data }) => {
          data?.userVerify && props.onComplete();
        });
      })
      .catch((e: Error) => {
        setError(e.toString());
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props, verify]);

  useMainButton({
    text: g("continue"),
    loading,
    visible: !loading && !error && !cameraError && cameraGranted,
    onClick: useCallback(() => {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const context = canvas?.getContext("2d");

      if (!canvas || !video || !context) return;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      context.translate(canvas.width, 0);
      context.scale(-1, 1);
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      streamRef.current?.getTracks().forEach((_) => _.stop());

      setLoading(true);
      canvas.toBlob((blob) => {
        if (!blob) return;
        uploadRef.current = new Upload(blob, "verification.png");
        upload();
      });
    }, [upload]),
  });

  return (
    <div className="verification">
      <Title className="verification-title" level="1" weight="2">
        {t("title")}
      </Title>
      <div className="verification-container">
        {error ? (
          <div className="verification-body">
            <ErrorIcon style={{ opacity: 0.7 }} width={32} height={32} />
            <Text
              style={{ opacity: 0.5 }}
              className="verification-body-message"
            >
              {error}
            </Text>
            <Button onClick={upload}>{g("retry")}</Button>
          </div>
        ) : loading ? (
          <div className="verification-body">
            <Spinner size="l" />
            <Text className="verification-body-message">{g("loading")}</Text>
            <Text className="verification-body-message">
              {t("loadingHint")}
            </Text>
          </div>
        ) : (
          (!cameraGranted || cameraError) && (
            <div className="verification-body">
              {!cameraGranted && (
                <>
                  <CameraIcon className="verification-body-icon" />
                  <Text
                    style={{ opacity: 0.5 }}
                    className="verification-body-message"
                  >
                    {t("cameraAccessRequired")}
                  </Text>
                  {cameraLoading && (
                    <>
                      <Spinner size="l" />
                      <Text
                        style={{ opacity: 0.5 }}
                        className="verification-body-message"
                      >
                        {t("gettingAccessToCamer")}
                      </Text>
                    </>
                  )}
                </>
              )}
              {cameraError && (
                <>
                  <Text className="verification-body-message">
                    <ErrorIcon
                      style={{ marginRight: 8, marginBottom: -1 }}
                      width={16}
                      height={16}
                    />
                    {cameraError.name === "NotAllowedError"
                      ? t("cameraAccessNotAllowed")
                      : t("cameraAccessError") + ": " + cameraError.message}
                  </Text>
                  <Button onClick={init}>{g("retry")}</Button>
                </>
              )}
            </div>
          )
        )}
        <div className="verification-video-container">
          <video ref={videoRef} playsInline autoPlay></video>
          <div className="verification-oval-container">
            <div className="verification-oval" />
          </div>
          <canvas style={{ display: "none" }} ref={canvasRef}></canvas>
          <div className="verification-hints">
            <div className="verification-hints-item">
              <InfoIcon style={{ marginRight: 8 }} width={16} height={16} />
              {t("hint")}
            </div>
          </div>
          <div className="verification-photo-description">
            <LockIcon width={16} height={16} />
            {t("secureHint")}
          </div>
        </div>
      </div>
    </div>
  );
};

function getQueryParams(): Record<string, string> {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params: Record<string, string> = {};

  urlParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
}
