import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources";
import config from "config";
import WebApp from "@twa-dev/sdk";

export const defaultNS = "g";
export const fallbackLng = config.defaultLocale;

i18n.use(initReactI18next).init({
  lng:
    localStorage.getItem("lang") ||
    WebApp.initDataUnsafe.user?.language_code ||
    undefined,
  resources,
  fallbackLng,
  defaultNS,
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});
