export default {
  g: {
    flag: "🇺🇦",
    langName: "Ukrainian",
    langNameNative: "Українська",
    continue: "Продовжити",
    save: "Зберегти",
    retry: "Спробувати знову",
    loading: "Завантаження...",
    genderMany: {
      all: "Усі",
      male: "Чоловіки",
      female: "Жінки",
    },
  },
  CreateAccount: {
    slogan: "Місце, де мрії стають перемогами!",
    gender: {
      title: "Привіт {{name}}, Ви...",
      male: "Чоловік",
      female: "Жінка",
    },
  },
  Settings: {
    title: "Налаштування",
    language: "Мова",
    auto: "Автоматично",
    country: "Країна",
    hideProfile: {
      label: "Приховати профіль",
      description:
        "Ваш профіль буде приховано від усіх, ніби ви його видалили.",
    },
    profileHidden:
      "Ваш профіль приховано і він невидимий для інших користувачів.",
  },
  Tabs: {
    vote: "Голосувати",
    contest: "Конкурс",
    friends: "Друзі",
    earn: "Заробити",
    wallet: "Гаманець",
  },
  Vote: {
    whoIsBetter: "Кого ви більше любите?",
    votingForLeader: "Голосування за лідера",
    noUsers: {
      title: "🚀 Це все на сьогодні",
      description:
        "Запросіть друзів, щоб продовжити голосування і заробляти монети",
      inviteFriends: "Запросити друзів",
    },
  },
  Leaders: {
    rewards: "Нагороди",
    comingSoon: "Скоро",
    prizePool: "Призовий фонд",
    moreInfo: "Детальніше",
    contest: "Конкурс",
    richest: "Найбагатші",
    sponsors: "Спонсори",
    description: {
      likes:
        "Запрошуйте друзів та діліться своїм профілем, щоб зібрати більше голосів та збільшити шанси на перемогу.",
      coins:
        "Виконуйте завдання, голосуйте за інших учасників та запрошуйте друзів, щоб заробити більше монет та зміцнити свої позиції.",
      stars:
        "Голосуйте за профілі на сторінці профілю, щоб отримувати зірки та підніматися в рейтингу.",
    },
  },
  PrizePool: {
    text: `<b>Призові місця</b>:
<b>1-е місце</b>: 50% USD і 10.000 монет
<b>2-е місце</b>: 30% USD і 3.000 монет
<b>3-е місце</b>: 20% USD і 2.000 монет
<b>4-е-10-е місця</b>: по 1.000 монет кожне
<b>11-е-50-е місця</b>: по 500 монет кожне
<b>51-е-100-е місця</b>: по 250 монет кожне

<b>Призовий фонд</b> збільшується, коли хтось голосує за вас на сторінці вашого профілю.

<b>Умови</b>:
Усі профілі, які завантажують фото і виконують завдання, мають право брати участь у конкурсі.

<b>Дата завершення</b>:
{{endDate}}

<b>Виплата нагород</b>:
Ви можете отримати свою нагороду у вигляді <b>TON</b> — криптовалюти, розробленої Telegram.`,
  },
  LeadersItem: {
    join: "Приєднатися",
  },
  Friends: {
    title: "Запросіть друзів!",
    description: "Ви та ваш друг отримаєте бонуси",
    bonus: {
      profit: "<coins>+{{count}}</coins> для вас і вашого друга",
      invite: {
        title: "Запросити друга",
      },
      inviteWithPremium: {
        title: "Запросити друга",
        description: "з Telegram Premium",
      },
    },
    list: {
      title: "Список ваших друзів",
    },
    inviteFriend: "Запросити друга",
    inviteText: `Приєднуйтесь до мене, голосуйте за найгарніших хлопців та дівчат і отримуйте цінні призи!
  
  🌟 +{{coins}} монет як перший подарунок
  🔥 +{{coinsPremium}} монет, якщо у вас є Telegram Premium
  
  🏆 Заробляйте монети за кожен голос і обирайте найпривабливіших учасників. Хочете більше? Учасуйте самі та змагайтеся за цінні призи!
  
  Не втрачайте свій шанс, станьте частиною нашої зіркової спільноти та вигравайте!`,
    linkCopied: "Посилання скопійовано",
  },
  Earn: {
    title: "Заробляйте більше монет",
    tasksList: "Список завдань",
    check: "Перевірити",
    inReview: "На розгляді",
    subscribe: "Підписатися",
    participate: "Учасувати",
    invite: "Запросити",
    verify: "Підтвердити",
    taskCompleted: "Завдання виконано",
  },
  Wallet: {
    title: "Отримуйте нагороди в TON",
    description:
      "Виконуйте завдання, щоб заробити TON, криптовалюту, розроблену Telegram",
    rewards: "Нагороди",
    withdrawal: "Виведення",
    walletConnected: "Ваш гаманець TON підключений",
    walletDescription: "Незабаром буде доступно більше можливостей",
  },
  VerificationRequired: {
    title: "Потрібна Підтвердження",
    description:
      "Щоб брати участь у конкурсі, вам потрібно підтвердити свій акаунт.",
    bdate: {
      title: "Ваша дата народження",
    },
  },
  Verification: {
    title: "Підтвердження",
    hint: "Тримайте обличчя всередині овалу",
    secureHint: "Ніхто не побачить це фото",
    success: "Ви успішно пройшли підтвердження",
    goToApp: "Перейти до додатку",
    verification: "Підтвердження...",
    cameraAccessRequired:
      "Будь ласка, дозвольте доступ до камери, щоб продовжити",
    gettingAccessToCamer: "Отримання доступу до камери...",
    cameraAccessNotAllowed: "Доступ до камери заборонено користувачем.",
    cameraAccessError: "Помилка доступу до камери",
    loadingHint: "Це може зайняти деякий час. Будь ласка, будьте терплячими.",
  },
  Profile: {
    vote: "Голосувати",
    askVote: "Попросити голосувати",
    inviteText: `Привіт! 😊 Будь ласка, підтримайте мене своїм голосом у конкурсі TON Stars! Кожен голос важливий і допомагає мені наблизитися до виграшу цінних призів.

🌟 +{{coins}} монет як перший подарунок
🔥 +{{coinsPremium}} монет, якщо у вас є Telegram Premium

Голосуйте за мій профіль тут: {{url}}

Дякую за вашу підтримку! 🌟`,
    joinContest: "Приєднатися до конкурсу",
    gender: {
      male: "Чоловічий",
      female: "Жіночий",
    },
    photos: {
      inReview:
        "Ваші нові фотографії на розгляді.\nЦе може зайняти деякий час.",
      rejected:
        "Одна або більше фотографій були відхилені з наступної причини:\n{{reason}}.\n\nБудь ласка, завантажте інші фотографії і спробуйте знову.",
    },
  },
  ProfileEdit: {
    title: "Ваш Профіль",
    name: {
      label: "Ваше Ім'я",
      placeholder: "Введіть своє ім'я",
    },
    instagram: {
      description: "Профіль в Instagram повинен бути відкритим",
    },
    photos: {
      verificationPhotoUploaded:
        "Ваше фото для верифікації завантажено, ніхто його не побачить.",
      label: "Ваші Фотографії",
      mainPhoto: "Головне Фото",
      error:
        "Одна або більше фотографій не підходять, будь ласка, зробіть інший вибір.",
      hint: "Виберіть ваші найвражаючі фотографії, де немає інших людей і ваше обличчя чітко і виразно видно.",
      inReview:
        "Ваші нові фотографії на розгляді.\nЦе може зайняти деякий час.",
      rejected:
        "Одна або більше фотографій були відхилені з наступної причини:\n{{reason}}.\n\nБудь ласка, завантажте інші фотографії і спробуйте знову.",
    },
    submit: "Приєднатися до Конкурсу",
    photosUploading: "Завантаження фото...",
    success: "Ваш профіль був оновлений",
    errors: {
      name: "Вкажіть ваше ім'я",
      photos: "Будь ласка, виберіть 3 фотографії",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Головне фото",
    noFace: "Ваше обличчя не виявлено",
    otherPeople: "На фотографії повинно бути тільки ваше обличчя",
  },
};
