import {
  Cell,
  List,
  Placeholder,
  Section,
  Subheadline,
} from "@telegram-apps/telegram-ui";
import CoinIcon from "../../assets/coin.svg?react";
import { useTranslation } from "react-i18next";
import { gql } from "__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { dispatch } from "store";
import { setTabId } from "store/settings";
import { Modal } from "components/Modal/Modal";
import { Fragment, useState } from "react";
import "./Earn.css";
import toast from "react-hot-toast";
import CheckmarkGreenIcon from "assets/checkmark-green.svg?react";
import { LoadingPlaceholder } from "components/LoadingPlaceholder/LoadingPlaceholder";

const Earn_Query = gql(`
  query Earn_Query {
    tasks {
      id
      image
      localizedTitle
      localizedDescription
      buttonType
      buttonUrl
      reward
      startedReviewAt
      completedAt
      inReview
    }
  }
`);

const Earn_Mutation = gql(`
  mutation Earn_Mutation(
    $taskId: String!
  ) {
    taskComplete(taskId: $taskId) {
      id
      startedReviewAt
      completedAt
      inReview
      updatedUser {
        id
        coins
      }
    }
  }
`);

export const Earn = () => {
  const query = useQuery(Earn_Query);

  const { t, i18n } = useTranslation("Earn");

  const [taskId, setTaskId] = useState<string>();

  const [completeTask, { loading }] = useMutation(Earn_Mutation);

  return (
    <List className="scroll">
      <Placeholder className="earn-placeholder" header={t("title")}>
        <CoinIcon width={94} height={94} />
      </Placeholder>
      <Subheadline style={{ padding: "0 16px" }} level="1" weight="1">
        {t("tasksList")}
      </Subheadline>
      <LoadingPlaceholder {...query}>
        <Section>
          {query.data?.tasks.map((_) => {
            return (
              <Fragment key={_.id}>
                <Cell
                  className="earn-cell"
                  style={{
                    padding: "8px 16px",
                  }}
                  before={
                    _.image ? (
                      <img className="earn-item-image" src={_.image} />
                    ) : (
                      <div className="earn-item-image" />
                    )
                  }
                  after={
                    _.completedAt && (
                      <CheckmarkGreenIcon width={24} height={24} />
                    )
                  }
                  subtitle={
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                      }}
                    >
                      <CoinIcon width={18} height={18} />
                      <b style={{ color: "var(--tgui--text_color)" }}>
                        +{Intl.NumberFormat(i18n.language).format(_.reward)}
                      </b>
                    </div>
                  }
                  onClick={() => {
                    setTaskId(_.id);
                  }}
                >
                  <div className="earn-item-title">{_.localizedTitle}</div>
                </Cell>
                {taskId === _.id && (
                  <Modal
                    onClose={() => setTaskId(undefined)}
                    image={_.image}
                    title={_.localizedTitle}
                    description={_.localizedDescription}
                    button={
                      _.buttonType
                        ? {
                            href: _.buttonUrl?.startsWith("http")
                              ? _.buttonUrl
                              : undefined,
                            name: t(_.buttonType, {
                              defaultValue: _.buttonType,
                            }),
                            onClick: () => {
                              if (!_.buttonUrl) return;
                              if (_.buttonUrl === "friends") {
                                setTaskId(undefined);
                                dispatch(setTabId("friends"));
                              } else if (_.buttonUrl === "contest") {
                                setTaskId(undefined);
                                dispatch(setTabId("leaders"));
                              }
                            },
                          }
                        : undefined
                    }
                    reward={_.reward}
                    mainButton={
                      _.buttonType === "subscribe" && !_.completedAt
                        ? {
                            name: t(_.inReview ? "inReview" : "check"),
                            disabled: _.inReview || false,
                            loading: loading,
                            href: _.buttonUrl?.startsWith("http")
                              ? _.buttonUrl
                              : undefined,
                            onClick: () => {
                              completeTask({
                                variables: {
                                  taskId: _.id,
                                },
                              }).then(({ data }) => {
                                if (
                                  data?.taskComplete?.completedAt &&
                                  !data.taskComplete.inReview
                                ) {
                                  toast.success(t("taskCompleted"));
                                  setTaskId(undefined);
                                }
                              });
                            },
                          }
                        : undefined
                    }
                  />
                )}
              </Fragment>
            );
          })}
        </Section>
      </LoadingPlaceholder>
    </List>
  );
};
