export default {
  g: {
    flag: "🇩🇰",
    langName: "Danish",
    langNameNative: "Dansk",
    continue: "Fortsæt",
    save: "Gem",
    retry: "Prøv igen",
    loading: "Indlæser...",
    genderMany: {
      all: "Alle",
      male: "Mænd",
      female: "Kvinder",
    },
  },
  CreateAccount: {
    slogan: "Stedet, hvor drømme bliver til sejre!",
    gender: {
      title: "Hej {{name}}, Du er...",
      male: "Mand",
      female: "Kvinde",
    },
  },
  Settings: {
    title: "Indstillinger",
    language: "Sprog",
    auto: "Automatisk",
    country: "Land",
    hideProfile: {
      label: "Skjul profil",
      description:
        "Din profil vil blive skjult for alle, som om du havde slettet den.",
    },
    profileHidden: "Din profil er skjult og ikke synlig for andre brugere.",
  },
  Tabs: {
    vote: "Stem",
    contest: "Konkurrence",
    friends: "Venner",
    earn: "Tjen",
    wallet: "Pung",
  },
  Vote: {
    whoIsBetter: "Hvem kan du bedst lide?",
    votingForLeader: "Stemmer på lederen",
    noUsers: {
      title: "🚀 Det er alt for i dag",
      description:
        "Inviter venner til at fortsætte med at stemme og tjene mønter",
      inviteFriends: "Inviter venner",
    },
  },
  Leaders: {
    rewards: "Belønninger",
    comingSoon: "Kommer snart",
    prizePool: "Præmiepulje",
    moreInfo: "Mere info",
    contest: "Konkurrence",
    richest: "Rigeste",
    sponsors: "Sponsorer",
    description: {
      likes:
        "Inviter venner og del din profil for at samle flere stemmer og øge dine chancer for at vinde.",
      coins:
        "Fuldfør opgaver, stem på andre deltagere, og inviter venner for at tjene flere mønter og styrke din position.",
      stars:
        "Stem på profiler på profilsiden for at modtage stjerner og klatre op i ranglisterne.",
    },
  },
  PrizePool: {
    text: `<b>Præmievindende pladser</b>:
<b>1. plads</b>: 50% USD og 10,000 mønter
<b>2. plads</b>: 30% USD og 3,000 mønter
<b>3. plads</b>: 20% USD og 2,000 mønter
<b>4.-10. pladser</b>: 1,000 mønter hver
<b>11.-50. pladser</b>: 500 mønter hver
<b>51.-100. pladser</b>: 250 mønter hver

<b>Præmiepuljen</b> øges, når nogen stemmer på dig på din profils side.

<b>Betingelser</b>:
Alle profiler, der uploader et billede og fuldfører opgaverne, er berettigede til at deltage i konkurrencen.

<b>Slutdato</b>:
{{endDate}}

<b>Belønningsudbetaling</b>:
Du kan modtage din belønning i <b>TON</b> — kryptovalutaen udviklet af Telegram.`,
  },
  LeadersItem: {
    join: "Deltag",
  },
  Friends: {
    title: "Inviter venner!",
    description: "Du og din ven vil modtage bonusser",
    bonus: {
      profit: "<coins>+{{count}}</coins> til dig og din ven",
      invite: {
        title: "Inviter en ven",
      },
      inviteWithPremium: {
        title: "Inviter en ven",
        description: "med Telegram Premium",
      },
    },
    list: {
      title: "Din venneliste",
    },
    inviteFriend: "Inviter en ven",
    inviteText: `Deltag med mig, stem på de smukkeste fyre og piger, og modtag værdifulde præmier!
  
  🌟 +{{coins}} mønter som første gave
  🔥 +{{coinsPremium}} mønter, hvis du har Telegram Premium
  
  🏆 Tjen mønter for hver stemme og vælg de mest attraktive deltagere. Vil du have mere? Deltag selv og konkurrer om værdifulde præmier!
  
  Gå ikke glip af din chance, bliv en del af vores stjernefællesskab og vind!`,
    linkCopied: "Link kopieret",
  },
  Earn: {
    title: "Tjen flere mønter",
    tasksList: "Opgaveliste",
    check: "Tjek",
    inReview: "Under gennemgang",
    subscribe: "Abonner",
    participate: "Deltag",
    invite: "Inviter",
    verify: "Bekræft",
    taskCompleted: "Opgave fuldført",
  },
  Wallet: {
    title: "Få belønninger i TON",
    description:
      "Fuldfør udfordringer for at tjene TON, en kryptomønt udviklet af Telegram",
    rewards: "Belønninger",
    withdrawal: "Udbetaling",
    walletConnected: "Din TON-tegnebog er forbundet",
    walletDescription: "Flere muligheder vil snart være tilgængelige",
  },
  VerificationRequired: {
    title: "Bekræftelse påkrævet",
    description: "For at deltage i konkurrencen skal du bekræfte din konto.",
    bdate: {
      title: "Din fødselsdato",
    },
  },
  Verification: {
    title: "Bekræftelse",
    hint: "Hold dit ansigt inden for ovalen",
    secureHint: "Ingen vil se dette foto",
    success: "Du har fuldført bekræftelsen med succes",
    goToApp: "Gå til appen",
    verification: "Bekræfter...",
    cameraAccessRequired:
      "Tillad venligst adgang til kameraet for at fortsætte",
    gettingAccessToCamer: "Få adgang til kameraet...",
    cameraAccessNotAllowed: "Kameraadgang nægtet af brugeren.",
    cameraAccessError: "Fejl ved adgang til kameraet",
    loadingHint: "Dette kan tage lidt tid. Vær venligst tålmodig.",
  },
  Profile: {
    vote: "Stem",
    askVote: "Bed om at stemme",
    inviteText: `Hej! 😊 Støt mig venligst med din stemme i TON Stars-konkurrencen! Hver stemme er afgørende og hjælper mig med at komme tættere på at vinde værdifulde præmier.

🌟 +{{coins}} mønter som en første gave
🔥 +{{coinsPremium}} mønter, hvis du har Telegram Premium

Stem på min profil her: {{url}}

Tak for din støtte! 🌟`,
    joinContest: "Deltag i konkurrence",
    gender: {
      male: "Mand",
      female: "Kvinde",
    },
    photos: {
      inReview:
        "Dine nye billeder er under gennemgang.\nDette kan tage lidt tid.",
      rejected:
        "Et eller flere billeder er blevet afvist af følgende grund:\n{{reason}}.\n\nUpload venligst andre billeder og prøv igen.",
    },
  },
  ProfileEdit: {
    title: "Din profil",
    name: {
      label: "Dit navn",
      placeholder: "Indtast dit navn",
    },
    instagram: {
      description: "Instagram-profilen skal være offentlig",
    },
    photos: {
      verificationPhotoUploaded:
        "Dit verificeringsfoto er blevet uploadet, ingen vil se det.",
      label: "Dine billeder",
      mainPhoto: "Hovedfoto",
      error: "Et eller flere billeder er ikke egnede, venligst vælg andre.",
      hint: "Vælg dine mest imponerende billeder, hvor der ikke er andre personer, og dit ansigt er tydeligt og udtryksfuldt synligt.",
      inReview:
        "Dine nye billeder er under gennemgang.\nDette kan tage lidt tid.",
      rejected:
        "Et eller flere billeder er blevet afvist af følgende grund:\n{{reason}}.\n\nUpload venligst andre billeder og prøv igen.",
    },
    submit: "Deltag i konkurrence",
    photosUploading: "Uploader foto...",
    success: "Din profil er blevet opdateret",
    errors: {
      name: "Angiv dit navn",
      photos: "Vælg venligst 3 billeder",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Hovedfoto",
    noFace: "Dit ansigt er ikke blevet registreret",
    otherPeople: "Billedet skal kun vise dit ansigt",
  },
};
