import {
  Button,
  List,
  Section,
  SegmentedControl,
  Text,
} from "@telegram-apps/telegram-ui";
import CoinsIcon from "./icons/coins.svg?react";
import CoinIcon from "assets/coin.svg?react";
import "./Leaders.css";
import { LeadersItem } from "./LeadersItem";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useQuery } from "@apollo/client";
import { LoadingPlaceholder } from "components/LoadingPlaceholder/LoadingPlaceholder";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/Select/Select";
import { LeaderOrderBy, UserGender } from "__generated__/graphql";
import { ProfileVoteProps } from "components/ProfileVote/ProfileVote";
import { ProfileProps } from "components/Profile/Profile";
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem";
import { LeadersTimer } from "./LeadersTimer";
import { LeadersMoney } from "./LeadersMoney";
import { Modal } from "components/Modal/Modal";
import { PrizePool } from "components/PrizePool/PrizePool";

const genders = ["all", UserGender.Female, UserGender.Male] as const;

const LeadersUser_QueryFragment = gql(`
  fragment LeadersUser_QueryFragment on User {
    id
    gender
  }
`);

const Leaders_Query = gql(`
  query Leaders_Query($gender: UserGender, $orderBy: LeaderOrderBy!) {
   leaders(gender: $gender, orderBy: $orderBy) {
     id
     isMe
     ...LeaderItem_QueryFragment
   }
   user {
    id
    gender
    starsCount
    place
    genderPlace
    placeCoins
    genderPlaceCoins
    placeStars
    genderPlaceStars
    hidden
    haveVerificationPhoto
    ...LeaderItem_QueryFragment
   }
  }
`);

type LeadersProps = {
  user?: FragmentType<typeof LeadersUser_QueryFragment> | null;
  voteConfig: ProfileVoteProps["voteConfig"];
  bonus: ProfileProps["bonus"];
};

export const Leaders = (props: LeadersProps) => {
  const user = getFragmentData(LeadersUser_QueryFragment, props.user);
  const [orderBy, setOrderBy] = useState<LeaderOrderBy>(LeaderOrderBy.Likes);
  const [_gender, setGender] = useState<(typeof genders)[number]>(
    user?.gender || genders[1]
  );

  const gender = orderBy === "likes" ? _gender : "all";

  const query = useQuery(Leaders_Query, {
    variables: { orderBy, gender: gender === "all" ? undefined : gender },
  });
  const { t: g } = useTranslation();
  const { t, i18n } = useTranslation("Leaders");

  const meRef = useRef<HTMLDivElement>(null);
  const meFixedRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    const parent = meFixedRef.current?.parentElement;

    if (!meRef.current || !meFixedRef.current || !parent) {
      if (parent) {
        parent.style.visibility = "hidden";
        parent.style.opacity = "0";
      }
      return;
    }

    const rect = [
      meRef.current.getBoundingClientRect(),
      meFixedRef.current.getBoundingClientRect(),
    ];

    const y1 = rect[0].y;
    const y2 = rect[1].y + 3;

    if (y1 <= y2) {
      parent.style.visibility = "hidden";
      parent.style.opacity = "0";
    } else {
      parent.style.visibility = "visible";
      parent.style.opacity = "1";
    }
  }, []);

  useEffect(onScroll, [onScroll, query.data]);

  useEffect(() => {
    document.body.onscroll = onScroll;

    return () => {
      document.body.onscroll = null;
    };
  }, [onScroll]);

  const place = useMemo(() => {
    if (!query.data?.user) return;

    let place = query.data.leaders.findIndex(
      (_) => _.id === query.data?.user?.id
    );

    if (place >= 0) return place + 1;
    else place = -1;

    if (gender === query.data.user.gender) {
      place =
        (orderBy === "coins"
          ? query.data.user.genderPlace
          : orderBy === "stars"
          ? query.data.user.genderPlaceStars
          : query.data.user.genderPlace) || 0;
    } else if (gender === "all") {
      place =
        (orderBy === "coins"
          ? query.data.user.placeCoins
          : orderBy === "stars"
          ? query.data.user.placeStars
          : query.data.user.place) || 0;
    }
    return place;
  }, [gender, orderBy, query.data?.leaders, query.data?.user]);

  const leaders = useMemo(() => {
    if (!query.data?.leaders) return [];
    const leaders = [...query.data.leaders] as (typeof query.data.user & {
      place?: number | null;
      isMe?: boolean;
    })[];

    if (
      query.data?.user &&
      !leaders.some((_) => _.id === query.data?.user?.id)
    ) {
      if (
        (orderBy === "stars" && query.data.user.starsCount) ||
        orderBy !== "stars"
      )
        leaders.push({
          ...query.data.user,
          place,
        });
    }

    return leaders;
  }, [orderBy, place, query]);

  const [prizePoolVisible, setPrizePoolVisible] = useState(false);

  return (
    <div className="leaders-container">
      <List className="scroll leaders-list">
        <SegmentedControl className="wallet-tabs">
          <SegmentedControlItem
            onClick={() => setOrderBy(LeaderOrderBy.Likes)}
            selected={orderBy === "likes"}
            className={orderBy === "likes" ? "selected" : ""}
          >
            {t("contest")}
          </SegmentedControlItem>
          <SegmentedControlItem
            onClick={() => setOrderBy(LeaderOrderBy.Coins)}
            selected={orderBy === "coins"}
            className={orderBy === "coins" ? "selected" : ""}
          >
            {t("richest")}
          </SegmentedControlItem>
          <SegmentedControlItem
            onClick={() => setOrderBy(LeaderOrderBy.Stars)}
            selected={orderBy === "stars"}
            className={orderBy === "stars" ? "selected" : ""}
          >
            {t("sponsors")}
          </SegmentedControlItem>
        </SegmentedControl>
        {orderBy === LeaderOrderBy.Likes && (
          <Select
            value={gender}
            options={genders
              .filter((_) => _ !== "all")
              .map((_) => ({
                value: _,
                text: g(`genderMany.${_}`),
              }))}
            onChange={setGender}
          />
        )}
        {gender !== "all" && orderBy === "likes" ? (
          <div className="leaders-prize">
            <LeadersTimer />
            <div className="leaders-prize-title">{t("prizePool")}</div>
            <div className="leaders-prize-items">
              <div className="leaders-prize-item">
                <div className="leaders-prize-item-icon">💵</div>
                <LeadersMoney />
              </div>
              +
              <div className="leaders-prize-item">
                <CoinIcon width={28} height={28} />
                <div>{Intl.NumberFormat(i18n.language).format(52750)}</div>
              </div>
            </div>
            <Button
              style={{ marginTop: 16 }}
              mode="gray"
              size="m"
              onClick={() => setPrizePoolVisible(true)}
            >
              {t("moreInfo")}
            </Button>
            {prizePoolVisible && (
              <Modal
                title={t("contest")}
                onClose={() => setPrizePoolVisible(false)}
              >
                <PrizePool />
              </Modal>
            )}
          </div>
        ) : (
          <div className="leaders-body">
            <CoinsIcon width={64} height={64} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text weight="1">{t("rewards")}</Text>
              <Text
                style={{
                  color: "var(--tgui--secondary_hint_color)",
                  fontSize: 14,
                }}
              >
                {t("comingSoon")}
              </Text>
            </div>
          </div>
        )}
        <div className="leaders-description">{t(`description.${orderBy}`)}</div>
        <LoadingPlaceholder {...query}>
          <Section>
            {leaders.map((_, index) => {
              return (
                <LeadersItem
                  key={_.id}
                  itemRef={_.isMe ? meRef : undefined}
                  user={_}
                  place={
                    _.place ? (_.place !== -1 ? _.place : undefined) : index + 1
                  }
                  voteConfig={props.voteConfig}
                  bonus={props.bonus}
                  type={orderBy}
                  hidden={_.hidden}
                  haveVerificationPhoto={
                    query.data?.user?.haveVerificationPhoto || false
                  }
                />
              );
            })}
          </Section>
        </LoadingPlaceholder>
        {query.data?.user && (
          <div className="leaders-item-fixed-container">
            <LeadersItem
              itemRef={meFixedRef}
              user={query.data.user}
              place={place}
              fixed
              voteConfig={props.voteConfig}
              bonus={props.bonus}
              type={orderBy}
              hidden={query.data.user.hidden}
              haveVerificationPhoto={
                query.data?.user?.haveVerificationPhoto || false
              }
            />
          </div>
        )}
      </List>
    </div>
  );
};
