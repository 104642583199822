import { Avatar, Button, Cell } from "@telegram-apps/telegram-ui";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { Modal } from "components/Modal/Modal";
import { Profile, ProfileProps } from "components/Profile/Profile";
import { RefObject, useMemo, useState } from "react";
import countries from "data/countries.json";
import HeartIcon from "assets/heart.svg?react";
import CoinIcon from "assets/coin.svg?react";
import StarsIcon from "assets/star.svg?react";
import "./LeadersItem.css";
import { useTranslation } from "react-i18next";
import { VerificationRequired } from "components/Verification/VerificationRequired";
import { ProfileEdit } from "components/ProfileEdit/ProfileEdit";
import VerifiedIcon from "assets/verified.svg?react";
import ClockIcon from "assets/clock.svg?react";
import RejectedIcon from "assets/rejected.svg?react";
import { ProfileVoteProps } from "components/ProfileVote/ProfileVote";

const LeaderItem_QueryFragment = gql(`
    fragment LeaderItem_QueryFragment on User {
        ...ProfileUser_QueryFragment
        ...VerificationRequiredUser_QueryFragment
        ...ProfileEditUser_QueryFragment
        id
        photo_url
        name
        age
        verified
        countryCode
        likesCount
        coins
        starsCount
        isMe
        uploads {
          id
          faceUrl: url(size: 200, face: true)
        }
        uploadsPending {
          id
        }
        photoStatus {
          rejectReasonText
        }
    }
`);

type LeadersItemProps = {
  itemRef?: RefObject<HTMLDivElement>;
  place?: number;
  user: FragmentType<typeof LeaderItem_QueryFragment>;
  fixed?: boolean;
  voteConfig: ProfileVoteProps["voteConfig"];
  bonus: ProfileProps["bonus"];
  type: "likes" | "stars" | "coins";
  hidden?: boolean;
  haveVerificationPhoto?: boolean;
};

export const LeadersItem = (props: LeadersItemProps) => {
  const user = getFragmentData(LeaderItem_QueryFragment, props.user);
  const { t, i18n } = useTranslation("LeadersItem");

  const [profileVisible, setProfileVisible] = useState(false);
  const [verificationVisible, setVerificationVisible] = useState(false);
  const [profileEditVisible, setProfileEditVisible] = useState(false);

  const flag = useMemo(() => {
    return countries.find((_) => _.code === user.countryCode)?.flag;
  }, [user.countryCode]);

  return (
    <>
      <Cell
        ref={props.itemRef}
        className={`leaders-item ${props.fixed ? "leaders-item-fixed" : ""}`}
        after={
          user.uploads.length || !user.isMe ? (
            <div className="leaders-item-place">
              {props.place && props.place !== -1
                ? props.place >= 10000
                  ? Intl.NumberFormat(i18n.language).format(props.place) + "+"
                  : Intl.NumberFormat(i18n.language).format(props.place)
                : "-"}
            </div>
          ) : (
            <Button
              size="s"
              onClick={(e) => {
                e.stopPropagation();
                if (user?.verified || props.haveVerificationPhoto)
                  setProfileEditVisible(true);
                else setVerificationVisible(true);
              }}
            >
              {t("join")}
            </Button>
          )
        }
        before={
          <div className="leaders-item-before">
            <Avatar
              src={user.uploads[0]?.faceUrl || user.photo_url || ""}
              acronym={
                (user.name?.[0]?.toUpperCase() || "") +
                (user.name?.[1]?.toUpperCase() || "")
              }
              size={48}
            />
          </div>
        }
        onClick={() => setProfileVisible(true)}
      >
        <div className="leaders-item-header">
          <span className="leaders-item-flag">{flag}</span>
          <span className="leaders-item-name">{user.name}</span>
          {!!user.age && (
            <span className="leaders-item-age">
              , {Intl.NumberFormat(i18n.language).format(user.age)}
            </span>
          )}
          <span className="leaders-item-icons">
            {user.verified && <VerifiedIcon width={16} height={16} />}
            {user?.photoStatus?.rejectReasonText ? (
              <RejectedIcon width={18} height={18} />
            ) : (
              !!user?.uploadsPending.length && (
                <ClockIcon width={14} height={14} color="rgb(255, 151, 0)" />
              )
            )}
          </span>
        </div>
        <div className="leaders-item-sub">
          {props.type === "likes" && !!user.likesCount && (
            <div className="leaders-item-likes">
              <HeartIcon
                style={{ marginTop: -1, marginRight: 2, marginLeft: 2 }}
                width={16}
                height={16}
              />{" "}
              {Intl.NumberFormat(i18n.language).format(user.likesCount)}
            </div>
          )}
          {props.type === "coins" && !!user.coins && (
            <div className="leaders-item-likes">
              <CoinIcon
                style={{ marginTop: -1, marginRight: 2, marginLeft: 2 }}
                width={16}
                height={16}
              />
              {Intl.NumberFormat(i18n.language).format(user.coins)}
            </div>
          )}
          {props.type === "stars" && !!user.starsCount && (
            <div className="leaders-item-likes">
              <StarsIcon
                style={{ marginTop: -1, marginRight: 2, marginLeft: 2 }}
                color="rgb(245, 186, 38)"
                width={16}
                height={16}
              />{" "}
              {Intl.NumberFormat(i18n.language).format(user.starsCount)}
            </div>
          )}
        </div>

        {profileVisible && (
          <Modal fullscreen onClose={() => setProfileVisible(false)}>
            <Profile
              user={user}
              voteConfig={props.voteConfig}
              bonus={props.bonus}
            />
          </Modal>
        )}
      </Cell>
      {verificationVisible && (
        <VerificationRequired
          user={user}
          onClose={() => setVerificationVisible(false)}
          onComplete={() => setProfileEditVisible(true)}
        />
      )}
      {profileEditVisible && (
        <Modal fullscreen onClose={() => setProfileEditVisible(false)}>
          <ProfileEdit
            user={user}
            onClose={() => setProfileEditVisible(false)}
          />
        </Modal>
      )}
    </>
  );
};
