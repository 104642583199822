export default {
  g: {
    flag: "🇧🇷",
    langName: "Portuguese (Brazil)",
    langNameNative: "Português (Brasil)",
    continue: "Continuar",
    save: "Salvar",
    retry: "Tentar novamente",
    loading: "Carregando...",
    genderMany: {
      all: "Todos",
      male: "Homens",
      female: "Mulheres",
    },
  },
  CreateAccount: {
    slogan: "O lugar onde os sonhos se tornam vitórias!",
    gender: {
      title: "Olá {{name}}, Você é...",
      male: "Masculino",
      female: "Feminino",
    },
  },
  Settings: {
    title: "Configurações",
    language: "Idioma",
    auto: "Automático",
    country: "País",
    hideProfile: {
      label: "Ocultar Perfil",
      description:
        "Seu perfil ficará oculto para todos, como se você o tivesse excluído.",
    },
    profileHidden: "Seu perfil está oculto e não visível para outros usuários.",
  },
  Tabs: {
    vote: "Votar",
    contest: "Concurso",
    friends: "Amigos",
    earn: "Ganhar",
    wallet: "Carteira",
  },
  Vote: {
    whoIsBetter: "Quem você gosta mais?",
    votingForLeader: "Votando no líder",
    noUsers: {
      title: "🚀 Isso é tudo por hoje",
      description: "Convide amigos para continuar votando e ganhando moedas",
      inviteFriends: "Convidar Amigos",
    },
  },
  Leaders: {
    rewards: "Recompensas",
    comingSoon: "Em breve",
    prizePool: "Prêmio",
    moreInfo: "Mais informações",
    contest: "Concurso",
    richest: "Mais ricos",
    sponsors: "Patrocinadores",
    description: {
      likes:
        "Convide amigos e compartilhe seu perfil para obter mais votos e aumentar suas chances de ganhar.",
      coins:
        "Complete tarefas, vote em outros participantes e convide amigos para ganhar mais moedas e fortalecer sua posição.",
      stars:
        "Vote em perfis na página do perfil para receber estrelas e subir nas classificações.",
    },
  },
  PrizePool: {
    text: `<b>Lugares premiados</b>:
<b>1º lugar</b>: 50% USD e 10.000 moedas
<b>2º lugar</b>: 30% USD e 3.000 moedas
<b>3º lugar</b>: 20% USD e 2.000 moedas
<b>4º-10º lugares</b>: 1.000 moedas cada
<b>11º-50º lugares</b>: 500 moedas cada
<b>51º-100º lugares</b>: 250 moedas cada

<b>O prêmio</b> aumenta quando alguém vota em você na sua página de perfil.

<b>Condições</b>:
Todos os perfis que fizerem upload de uma foto e completarem as tarefas estão aptos a participar do concurso.

<b>Data de término</b>:
{{endDate}}

<b>Pagamento da recompensa</b>:
Você pode receber sua recompensa em <b>TON</b> — a criptomoeda desenvolvida pelo Telegram.`,
  },
  LeadersItem: {
    join: "Entrar",
  },
  Friends: {
    title: "Convide amigos!",
    description: "Você e seu amigo receberão bônus",
    bonus: {
      profit: "<coins>+{{count}}</coins> para você e seu amigo",
      invite: {
        title: "Convide um amigo",
      },
      inviteWithPremium: {
        title: "Convide um amigo",
        description: "com Telegram Premium",
      },
    },
    list: {
      title: "Lista de seus amigos",
    },
    inviteFriend: "Convide um amigo",
    inviteText: `Junte-se a mim, participe das votações para os rapazes e moças mais bonitos e ganhe prêmios valiosos!
  
  🌟 +{{coins}} moedas como primeiro presente
  🔥 +{{coinsPremium}} moedas se você tiver Telegram Premium
  
  🏆 Ganhe moedas a cada voto e escolha os participantes mais atraentes. Quer mais? Participe você mesmo e concorra a prêmios valiosos!
  
  Não perca sua chance, torne-se parte da nossa comunidade de estrelas e vença!`,
    linkCopied: "Link copiado",
  },
  Earn: {
    title: "Ganhe mais moedas",
    tasksList: "Lista de tarefas",
    check: "Verificar",
    inReview: "Em revisão",
    subscribe: "Inscreva-se",
    participate: "Participe",
    invite: "Convide",
    verify: "Verifique",
    taskCompleted: "Tarefa concluída",
  },
  Wallet: {
    title: "Receba recompensas em TON",
    description:
      "Complete desafios para ganhar TON, uma moeda criptográfica desenvolvida pelo Telegram",
    rewards: "Recompensas",
    withdrawal: "Retirada",
    walletConnected: "Sua carteira TON está conectada",
    walletDescription: "Mais oportunidades estarão disponíveis em breve",
  },
  VerificationRequired: {
    title: "Verificação Necessária",
    description:
      "Para participar do concurso, você precisa verificar sua conta.",
    bdate: {
      title: "Sua data de nascimento",
    },
  },
  Verification: {
    title: "Verificação",
    hint: "Mantenha seu rosto dentro do oval",
    secureHint: "Ninguém verá esta foto",
    success: "Você completou a verificação com sucesso",
    goToApp: "Ir para o aplicativo",
    verification: "Verificando...",
    cameraAccessRequired: "Por favor, permita o acesso à câmera para continuar",
    gettingAccessToCamer: "Obtendo acesso à câmera...",
    cameraAccessNotAllowed: "Acesso à câmera negado pelo usuário.",
    cameraAccessError: "Erro de acesso à câmera",
    loadingHint: "Isso pode levar algum tempo. Por favor, seja paciente.",
  },
  Profile: {
    vote: "Votar",
    askVote: "Pedir para Votar",
    inviteText: `Oi! 😊 Por favor, apoie-me com seu voto no concurso TON Stars! Cada voto é crucial e me ajuda a ficar mais perto de ganhar prêmios valiosos.

🌟 +{{coins}} moedas como primeiro presente
🔥 +{{coinsPremium}} moedas se você tiver o Telegram Premium

Vote no meu perfil aqui: {{url}}

Obrigado pelo seu apoio! 🌟`,
    joinContest: "Participar do concurso",
    gender: {
      male: "Masculino",
      female: "Feminino",
    },
    photos: {
      inReview:
        "Suas novas fotos estão em revisão.\nIsso pode levar algum tempo.",
      rejected:
        "Uma ou mais fotos foram rejeitadas pelo seguinte motivo:\n{{reason}}.\n\nPor favor, faça o upload de fotos diferentes e tente novamente.",
    },
  },
  ProfileEdit: {
    title: "Seu Perfil",
    name: {
      label: "Seu Nome",
      placeholder: "Digite seu nome",
    },
    instagram: {
      description: "O perfil do Instagram deve ser público",
    },
    photos: {
      verificationPhotoUploaded:
        "Sua foto de verificação foi enviada, ninguém a verá.",
      label: "Suas Fotos",
      mainPhoto: "Foto Principal",
      error:
        "Uma ou mais fotos não são adequadas, por favor, faça outra seleção.",
      hint: "Escolha suas fotos mais impressionantes, onde não há outras pessoas e seu rosto é claramente visível.",
      inReview:
        "Suas novas fotos estão em revisão.\nIsso pode levar algum tempo.",
      rejected:
        "Uma ou mais fotos foram rejeitadas pelo seguinte motivo:\n{{reason}}.\n\nPor favor, faça o upload de fotos diferentes e tente novamente.",
    },
    submit: "Participar do Concurso",
    photosUploading: "Carregando foto...",
    success: "Seu perfil foi atualizado",
    errors: {
      name: "Insira seu nome",
      photos: "Por favor, selecione 3 fotos",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto Principal",
    noFace: "Seu rosto não foi detectado",
    otherPeople: "A foto deve mostrar apenas seu rosto",
  },
};
