export default {
  g: {
    flag: "🇨🇳",
    langName: "Chinese (Simplified)",
    langNameNative: "简体中文",
    continue: "继续",
    save: "保存",
    retry: "重试",
    loading: "加载中...",
    genderMany: {
      all: "全部",
      male: "男性",
      female: "女性",
    },
  },
  CreateAccount: {
    slogan: "梦想成真的地方！",
    gender: {
      title: "你好 {{name}}，您是...",
      male: "男性",
      female: "女性",
    },
  },
  Settings: {
    title: "设置",
    language: "语言",
    auto: "自动",
    country: "国家",
    hideProfile: {
      label: "隐藏个人资料",
      description: "您的个人资料将对所有人隐藏，仿佛您已经删除了它。",
    },
    profileHidden: "您的个人资料已隐藏，其他用户无法看到。",
  },
  Tabs: {
    vote: "投票",
    contest: "比赛",
    friends: "朋友",
    earn: "赚取",
    wallet: "钱包",
  },
  Vote: {
    whoIsBetter: "你更喜欢谁？",
    votingForLeader: "为领袖投票",
    noUsers: {
      title: "🚀 今天就到这里",
      description: "邀请朋友继续投票并赚取硬币",
      inviteFriends: "邀请朋友",
    },
  },
  Leaders: {
    rewards: "奖励",
    comingSoon: "即将推出",
    prizePool: "奖金池",
    moreInfo: "更多信息",
    contest: "比赛",
    richest: "最富有",
    sponsors: "赞助商",
    description: {
      likes: "邀请朋友并分享您的个人资料，以获取更多选票并增加获胜的机会。",
      coins:
        "完成任务，为其他参与者投票，并邀请朋友以赚取更多硬币并巩固您的地位。",
      stars: "在个人资料页面上为个人资料投票以获得星星并在排名中攀升。",
    },
  },
  PrizePool: {
    text: `<b>获奖名次</b>:
<b>第一名</b>: 50% USD 和 10,000 硬币
<b>第二名</b>: 30% USD 和 3,000 硬币
<b>第三名</b>: 20% USD 和 2,000 硬币
<b>第4至10名</b>: 每名 1,000 硬币
<b>第11至50名</b>: 每名 500 硬币
<b>第51至100名</b>: 每名 250 硬币

<b>奖金池</b> 随着有人在您的个人资料页面上为您投票而增加。

<b>条件</b>:
所有上传照片并完成任务的个人资料都有资格参加比赛。

<b>结束日期</b>:
{{endDate}}

<b>奖励支付</b>:
您可以通过 <b>TON</b> 接收您的奖励——由 Telegram 开发的加密货币。`,
  },
  LeadersItem: {
    join: "加入",
  },
  Friends: {
    title: "邀请朋友！",
    description: "您和您的朋友将获得奖励",
    bonus: {
      profit: "<coins>+{{count}}</coins> 给您和您的朋友",
      invite: {
        title: "邀请朋友",
      },
      inviteWithPremium: {
        title: "邀请朋友",
        description: "使用Telegram Premium",
      },
    },
    list: {
      title: "您的朋友列表",
    },
    inviteFriend: "邀请朋友",
    inviteText: `加入我，参与最美男孩和女孩的投票，并获得宝贵的奖品！
  
  🌟 +{{coins}} 作为首次礼物
  🔥 +{{coinsPremium}} 如果您有Telegram Premium
  
  🏆 每次投票赚取硬币，并选择最有吸引力的参与者。想要更多？自己参加比赛，竞争宝贵奖品！
  
  不要错过机会，成为我们明星社区的一员并赢取奖品！`,
    linkCopied: "链接已复制",
  },
  Earn: {
    title: "赚取更多硬币",
    tasksList: "任务列表",
    check: "检查",
    inReview: "审核中",
    subscribe: "订阅",
    participate: "参与",
    invite: "邀请",
    verify: "验证",
    taskCompleted: "任务完成",
  },
  Wallet: {
    title: "获取 TON 奖励",
    description: "完成挑战以赚取 TON，这是 Telegram 开发的加密货币",
    rewards: "奖励",
    withdrawal: "提款",
    walletConnected: "您的 TON 钱包已连接",
    walletDescription: "更多机会即将到来",
  },
  VerificationRequired: {
    title: "需要验证",
    description: "要参加比赛，您需要验证您的账户。",
    bdate: {
      title: "你的出生日期",
    },
  },
  Verification: {
    title: "验证",
    hint: "将你的脸保持在椭圆内",
    secureHint: "没有人会看到这张照片",
    success: "您已成功完成验证",
    goToApp: "前往应用",
    verification: "验证中...",
    cameraAccessRequired: "请授予摄像头访问权限以继续",
    gettingAccessToCamer: "获取摄像头访问权限中...",
    cameraAccessNotAllowed: "用户拒绝了摄像头访问权限。",
    cameraAccessError: "摄像头访问错误",
    loadingHint: "这可能需要一些时间。请耐心等待。",
  },
  Profile: {
    vote: "投票",
    askVote: "请求投票",
    inviteText: `你好！😊 请通过你的投票支持我参加TON Stars比赛！每一票都至关重要，有助于我更接近赢得宝贵的奖品。

🌟 +{{coins}} 枚硬币作为第一份礼物
🔥 如果你有Telegram Premium +{{coinsPremium}} 枚硬币

在这里为我的个人资料投票：{{url}}

谢谢你的支持！🌟`,
    joinContest: "参加比赛",
    gender: {
      male: "男",
      female: "女",
    },
    photos: {
      inReview: "您的新照片正在审核中。\n这可能需要一些时间。",
      rejected:
        "一张或多张照片因以下原因被拒绝:\n{{reason}}。\n\n请上传不同的照片并重试。",
    },
  },
  ProfileEdit: {
    title: "你的个人资料",
    name: {
      label: "你的名字",
      placeholder: "输入你的名字",
    },
    instagram: {
      description: "Instagram 个人资料必须公开",
    },
    photos: {
      verificationPhotoUploaded: "您的验证照片已上传，任何人都看不到。",
      label: "你的照片",
      mainPhoto: "主要照片",
      error: "一张或多张照片不合适，请重新选择。",
      hint: "选择最令人印象深刻的照片，其中没有其他人，并且你的脸清晰可见。",
      inReview: "您的新照片正在审核中。\n这可能需要一些时间。",
      rejected:
        "一张或多张照片因以下原因被拒绝:\n{{reason}}。\n\n请上传不同的照片并重试。",
    },
    submit: "参加比赛",
    photosUploading: "正在上传照片...",
    success: "您的个人资料已更新",
    errors: {
      name: "请输入您的名字",
      photos: "请选择3张照片",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "主要照片",
    noFace: "未检测到您的面部",
    otherPeople: "照片中应仅显示您的面部",
  },
};
