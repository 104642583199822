export default {
  g: {
    flag: "🇮🇳",
    langName: "Malayalam",
    langNameNative: "മലയാളം",
    continue: "തുടരുക",
    save: "സംരക്ഷിക്കുക",
    retry: "വീണ്ടും ശ്രമിക്കുക",
    loading: "ലോഡ് ചെയ്യുന്നു...",
    genderMany: {
      all: "എല്ലാം",
      male: "ആണ്",
      female: "പെൺ",
    },
  },
  CreateAccount: {
    slogan: "സ്വപ്നങ്ങൾ വിജയങ്ങളിലേക്ക് മാറുന്ന സ്ഥലം!",
    gender: {
      title: "ഹലോ {{name}}, നിങ്ങൾ...",
      male: "ആൺ",
      female: "പെൺ",
    },
  },
  Settings: {
    title: "ക്രമീകരണങ്ങൾ",
    language: "ഭാഷ",
    auto: "സ്വയമേവ",
    country: "രാജ്യം",
    hideProfile: {
      label: "പ്രൊഫൈൽ മറയ്ക്കുക",
      description:
        "നിങ്ങൾ ഇത് ഇല്ലാതാക്കിയതിന് തുല്യമായി നിങ്ങളുടെ പ്രൊഫൈൽ എല്ലാവരിൽ നിന്നും മറച്ചിരിക്കും.",
    },
    profileHidden:
      "നിങ്ങളുടെ പ്രൊഫൈൽ മറച്ചിരിക്കുന്നു, ഇത് മറ്റുള്ളവർക്കു ദൃശ്യമല്ല.",
  },
  Tabs: {
    vote: "വോട്ട് ചെയ്യുക",
    contest: "മൽസരം",
    friends: "സുഹൃത്തുക്കൾ",
    earn: "സമ്പാദിക്കുക",
    wallet: "വാലറ്റ്",
  },
  Vote: {
    whoIsBetter: "നിങ്ങൾക്ക് കൂടുതൽ ഇഷ്ടപ്പെടുന്നത് ആരാണ്?",
    votingForLeader: "ലീഡർക്ക് വേണ്ടി വോട്ടെടുപ്പ്",
    noUsers: {
      title: "🚀 ഇന്നത്തെക്കായുള്ളത് ഇത്രയേ ഉള്ളൂ",
      description:
        "വോട്ടിംഗ് തുടരാനും നാണയങ്ങൾ സമ്പാദിക്കാനും സുഹൃത്തുക്കളെ ക്ഷണിക്കുക",
      inviteFriends: "സുഹൃത്തുക്കളെ ക്ഷണിക്കുക",
    },
  },
  Leaders: {
    rewards: "പരിത്യാഗങ്ങൾ",
    comingSoon: "വീണ്ടുമുണ്ടാകും",
    prizePool: "സമ്മാനക്കുടം",
    moreInfo: "കൂടുതൽ വിവരങ്ങൾ",
    contest: "മത്സരം",
    richest: "ശ്രേഷ്ഠമായവ",
    sponsors: "സ്പോൺസറുകൾ",
    description: {
      likes:
        "കൂടുതൽ വോട്ടുകൾ സമാഹരിച്ച് ജയിക്കാനുള്ള സാധ്യതകൾ വർദ്ധിപ്പിക്കാൻ സുഹൃത്തുക്കളെ ക്ഷണിക്കുക, പ്രൊഫൈൽ പങ്കിടുക.",
      coins:
        "ടാസ്കുകൾ പൂർത്തിയാക്കുക, മറ്റു പങ്കെടുക്കുന്നവർക്കു വേണ്ടി വോട്ട് ചെയ്യുക, കൂടുതൽ നാണയങ്ങൾ സമ്പാദിക്കാൻ സുഹൃത്തുക്കളെ ക്ഷണിക്കുക, നിങ്ങളുടെ നിലപാട് ശക്തമാക്കുക.",
      stars:
        "പ്രൊഫൈൽ പേജിൽ പ്രൊഫൈലുകൾക്കായി വോട്ട് ചെയ്യുക, നക്ഷത്രങ്ങൾ നേടുക, റാങ്കുകളിൽ ഉയരുക.",
    },
  },
  PrizePool: {
    text: `<b>സമ്മാനസ്ഥലങ്ങൾ</b>:
<b>1-ാം സ്ഥലം</b>: 50% USD, 10,000 നാണയങ്ങൾ
<b>2-ാം സ്ഥലം</b>: 30% USD, 3,000 നാണയങ്ങൾ
<b>3-ാം സ്ഥലം</b>: 20% USD, 2,000 നാണയങ്ങൾ
<b>4-ാം-10-ാം സ്ഥാനങ്ങൾ</b>: ഓരോരുത്തർക്കും 1,000 നാണയങ്ങൾ
<b>11-ാം-50-ാം സ്ഥാനങ്ങൾ</b>: ഓരോരുത്തർക്കും 500 നാണയങ്ങൾ
<b>51-ാം-100-ാം സ്ഥാനങ്ങൾ</b>: ഓരോരുത്തർക്കും 250 നാണയങ്ങൾ

<b>സമ്മാനക്കുടം</b> നിങ്ങളുടെ പ്രൊഫൈൽ പേജിൽ ആരെങ്കിലും നിങ്ങളുടെ പ്രൊഫൈൽക്ക് വേണ്ടി വോട്ട് ചെയ്താൽ വർദ്ധിക്കും.

<b>ശരത്തുകൾ</b>:
ഫോട്ടോ അപ്‌ലോഡ് ചെയ്യുകയും ടാസ്കുകൾ പൂർത്തിയാക്കുകയും ചെയ്യുന്ന എല്ലാ പ്രൊഫൈലുകൾക്കും മത്സരത്തിൽ പങ്കെടുക്കാനുള്ള അർഹത ഉണ്ട്.

<b>അവസാന തീയതി</b>:
{{endDate}}

<b>സമ്മാനങ്ങൾ നൽകൽ</b>:
നിങ്ങൾക്ക് നിങ്ങളുടെ സമ്മാനം <b>TON</b> -ൽ ലഭ്യമാണ് - ഇത് Telegram വികസിപ്പിച്ചെടുത്ത ക്രിപ്റ്റോകറൻസി ആണ്.`,
  },
  LeadersItem: {
    join: "ചേരുക",
  },
  Friends: {
    title: "സുഹൃത്തുക്കളെ ക്ഷണിക്കുക!",
    description: "നിങ്ങളും നിങ്ങളുടെ സുഹൃത്തും ബോണസ് നേടും",
    bonus: {
      profit: "<coins>+{{count}}</coins> നിങ്ങൾക്കും സുഹൃത്തിനും",
      invite: {
        title: "സുഹൃത്തിനെ ക്ഷണിക്കുക",
      },
      inviteWithPremium: {
        title: "സുഹൃത്തിനെ ക്ഷണിക്കുക",
        description: "ടെലഗ്രാം പ്രീമിയവുമായി",
      },
    },
    list: {
      title: "നിങ്ങളുടെ സുഹൃത്തുകളുടെ പട്ടിക",
    },
    inviteFriend: "സുഹൃത്തിനെ ക്ഷണിക്കുക",
    inviteText: `എന്നെ കൂടെ ചേരൂ, ഏറ്റവും സുന്ദരനായ ആൺകുട്ടികളും പെൺകുട്ടികൾക്കും വേണ്ടി വോട്ട് ചെയ്യൂ һәм വിലമതിക്കാനാവാത്ത സമ്മാനങ്ങൾ നേടൂ!
  
  🌟 ആദ്യ സമ്മാനമായി +{{coins}} നാണയങ്ങൾ
  🔥 നിങ്ങൾക്ക് ടെലഗ്രാം പ്രീമിയം ഉണ്ടെങ്കിൽ +{{coinsPremium}} നാണയങ്ങൾ
  
  🏆 ഓരോ വോട്ടിനും നാണയങ്ങൾ നേടൂ һәм ഏറ്റവും ആകർഷകമായ പങ്കെടുക്കുന്നവരെ തിരഞ്ഞെടുക്കൂ. കൂടുതൽ ആഗ്രഹിക്കുന്നുണ്ടോ? നിങ്ങൾ തന്നെ പങ്കെടുക്കുക һәм വിലമതിക്കാനാവാത്ത സമ്മാനങ്ങൾക്കായി മൽസരിക്കുക!
  
  നിങ്ങളുടെ അവസരം നഷ്ടമാകരുത്, ഞങ്ങളുടെ നക്ഷത്ര സമുദായത്തിന്റെ ഭാഗമാകൂ һәм ജയിക്കൂ!`,
    linkCopied: "ലിങ്ക് പകർത്തി",
  },
  Earn: {
    title: "കൂടുതൽ നാണയങ്ങൾ സമ്പാദിക്കുക",
    tasksList: "ടാസ്‌ക് ലിസ്റ്റ്",
    check: "പരിശോധിക്കുക",
    inReview: "റിവ്യൂവിൽ",
    subscribe: "സബ്സ്ക്രൈബ് ചെയ്യുക",
    participate: "പങ്കെടുക്കുക",
    invite: "ക്ഷണിക്കുക",
    verify: "സ്ഥിരീകരിക്കുക",
    taskCompleted: "ടാസ്‌ക് പൂർത്തിയായി",
  },
  Wallet: {
    title: "TON-ൽ പ്രതിഫലങ്ങൾ നേടുക",
    description:
      "ടെലിഗ്രാം വികസിപ്പിച്ച ഒരു ക്രിപ്റ്റോ കോയിനായ TON നേടാൻ വെല്ലുവിളികൾ പൂർത്തിയാക്കുക",
    rewards: "പ്രതിഫലങ്ങൾ",
    withdrawal: "പിൻവലിക്കൽ",
    walletConnected: "നിങ്ങളുടെ TON വാലറ്റ് ബന്ധിപ്പിച്ചിട്ടുണ്ട്",
    walletDescription: "കൂടുതൽ അവസരങ്ങൾ ഉടൻ ലഭ്യമായിരിക്കും",
  },
  VerificationRequired: {
    title: "സ്ഥിരീകരണം ആവശ്യമാണ്",
    description:
      "മൽസരത്തിൽ പങ്കെടുക്കാൻ, നിങ്ങൾക്ക് നിങ്ങളുടെ അക്കൗണ്ട് സ്ഥിരീകരിക്കേണ്ടതുണ്ട്.",
    bdate: {
      title: "നിങ്ങളുടെ ജന്മ തീയതി",
    },
  },
  Verification: {
    title: "സ്ഥിരീകരണം",
    hint: "നിങ്ങളുടെ മുഖം ഓവലിനുള്ളിൽ വയ്ക്കുക",
    secureHint: "ഈ ചിത്രം ആരും കാണില്ല",
    success: "നിങ്ങൾ വിജയകരമായി സ്ഥിരീകരണം പൂർത്തിയാക്കി",
    goToApp: "ആപ്പിലേക്ക് പോവുക",
    verification: "സ്ഥിരീകരിക്കുന്നു...",
    cameraAccessRequired: "തുടരാൻ ദയവായി ക്യാമറ ആക്സസ് അനുവദിക്കുക",
    gettingAccessToCamer: "ക്യാമറ ആക്സസ് നേടുന്നു...",
    cameraAccessNotAllowed: "ഉപയോക്താവ് ക്യാമറ ആക്സസ് നിരസിച്ചു.",
    cameraAccessError: "ക്യാമറ ആക്സസ് പിഴവ്",
    loadingHint: "ഇത് കുറച്ചു സമയം എടുക്കും. ദയവായി ക്ഷമിക്കൂ.",
  },
  Profile: {
    vote: "വോട്ട്",
    askVote: "വോട്ട് ചോദിക്കുക",
    inviteText: `ഹായ്! 😊 TON സ്റ്റാർസ് മത്സരത്തിൽ നിങ്ങളുടെ വോട്ടുമായി എന്നെ പിന്തുണയ്ക്കുക! ഓരോ വോട്ടും നിർണായകമാണ്, കൂടാതെ വിലപ്പെട്ട സമ്മാനങ്ങൾ നേടാൻ എന്നെ സഹായിക്കുന്നു.

🌟 ആദ്യ സമ്മാനമായി +{{coins}} നാണയങ്ങൾ
🔥 നിങ്ങളുടെ પાસે Telegram Premium ഉണ്ടെങ്കിൽ +{{coinsPremium}} നാണയങ്ങൾ

എന്റെ പ്രൊഫൈലിന് ഇവിടെ വോട്ട് ചെയ്യുക: {{url}}

നിങ്ങളുടെ പിന്തുണയ്ക്ക് നന്ദി! 🌟`,
    joinContest: "മത്സരത്തിൽ ചേർക്കുക",
    gender: {
      male: "പുരുഷൻ",
      female: "സ്ത്രീ",
    },
    photos: {
      inReview:
        "നിങ്ങളുടെ പുതിയ ഫോട്ടോകൾ നിരീക്ഷണത്തിലാണ്.\nഇത് കുറച്ച് സമയം എടുക്കാം.",
      rejected:
        "ഒരു അല്ലെങ്കിൽ അധികം ഫോട്ടോകൾ താഴെ പറഞ്ഞ കാരണം മൂലം തള്ളിക്കളഞ്ഞിട്ടുണ്ട്:\n{{reason}}.\n\nദയവായി വ്യത്യസ്തമായ ഫോട്ടോകൾ അപ്ലോഡ് ചെയ്യുക, വീണ്ടും ശ്രമിക്കുക.",
    },
  },
  ProfileEdit: {
    title: "നിങ്ങളുടെ പ്രൊഫൈൽ",
    name: {
      label: "നിങ്ങളുടെ പേര്",
      placeholder: "നിങ്ങളുടെ പേര് നൽകുക",
    },
    instagram: {
      description: "Instagram പ്രൊഫൈൽ പബ്ലിക് ആയിരിക്കണം",
    },
    photos: {
      verificationPhotoUploaded:
        "നിങ്ങളുടെ പരിശോധന ഫോട്ടോ അപ്‌ലോഡ് ചെയ്തിരിക്കുന്നു, അത് ആരും കാണില്ല.",
      label: "നിങ്ങളുടെ ഫോട്ടോകൾ",
      mainPhoto: "പ്രധാന ഫോട്ടോ",
      error:
        "ഒരു അല്ലെങ്കിൽ അധികം ഫോട്ടോകൾ അനുയോജ്യമായിട്ടില്ല, ദയവായി മറ്റൊന്ന് തിരഞ്ഞെടുക്കുക.",
      hint: "മറ്റു ആളുകൾ ഇല്ലാത്ത, നിങ്ങളുടെ മുഖം തികച്ചും വ്യക്തമായും സ്പഷ്ടമായും കാണുന്ന ഏറ്റവും സ്വാധീനമേറിയ ഫോട്ടോകൾ തിരഞ്ഞെടുക്കുക.",
      inReview:
        "നിങ്ങളുടെ പുതിയ ഫോട്ടോകൾ നിരീക്ഷണത്തിലാണ്.\nഇത് കുറച്ച് സമയം എടുക്കാം.",
      rejected:
        "ഒരു അല്ലെങ്കിൽ അധികം ഫോട്ടോകൾ താഴെ പറഞ്ഞ കാരണം മൂലം തള്ളിക്കളഞ്ഞിട്ടുണ്ട്:\n{{reason}}.\n\nദയവായി വ്യത്യസ്തമായ ഫോട്ടോകൾ അപ്ലോഡ് ചെയ്യുക, വീണ്ടും ശ്രമിക്കുക.",
    },
    submit: "മത്സരത്തിൽ ചേർക്കുക",
    photosUploading: "ഫോട്ടോ അപ്ലോഡുചെയ്യുന്നു...",
    success: "നിങ്ങളുടെ പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്തു",
    errors: {
      name: "താങ്കളുടെ പേര് ചൊല്ലുക",
      photos: "3 ചിത്രങ്ങൾ തിരഞ്ഞെടുക്കുക",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "പ്രധാന ഫോട്ടോ",
    noFace: "നിങ്ങളുടെ മുഖം കണ്ടെത്തിയില്ല",
    otherPeople: "ഫോട്ടോയിൽ നിങ്ങളുടെ മുഖം മാത്രം കാണണം",
  },
};
