export default {
  g: {
    flag: "🇸🇦",
    langName: "Arabic",
    langNameNative: "العربية",
    continue: "متابعة",
    save: "حفظ",
    retry: "إعادة المحاولة",
    loading: "جارٍ التحميل...",
    genderMany: {
      all: "الكل",
      male: "الذكور",
      female: "الإناث",
    },
  },
  CreateAccount: {
    slogan: "المكان الذي تتحول فيه الأحلام إلى انتصارات!",
    gender: {
      title: "مرحبًا {{name}}، أنت...",
      male: "ذكر",
      female: "أنثى",
    },
  },
  Settings: {
    title: "الإعدادات",
    language: "اللغة",
    auto: "تلقائي",
    country: "البلد",
    hideProfile: {
      label: "إخفاء الملف الشخصي",
      description: "سيتم إخفاء ملفك الشخصي عن الجميع، كما لو كنت قد حذفته.",
    },
    profileHidden: "تم إخفاء ملفك الشخصي وغير مرئي للمستخدمين الآخرين.",
  },
  Tabs: {
    vote: "تصويت",
    contest: "مسابقة",
    friends: "الأصدقاء",
    earn: "ربح",
    wallet: "محفظة",
  },
  Vote: {
    whoIsBetter: "من تحب أكثر؟",
    votingForLeader: "التصويت للقائد",
    noUsers: {
      title: "🚀 هذا كل شيء لليوم",
      description: "ادعوا الأصدقاء للاستمرار في التصويت وكسب العملات",
      inviteFriends: "ادعوا الأصدقاء",
    },
  },
  Leaders: {
    rewards: "المكافآت",
    comingSoon: "قريبًا",
    prizePool: "صندوق الجوائز",
    moreInfo: "المزيد من المعلومات",
    contest: "المسابقة",
    richest: "الأغنى",
    sponsors: "الرعاة",
    description: {
      likes:
        "ادعُ أصدقاءك وشارك ملفك الشخصي لجمع المزيد من الأصوات وزيادة فرصك في الفوز.",
      coins:
        "أكمل المهام، صوّت للمشاركين الآخرين وادعُ أصدقاءك لتحصيل المزيد من العملات وتعزيز موقعك.",
      stars:
        "صوّت للملفات الشخصية على صفحة الملف الشخصي لتحصل على نجوم وتصعد في التصنيفات.",
    },
  },
  PrizePool: {
    text: `<b>المراكز الفائزة بالجوائز</b>:
<b>المركز الأول</b>: 50% دولار أمريكي و10,000 عملة
<b>المركز الثاني</b>: 30% دولار أمريكي و3,000 عملة
<b>المركز الثالث</b>: 20% دولار أمريكي و2,000 عملة
<b>المراكز 4-10</b>: 1,000 عملة لكل منها
<b>المراكز 11-50</b>: 500 عملة لكل منها
<b>المراكز 51-100</b>: 250 عملة لكل منها

<b>صندوق الجوائز</b> يزداد عندما يصوّت لك أحدهم على صفحتك الشخصية.

<b>الشروط</b>:
كل الملفات الشخصية التي ترفع صورة وتكمل المهام مؤهلة للمشاركة في المسابقة.

<b>تاريخ الانتهاء</b>:
{{endDate}}

<b>دفع المكافآت</b>:
يمكنك استلام مكافأتك بعملة <b>TON</b— العملة المشفرة التي تم تطويرها بواسطة Telegram.`,
  },
  LeadersItem: {
    join: "انضم",
  },
  Friends: {
    title: "ادع أصدقاءك!",
    description: "أنت وصديقك ستحصلان على مكافآت",
    bonus: {
      profit: "<coins>+{{count}}</coins> لك ولصديقك",
      invite: {
        title: "ادع صديقاً",
      },
      inviteWithPremium: {
        title: "ادع صديقاً",
        description: "مع تليجرام بريميوم",
      },
    },
    list: {
      title: "قائمة أصدقائك",
    },
    inviteFriend: "ادع صديقاً",
    inviteText: `انضم إلي، شارك في التصويت لأجمل الشباب والفتيات واحصل على جوائز قيمة!

🌟 +{{coins}} عملات كهدية أولى
🔥 +{{coinsPremium}} عملات إذا كنت تمتلك تليجرام بريميوم

🏆 اكسب عملات عن كل تصويت واختر المشاركين الأكثر جاذبية. تريد المزيد؟ شارك بنفسك وتنافس على جوائز قيمة!

لا تفوت فرصتك، كن جزءاً من مجتمع نجومنا واربح!`,
    linkCopied: "تم نسخ الرابط",
  },
  Earn: {
    title: "اكسب المزيد من العملات",
    tasksList: "قائمة المهام",
    check: "تحقق",
    inReview: "قيد المراجعة",
    subscribe: "اشترك",
    participate: "شارك",
    invite: "ادعُ",
    verify: "تحقق",
    taskCompleted: "تم إكمال المهمة",
  },
  Wallet: {
    title: "احصل على المكافآت بعملة TON",
    description: "أكمل التحديات لكسب عملة TON، وهي عملة مشفرة طورتها Telegram",
    rewards: "المكافآت",
    withdrawal: "السحب",
    walletConnected: "محفظة TON الخاصة بك متصلة",
    walletDescription: "ستتوفر المزيد من الفرص قريباً",
  },
  VerificationRequired: {
    title: "التحقق مطلوب",
    description: "للمشاركة في المسابقة، يجب عليك التحقق من حسابك.",
    bdate: {
      title: "تاريخ ميلادك",
    },
  },
  Verification: {
    title: "التحقق",
    hint: "حافظ على وجهك داخل الشكل البيضاوي",
    secureHint: "لن يرى أحد هذه الصورة",
    success: "لقد أكملت التحقق بنجاح",
    goToApp: "اذهب إلى التطبيق",
    verification: "جارٍ التحقق...",
    cameraAccessRequired: "يرجى السماح بالوصول إلى الكاميرا للمتابعة",
    gettingAccessToCamer: "جارٍ الحصول على الوصول إلى الكاميرا...",
    cameraAccessNotAllowed: "تم رفض الوصول إلى الكاميرا من قبل المستخدم.",
    cameraAccessError: "خطأ في الوصول إلى الكاميرا",
    loadingHint: "قد يستغرق هذا بعض الوقت. يرجى التحلي بالصبر.",
  },
  Profile: {
    vote: "صوّت",
    askVote: "اطلب التصويت",
    inviteText: `مرحبًا! 😊 يرجى دعمي بصوتك في مسابقة TON Stars! كل صوت مهم ويساعدني على الاقتراب من الفوز بجوائز قيمة.

🌟 +{{coins}} عملات كهدية أولى
🔥 +{{coinsPremium}} عملات إذا كان لديك Telegram Premium

صوت لملفي الشخصي هنا: {{url}}

شكرًا لدعمك! 🌟`,
    joinContest: "انضم إلى المسابقة",
    gender: {
      male: "ذكر",
      female: "أنثى",
    },
    photos: {
      inReview: "صورك الجديدة قيد المراجعة.\nقد يستغرق ذلك بعض الوقت.",
      rejected:
        "تم رفض صورة واحدة أو أكثر للسبب التالي:\n{{reason}}.\n\nيرجى تحميل صور مختلفة والمحاولة مرة أخرى.",
    },
  },
  ProfileEdit: {
    title: "ملفك الشخصي",
    name: {
      label: "اسمك",
      placeholder: "أدخل اسمك",
    },
    instagram: {
      description: "يجب أن يكون ملف تعريف Instagram عامًا",
    },
    photos: {
      verificationPhotoUploaded:
        "تم تحميل صورة التحقق الخاصة بك، لن يراها أحد.",
      label: "صورك",
      mainPhoto: "الصورة الرئيسية",
      error: "صورة واحدة أو أكثر غير مناسبة، يرجى اختيار أخرى.",
      hint: "اختر صورك الأكثر إبهارًا، حيث لا يوجد أشخاص آخرون، ويكون وجهك واضحًا ومعبرًا.",
      inReview: "صورك الجديدة قيد المراجعة.\nقد يستغرق ذلك بعض الوقت.",
      rejected:
        "تم رفض صورة واحدة أو أكثر للسبب التالي:\n{{reason}}.\n\nيرجى تحميل صور مختلفة والمحاولة مرة أخرى.",
    },
    submit: "انضم إلى المسابقة",
    photosUploading: "جاري تحميل الصورة...",
    success: "تم تحديث ملفك الشخصي",
    errors: {
      name: "يرجى إدخال اسمك",
      photos: "يرجى اختيار 3 صور",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "الصورة الرئيسية",
    noFace: "وجهك غير مكتشف",
    otherPeople: "يجب أن تظهر الصورة وجهك فقط",
  },
};
