export default {
  g: {
    flag: "🇮🇷",
    langName: "Persian",
    langNameNative: "فارسی",
    continue: "ادامه",
    save: "ذخیره",
    retry: "تلاش مجدد",
    loading: "در حال بارگذاری...",
    genderMany: {
      all: "همه",
      male: "مردان",
      female: "زنان",
    },
  },
  CreateAccount: {
    slogan: "جایی که رویاها به پیروزی تبدیل می‌شوند!",
    gender: {
      title: "سلام {{name}}، شما...",
      male: "مرد",
      female: "زن",
    },
  },
  Settings: {
    title: "تنظیمات",
    language: "زبان",
    auto: "خودکار",
    country: "کشور",
    hideProfile: {
      label: "پنهان کردن پروفایل",
      description:
        "پروفایل شما از دید همه پنهان می‌شود، انگار که آن را حذف کرده‌اید.",
    },
    profileHidden:
      "پروفایل شما پنهان شده و برای سایر کاربران قابل مشاهده نیست.",
  },
  Tabs: {
    vote: "رأی",
    contest: "مسابقه",
    friends: "دوستان",
    earn: "کسب درآمد",
    wallet: "کیف پول",
  },
  Vote: {
    whoIsBetter: "کدام را بیشتر دوست دارید؟",
    votingForLeader: "رأی دادن برای رهبر",
    noUsers: {
      title: "🚀 این همه برای امروز است",
      description:
        "دوستان خود را دعوت کنید تا به رأی دادن و کسب سکه‌ها ادامه دهند",
      inviteFriends: "دوستان را دعوت کنید",
    },
  },
  Leaders: {
    rewards: "پاداش‌ها",
    comingSoon: "به زودی",
    prizePool: "استخر جوایز",
    moreInfo: "اطلاعات بیشتر",
    contest: "مسابقه",
    richest: "ثروتمندترین",
    sponsors: "حامیان",
    description: {
      likes:
        "دوستان خود را دعوت کنید و پروفایل خود را به اشتراک بگذارید تا رای‌های بیشتری جمع کنید و شانس خود را برای برنده شدن افزایش دهید.",
      coins:
        "وظایف را کامل کنید، به شرکت کنندگان دیگر رای دهید و دوستان خود را دعوت کنید تا سکه‌های بیشتری کسب کنید و موقعیت خود را تقویت کنید.",
      stars:
        "برای دریافت ستاره و بالا رفتن در رتبه‌بندی‌ها، در صفحه پروفایل به پروفایل‌ها رای دهید.",
    },
  },
  PrizePool: {
    text: `<b>مکان‌های برنده جوایز</b>:
<b>مکان اول</b>: 50% USD و 10,000 سکه
<b>مکان دوم</b>: 30% USD و 3,000 سکه
<b>مکان سوم</b>: 20% USD و 2,000 سکه
<b>مکان‌های چهارم تا دهم</b>: هر کدام 1,000 سکه
<b>مکان‌های یازدهم تا پنجاهم</b>: هر کدام 500 سکه
<b>مکان‌های پنجاه و یکم تا صد</b>: هر کدام 250 سکه

<b>استخر جوایز</b> افزایش می‌یابد وقتی کسی در صفحه پروفایل شما به شما رای دهد.

<b>شرایط</b>:
تمام پروفایل‌هایی که عکس آپلود می‌کنند و وظایف را تکمیل می‌کنند، واجد شرایط شرکت در مسابقه هستند.

<b>تاریخ پایان</b>:
{{endDate}}

<b>پرداخت پاداش</b>:
شما می‌توانید پاداش خود را به <b>TON</b> دریافت کنید — ارز دیجیتالی که توسط تلگرام توسعه یافته است.`,
  },
  LeadersItem: {
    join: "پیوستن",
  },
  Friends: {
    title: "دوستان خود را دعوت کنید!",
    description: "شما و دوستتان پاداش خواهید گرفت",
    bonus: {
      profit: "<coins>+{{count}}</coins> برای شما و دوستتان",
      invite: {
        title: "دعوت دوست",
      },
      inviteWithPremium: {
        title: "دعوت دوست",
        description: "با تلگرام پریمیوم",
      },
    },
    list: {
      title: "لیست دوستان شما",
    },
    inviteFriend: "دعوت دوست",
    inviteText: `به من بپیوندید، در رأی‌گیری برای زیباترین پسران و دختران شرکت کنید و جوایز ارزشمند دریافت کنید!
  
  🌟 +{{coins}} سکه به عنوان هدیه اول
  🔥 +{{coinsPremium}} سکه اگر تلگرام پریمیوم دارید
  
  🏆 برای هر رأی سکه کسب کنید و جذاب‌ترین شرکت‌کنندگان را انتخاب کنید. بیشتر می‌خواهید؟ خودتان شرکت کنید و برای جوایز ارزشمند رقابت کنید!
  
  فرصت خود را از دست ندهید، بخشی از جامعه ستارگان ما شوید و برنده شوید!`,
    linkCopied: "پیوند کپی شد",
  },
  Earn: {
    title: "کسب سکه‌های بیشتر",
    tasksList: "لیست وظایف",
    check: "بررسی",
    inReview: "در حال بررسی",
    subscribe: "اشتراک",
    participate: "شرکت",
    invite: "دعوت",
    verify: "تأیید",
    taskCompleted: "وظیفه تکمیل شد",
  },
  Wallet: {
    title: "در TON پاداش بگیرید",
    description:
      "چالش‌ها را تکمیل کنید تا TON کسب کنید، یک ارز دیجیتال که توسط تلگرام توسعه یافته است",
    rewards: "پاداش‌ها",
    withdrawal: "برداشت",
    walletConnected: "کیف پول TON شما متصل است",
    walletDescription: "به زودی فرصت‌های بیشتری در دسترس خواهد بود",
  },
  VerificationRequired: {
    title: "تأیید لازم است",
    description: "برای شرکت در مسابقه باید حساب خود را تأیید کنید.",
    bdate: {
      title: "تاریخ تولد شما",
    },
  },
  Verification: {
    title: "تأیید",
    hint: "صورت خود را در داخل بیضی نگه دارید",
    secureHint: "هیچ‌کس این عکس را نخواهد دید",
    success: "شما با موفقیت تأیید شدید",
    goToApp: "به برنامه بروید",
    verification: "در حال تأیید...",
    cameraAccessRequired: "لطفاً برای ادامه دسترسی به دوربین را مجاز کنید",
    gettingAccessToCamer: "دسترسی به دوربین دریافت می‌شود...",
    cameraAccessNotAllowed: "دسترسی به دوربین توسط کاربر رد شد.",
    cameraAccessError: "خطای دسترسی به دوربین",
    loadingHint: "این ممکن است کمی زمان ببرد. لطفاً صبور باشید.",
  },
  Profile: {
    vote: "رای دهید",
    askVote: "درخواست رای",
    inviteText: `سلام! 😊 لطفا با رای خود از من در مسابقه TON Stars حمایت کنید! هر رای حیاتی است و به من کمک می‌کند تا به برنده شدن جوایز ارزشمند نزدیک‌تر شوم.

🌟 +{{coins}} سکه به عنوان اولین هدیه
🔥 +{{coinsPremium}} سکه اگر تلگرام پرمیوم دارید

به پروفایل من رای دهید: {{url}}

از حمایت شما سپاسگزارم! 🌟`,
    joinContest: "شرکت در مسابقه",
    gender: {
      male: "مرد",
      female: "زن",
    },
    photos: {
      inReview:
        "عکس‌های جدید شما در حال بررسی هستند.\nاین ممکن است مدتی طول بکشد.",
      rejected:
        "یک یا چند عکس به دلایل زیر رد شده‌اند:\n{{reason}}.\n\nلطفاً عکس‌های دیگری بارگذاری کنید و دوباره امتحان کنید.",
    },
  },
  ProfileEdit: {
    title: "پروفایل شما",
    name: {
      label: "نام شما",
      placeholder: "نام خود را وارد کنید",
    },
    instagram: {
      description: "پروفایل اینستاگرام باید عمومی باشد",
    },
    photos: {
      verificationPhotoUploaded:
        "عکس تأیید شما بارگذاری شده است، هیچ‌کس آن را نخواهد دید.",
      label: "عکس‌های شما",
      mainPhoto: "عکس اصلی",
      error: "یک یا چند عکس مناسب نیستند، لطفاً عکس‌های دیگری انتخاب کنید.",
      hint: "عکس‌های تاثیرگذار خود را انتخاب کنید، جایی که هیچ فرد دیگری وجود ندارد و چهره شما به وضوح و به طور واضح قابل مشاهده است.",
      inReview:
        "عکس‌های جدید شما در حال بررسی هستند.\nاین ممکن است مدتی طول بکشد.",
      rejected:
        "یک یا چند عکس به دلایل زیر رد شده‌اند:\n{{reason}}.\n\nلطفاً عکس‌های دیگری بارگذاری کنید و دوباره امتحان کنید.",
    },
    submit: "شرکت در مسابقه",
    photosUploading: "در حال بارگذاری عکس...",
    success: "پروفایل شما به روز شد",
    errors: {
      name: "لطفاً نام خود را وارد کنید",
      photos: "لطفاً ۳ عکس انتخاب کنید",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "عکس اصلی",
    noFace: "چهره شما شناسایی نشد",
    otherPeople: "عکس باید فقط چهره شما را نشان دهد",
  },
};
