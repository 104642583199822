export default {
  g: {
    flag: "🇳🇴",
    langName: "Norwegian Bokmål",
    langNameNative: "Norsk Bokmål",
    continue: "Fortsett",
    save: "Lagre",
    retry: "Prøv igjen",
    loading: "Laster...",
    genderMany: {
      all: "Alle",
      male: "Menn",
      female: "Kvinner",
    },
  },
  CreateAccount: {
    slogan: "Stedet der drømmer blir til seire!",
    gender: {
      title: "Hei {{name}}, Du er...",
      male: "Mann",
      female: "Kvinne",
    },
  },
  Settings: {
    title: "Innstillinger",
    language: "Språk",
    auto: "Automatisk",
    country: "Land",
    hideProfile: {
      label: "Skjul profil",
      description:
        "Profilen din vil bli skjult for alle, som om du hadde slettet den.",
    },
    profileHidden: "Profilen din er skjult og ikke synlig for andre brukere.",
  },
  Tabs: {
    vote: "Stem",
    contest: "Konkurranse",
    friends: "Venner",
    earn: "Tjen",
    wallet: "Lommebok",
  },
  Vote: {
    whoIsBetter: "Hvem liker du best?",
    votingForLeader: "Stemmer for lederen",
    noUsers: {
      title: "🚀 Det var alt for i dag",
      description: "Inviter venner for å fortsette å stemme og tjene mynter",
      inviteFriends: "Inviter Venner",
    },
  },
  Leaders: {
    rewards: "Belønninger",
    comingSoon: "Kommer snart",
    prizePool: "Premiepott",
    moreInfo: "Mer informasjon",
    contest: "Konkurranse",
    richest: "Rikeste",
    sponsors: "Sponsorer",
    description: {
      likes:
        "Inviter venner og del profilen din for å samle flere stemmer og øke vinnersjansene dine.",
      coins:
        "Fullfør oppgaver, stem på andre deltakere, og inviter venner for å tjene flere mynter og styrke posisjonen din.",
      stars:
        "Stem på profiler på profilsiden for å motta stjerner og klatre på rangeringen.",
    },
  },
  PrizePool: {
    text: `<b>Premievinnende plasser</b>:
<b>1. plass</b>: 50% USD og 10,000 mynter
<b>2. plass</b>: 30% USD og 3,000 mynter
<b>3. plass</b>: 20% USD og 2,000 mynter
<b>4.-10. plass</b>: 1,000 mynter hver
<b>11.-50. plass</b>: 500 mynter hver
<b>51.-100. plass</b>: 250 mynter hver

<b>Premiepott</b> øker når noen stemmer på deg på profilsiden din.

<b>Vilkår</b>:
Alle profiler som laster opp et bilde og fullfører oppgavene er kvalifisert til å delta i konkurransen.

<b>Sluttdato</b>:
{{endDate}}

<b>Utbetaling av belønninger</b>:
Du kan motta belønningen din i <b>TON</b> — kryptovalutaen utviklet av Telegram.`,
  },
  LeadersItem: {
    join: "Bli med",
  },
  Friends: {
    title: "Inviter venner!",
    description: "Du og din venn vil motta bonuser",
    bonus: {
      profit: "<coins>+{{count}}</coins> til deg og din venn",
      invite: {
        title: "Inviter venn",
      },
      inviteWithPremium: {
        title: "Inviter venn",
        description: "med Telegram Premium",
      },
    },
    list: {
      title: "Din venneliste",
    },
    inviteFriend: "Inviter venn",
    inviteText: `Bli med meg, delta i avstemningen for de vakreste guttene og jentene, og få verdifulle premier!
  
  🌟 +{{coins}} mynter som første gave
  🔥 +{{coinsPremium}} mynter hvis du har Telegram Premium
  
  🏆 Tjen mynter for hver stemme og velg de mest attraktive deltakerne. Vil du ha mer? Delta selv og konkurrer om verdifulle premier!
  
  Ikke gå glipp av sjansen din, bli en del av vårt stjernefellesskap og vinn!`,
    linkCopied: "Lenke kopiert",
  },
  Earn: {
    title: "Tjen flere mynter",
    tasksList: "Oppgaveliste",
    check: "Sjekk",
    inReview: "Til gjennomgang",
    subscribe: "Abonner",
    participate: "Delta",
    invite: "Inviter",
    verify: "Verifiser",
    taskCompleted: "Oppgave fullført",
  },
  Wallet: {
    title: "Få belønninger i TON",
    description:
      "Fullfør utfordringer for å tjene TON, en kryptovaluta utviklet av Telegram",
    rewards: "Belønninger",
    withdrawal: "Uttak",
    walletConnected: "Din TON-lommebok er tilkoblet",
    walletDescription: "Flere muligheter blir snart tilgjengelige",
  },
  VerificationRequired: {
    title: "Verifisering kreves",
    description: "For å delta i konkurransen må du verifisere kontoen din.",
    bdate: {
      title: "Din fødselsdato",
    },
  },
  Verification: {
    title: "Verifisering",
    hint: "Hold ansiktet ditt innenfor ovalen",
    secureHint: "Ingen vil se dette bildet",
    success: "Du har fullført verifiseringen",
    goToApp: "Gå til appen",
    verification: "Verifiserer...",
    cameraAccessRequired: "Vennligst tillat kamera tilgang for å fortsette",
    gettingAccessToCamer: "Får tilgang til kamera...",
    cameraAccessNotAllowed: "Bruker nektet kamera tilgang.",
    cameraAccessError: "Kamera tilgangsfeil",
    loadingHint: "Dette kan ta litt tid. Vennligst vær tålmodig.",
  },
  Profile: {
    vote: "Stem",
    askVote: "Be om Stemmer",
    inviteText: `Hei! 😊 Vennligst støtt meg med din stemme i TON Stars-konkurransen! Hver stemme er avgjørende og hjelper meg å komme nærmere å vinne verdifulle premier.

🌟 +{{coins}} mynter som første gave
🔥 +{{coinsPremium}} mynter hvis du har Telegram Premium

Stem på min profil her: {{url}}

Takk for støtten! 🌟`,
    joinContest: "Bli med i konkurransen",
    gender: {
      male: "Mann",
      female: "Kvinne",
    },
    photos: {
      inReview: "Dine nye bilder er under vurdering.\nDette kan ta litt tid.",
      rejected:
        "Ett eller flere bilder ble avvist av følgende grunn:\n{{reason}}.\n\nLast opp andre bilder og prøv igjen.",
    },
  },
  ProfileEdit: {
    title: "Din profil",
    name: {
      label: "Ditt navn",
      placeholder: "Skriv inn ditt navn",
    },
    instagram: {
      description: "Instagram-profilen må være offentlig",
    },
    photos: {
      label: "Dine bilder",
      verificationPhotoUploaded:
        "Verifiseringsbildet ditt er lastet opp, ingen vil se det.",
      mainPhoto: "Hovedbilde",
      error: "Ett eller flere bilder er ikke egnet, vennligst velg andre.",
      hint: "Velg dine mest imponerende bilder, der det ikke er andre mennesker, og ansiktet ditt er klart og uttrykksfullt synlig.",
      inReview: "Dine nye bilder er under vurdering.\nDette kan ta litt tid.",
      rejected:
        "Ett eller flere bilder ble avvist av følgende grunn:\n{{reason}}.\n\nLast opp andre bilder og prøv igjen.",
    },
    submit: "Bli med i konkurransen",
    photosUploading: "Laster opp bilde...",
    success: "Profilen din er oppdatert",
    errors: {
      name: "Angi navnet ditt",
      photos: "Velg 3 bilder",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Hovedbilde",
    noFace: "Ditt ansikt ble ikke oppdaget",
    otherPeople: "Bildet skal bare vise ansiktet ditt",
  },
};
