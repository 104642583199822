export default {
  g: {
    flag: "🇧🇾",
    langName: "Belarusian",
    langNameNative: "Беларуская",
    continue: "Працягнуць",
    save: "Захаваць",
    retry: "Паспрабаваць зноў",
    loading: "Загрузка...",
    genderMany: {
      all: "Усе",
      male: "Мужчыны",
      female: "Жанчыны",
    },
  },
  CreateAccount: {
    slogan: "Месца, дзе мары становяцца перамогамі!",
    gender: {
      title: "Прывітанне {{name}}, Вы...",
      male: "Мужчына",
      female: "Жанчына",
    },
  },
  Settings: {
    title: "Наладкі",
    language: "Мова",
    auto: "Аўтаматычна",
    country: "Краіна",
    hideProfile: {
      label: "Схаваць профіль",
      description:
        "Ваш профіль будзе схаваны для ўсіх, як быццам вы яго выдалілі.",
    },
    profileHidden: "Ваш профіль схаваны і не бачны іншым карыстальнікам.",
  },
  Tabs: {
    vote: "Галасаваць",
    contest: "Конкурс",
    friends: "Сябры",
    earn: "Зарабіць",
    wallet: "Кашалёк",
  },
  Vote: {
    whoIsBetter: "Хто вам больш падабаецца?",
    votingForLeader: "Галасаванне за лідара",
    noUsers: {
      title: "🚀 На сёння ўсё",
      description:
        "Запрасіце сяброў, каб працягнуць галасаванне і зарабіць манеты",
      inviteFriends: "Запрасіць сяброў",
    },
  },
  Leaders: {
    rewards: "Узнагароды",
    comingSoon: "Скора",
    prizePool: "Прызавы фонд",
    moreInfo: "Больш падрабязна",
    contest: "Конкурc",
    richest: "Самыя багатыя",
    sponsors: "Спонсары",
    description: {
      likes:
        "Запрашайце сяброў і дзяліцеся сваім профілем, каб сабраць больш галасоў і павялічыць шанцы на перамогу.",
      coins:
        "Выконвайце заданні, галасуйце за іншых удзельнікаў і запрашайце сяброў, каб зарабіць больш манет і ўмацаваць свае пазіцыі.",
      stars:
        "Галасуйце за профілі на старонцы профілю, каб атрымліваць зоркі і паднімацца ў рэйтынгу.",
    },
  },
  PrizePool: {
    text: `<b>Прызавыя месцы</b>:
<b>1-е месца</b>: 50% USD і 10,000 манет
<b>2-е месца</b>: 30% USD і 3,000 манет
<b>3-е месца</b>: 20% USD і 2,000 манет
<b>4-е-10-е месцы</b>: па 1,000 манет кожнае
<b>11-е-50-е месцы</b>: па 500 манет кожнае
<b>51-е-100-е месцы</b>: па 250 манет кожнае

<b>Прызавы фонд</b> павялічваецца, калі хтосьці галасуе за вас на вашай старонцы профілю.

<b>Умовы</b>:
Усе профілі, якія загрузілі фота і выканалі заданні, маюць права ўдзельнічаць у конкурсе.

<b>Дата заканчэння</b>:
{{endDate}}

<b>Выплата ўзнагарод</b>:
Вы можаце атрымаць сваю ўзнагароду ў <b>TON</b> — крыптавалюце, распрацаванай Telegram.`,
  },
  LeadersItem: {
    join: "Далучыцца",
  },
  Friends: {
    title: "Запрашайце сяброў!",
    description: "Вы і ваш сябар атрымаеце бонусы",
    bonus: {
      profit: "<coins>+{{count}}</coins> для вас і вашага сябра",
      invite: {
        title: "Запрасіць сябра",
      },
      inviteWithPremium: {
        title: "Запрасіць сябра",
        description: "з Telegram Premium",
      },
    },
    list: {
      title: "Спіс вашых сяброў",
    },
    inviteFriend: "Запрасіць сябра",
    inviteText: `Далучайцеся да мяне, галасуйце за самых прыгожых хлопцаў і дзяўчат і атрымлівайце каштоўныя прызы!
  
  🌟 +{{coins}} манет як першы падарунак
  🔥 +{{coinsPremium}} манет, калі ў вас ёсць Telegram Premium
  
  🏆 Зарабляйце манеты за кожны голас і выбірайце самых прывабных удзельнікаў. Хочаце больш? Станьце ўдзельнікам самі і змагайцеся за каштоўныя прызы!
  
  Не прапусціце свой шанец, станьце часткай нашай зорнай супольнасці і выйгравайце!`,
    linkCopied: "Спасылка скапіравана",
  },
  Earn: {
    title: "Зарабляйце больш манет",
    tasksList: "Спіс задач",
    check: "Праверыць",
    inReview: "На разглядзе",
    subscribe: "Падпісацца",
    participate: "Прымаць удзел",
    invite: "Запрасіць",
    verify: "Праверыць",
    taskCompleted: "Задача выканана",
  },
  Wallet: {
    title: "Атрымлівайце ўзнагароды ў TON",
    description:
      "Выконвайце заданні, каб зарабіць TON, криптовалюту, распрацаваную Telegram",
    rewards: "Узнагароды",
    withdrawal: "Вывад сродкаў",
    walletConnected: "Ваш кашалёк TON падключаны",
    walletDescription: "Скора будзе даступна больш магчымасцей",
  },
  VerificationRequired: {
    title: "Патрабуецца праверка",
    description: "Для ўдзелу ў конкурсе трэба праверыць ваш уліковы запіс.",
    bdate: {
      title: "Дата вашага нараджэння",
    },
  },
  Verification: {
    title: "Праверка",
    hint: "Трымайце твар унутры авала",
    secureHint: "Ніхто не ўбачыць гэта фота",
    success: "Вы паспяхова прайшлі праверку",
    goToApp: "Перайсці ў дадатак",
    verification: "Праверка...",
    cameraAccessRequired:
      "Калі ласка, дазвольце доступ да камеры, каб працягнуць",
    gettingAccessToCamer: "Атрымліваем доступ да камеры...",
    cameraAccessNotAllowed: "Доступ да камеры забаронены карыстальнікам.",
    cameraAccessError: "Памылка доступу да камеры",
    loadingHint:
      "Гэта можа заняць некаторы час. Калі ласка, будзьце цярплівыя.",
  },
  Profile: {
    vote: "Галасаваць",
    askVote: "Папрасіць прагаласаваць",
    inviteText: `Прывітанне! 😊 Калі ласка, падтрымай мяне сваім голасам у конкурсе TON Stars! Кожны голас важны і дапаможа мне наблізіцца да перамогі і каштоўных прызоў.

🌟 +{{coins}} манет як першы падарунак
🔥 +{{coinsPremium}} манет, калі ў вас ёсць Telegram Premium

Галасуйце за мой профіль тут: {{url}}

Дзякуй за вашу падтрымку! 🌟`,
    joinContest: "Далучайцеся да конкурсу",
    gender: {
      male: "Мужчынскі",
      female: "Жаночы",
    },
    photos: {
      inReview:
        "Вашы новыя фатаграфіі знаходзяцца на разглядзе.\nГэта можа заняць некаторы час.",
      rejected:
        "Адна ці некалькі фатаграфій былі адхілены па наступнай прычыне:\n{{reason}}.\n\nКалі ласка, загрузіце іншыя фатаграфіі і паспрабуйце зноў.",
    },
  },
  ProfileEdit: {
    title: "Ваш профіль",
    name: {
      label: "Вашае імя",
      placeholder: "Увядзіце ваша імя",
    },
    instagram: {
      description: "Профіль у Instagram павінен быць адкрыты",
    },
    photos: {
      verificationPhotoUploaded:
        "Вашае фота для верыфікацыі загружана, ніхто яго не ўбачыць.",
      label: "Вашы фатаграфіі",
      mainPhoto: "Асноўнае фота",
      error:
        "Адна ці некалькі фатаграфій не падыходзяць, калі ласка, выберыце іншыя.",
      hint: "Выбірайце вашыя найбольш уражлівыя фатаграфіі, дзе няма іншых людзей, і ваша твар ясна і выразна бачны.",
      inReview:
        "Вашы новыя фатаграфіі знаходзяцца на разглядзе.\nГэта можа заняць некаторы час.",
      rejected:
        "Адна ці некалькі фатаграфій былі адхілены па наступнай прычыне:\n{{reason}}.\n\nКалі ласка, загрузіце іншыя фатаграфіі і паспрабуйце зноў.",
    },
    submit: "Далучайцеся да конкурсу",
    photosUploading: "Загрузка фота...",
    success: "Ваш профіль быў абноўлены",
    errors: {
      name: "Укажыце ваша імя",
      photos: "Калі ласка, абярыце 3 фатаграфіі",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Асноўнае фота",
    noFace: "Ваша твар не вызначана",
    otherPeople: "На фатаграфіі павінна быць толькі ваша твар",
  },
};
