export async function preloadImages(
  imageUrls: string[]
): Promise<HTMLImageElement[]> {
  const loadImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
    });
  };

  const promises = imageUrls.map((url) => loadImage(url));
  return Promise.all(promises);
}
