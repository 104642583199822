export default {
  g: {
    flag: "🇺🇿",
    langName: "Uzbek",
    langNameNative: "O'zbekcha",
    continue: "Davom eting",
    save: "Saqlash",
    retry: "Qayta urinib ko'ring",
    loading: "Yuklanmoqda...",
    genderMany: {
      all: "Barchasi",
      male: "Erkaklar",
      female: "Ayollar",
    },
  },
  CreateAccount: {
    slogan: "Orzular g'alabalarga aylanuvchi joy!",
    gender: {
      title: "Salom {{name}}, Siz...",
      male: "Erkak",
      female: "Ayol",
    },
  },
  Settings: {
    title: "Sozlamalar",
    language: "Til",
    auto: "Avtomatik",
    country: "Mamlakat",
    hideProfile: {
      label: "Profilni yashirish",
      description:
        "Profilingiz hamma uchun yashiriladi, xuddi uni o‘chirib tashlagandek.",
    },
    profileHidden:
      "Profilingiz yashirilgan va boshqa foydalanuvchilar uchun ko‘rinmaydi.",
  },
  Tabs: {
    vote: "Ovoz berish",
    contest: "Tanlov",
    friends: "Do'stlar",
    earn: "Daromad",
    wallet: "Hamyon",
  },
  Vote: {
    whoIsBetter: "Sizga kim ko'proq yoqadi?",
    votingForLeader: "Lider uchun ovoz berish",
    noUsers: {
      title: "🚀 Bugun uchun shuncha",
      description:
        "Do'stlarni taklif qilib, ovoz berishni davom eting va tanga oling",
      inviteFriends: "Do'stlarni taklif qiling",
    },
  },
  Leaders: {
    rewards: "Mukofotlar",
    comingSoon: "Tez orada",
    prizePool: "Mukofotlar jamgʻarmasi",
    moreInfo: "Koʻproq maʼlumot",
    contest: "Tanlov",
    richest: "Eng boylar",
    sponsors: "Homiylar",
    description: {
      likes:
        "Doʻstlarni taklif qiling va profilni boʻlishing, koʻproq ovoz toʻplang va yutish imkoniyatlaringizni oshiring.",
      coins:
        "Vazifalarni bajaring, boshqa ishtirokchilar uchun ovoz bering va koʻproq tangalar olish va pozitsiyangizni mustahkamlash uchun doʻstlarni taklif qiling.",
      stars:
        "Profil sahifasidagi profillar uchun ovoz bering, yulduzlarni oling va reytingda yuqoriga koʻtariling.",
    },
  },
  PrizePool: {
    text: `<b>Mukofot o'rinlari</b>:
<b>1-o'rin</b>: 50% USD va 10,000 tanga
<b>2-o'rin</b>: 30% USD va 3,000 tanga
<b>3-o'rin</b>: 20% USD va 2,000 tanga
<b>4-10-o'rinlar</b>: har biri 1,000 tanga
<b>11-50-o'rinlar</b>: har biri 500 tanga
<b>51-100-o'rinlar</b>: har biri 250 tanga

<b>Mukofotlar jamgʻarmasi</b> kimdir siz uchun profilingiz sahifasida ovoz berganda oshadi.

<b>Shartlar</b>:
Rasm yuklagan va vazifalarni bajargan barcha profillar tanlovda ishtirok etishga haqli.

<b>Tugash sanasi</b>:
{{endDate}}

<b>Mukofot toʻlovi</b>:
Siz mukofotingizni Telegram tomonidan ishlab chiqilgan <b>TON</b> kriptovalyutasida olishingiz mumkin.`,
  },
  LeadersItem: {
    join: "Qo'shilish",
  },
  Friends: {
    title: "Do'stlaringizni taklif qiling!",
    description: "Siz va do'stingiz bonus olasiz",
    bonus: {
      profit: "<coins>+{{count}}</coins> siz va do'stingiz uchun",
      invite: {
        title: "Do'stni taklif qiling",
      },
      inviteWithPremium: {
        title: "Do'stni taklif qiling",
        description: "Telegram Premium bilan",
      },
    },
    list: {
      title: "Sizning do'stlaringiz ro'yxati",
    },
    inviteFriend: "Do'stni taklif qiling",
    inviteText: `Menga qo'shiling, eng chiroyli yigitlar va qizlar uchun ovoz bering va qimmatbaho sovrinlar yutib oling!
  
  🌟 Birinchi sovg'a sifatida +{{coins}} tanga
  🔥 Agar Telegram Premium bo'lsa +{{coinsPremium}} tanga
  
  🏆 Har bir ovoz uchun tanga oling va eng jozibali ishtirokchilarni tanlang. Ko'proq istaysizmi? O'zingiz ishtirok eting va qimmatbaho sovrinlar uchun raqobat qiling!
  
  Shansingizni qo'ldan boy bermang, yulduzlar jamiyatimizning bir qismi bo'ling va yutib oling!`,
    linkCopied: "Havola nusxalandi",
  },
  Earn: {
    title: "Ko'proq tanga oling",
    tasksList: "Vazifalar ro'yxati",
    check: "Tekshirish",
    inReview: "Ko'rib chiqilmoqda",
    subscribe: "Obuna bo'lish",
    participate: "Ishtirok etish",
    invite: "Taklif qilish",
    verify: "Tasdiqlash",
    taskCompleted: "Vazifa bajarildi",
  },
  Wallet: {
    title: "TON-da mukofotlar oling",
    description:
      "Telegram tomonidan ishlab chiqilgan TON kripto pulini ishlab topish uchun topshiriqlarni bajaring",
    rewards: "Mukofotlar",
    withdrawal: "Pul yechish",
    walletConnected: "TON hamyoningiz ulangan",
    walletDescription: "Yaqin orada ko'proq imkoniyatlar mavjud bo'ladi",
  },
  VerificationRequired: {
    title: "Tasdiqlash talab qilinadi",
    description:
      "Tanlovda ishtirok etish uchun hisobingizni tasdiqlashingiz kerak.",
    bdate: {
      title: "Tug'ilgan sanangiz",
    },
  },
  Verification: {
    title: "Tasdiqlash",
    hint: "Yuzingizni oval ichida saqlang",
    secureHint: "Bu fotosuratni hech kim ko'rmaydi",
    success: "Siz tasdiqlashni muvaffaqiyatli yakunladingiz",
    goToApp: "Ilovaga o'tish",
    verification: "Tasdiqlanmoqda...",
    cameraAccessRequired:
      "Davom etish uchun iltimos, kamera kirishini ruxsat qiling",
    gettingAccessToCamer: "Kamera kirishi olinmoqda...",
    cameraAccessNotAllowed:
      "Foydalanuvchi tomonidan kamera kirishi rad etildi.",
    cameraAccessError: "Kamera kirishi xatosi",
    loadingHint: "Bu biroz vaqt olishi mumkin. Iltimos, sabr qiling.",
  },
  Profile: {
    vote: "Ovoz bering",
    askVote: "Ovoz so'rang",
    inviteText: `Salom! 😊 Iltimos, TON Stars tanlovida ovozingiz bilan meni qo'llab-quvvatlang! Har bir ovoz muhim va qimmatbaho sovrinlarni yutishga yordam beradi.

🌟 Birinchi sovg'a sifatida +{{coins}} tanga
🔥 Agar sizda Telegram Premium bo'lsa +{{coinsPremium}} tanga

Mening profilim uchun bu yerda ovoz bering: {{url}}

Qo'llab-quvvatlaganingiz uchun rahmat! 🌟`,
    joinContest: "Tanlovga qo'shilish",
    gender: {
      male: "Erkak",
      female: "Ayol",
    },
    photos: {
      inReview:
        "Yangi fotosuratlaringiz ko'rib chiqilmoqda.\nBu biroz vaqt talab qilishi mumkin.",
      rejected:
        "Bir yoki bir nechta fotosurat quyidagi sababga ko'ra rad etildi:\n{{reason}}.\n\nIltimos, boshqa fotosuratlarni yuklab ko'ring va yana urinib ko'ring.",
    },
  },
  ProfileEdit: {
    title: "Profilingiz",
    name: {
      label: "Ismingiz",
      placeholder: "Ismingizni kiriting",
    },
    instagram: {
      description: "Instagram profili ochiq bo'lishi kerak",
    },
    photos: {
      verificationPhotoUploaded:
        "Tasdiqlash fotosuratingiz yuklandi, hech kim ko'rmaydi.",
      label: "Fotosuratlaringiz",
      mainPhoto: "Asosiy foto",
      error:
        "Bir yoki bir nechta fotosuratlar mos kelmaydi, iltimos boshqa tanlov qiling.",
      hint: "Boshqa odamlar yo'q bo'lgan, yuzingiz aniq va ifodali ko'rinadigan eng ta'sirli fotosuratlaringizni tanlang.",
      inReview:
        "Yangi fotosuratlaringiz ko'rib chiqilmoqda.\nBu biroz vaqt talab qilishi mumkin.",
      rejected:
        "Bir yoki bir nechta fotosurat quyidagi sababga ko'ra rad etildi:\n{{reason}}.\n\nIltimos, boshqa fotosuratlarni yuklab ko'ring va yana urinib ko'ring.",
    },
    submit: "Tanlovga qo'shilish",
    photosUploading: "Fotoni yuklash...",
    success: "Profilingiz yangilandi",
    errors: {
      name: "Ismingizni kiriting",
      photos: "Iltimos, 3 ta fotosurat tanlang",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Asosiy foto",
    noFace: "Yuzingiz aniqlanmadi",
    otherPeople: "Fotosurat faqat sizning yuzingizni ko'rsatishi kerak",
  },
};
