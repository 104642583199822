export default {
  g: {
    flag: "🇰🇭",
    langName: "Khmer",
    langNameNative: "ខ្មែរ",
    continue: "បន្ត",
    save: "រក្សាទុក",
    retry: "សាកល្បងម្តងទៀត",
    loading: "កំពុងផ្ទុក...",
    genderMany: {
      all: "ទាំងអស់",
      male: "បុរស",
      female: "ស្ត្រី",
    },
  },
  CreateAccount: {
    slogan: "កន្លែងដែលក្តីស្រមៃបានផ្លាស់ប្តូរទៅជាការឈ្នះ!",
    gender: {
      title: "សួស្ដី {{name}}, អ្នកគឺជា...",
      male: "បុរស",
      female: "ស្ត្រី",
    },
  },
  Settings: {
    title: "ការកំណត់",
    language: "ភាសា",
    auto: "ស្វ័យប្រវត្តិ",
    country: "ប្រទេស",
    hideProfile: {
      label: "លាក់ប្រវត្តិរូប",
      description:
        "ប្រវត្តិរូបរបស់អ្នកនឹងត្រូវលាក់ពីគេគ្រប់គ្នា ដូចជាអ្នកបានលុបវាហើយ។",
    },
    profileHidden:
      "ប្រវត្តិរូបរបស់អ្នកត្រូវបានលាក់ ហើយមិនអាចមើលឃើញដោយអ្នកប្រើផ្សេងទៀតទេ។",
  },
  Tabs: {
    vote: "បោះឆ្នោត",
    contest: "ប្រកួត",
    friends: "មិត្តភក្តិ",
    earn: "រកប្រាក់",
    wallet: "កាបូប",
  },
  Vote: {
    whoIsBetter: "អ្នកចូលចិត្តនរណាជាង?",
    votingForLeader: "បោះឆ្នោតឱ្យមេដឹកនាំ",
    noUsers: {
      title: "🚀 នេះគឺជាអ្វីៗសម្រាប់ថ្ងៃនេះ",
      description: "អញ្ជើញមិត្តភក្តិដើម្បីបន្តការបោះឆ្នោត និងរកប្រាក់",
      inviteFriends: "អញ្ជើញមិត្តភក្តិ",
    },
  },
  Leaders: {
    rewards: "រង្វាន់",
    comingSoon: "មកដល់ឆាប់ៗ",
    prizePool: "អាងរង្វាន់",
    moreInfo: "ព័ត៌មានបន្ថែម",
    contest: "ការប្រកួតប្រជែង",
    richest: "សម្បូរបែបបំផុត",
    sponsors: "អ្នកឧបត្ថម្ភ",
    description: {
      likes:
        "អញ្ជើញមិត្តភក្តិ និងចែករំលែកប្រវត្តិរូបរបស់អ្នកដើម្បីប្រមូលបានឆ្នោតច្រើន និងបង្កើនឱកាសឈ្នះរបស់អ្នក។",
      coins:
        "បំពេញភារកិច្ច ឆ្នោតឱ្យអ្នកចូលរួមផ្សេងទៀត និងអញ្ជើញមិត្តភក្តិដើម្បីចំណូលបានកាក់ច្រើន និងពង្រឹងមុខតំណែងរបស់អ្នក។",
      stars:
        "ឆ្នោតឱ្យប្រវត្តិរូបនៅលើទំព័រប្រវត្តិរូបដើម្បីទទួលបានផ្កាយ និងឡើងក្នុងចំណាត់ថ្នាក់។",
    },
  },
  PrizePool: {
    text: `<b>កន្លែងឈ្នះរង្វាន់</b>:
<b>កន្លែងទី 1</b>: 50% USD និង 10,000 កាក់
<b>កន្លែងទី 2</b>: 30% USD និង 3,000 កាក់
<b>កន្លែងទី 3</b>: 20% USD និង 2,000 កាក់
<b>កន្លែងទី 4-10</b>: 1,000 កាក់ម្នាក់ៗ
<b>កន្លែងទី 11-50</b>: 500 កាក់ម្នាក់ៗ
<b>កន្លែងទី 51-100</b>: 250 កាក់ម្នាក់ៗ

<b>អាងរង្វាន់</b>កើនឡើងនៅពេលមាននរណាម្នាក់ឆ្នោតឱ្យអ្នកនៅលើទំព័រប្រវត្តិរូបរបស់អ្នក។

<b>លក្ខខណ្ឌ</b>:
ប្រវត្តិរូបទាំងអស់ដែលបញ្ចូលរូបថត និងបំពេញភារកិច្ចមានសិទ្ធិចូលរួមក្នុងការប្រកួតប្រជែង។

<b>កាលបរិច្ឆេទបញ្ចប់</b>:
{{endDate}}

<b>ការទូទាត់រង្វាន់</b>:
អ្នកអាចទទួលបានរង្វាន់របស់អ្នកជាមួយ <b>TON</b> — គ្រីបតូដែលបានអភិវឌ្ឍដោយ Telegram។`,
  },
  LeadersItem: {
    join: "ចូលរួម",
  },
  Friends: {
    title: "អញ្ជើញមិត្តភក្តិ!",
    description: "អ្នកនិងមិត្តរបស់អ្នកនឹងទទួលបានបូណូស",
    bonus: {
      profit: "<coins>+{{count}}</coins> សម្រាប់អ្នកនិងមិត្តរបស់អ្នក",
      invite: {
        title: "អញ្ជើញមិត្ត",
      },
      inviteWithPremium: {
        title: "អញ្ជើញមិត្ត",
        description: "ជាមួយ Telegram Premium",
      },
    },
    list: {
      title: "បញ្ជីមិត្តរបស់អ្នក",
    },
    inviteFriend: "អញ្ជើញមិត្ត",
    inviteText: `ចូលរួមជាមួយខ្ញុំ ចូលរួមបោះឆ្នោតសម្រាប់បុរសនិងស្ត្រីស្អាតបំផុត ហើយទទួលបានរង្វាន់មានតម្លៃ!
  
  🌟 +{{coins}} កាក់ជាអំណោយដំបូង
  🔥 +{{coinsPremium}} កាក់ប្រសិនបើអ្នកមាន Telegram Premium
  
  🏆 រកកាក់សម្រាប់គ្រប់ការបោះឆ្នោត និង ជ្រើសរើសអ្នកចូលរួមដែលមានសម្រស់។ ចង់បានច្រើនទៀតមែនទេ? ចូលរួមដោយខ្លួនឯង និង ប្រកួតសម្រាប់រង្វាន់មានតម្លៃ!
  
  កុំខកខានឱកាសរបស់អ្នក ក្លាយជាផ្នែកនៃសហគមន៍ផ្កាយរបស់យើង ហើយឈ្នះ!`,
    linkCopied: "បានចម្លងតំណ",
  },
  Earn: {
    title: "រកកាក់ច្រើនទៀត",
    tasksList: "បញ្ជីភារកិច្ច",
    check: "ពិនិត្យ",
    inReview: "កំពុងពិនិត្យ",
    subscribe: "ជាវ",
    participate: "ចូលរួម",
    invite: "អញ្ជើញ",
    verify: "ផ្ទៀងផ្ទាត់",
    taskCompleted: "ភារកិច្ចបានបញ្ចប់",
  },
  Wallet: {
    title: "ទទួលបានរង្វាន់ក្នុង TON",
    description:
      "បញ្ចប់បញ្ហាដើម្បីរកប្រាក់ TON ដែលជាគ្រឿងអាល័យដែលបានអភិវឌ្ឍដោយ Telegram",
    rewards: "រង្វាន់",
    withdrawal: "ដកប្រាក់",
    walletConnected: "កាបូប TON របស់អ្នកត្រូវបានភ្ជាប់",
    walletDescription: "ឱកាសច្រើនទៀតនឹងមាននៅឆាប់ៗ",
  },
  VerificationRequired: {
    title: "តម្រូវការផ្ទៀងផ្ទាត់",
    description: "ដើម្បីចូលរួមប្រកួត អ្នកត្រូវផ្ទៀងផ្ទាត់គណនីរបស់អ្នក។",
    bdate: {
      title: "ថ្ងៃខែឆ្នាំកំណើតរបស់អ្នក",
    },
  },
  Verification: {
    title: "ផ្ទៀងផ្ទាត់",
    hint: "រក្សាមុខរបស់អ្នកនៅក្នុងរង្វង់អូវ៉ាល់",
    secureHint: "គ្មានអ្នកណាឃើញរូបថតនេះទេ",
    success: "អ្នកបានបញ្ចប់ការផ្ទៀងផ្ទាត់ដោយជោគជ័យ",
    goToApp: "ទៅកាន់កម្មវិធី",
    verification: "កំពុងផ្ទៀងផ្ទាត់...",
    cameraAccessRequired: "សូមអនុញ្ញាតឱ្យចូលប្រើកាមេរ៉ាដើម្បីបន្ត",
    gettingAccessToCamer: "កំពុងទទួលចូលដំណើរការកាមេរ៉ា...",
    cameraAccessNotAllowed: "ការចូលប្រើកាមេរ៉ាត្រូវបានបដិសេធដោយអ្នកប្រើប្រាស់។",
    cameraAccessError: "កំហុសក្នុងការចូលដំណើរការកាមេរ៉ា",
    loadingHint: "វាអាចចំណាយពេលខ្លះ។ សូមអត់ធ្មត់។",
  },
  Profile: {
    vote: "បោះឆ្នោត",
    askVote: "ស្នើសុំបោះឆ្នោត",
    inviteText: `សួស្តី! 😊 សូមគាំទ្រខ្ញុំជាមួយនឹងការបោះឆ្នោតរបស់អ្នកនៅក្នុងការប្រកួត TON Stars! ការបោះឆ្នោតគ្រប់ៗគ្នាគឺសំខាន់ណាស់ ហើយជួយឱ្យខ្ញុំកាន់តែជិតឈ្នះរង្វាន់ដែលមានតម្លៃ។

🌟 +{{coins}} កាក់ជាអំណោយដំបូង
🔥 +{{coinsPremium}} កាក់ប្រសិនបើអ្នកមាន Telegram Premium

បោះឆ្នោតសម្រាប់ប្រវត្តិរូបរបស់ខ្ញុំនៅទីនេះ: {{url}}

អរគុណសម្រាប់ការគាំទ្ររបស់អ្នក! 🌟`,
    joinContest: "ចូលរួមការប្រកួត",
    gender: {
      male: "ប្រុស",
      female: "ស្រី",
    },
    photos: {
      inReview:
        "រូបថតថ្មីរបស់អ្នកកំពុងសម្រាប់ពិនិត្យ។\nវាអាចត្រូវចំណាយពេលបន្តិច។",
      rejected:
        "រូបថតមួយឬច្រើនត្រូវបានបដិសេធដោយសារតែ:\n{{reason}}។\n\nសូមផ្ទុករូបថតផ្សេងទៀតឡើងហើយសាកល្បងម្ដងទៀត។",
    },
  },
  ProfileEdit: {
    title: "ពត៌មានរបស់អ្នក",
    name: {
      label: "ឈ្មោះរបស់អ្នក",
      placeholder: "បញ្ចូលឈ្មោះរបស់អ្នក",
    },
    instagram: {
      description: "គណនី Instagram ត្រូវតែជាសាធារណៈ",
    },
    photos: {
      verificationPhotoUploaded:
        "រូបថតផ្ទៀងផ្ទាត់របស់អ្នកត្រូវបានផ្ទុកឡើងហើយគ្មាននរណាម្នាក់បានឃើញវាទេ។",
      label: "រូបថតរបស់អ្នក",
      mainPhoto: "រូបថតសំខាន់",
      error: "រូបថតមួយឬច្រើនមិនសមរម្យទេ សូមជ្រើសរើសរូបថតផ្សេងទៀត។",
      hint: "ជ្រើសរើសរូបថតដែលមានភាពចំអស្ចារ្យបំផុតរបស់អ្នក ដែលនៅក្នុងនោះគ្មានមនុស្សផ្សេងទៀត ហើយមុខរបស់អ្នកត្រូវបានមើលឃើញយ៉ាងច្បាស់។",
      inReview:
        "រូបថតថ្មីរបស់អ្នកកំពុងសម្រាប់ពិនិត្យ។\nវាអាចត្រូវចំណាយពេលបន្តិច។",
      rejected:
        "រូបថតមួយឬច្រើនត្រូវបានបដិសេធដោយសារតែ:\n{{reason}}។\n\nសូមផ្ទុករូបថតផ្សេងទៀតឡើងហើយសាកល្បងម្ដងទៀត។",
    },
    submit: "ចូលរួមការប្រកួត",
    photosUploading: "កំពុងផ្ទុករូបថតឡើង...",
    success: "ពត៌មានរបស់អ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាព",
    errors: {
      name: "សូមបញ្ជាក់ឈ្មោះរបស់អ្នក",
      photos: "សូមជ្រើសរើសរូបថត ៣សន្លឹក",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "រូបថតសំខាន់",
    noFace: "មុខរបស់អ្នកមិនត្រូវបានរកឃើញទេ",
    otherPeople: "រូបថតគួរតែបង្ហាញតែមុខរបស់អ្នកប៉ុណ្ណោះ",
  },
};
