export default {
  g: {
    flag: "🇮🇳",
    langName: "Kannada",
    langNameNative: "ಕನ್ನಡ",
    continue: "ಮುಂದುವರಿಯಿರಿ",
    save: "ಉಳಿಸಿ",
    retry: "ಮರುಪ್ರಯತ್ನಿಸಿ",
    loading: "ಲೋಡ್ ಆಗುತ್ತಿದೆ...",
    genderMany: {
      all: "ಎಲ್ಲಾ",
      male: "ಪುರುಷರು",
      female: "ಮಹಿಳೆಗಳು",
    },
  },
  CreateAccount: {
    slogan: "ಸಪ್ಪನಗಳು ಗೆಲುವುಗಳಾಗುವ ಸ್ಥಳ!",
    gender: {
      title: "ಹಲೋ {{name}}, ನೀವು...",
      male: "ಪುರುಷ",
      female: "ಹೆಣ್ಣು",
    },
  },
  Settings: {
    title: "ಸಂಯೋಜನೆಗಳು",
    language: "ಭಾಷೆ",
    auto: "ಸ್ವಯಂಚಾಲಿತ",
    country: "ದೇಶ",
    hideProfile: {
      label: "ಪ್ರೊಫೈಲ್ ಮರೆಮಾಡಿ",
      description:
        "ನೀವು ಅದನ್ನು ಅಳಿಸಿದಂತೆಯೇ ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಎಲ್ಲರಿಗೂ ಮರೆಮಾಡಲಾಗುತ್ತದೆ.",
    },
    profileHidden: "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಮರೆಮಾಡಲಾಗಿದೆ ಮತ್ತು ಇತರ ಬಳಕೆದಾರರಿಗೆ ಕಾಣಿಸದು.",
  },
  Tabs: {
    vote: "ಮತ",
    contest: "ಸ್ಪರ್ಧೆ",
    friends: "ಸ್ನೇಹಿತರು",
    earn: "ಸಂಪಾದನೆ",
    wallet: "ವಾಲೆಟ್",
  },
  Vote: {
    whoIsBetter: "ನೀವು ಯಾರನ್ನು ಹೆಚ್ಚು ಇಷ್ಟಪಡುತ್ತೀರಿ?",
    votingForLeader: "ನಾಯಕನಿಗಾಗಿ ಮತದಾನ",
    noUsers: {
      title: "🚀 ಇವತ್ತಿನ ಪಾಠ ಮುಗಿತು",
      description:
        "ಮತದಾನವನ್ನು ಮುಂದುವರಿಸಲು ಮತ್ತು ನಾಣ್ಯಗಳನ್ನು ಸಂಪಾದಿಸಲು ಸ್ನೇಹಿತರನ್ನು ಆಹ್ವಾನಿಸಿ",
      inviteFriends: "ಸ್ನೇಹಿತರನ್ನು ಆಹ್ವಾನಿಸಿ",
    },
  },
  Leaders: {
    rewards: "ಪ್ರಶಸ್ತಿಗಳು",
    comingSoon: "ಶೀಘ್ರದಲ್ಲೇ ಬರಲಿದೆ",
    prizePool: "ಪ್ರಶಸ್ತಿ ನಿಧಿ",
    moreInfo: "ಹೆಚ್ಚಿನ ಮಾಹಿತಿ",
    contest: "ಸ್ಪರ್ಧೆ",
    richest: "ಅತ್ಯಂತ ಶ್ರೀಮಂತರು",
    sponsors: "ಹೆಚ್ಚುವರಿ",
    description: {
      likes:
        "ಹೆಚ್ಚು ಮತಗಳನ್ನು ಸಂಗ್ರಹಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಗೆಲುವಿನ ಅವಕಾಶಗಳನ್ನು ಹೆಚ್ಚಿಸಲು ಸ್ನೇಹಿತರನ್ನು ಆಹ್ವಾನಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಹಂಚಿಕೊಳ್ಳಿ.",
      coins:
        "ಕಾರ್ಯಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ, ಇತರ ಭಾಗವಹಿಸುವವರಿಗಾಗಿ ಮತ ನೀಡು ಮತ್ತು ಹೆಚ್ಚಿನ ನಾಣ್ಯಗಳನ್ನು ಗಳಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಸ್ಥಾನವನ್ನು ಬಲಪಡಿಸಲು ಸ್ನೇಹಿತರನ್ನು ಆಹ್ವಾನಿಸಿ.",
      stars:
        "ಪ್ರೊಫೈಲ್ ಪುಟದಲ್ಲಿ ಪ್ರೊಫೈಲ್‌ಗಳಿಗೆ ಮತ ನೀಡುವ ಮೂಲಕ ನಕ್ಷತ್ರಗಳನ್ನು ಪಡೆಯಿರಿ ಮತ್ತು ಶ್ರೇಣಿಯಲ್ಲಿ ಏರಿರಿ.",
    },
  },
  PrizePool: {
    text: `<b>ಪ್ರಶಸ್ತಿ ವಿಜೇತ ಸ್ಥಳಗಳು</b>:
<b>1ನೇ ಸ್ಥಾನ</b>: 50% USD ಮತ್ತು 10,000 ನಾಣ್ಯಗಳು
<b>2ನೇ ಸ್ಥಾನ</b>: 30% USD ಮತ್ತು 3,000 ನಾಣ್ಯಗಳು
<b>3ನೇ ಸ್ಥಾನ</b>: 20% USD ಮತ್ತು 2,000 ನಾಣ್ಯಗಳು
<b>4ನೇ-10ನೇ ಸ್ಥಳಗಳು</b>: ಪ್ರತಿ 1,000 ನಾಣ್ಯಗಳು
<b>11ನೇ-50ನೇ ಸ್ಥಳಗಳು</b>: ಪ್ರತಿ 500 ನಾಣ್ಯಗಳು
<b>51ನೇ-100ನೇ ಸ್ಥಳಗಳು</b>: ಪ್ರತಿ 250 ನಾಣ್ಯಗಳು

<b>ಪ್ರಶಸ್ತಿ ನಿಧಿ</b> ನೀವು ಪ್ರೊಫೈಲ್ ಪುಟದಲ್ಲಿ ನಿಮಗಾಗಿ ಮತ ನೀಡಿದಾಗ ಹೆಚ್ಚುತ್ತದೆ.

<b>ನಿಬಂಧನೆಗಳು</b>:
ಫೋಟೋ ಅಪ್ಲೋಡ್ ಮಾಡುವ ಮತ್ತು ಕಾರ್ಯಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸುವ ಎಲ್ಲಾ ಪ್ರೊಫೈಲ್‌ಗಳು ಸ್ಪರ್ಧೆಯಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳಲು ಅರ್ಹವಾಗಿವೆ.

<b>ಅಂತಿಮ ದಿನಾಂಕ</b>:
{{endDate}}

<b>ಪ್ರಶಸ್ತಿ ಪಾವತಿ</b>:
ನೀವು ನಿಮ್ಮ ಪ್ರಶಸ್ತಿಯನ್ನು <b>TON</b> ನಲ್ಲಿ ಪಡೆಯಬಹುದು — Telegram ನಿರ್ಮಿಸಿದ ಕ್ರಿಪ್ಟೋಕರೆನ್ಸಿ.`,
  },
  LeadersItem: {
    join: "ಸೇರು",
  },
  Friends: {
    title: "ಸ್ನೇಹಿತರನ್ನು ಆಹ್ವಾನಿಸಿ!",
    description: "ನೀವು ಮತ್ತು ನಿಮ್ಮ ಸ್ನೇಹಿತರು ಬೋನಸ್ ಪಡೆಯುತ್ತೀರಿ",
    bonus: {
      profit: "<coins>+{{count}}</coins> ನಿಮ್ಮ ಮತ್ತು ನಿಮ್ಮ ಸ್ನೇಹಿತನಿಗಾಗಿ",
      invite: {
        title: "ಸ್ನೇಹಿತನನ್ನು ಆಹ್ವಾನಿಸಿ",
      },
      inviteWithPremium: {
        title: "ಸ್ನೇಹಿತನನ್ನು ಆಹ್ವಾನಿಸಿ",
        description: "ಟೆಲಿಗ್ರಾಮ್ ಪ್ರೀಮಿಯಂನೊಂದಿಗೆ",
      },
    },
    list: {
      title: "ನಿಮ್ಮ ಸ್ನೇಹಿತರ ಪಟ್ಟಿ",
    },
    inviteFriend: "ಸ್ನೇಹಿತನನ್ನು ಆಹ್ವಾನಿಸಿ",
    inviteText: `ನನ್ನೊಡನೆ ಸೇರಿ, ಅತ್ಯಂತ ಸುಂದರ ಹುಡುಗರಿಗೆ ಮತ್ತು ಹುಡುಗಿಯರಿಗೆ ಮತ ನೀಡಿ ಮತ್ತು ಬೆಲೆಬಾಳುವ ಬಹುಮಾನಗಳನ್ನು ಗೆಲ್ಲಿ!
  
  🌟 ಮೊದಲ ಉಡುಗೊರೆಯಾಗಿ +{{coins}} ನಾಣ್ಯಗಳು
  🔥 ನಿಮ್ಮ ಬಳಿ ಟೆಲಿಗ್ರಾಮ್ ಪ್ರೀಮಿಯಂ ಇದ್ದರೆ +{{coinsPremium}} ನಾಣ್ಯಗಳು
  
  🏆 ಪ್ರತಿ ಮತಕ್ಕೆ ನಾಣ್ಯಗಳನ್ನು ಸಂಪಾದಿಸಿ ಮತ್ತು ಅತ್ಯಂತ ಆಕರ್ಷಕ ಭಾಗವಹಿಸುವವರನ್ನು ಆಯ್ಕೆಮಾಡಿ. ಮತ್ತಷ್ಟು ಬೇಕೇ? ನೀವು ಸ್ವತಃ ಪಾಲ್ಗೊಳ್ಳಿ ಮತ್ತು ಬೆಲೆಬಾಳುವ ಬಹುಮಾನಗಳಿಗೆ ಸ್ಪರ್ಧಿಸಿ!
  
  ನಿಮ್ಮ ಅವಕಾಶವನ್ನು ಕಳೆದುಕೊಳ್ಳಬೇಡಿ, ನಮ್ಮ ನಕ್ಷತ್ರ ಸಮುದಾಯದ ಭಾಗವಾಗಿರಿ ಮತ್ತು ಗೆಲ್ಲಿರಿ!`,
    linkCopied: "ಲಿಂಕ್ ನಕಲಿಸಲಾಗಿದೆ",
  },
  Earn: {
    title: "ಮತ್ತಷ್ಟು ನಾಣ್ಯಗಳನ್ನು ಸಂಪಾದಿಸಿ",
    tasksList: "ಕಾರ್ಯ ಪಟ್ಟಿ",
    check: "ಪರಿಶೀಲಿಸಿ",
    inReview: "ಪುನರಾವಲೋಕನದಲ್ಲಿ",
    subscribe: "ಚಂದಾದಾರರಾಗಿ",
    participate: "ಪಾಲ್ಗೊಳ್ಳಿ",
    invite: "ಆಹ್ವಾನಿಸಿ",
    verify: "ಪರಿಶೀಲಿಸಿ",
    taskCompleted: "ಕಾರ್ಯ ಪೂರ್ಣಗೊಂಡಿದೆ",
  },
  Wallet: {
    title: "TON ನಲ್ಲಿ ಬಹುಮಾನಗಳನ್ನು ಪಡೆಯಿರಿ",
    description:
      "Telegram ಅಭಿವೃದ್ಧಿಪಡಿಸಿರುವ ಕ್ರಿಪ್ಟೊ ನಾಣ್ಯವಾದ TON ಅನ್ನು ಸಂಪಾದಿಸಲು ಸವಾಲುಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ",
    rewards: "ಬಹುಮಾನಗಳು",
    withdrawal: "ವಾಪಸಾತು",
    walletConnected: "ನಿಮ್ಮ TON ವ್ಯಾಲೆಟ್ ಸಂಪರ್ಕಿಸಲಾಗಿದೆ",
    walletDescription: "ಹೆಚ್ಚಿನ ಅವಕಾಶಗಳು ಶೀಘ್ರದಲ್ಲೇ ಲಭ್ಯವಾಗುವವು",
  },
  VerificationRequired: {
    title: "ಪರಿಶೀಲನೆ ಅಗತ್ಯವಿದೆ",
    description: "ಸ್ಪರ್ಧೆಯಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳಲು, ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಪರಿಶೀಲಿಸಬೇಕು.",
    bdate: {
      title: "ನಿಮ್ಮ ಜನ್ಮ ದಿನಾಂಕ",
    },
  },
  Verification: {
    title: "ಪರಿಶೀಲನೆ",
    hint: "ನಿಮ್ಮ ಮುಖವನ್ನು ಓವಲ್ ಒಳಗೆ ಇಡಿ",
    secureHint: "ಯಾರು ಈ ಫೋಟೋವನ್ನು ನೋಡುವುದಿಲ್ಲ",
    success: "ನೀವು ಯಶಸ್ವಿಯಾಗಿ ಪರಿಶೀಲನೆ ಪೂರ್ಣಗೊಳಿಸಿದ್ದೀರಿ",
    goToApp: "ಅಪ್ಲಿಕೇಶನ್ ಗೆ ಹೋಗಿ",
    verification: "ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ...",
    cameraAccessRequired: "ಮುಂದುವರಿಸಲು ದಯವಿಟ್ಟು ಕ್ಯಾಮೆರಾ ಪ್ರವೇಶವನ್ನು ಅನುಮತಿಸಿ",
    gettingAccessToCamer: "ಕ್ಯಾಮೆರಾ ಪ್ರವೇಶ ಪಡೆಯಲಾಗುತ್ತಿದೆ...",
    cameraAccessNotAllowed: "ಬಳಕೆದಾರರಿಂದ ಕ್ಯಾಮೆರಾ ಪ್ರವೇಶ ನಿರಾಕರಿಸಲಾಗಿದೆ.",
    cameraAccessError: "ಕ್ಯಾಮೆರಾ ಪ್ರವೇಶ ದೋಷ",
    loadingHint: "ಇದು ಕೆಲವು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳಬಹುದು. ದಯವಿಟ್ಟು ತಾಳ್ಮೆಯಿರಿರಿ.",
  },
  Profile: {
    vote: "ಮತ",
    askVote: "ಮತ ಕೇಳಿ",
    inviteText: `ಹಲೋ! 😊 TON ಸ್ಟಾರ್ಸ್ ಸ್ಪರ್ಧೆಯಲ್ಲಿ ನಿಮ್ಮ ಮತದಿಂದ ನನ್ನನ್ನು ಬೆಂಬಲಿಸಿ! ಪ್ರತಿಯೊಂದು ಮತವೂ ಬಹಳ ಮುಖ್ಯವಾಗಿದ್ದು, ಬಹುಮುಲ್ಯ ಬಹುಮಾನಗಳನ್ನು ಗೆಲ್ಲಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.

🌟 ಪ್ರಥಮ ಉಡುಗೊರೆಯಾಗಿ +{{coins}} ನಾಣ್ಯಗಳು
🔥 ನೀವು Telegram Premium ಹೊಂದಿದ್ದರೆ +{{coinsPremium}} ನಾಣ್ಯಗಳು

ನನ್ನ ಪ್ರೊಫೈಲ್ ಗೆ ಇಲ್ಲಿ ಮತ ನೀಡಿ: {{url}}

ನಿಮ್ಮ ಬೆಂಬಲಕ್ಕೆ ಧನ್ಯವಾದಗಳು! 🌟`,
    joinContest: "ಸ್ಪರ್ಧೆಯಲ್ಲಿ ಸೇರಿ",
    gender: {
      male: "ಪುರುಷ",
      female: "ಮಹಿಳೆ",
    },
    photos: {
      inReview:
        "ನಿಮ್ಮ ಹೊಸ ಚಿತ್ರಗಳನ್ನು ವಿಮರ್ಶಿಸಲಾಗಿದೆ.\nಇದು ಸ್ವಲ್ಪ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು.",
      rejected:
        "ಒಂದು ಅಥವಾ ಹೆಚ್ಚು ಚಿತ್ರಗಳನ್ನು ಈ ಕಾರಣಕ್ಕಾಗಿ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ:\n{{reason}}.\n\nಬೇರೊಂದು ಚಿತ್ರಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    },
  },
  ProfileEdit: {
    title: "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್",
    name: {
      label: "ನಿಮ್ಮ ಹೆಸರು",
      placeholder: "ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    },
    instagram: {
      description: "Instagram ಪ್ರೊಫೈಲ್ ಸಾರ್ವಜನಿಕವಾಗಿರಬೇಕು",
    },
    photos: {
      verificationPhotoUploaded:
        "ನಿಮ್ಮ ಪರಿಶೀಲನೆ ಫೋಟೋವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ, ಅದನ್ನು ಯಾರೂ ನೋಡುವುದಿಲ್ಲ.",
      label: "ನಿಮ್ಮ ಚಿತ್ರಗಳು",
      mainPhoto: "ಮೇನ್ ಫೋಟೋ",
      error:
        "ಒಂದು ಅಥವಾ ಹೆಚ್ಚು ಚಿತ್ರಗಳು ಯೋಗ್ಯವಿಲ್ಲ, ದಯವಿಟ್ಟು ಮತ್ತೊಂದು ಆಯ್ಕೆ ಮಾಡಿ.",
      hint: "ನಿಮ್ಮ ಅತಿ ಅದ್ಭುತ ಚಿತ್ರಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ, ಅಲ್ಲಿ ಇತರ ವ್ಯಕ್ತಿಗಳು ಇಲ್ಲ, ಮತ್ತು ನಿಮ್ಮ ಮುಖವನ್ನು ಸ್ಪಷ್ಟವಾಗಿ ಮತ್ತು ವಾಕ್ಯಪೂರ್ಣವಾಗಿ ಕಾಣಬಹುದು.",
      inReview:
        "ನಿಮ್ಮ ಹೊಸ ಚಿತ್ರಗಳನ್ನು ವಿಮರ್ಶಿಸಲಾಗಿದೆ.\nಇದು ಸ್ವಲ್ಪ ಸಮಯವನ್ನು ತೆಗೆದುಕೊಳ್ಳಬಹುದು.",
      rejected:
        "ಒಂದು ಅಥವಾ ಹೆಚ್ಚು ಚಿತ್ರಗಳನ್ನು ಈ ಕಾರಣಕ್ಕಾಗಿ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ:\n{{reason}}.\n\nಬೇರೊಂದು ಚಿತ್ರಗಳನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.",
    },
    submit: "ಸ್ಪರ್ಧೆಯಲ್ಲಿ ಸೇರಿ",
    photosUploading: "ಫೋಟೋ ಅಪ್ಲೋಡ್ ಮಾಡಲಾಗುತ್ತಿದೆ...",
    success: "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಲಾಗಿದೆ",
    errors: {
      name: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಹೆಸರು ನಮೂದಿಸಿ",
      photos: "ದಯವಿಟ್ಟು 3 ಫೋಟೊಗಳನ್ನು ಆರಿಸಿ",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "ಮೇನ್ ಫೋಟೋ",
    noFace: "ನಿಮ್ಮ ಮುಖವನ್ನು ಪತ್ತೆ ಮಾಡಲಾಗಿಲ್ಲ",
    otherPeople: "ಚಿತ್ರವು ನಿಮ್ಮ ಮುಖವನ್ನು ಮಾತ್ರ ತೋರಿಸಬೇಕು",
  },
};
