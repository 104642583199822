export default {
  g: {
    flag: "🇺🇳",
    langName: "Esperanto",
    langNameNative: "Esperanto",
    continue: "Daŭrigi",
    save: "Konservi",
    retry: "Reprovi",
    loading: "Ŝarĝante...",
    genderMany: {
      all: "Ĉiuj",
      male: "Viroj",
      female: "Virinoj",
    },
  },
  CreateAccount: {
    slogan: "Loko kie Revoj iĝas Venkoj!",
    gender: {
      title: "Saluton {{name}}, Vi estas...",
      male: "Vira",
      female: "Ina",
    },
  },
  Settings: {
    title: "Agordoj",
    language: "Lingvo",
    auto: "Aŭtomata",
    country: "Lando",
    hideProfile: {
      label: "Kaŝi Profilon",
      description:
        "Via profilo estos kaŝita de ĉiuj, kvazaŭ vi estus forigis ĝin.",
    },
    profileHidden: "Via profilo estas kaŝita kaj ne videblas al aliaj uzantoj.",
  },
  Tabs: {
    vote: "Voĉdoni",
    contest: "Konkurso",
    friends: "Amikoj",
    earn: "Gajni",
    wallet: "Monujo",
  },
  Vote: {
    whoIsBetter: "Kiun vi pli ŝatas?",
    votingForLeader: "Voĉdono por la gvidanto",
    noUsers: {
      title: "🚀 Tio estas ĉio por hodiaŭ",
      description: "Invitu amikojn por daŭrigi voĉdonadon kaj gajni monerojn",
      inviteFriends: "Invitu Amikojn",
    },
  },
  Leaders: {
    rewards: "Rekompencoj",
    comingSoon: "Baldaŭ havebla",
    prizePool: "Premia Fondaĵo",
    moreInfo: "Pli da informoj",
    contest: "Konkurso",
    richest: "Plej riĉaj",
    sponsors: "Sponsoroj",
    description: {
      likes:
        "Invitu amikojn kaj dividu vian profilon por akiri pli da voĉoj kaj pliigi viajn ŝancojn por gajni.",
      coins:
        "Kompletigu taskojn, voĉdonu por aliaj partoprenantoj kaj invitu amikojn por gajni pli da moneroj kaj plifortigi vian pozicion.",
      stars:
        "Voĉdonu por profiloj en la profilpaĝo por ricevi stelojn kaj supreniri en la rangigoj.",
    },
  },
  PrizePool: {
    text: `<b>Premiaj lokoj</b>:
<b>1-a loko</b>: 50% USD kaj 10,000 moneroj
<b>2-a loko</b>: 30% USD kaj 3,000 moneroj
<b>3-a loko</b>: 20% USD kaj 2,000 moneroj
<b>4-a-10-a lokoj</b>: po 1,000 moneroj
<b>11-a-50-a lokoj</b>: po 500 moneroj
<b>51-a-100-a lokoj</b>: po 250 moneroj

<b>La premia fondaĵo</b> pliiĝas kiam iu voĉdonas por vi en via profilpaĝo.

<b>Kondiĉoj</b>:
Ĉiuj profiloj, kiuj alŝutas foton kaj plenumas la taskojn, rajtas partopreni en la konkurso.

<b>Fina dato</b>:
{{endDate}}

<b>Pagon de rekompencoj</b>:
Vi povas ricevi vian rekompencon en <b>TON</b> — la kriptovaluto evoluigita de Telegram.`,
  },
  LeadersItem: {
    join: "Aliĝi",
  },
  Friends: {
    title: "Invitu amikojn!",
    description: "Vi kaj via amiko ricevos bonusojn",
    bonus: {
      profit: "<coins>+{{count}}</coins> por vi kaj via amiko",
      invite: {
        title: "Invitu amikon",
      },
      inviteWithPremium: {
        title: "Invitu amikon",
        description: "kun Telegram Premium",
      },
    },
    list: {
      title: "Via listo de amikoj",
    },
    inviteFriend: "Invitu amikon",
    inviteText: `Aliĝu al mi, partoprenu en voĉdonado por la plej belaj knaboj kaj knabinoj, kaj ricevu valorajn premiojn!
  
  🌟 +{{coins}} moneroj kiel unua donaco
  🔥 +{{coinsPremium}} moneroj se vi havas Telegram Premium
  
  🏆 Gajnu monerojn por ĉiu voĉdono kaj elektu la plej allogajn partoprenantojn. Volas pli? Partoprenu mem kaj konkuru por valoraj premioj!
  
  Ne maltrafu vian ŝancon, fariĝu parto de nia stela komunumo kaj gajnu!`,
    linkCopied: "Ligo kopiita",
  },
  Earn: {
    title: "Gajnu pli da moneroj",
    tasksList: "Taskolisto",
    check: "Kontroli",
    inReview: "Reviziata",
    subscribe: "Aboni",
    participate: "Partopreni",
    invite: "Invitu",
    verify: "Verifi",
    taskCompleted: "Tasko kompleta",
  },
  Wallet: {
    title: "Akiru rekompencojn en TON",
    description:
      "Plenumu defiojn por gajni TON, kriptomonero evoluigita de Telegram",
    rewards: "Rekompencoj",
    withdrawal: "Retiro",
    walletConnected: "Via TON-monujo estas konektita",
    walletDescription: "Pli da ŝancoj baldaŭ disponeblos",
  },
  VerificationRequired: {
    title: "Bezonata Verifiko",
    description: "Por partopreni en la konkurso, vi devas verifi vian konton.",
    bdate: {
      title: "Via naskiĝdato",
    },
  },
  Verification: {
    title: "Verifiko",
    hint: "Tenigu vian vizaĝon ene de la ovalo",
    secureHint: "Neniu vidos ĉi tiun foton",
    success: "Vi sukcese kompletigis la verifikon",
    goToApp: "Iru al apliko",
    verification: "Verifikante...",
    cameraAccessRequired: "Bonvolu permesi aliron al la fotilo por daŭrigi",
    gettingAccessToCamer: "Akirante aliron al la fotilo...",
    cameraAccessNotAllowed: "Aliro al la fotilo malakceptita de la uzanto.",
    cameraAccessError: "Eraro pri fotilo",
    loadingHint: "Ĉi tio povas daŭri iom da tempo. Bonvolu esti pacienca.",
  },
  Profile: {
    vote: "Voĉdoni",
    askVote: "Petu Voĉdonon",
    inviteText: `Saluton! 😊 Bonvolu subteni min per via voĉo en la TON Stars-konkurso! Ĉiu voĉo estas kritika kaj helpas min pli proksimiĝi al gajnado de valoraj premioj.

🌟 +{{coins}} moneroj kiel unua donaco
🔥 +{{coinsPremium}} moneroj se vi havas Telegram Premium

Voĉdonu por mia profilo ĉi tie: {{url}}

Dankon pro via subteno! 🌟`,
    joinContest: "Aliĝi al konkurso",
    gender: {
      male: "Vira",
      female: "Ina",
    },
    photos: {
      inReview:
        "Viaj novaj fotoj estas reviziataj.\nĈi tio povas daŭri iom da tempo.",
      rejected:
        "Unu aŭ pli da fotoj estis malakceptitaj pro la sekva kialo:\n{{reason}}.\n\nBonvolu alŝuti malsamajn fotojn kaj provu denove.",
    },
  },
  ProfileEdit: {
    title: "Via Profilo",
    name: {
      label: "Via Nomo",
      placeholder: "Enigu vian nomon",
    },
    instagram: {
      description: "Instagram-profilo devas esti publika",
    },
    photos: {
      verificationPhotoUploaded:
        "Via kontrola foto estis alŝutita, neniu vidos ĝin.",
      label: "Viaj Fotoj",
      mainPhoto: "Ĉefa Foto",
      error: "Unu aŭ pli da fotoj ne taŭgas, bonvolu fari alian elekton.",
      hint: "Elektu viajn plej impresajn fotojn, kie ne estas aliaj homoj, kaj via vizaĝo estas klare kaj esprime videbla.",
      inReview:
        "Viaj novaj fotoj estas reviziataj.\nĈi tio povas daŭri iom da tempo.",
      rejected:
        "Unu aŭ pli da fotoj estis malakceptitaj pro la sekva kialo:\n{{reason}}.\n\nBonvolu alŝuti malsamajn fotojn kaj provu denove.",
    },
    submit: "Aliĝi al konkurso",
    photosUploading: "Alŝutas foton...",
    success: "Via profilo estis ĝisdatigita",
    errors: {
      name: "Indiku vian nomon",
      photos: "Bonvolu elekti 3 fotojn",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Ĉefa foto",
    noFace: "Via vizaĝo ne estas detektita",
    otherPeople: "La foto devus montri nur vian vizaĝon",
  },
};
