export default {
  g: {
    flag: "🇹🇼",
    langName: "Chinese (Traditional)",
    langNameNative: "繁體中文",
    continue: "繼續",
    save: "保存",
    retry: "重試",
    loading: "加載中...",
    genderMany: {
      all: "全部",
      male: "男性",
      female: "女性",
    },
  },
  CreateAccount: {
    slogan: "夢想成真的地方！",
    gender: {
      title: "你好 {{name}}，您是...",
      male: "男性",
      female: "女性",
    },
  },
  Settings: {
    title: "設置",
    language: "語言",
    auto: "自動",
    country: "國家",
    hideProfile: {
      label: "隱藏個人資料",
      description: "您的個人資料將對所有人隱藏，彷彿您已經刪除它。",
    },
    profileHidden: "您的個人資料已隱藏，其他用戶無法看到。",
  },
  Tabs: {
    vote: "投票",
    contest: "比賽",
    friends: "朋友",
    earn: "賺取",
    wallet: "錢包",
  },
  Vote: {
    whoIsBetter: "你更喜歡誰？",
    votingForLeader: "為領袖投票",
    noUsers: {
      title: "🚀 今天就到這裡",
      description: "邀請朋友繼續投票並賺取硬幣",
      inviteFriends: "邀請朋友",
    },
  },
  Leaders: {
    rewards: "獎勵",
    comingSoon: "即將推出",
    prizePool: "獎金池",
    moreInfo: "更多信息",
    contest: "比賽",
    richest: "最富有",
    sponsors: "贊助商",
    description: {
      likes: "邀請朋友並分享您的個人資料，以獲取更多選票並增加獲勝的機會。",
      coins:
        "完成任務，為其他參與者投票，並邀請朋友以賺取更多硬幣並鞏固您的地位。",
      stars: "在個人資料頁面上為個人資料投票以獲得星星並在排名中攀升。",
    },
  },
  PrizePool: {
    text: `<b>獲獎名次</b>:
<b>第一名</b>: 50% USD 和 10,000 硬幣
<b>第二名</b>: 30% USD 和 3,000 硬幣
<b>第三名</b>: 20% USD 和 2,000 硬幣
<b>第4至10名</b>: 每名 1,000 硬幣
<b>第11至50名</b>: 每名 500 硬幣
<b>第51至100名</b>: 每名 250 硬幣

<b>獎金池</b> 隨著有人在您的個人資料頁面上為您投票而增加。

<b>條件</b>:
所有上傳照片並完成任務的個人資料都有資格參加比賽。

<b>結束日期</b>:
{{endDate}}

<b>獎勵支付</b>:
您可以通過 <b>TON</b> 接收您的獎勵——由 Telegram 開發的加密貨幣。`,
  },
  LeadersItem: {
    join: "加入",
  },
  Friends: {
    title: "邀請朋友！",
    description: "您和您的朋友將獲得獎勵",
    bonus: {
      profit: "<coins>+{{count}}</coins> 給您和您的朋友",
      invite: {
        title: "邀請朋友",
      },
      inviteWithPremium: {
        title: "邀請朋友",
        description: "使用Telegram Premium",
      },
    },
    list: {
      title: "您的朋友列表",
    },
    inviteFriend: "邀請朋友",
    inviteText: `加入我，參與最美男孩和女孩的投票，並獲得寶貴的獎品！
  
  🌟 +{{coins}} 作為首次禮物
  🔥 +{{coinsPremium}} 如果您有Telegram Premium
  
  🏆 每次投票賺取硬幣，並選擇最有吸引力的參與者。想要更多？自己參加比賽，競爭寶貴獎品！
  
  不要錯過機會，成為我們明星社區的一員並贏取獎品！`,
    linkCopied: "連結已復制",
  },
  Earn: {
    title: "賺取更多硬幣",
    tasksList: "任務列表",
    check: "檢查",
    inReview: "審核中",
    subscribe: "訂閱",
    participate: "參與",
    invite: "邀請",
    verify: "驗證",
    taskCompleted: "任務完成",
  },
  Wallet: {
    title: "獲取 TON 獎勵",
    description: "完成挑戰以賺取 TON，這是 Telegram 開發的加密貨幣",
    rewards: "獎勵",
    withdrawal: "提款",
    walletConnected: "您的 TON 錢包已連接",
    walletDescription: "更多機會即將到來",
  },
  VerificationRequired: {
    title: "需要驗證",
    description: "要參加比賽，您需要驗證您的帳戶。",
    bdate: {
      title: "你的出生日期",
    },
  },
  Verification: {
    title: "驗證",
    hint: "將您的臉保持在橢圓內",
    secureHint: "沒有人會看到這張照片",
    success: "您已成功完成驗證",
    goToApp: "前往應用",
    verification: "驗證中...",
    cameraAccessRequired: "請授予攝像頭訪問權限以繼續",
    gettingAccessToCamer: "獲取攝像頭訪問權限中...",
    cameraAccessNotAllowed: "用戶拒絕了攝像頭訪問權限。",
    cameraAccessError: "攝像頭訪問錯誤",
    loadingHint: "這可能需要一些時間。請耐心等待。",
  },
  Profile: {
    vote: "投票",
    askVote: "請求投票",
    inviteText: `你好！😊 請通過你的投票支持我參加TON Stars比賽！每一票都至關重要，有助於我更接近贏得寶貴的獎品。

🌟 +{{coins}} 枚硬幣作為第一份禮物
🔥 如果你有Telegram Premium +{{coinsPremium}} 枚硬幣

在這裡為我的個人資料投票：{{url}}

謝謝你的支持！🌟`,
    joinContest: "參加比賽",
    gender: {
      male: "男",
      female: "女",
    },
    photos: {
      inReview: "您的新照片正在審核中。\n這可能需要一些時間。",
      rejected:
        "一張或多張照片因以下原因被拒絕:\n{{reason}}。\n\n請上傳不同的照片並重試。",
    },
  },
  ProfileEdit: {
    title: "你的個人資料",
    name: {
      label: "你的名字",
      placeholder: "輸入你的名字",
    },
    instagram: {
      description: "Instagram 個人資料必須公開",
    },
    photos: {
      verificationPhotoUploaded: "您的驗證照片已上傳，沒有人會看到它。",
      label: "你的照片",
      mainPhoto: "主要照片",
      error: "一張或多張照片不合適，請重新選擇。",
      hint: "選擇最令人印象深刻的照片，其中沒有其他人，並且你的臉清晰可見。",
      inReview: "您的新照片正在審核中。\n這可能需要一些時間。",
      rejected:
        "一張或多張照片因以下原因被拒絕:\n{{reason}}。\n\n請上傳不同的照片並重試。",
    },
    submit: "參加比賽",
    photosUploading: "正在上傳照片...",
    success: "您的個人資料已更新",
    errors: {
      name: "請輸入您的名字",
      photos: "請選擇3張照片",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "主要照片",
    noFace: "未檢測到您的面部",
    otherPeople: "照片中應僅顯示您的面部",
  },
};
