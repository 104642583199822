import { useTranslation } from "react-i18next";
import "./PrizePool.css";
import moment from "moment";

export const PrizePool = () => {
  const { i18n, t } = useTranslation("PrizePool");

  return (
    <div
      className="prize-pool"
      dangerouslySetInnerHTML={{
        __html: t("text", {
          endDate: new Intl.DateTimeFormat(i18n.language, {
            dateStyle: "medium",
          }).format(moment("2024-12-31 23:59:59").toDate()),
        }),
      }}
    />
  );
};
