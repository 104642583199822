export default {
  g: {
    flag: "🇦🇩",
    langName: "Catalan",
    langNameNative: "Català",
    continue: "Continuar",
    save: "Desar",
    retry: "Torna-ho a provar",
    loading: "Carregant...",
    genderMany: {
      all: "Tots",
      male: "Homes",
      female: "Dones",
    },
  },
  CreateAccount: {
    slogan: "Lloc on els somnis es converteixen en victòries!",
    gender: {
      title: "Hola {{name}}, Tu ets...",
      male: "Home",
      female: "Dona",
    },
  },
  Settings: {
    title: "Configuració",
    language: "Idioma",
    auto: "Automàtic",
    country: "País",
    hideProfile: {
      label: "Amaga el perfil",
      description:
        "El teu perfil quedarà amagat per a tothom, com si l'haguessis eliminat.",
    },
    profileHidden:
      "El teu perfil està amagat i no és visible per a altres usuaris.",
  },
  Tabs: {
    vote: "Votar",
    contest: "Concurs",
    friends: "Amics",
    earn: "Guanyar",
    wallet: "Cartera",
  },
  Vote: {
    whoIsBetter: "Qui t'agrada més?",
    votingForLeader: "Votant pel líder",
    noUsers: {
      title: "🚀 Això és tot per avui",
      description: "Convida amics per continuar votant i guanyant monedes",
      inviteFriends: "Convida amics",
    },
  },
  Leaders: {
    rewards: "Recompenses",
    comingSoon: "Pròximament",
    prizePool: "Bossa de premis",
    moreInfo: "Més informació",
    contest: "Concurs",
    richest: "Els més rics",
    sponsors: "Patrocinadors",
    description: {
      likes:
        "Convida amics i comparteix el teu perfil per obtenir més vots i augmentar les teves possibilitats de guanyar.",
      coins:
        "Completa tasques, vota per altres participants i convida amics per guanyar més monedes i enfortir la teva posició.",
      stars:
        "Vota pels perfils a la pàgina de perfil per rebre estrelles i pujar en les classificacions.",
    },
  },
  PrizePool: {
    text: `<b>Llocs guanyadors de premis</b>:
<b>1r lloc</b>: 50% USD i 10,000 monedes
<b>2n lloc</b>: 30% USD i 3,000 monedes
<b>3r lloc</b>: 20% USD i 2,000 monedes
<b>4t-10è llocs</b>: 1,000 monedes cadascun
<b>11è-50è llocs</b>: 500 monedes cadascun
<b>51è-100è llocs</b>: 250 monedes cadascun

<b>La bossa de premis</b> augmenta quan algú vota per tu a la teva pàgina de perfil.

<b>Condicions</b>:
Tots els perfils que carreguin una foto i completin les tasques són elegibles per participar en el concurs.

<b>Data de finalització</b>:
{{endDate}}

<b>Pagament de recompenses</b>:
Pots rebre la teva recompensa en <b>TON</b> — la criptomoneda desenvolupada per Telegram.`,
  },
  LeadersItem: {
    join: "Unir-se",
  },
  Friends: {
    title: "Convida als teus amics!",
    description: "Tu i el teu amic rebreu bonificacions",
    bonus: {
      profit: "<coins>+{{count}}</coins> per a tu i el teu amic",
      invite: {
        title: "Convida un amic",
      },
      inviteWithPremium: {
        title: "Convida un amic",
        description: "amb Telegram Premium",
      },
    },
    list: {
      title: "Llista dels teus amics",
    },
    inviteFriend: "Convida un amic",
    inviteText: `Uneix-te a mi, participa en les votacions dels nois i noies més guapos, i aconsegueix premis valuosos!
  
  🌟 +{{coins}} monedes com a primer regal
  🔥 +{{coinsPremium}} monedes si tens Telegram Premium
  
  🏆 Guanya monedes per cada vot i tria els participants més atractius. Vols més? Participa tu mateix i competeix per premis valuosos!
  
  No perdis la teva oportunitat, uneix-te a la nostra comunitat d'estrelles i guanya!`,
    linkCopied: "Enllaç copiat",
  },
  Earn: {
    title: "Guanya més monedes",
    tasksList: "Llista de tasques",
    check: "Revisar",
    inReview: "En revisió",
    subscribe: "Subscriu-te",
    participate: "Participa",
    invite: "Convida",
    verify: "Verifica",
    taskCompleted: "Tasca completada",
  },
  Wallet: {
    title: "Obteniu recompenses en TON",
    description:
      "Completa reptes per guanyar TON, una criptomoneda desenvolupada per Telegram",
    rewards: "Recompenses",
    withdrawal: "Retirada",
    walletConnected: "La vostra cartera TON està connectada",
    walletDescription: "Aviat estaran disponibles més oportunitats",
  },
  VerificationRequired: {
    title: "Cal verificació",
    description:
      "Per participar en el concurs, has de verificar el teu compte.",
    bdate: {
      title: "Data de naixement",
    },
  },
  Verification: {
    title: "Verificació",
    hint: "Mantingueu la cara dins de l'oval",
    secureHint: "Ningú no veurà aquesta foto",
    success: "Has completat la verificació amb èxit",
    goToApp: "Anar a l'aplicació",
    verification: "Verificant...",
    cameraAccessRequired: "Si us plau, dona accés a la càmera per continuar",
    gettingAccessToCamer: "Obtenint accés a la càmera...",
    cameraAccessNotAllowed: "Accés a la càmera denegat per l'usuari.",
    cameraAccessError: "Error d'accés a la càmera",
    loadingHint: "Això pot trigar una mica. Si us plau, sigues pacient.",
  },
  Profile: {
    vote: "Votar",
    askVote: "Demanar que votin",
    inviteText: `Hola! 😊 Si us plau, dóna'm suport amb el teu vot al concurs TON Stars! Cada vot és crucial i m'ajuda a acostar-me a guanyar premis valuosos.

🌟 +{{coins}} monedes com a primer regal
🔥 +{{coinsPremium}} monedes si tens Telegram Premium

Vota el meu perfil aquí: {{url}}

Gràcies pel teu suport! 🌟`,
    joinContest: "Unir-se al concurs",
    gender: {
      male: "Masculí",
      female: "Femení",
    },
    photos: {
      inReview:
        "Les teves noves fotos estan en revisió.\nAixò pot trigar una mica.",
      rejected:
        "Una o més fotos han estat rebutjades per la següent raó:\n{{reason}}.\n\nSi us plau, puja fotos diferents i torna-ho a intentar.",
    },
  },
  ProfileEdit: {
    title: "El teu perfil",
    name: {
      label: "El teu nom",
      placeholder: "Introdueix el teu nom",
    },
    instagram: {
      description: "El perfil d'Instagram ha de ser públic",
    },
    photos: {
      verificationPhotoUploaded:
        "La teva foto de verificació s'ha pujat, ningú la veurà.",
      label: "Les teves fotos",
      mainPhoto: "Foto principal",
      error:
        "Una o més fotos no són adequades, si us plau, fes una altra selecció.",
      hint: "Tria les teves fotos més impressionants, on no hi hagi altres persones, i la teva cara sigui clarament i expressivament visible.",
      inReview:
        "Les teves noves fotos estan en revisió.\nAixò pot trigar una mica.",
      rejected:
        "Una o més fotos han estat rebutjades per la següent raó:\n{{reason}}.\n\nSi us plau, puja fotos diferents i torna-ho a intentar.",
    },
    submit: "Unir-se al concurs",
    photosUploading: "Pujant foto...",
    success: "El teu perfil ha estat actualitzat",
    errors: {
      name: "Indiqueu el vostre nom",
      photos: "Si us plau, seleccioneu 3 fotos",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto principal",
    noFace: "La teva cara no ha estat detectada",
    otherPeople: "La foto ha de mostrar només la teva cara",
  },
};
