export default {
  g: {
    flag: "🇮🇳",
    langName: "Hindi",
    langNameNative: "हिन्दी",
    continue: "जारी रखें",
    save: "सहेजें",
    retry: "पुनः प्रयास करें",
    loading: "लोड हो रहा है...",
    genderMany: {
      all: "सभी",
      male: "पुरुष",
      female: "महिलाएं",
    },
  },
  CreateAccount: {
    slogan: "वह जगह जहाँ सपने जीत में बदल जाते हैं!",
    gender: {
      title: "नमस्ते {{name}}, आप...",
      male: "पुरुष",
      female: "महिला",
    },
  },
  Settings: {
    title: "सेटिंग्स",
    language: "भाषा",
    auto: "स्वचालित",
    country: "देश",
    hideProfile: {
      label: "प्रोफ़ाइल छिपाएँ",
      description:
        "आपकी प्रोफ़ाइल सभी से छिपा दी जाएगी, जैसे आपने इसे हटा दिया हो।",
    },
    profileHidden:
      "आपकी प्रोफ़ाइल छिपी हुई है और अन्य उपयोगकर्ताओं को दिखाई नहीं दे रही है।",
  },
  Tabs: {
    vote: "वोट करें",
    contest: "प्रतियोगिता",
    friends: "दोस्त",
    earn: "कमाएं",
    wallet: "वॉलेट",
  },
  Vote: {
    whoIsBetter: "आप किसे अधिक पसंद करते हैं?",
    votingForLeader: "नेता के लिए मतदान",
    noUsers: {
      title: "🚀 आज के लिए बस इतना ही",
      description:
        "मित्रों को वोटिंग जारी रखने और सिक्के कमाने के लिए आमंत्रित करें",
      inviteFriends: "मित्रों को आमंत्रित करें",
    },
  },
  Leaders: {
    rewards: "पुरस्कार",
    comingSoon: "जल्द ही आ रहा है",
    prizePool: "पुरस्कार पूल",
    moreInfo: "अधिक जानकारी",
    contest: "प्रतियोगिता",
    richest: "सबसे अमीर",
    sponsors: "प्रायोजक",
    description: {
      likes:
        "अधिक वोट प्राप्त करने और जीतने की संभावनाओं को बढ़ाने के लिए दोस्तों को आमंत्रित करें और अपने प्रोफ़ाइल को साझा करें।",
      coins:
        "अधिक सिक्के कमाने और अपनी स्थिति को मजबूत करने के लिए कार्यों को पूरा करें, अन्य प्रतिभागियों के लिए वोट करें और दोस्तों को आमंत्रित करें।",
      stars:
        "सितारे प्राप्त करने और रैंकिंग में ऊपर चढ़ने के लिए प्रोफ़ाइल पृष्ठ पर प्रोफ़ाइल के लिए वोट करें।",
    },
  },
  PrizePool: {
    text: `<b>पुरस्कार विजेता स्थान</b>:
<b>1 स्थान</b>: 50% USD और 10,000 सिक्के
<b>2 स्थान</b>: 30% USD और 3,000 सिक्के
<b>3 स्थान</b>: 20% USD और 2,000 सिक्के
<b>4-10 स्थान</b>: प्रत्येक 1,000 सिक्के
<b>11-50 स्थान</b>: प्रत्येक 500 सिक्के
<b>51-100 स्थान</b>: प्रत्येक 250 सिक्के

<b>पुरस्कार पूल</b> बढ़ता है जब कोई आपके प्रोफाइल पृष्ठ पर आपके लिए वोट करता है।

<b>शर्तें</b>:
सभी प्रोफाइल जो फोटो अपलोड करते हैं और कार्यों को पूरा करते हैं, प्रतियोगिता में भाग लेने के लिए पात्र हैं।

<b>समाप्ति तिथि</b>:
{{endDate}}

<b>पुरस्कार का भुगतान</b>:
आप अपना पुरस्कार <b>TON</b> में प्राप्त कर सकते हैं — यह क्रिप्टोक्यूरेंसी Telegram द्वारा विकसित की गई है।`,
  },
  LeadersItem: {
    join: "शामिल हों",
  },
  Friends: {
    title: "दोस्तों को आमंत्रित करें!",
    description: "आप और आपका दोस्त बोनस प्राप्त करेंगे",
    bonus: {
      profit: "<coins>+{{count}}</coins> आपके और आपके दोस्त के लिए",
      invite: {
        title: "दोस्त को आमंत्रित करें",
      },
      inviteWithPremium: {
        title: "दोस्त को आमंत्रित करें",
        description: "टेलीग्राम प्रीमियम के साथ",
      },
    },
    list: {
      title: "आपके दोस्तों की सूची",
    },
    inviteFriend: "दोस्त को आमंत्रित करें",
    inviteText: `मेरे साथ जुड़ें, सबसे सुंदर लड़के और लड़कियों के लिए वोट करें और मूल्यवान पुरस्कार प्राप्त करें!
  
  🌟 +{{coins}} सिक्के पहले उपहार के रूप में
  🔥 +{{coinsPremium}} सिक्के अगर आपके पास टेलीग्राम प्रीमियम है
  
  🏆 प्रत्येक वोट के लिए सिक्के कमाएं और सबसे आकर्षक प्रतिभागियों को चुनें। और अधिक चाहते हैं? स्वयं भाग लें और मूल्यवान पुरस्कारों के लिए प्रतिस्पर्धा करें!
  
  अपना मौका न चूकें, हमारे स्टार समुदाय का हिस्सा बनें और जीतें!`,
    linkCopied: "लिंक कॉपी हो गया",
  },
  Earn: {
    title: "अधिक सिक्के कमाएं",
    tasksList: "कार्य सूची",
    check: "जाँच करें",
    inReview: "समीक्षा में",
    subscribe: "सब्सक्राइब करें",
    participate: "भाग लें",
    invite: "आमंत्रित करें",
    verify: "सत्यापित करें",
    taskCompleted: "कार्य पूर्ण हुआ",
  },
  Wallet: {
    title: "TON में इनाम प्राप्त करें",
    description:
      "TON, एक क्रिप्टो सिक्का जो Telegram द्वारा विकसित किया गया है, कमाने के लिए चुनौतियों को पूरा करें",
    rewards: "इनाम",
    withdrawal: "निकासी",
    walletConnected: "आपका TON वॉलेट कनेक्टेड है",
    walletDescription: "अधिक अवसर जल्द ही उपलब्ध होंगे",
  },
  VerificationRequired: {
    title: "सत्यापन आवश्यक",
    description:
      "प्रतियोगिता में भाग लेने के लिए आपको अपना खाता सत्यापित करना होगा।",
    bdate: {
      title: "आपकी जन्मतिथि",
    },
  },
  Verification: {
    title: "सत्यापन",
    hint: "अपना चेहरा अंडाकार के अंदर रखें",
    secureHint: "कोई भी इस फोटो को नहीं देखेगा",
    success: "आपने सफलतापूर्वक सत्यापन पूरा कर लिया है",
    goToApp: "ऐप में जाएं",
    verification: "सत्यापित किया जा रहा है...",
    cameraAccessRequired: "कृपया जारी रखने के लिए कैमरा एक्सेस की अनुमति दें",
    gettingAccessToCamer: "कैमरा एक्सेस प्राप्त किया जा रहा है...",
    cameraAccessNotAllowed: "उपयोगकर्ता ने कैमरा एक्सेस अस्वीकृत कर दिया।",
    cameraAccessError: "कैमरा एक्सेस त्रुटि",
    loadingHint: "इसमें कुछ समय लग सकता है। कृपया धैर्य रखें।",
  },
  Profile: {
    vote: "वोट करें",
    askVote: "वोट मांगें",
    inviteText: `नमस्ते! 😊 कृपया TON Stars प्रतियोगिता में अपने वोट से मेरा समर्थन करें! हर वोट महत्वपूर्ण है और मुझे कीमती पुरस्कार जीतने के करीब लाने में मदद करता है।

🌟 +{{coins}} सिक्के पहले उपहार के रूप में
🔥 +{{coinsPremium}} सिक्के अगर आपके पास Telegram Premium है

मेरे प्रोफाइल के लिए यहाँ वोट करें: {{url}}

आपके समर्थन के लिए धन्यवाद! 🌟`,
    joinContest: "प्रतियोगिता में शामिल हों",
    gender: {
      male: "पुरुष",
      female: "महिला",
    },
    photos: {
      inReview: "आपकी नई तस्वीरें समीक्षा में हैं।\nइसमें कुछ समय लग सकता है।",
      rejected:
        "एक या अधिक तस्वीरों को निम्नलिखित कारण से अस्वीकार कर दिया गया है:\n{{reason}}।\n\nकृपया अलग तस्वीरें अपलोड करें और पुनः प्रयास करें।",
    },
  },
  ProfileEdit: {
    title: "आपकी प्रोफ़ाइल",
    name: {
      label: "आपका नाम",
      placeholder: "अपना नाम दर्ज करें",
    },
    instagram: {
      description: "Instagram प्रोफ़ाइल सार्वजनिक होनी चाहिए",
    },
    photos: {
      verificationPhotoUploaded:
        "आपका सत्यापन फ़ोटो अपलोड कर दिया गया है, इसे कोई नहीं देखेगा।",
      label: "आपकी तस्वीरें",
      mainPhoto: "मुख्य तस्वीर",
      error: "एक या अधिक तस्वीरें उपयुक्त नहीं हैं, कृपया अन्य चयन करें।",
      hint: "अपनी सबसे प्रभावशाली तस्वीरें चुनें, जहाँ अन्य लोग नहीं हों, और आपका चेहरा स्पष्ट और स्पष्ट रूप से दिखाई दे।",
      inReview: "आपकी नई तस्वीरें समीक्षा में हैं।\nइसमें कुछ समय लग सकता है।",
      rejected:
        "एक या अधिक तस्वीरों को निम्नलिखित कारण से अस्वीकार कर दिया गया है:\n{{reason}}।\n\nकृपया अलग तस्वीरें अपलोड करें और पुनः प्रयास करें।",
    },
    submit: "प्रतियोगिता में शामिल हों",
    photosUploading: "फोटो अपलोड हो रही है...",
    success: "आपकी प्रोफ़ाइल अपडेट कर दी गई है",
    errors: {
      name: "कृपया अपना नाम दर्ज करें",
      photos: "कृपया 3 तस्वीरें चुनें",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "मुख्य तस्वीर",
    noFace: "आपका चेहरा पहचाना नहीं गया",
    otherPeople: "फोटो में केवल आपका चेहरा होना चाहिए",
  },
};
