import config from "config";
import { SupportedLanguages, supportedLanguages } from "./resources";

// const langTags = Localization.getLocales().map((_) => _.languageTag);

const langTags: string[] = [];

const getLocaleByLangTag = (tag: string): SupportedLanguages | undefined => {
  const match = tag.match(/(.+)-/);
  const langCode = match?.[1];

  if (!langCode) return;

  if (supportedLanguages.includes(langCode as SupportedLanguages))
    return langCode as SupportedLanguages;

  const locale = supportedLanguages.find((lang) =>
    lang.startsWith(langCode)
  ) as SupportedLanguages;
  if (locale) return locale;

  return getLocaleByLangTag(langCode);
};

export const getFirstSupportedLocale = (tag: string) => {
  if (supportedLanguages.includes(tag as SupportedLanguages))
    return tag as SupportedLanguages;

  const locale = getLocaleByLangTag(tag);

  if (locale) return locale;

  return config.defaultLocale;
};

export const getInitialLang = (): SupportedLanguages => {
  for (const tag of langTags) {
    if (supportedLanguages.includes(tag as SupportedLanguages))
      return tag as SupportedLanguages;

    const locale = getLocaleByLangTag(tag);

    if (locale) return locale;
  }
  return config.defaultLocale;
};
