import "./ProfileVote.css";
import { Spinner } from "@telegram-apps/telegram-ui";
// import PinIcon from "../../assets/pin.svg?react";
import HeartIcon from "../../assets/heart.svg?react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useTranslation } from "react-i18next";
import StarIcon from "assets/star.svg?react";
import CoinIcon from "assets/coin.svg?react";
import WebApp from "@twa-dev/sdk";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { useCallback } from "react";

const ProfileVotePaid_Query = gql(`
  query ProfileVotePaid_Query {
    invoiceUsersLast {
      id
      likesCount
      starsCount
      coins
    }
  }
`);

const ProfileVoteInvoiceCreate_Mutation = gql(`
  mutation ProfileInvoiceCreate_Mutation(
    $userId: BigInt!
    $likes: Int!
  ) {
    invoiceCreate(userId: $userId likes: $likes) {
      link
    }
  }
`);

const ProfileVote_QueryFragment = gql(`
  fragment ProfileVote_QueryFragment on VoteConfig {
    likePrice
    likeAmounts
  }
`);

export type ProfileVoteProps = {
  userId: bigint;
  voteConfig: FragmentType<typeof ProfileVote_QueryFragment> | null;
  onClose: () => void;
};

export const ProfileVote = (props: ProfileVoteProps) => {
  const voteConfig = getFragmentData(
    ProfileVote_QueryFragment,
    props.voteConfig
  );
  const [invoiceCreate, { loading }] = useMutation(
    ProfileVoteInvoiceCreate_Mutation
  );

  const { fetchMore } = useQuery(ProfileVotePaid_Query, { skip: true });

  const { i18n } = useTranslation();

  const refetchUser = useCallback(() => {
    setTimeout(() => {
      fetchMore({}).then(({ data }) => {
        if (!data.invoiceUsersLast.length) refetchUser();
      });
    }, 1000);
  }, [fetchMore]);

  return (
    <div className="profile-vote">
      {voteConfig?.likeAmounts.map((likes) => (
        <div
          key={likes}
          className="profile-vote-item"
          onClick={() => {
            invoiceCreate({
              variables: { userId: props.userId, likes },
            })
              .then(({ data }) => {
                if (!data?.invoiceCreate) return;
                WebApp.openInvoice(data.invoiceCreate?.link, (result) => {
                  if (result === "paid") {
                    props.onClose();
                    refetchUser();
                  }
                });
              })
              .catch((e: Error) => {
                toast.error(e.message);
              });
          }}
        >
          <div className="profile-vote-item-hearts">
            <HeartIcon width={24} height={24} />
            {Intl.NumberFormat(i18n.language).format(likes)}
          </div>
          <div className="profile-vote-footer">
            <div className="profile-vote-item-coins">
              +{Intl.NumberFormat(i18n.language).format(likes)}
              <CoinIcon
                style={{ marginRight: 1, marginTop: -2 }}
                color="rgb(245, 186, 38)"
                width={16}
                height={16}
              />
            </div>
            <div className="profile-vote-item-stars">
              +
              {Intl.NumberFormat(i18n.language).format(
                likes * voteConfig.likePrice
              )}
              <StarIcon
                style={{ marginTop: -4 }}
                color="rgb(245, 186, 38)"
                width={18}
                height={18}
              />
            </div>
          </div>
        </div>
      ))}
      {loading && (
        <div className="profile-vote-loading">
          <Spinner size="m" />
        </div>
      )}
    </div>
  );
};
