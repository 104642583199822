export default {
  g: {
    flag: "🇫🇮",
    langName: "Finnish",
    langNameNative: "Suomi",
    continue: "Jatka",
    save: "Tallenna",
    retry: "Yritä uudelleen",
    loading: "Ladataan...",
    genderMany: {
      all: "Kaikki",
      male: "Miehet",
      female: "Naiset",
    },
  },
  CreateAccount: {
    slogan: "Paikka, missä unelmat muuttuvat voitoiksi!",
    gender: {
      title: "Hei {{name}}, Sinä olet...",
      male: "Mies",
      female: "Nainen",
    },
  },
  Settings: {
    title: "Asetukset",
    language: "Kieli",
    auto: "Automaattinen",
    country: "Maa",
    hideProfile: {
      label: "Piilota profiili",
      description:
        "Profiilisi piilotetaan kaikilta, ikään kuin olisit poistanut sen.",
    },
    profileHidden: "Profiilisi on piilotettu, eikä se näy muille käyttäjille.",
  },
  Tabs: {
    vote: "Äänestä",
    contest: "Kilpailu",
    friends: "Ystävät",
    earn: "Ansaitse",
    wallet: "Lompakko",
  },
  Vote: {
    whoIsBetter: "Kuka on parempi?",
    votingForLeader: "Äänestetään johtajaa",
    noUsers: {
      title: "🚀 Tässä kaikki tältä päivältä",
      description:
        "Kutsu ystäviä jatkamaan äänestystä ja ansaitsemaan kolikoita",
      inviteFriends: "Kutsu Ystäviä",
    },
  },
  Leaders: {
    rewards: "Palkinnot",
    comingSoon: "Tulossa pian",
    prizePool: "Palkintopotti",
    moreInfo: "Lisätietoja",
    contest: "Kilpailu",
    richest: "Rikkaimmat",
    sponsors: "Sponsorit",
    description: {
      likes:
        "Kutsu ystäviä ja jaa profiilisi saadaksesi enemmän ääniä ja parantaaksesi voittomahdollisuuksiasi.",
      coins:
        "Suorita tehtäviä, äänestä muita osallistujia ja kutsu ystäviä ansaitaksesi enemmän kolikoita ja vahvistaaksesi asemaasi.",
      stars:
        "Äänestä profiileja profiilisivulla saadaksesi tähtiä ja noustaksesi sijoituksissa.",
    },
  },
  PrizePool: {
    text: `<b>Palkittavat sijat</b>:
<b>1. sija</b>: 50% USD ja 10,000 kolikkoa
<b>2. sija</b>: 30% USD ja 3,000 kolikkoa
<b>3. sija</b>: 20% USD ja 2,000 kolikkoa
<b>4.-10. sijat</b>: 1,000 kolikkoa kukin
<b>11.-50. sijat</b>: 500 kolikkoa kukin
<b>51.-100. sijat</b>: 250 kolikkoa kukin

<b>Palkintopotti</b> kasvaa, kun joku äänestää sinua profiilisivullasi.

<b>Ehdoт</b>:
Kaikki profiilit, jotka lataavat kuvan ja suorittavat tehtävät, ovat oikeutettuja osallistumaan kilpailuun.

<b>Päättymispäivä</b>:
{{endDate}}

<b>Palkkioiden maksu</b>:
Voit vastaanottaa palkkiosi <b>TON</b> - kryptovaluutassa, joka on kehitetty Telegramin toimesta.`,
  },
  LeadersItem: {
    join: "Liity",
  },
  Friends: {
    title: "Kutsu ystäviä!",
    description: "Sinä ja ystäväsi saatte bonuksia",
    bonus: {
      profit: "<coins>+{{count}}</coins> sinulle ja ystävällesi",
      invite: {
        title: "Kutsu ystävä",
      },
      inviteWithPremium: {
        title: "Kutsu ystävä",
        description: "Telegram Premiumin kanssa",
      },
    },
    list: {
      title: "Ystäväsi lista",
    },
    inviteFriend: "Kutsu ystävä",
    inviteText: `Liity minuun, osallistu kauneimpien poikien ja tyttöjen äänestykseen ja saa arvokkaita palkintoja!
  
  🌟 +{{coins}} kolikkoa ensimmäisenä lahjana
  🔥 +{{coinsPremium}} kolikkoa, jos sinulla on Telegram Premium
  
  🏆 Ansaitse kolikoita jokaisesta äänestä ja valitse houkuttelevimmat osallistujat. Haluatko lisää? Osallistu itse ja kilpaile arvokkaista palkinnoista!
  
  Älä missaa tilaisuuttasi, liity tähtiyhteisöömme ja voita!`,
    linkCopied: "Linkki kopioitu",
  },
  Earn: {
    title: "Ansaitse enemmän kolikoita",
    tasksList: "Tehtävälista",
    check: "Tarkista",
    inReview: "Tarkistuksessa",
    subscribe: "Tilaa",
    participate: "Osallistu",
    invite: "Kutsu",
    verify: "Vahvista",
    taskCompleted: "Tehtävä suoritettu",
  },
  Wallet: {
    title: "Saa palkintoja TONissa",
    description:
      "Suorita haasteita ansaitaksesi TONia, Telegramin kehittämä kryptokolikko",
    rewards: "Palkinnot",
    withdrawal: "Nosto",
    walletConnected: "TON-lompakkosi on yhdistetty",
    walletDescription: "Lisää mahdollisuuksia tulee pian saataville",
  },
  VerificationRequired: {
    title: "Vahvistus vaaditaan",
    description: "Osallistuaksesi kilpailuun sinun täytyy vahvistaa tilisi.",
    bdate: {
      title: "Syntymäpäiväsi",
    },
  },
  Verification: {
    title: "Vahvistus",
    hint: "Pidä kasvosi ovaalin sisällä",
    secureHint: "Kukaan ei näe tätä kuvaa",
    success: "Olet onnistuneesti suorittanut vahvistuksen",
    goToApp: "Siirry sovellukseen",
    verification: "Vahvistus käynnissä...",
    cameraAccessRequired: "Salli pääsy kameraan jatkaaksesi",
    gettingAccessToCamer: "Kameran käyttöoikeuden hankkiminen...",
    cameraAccessNotAllowed: "Käyttäjä on kieltänyt pääsyn kameraan.",
    cameraAccessError: "Kameran käyttöoikeusvirhe",
    loadingHint:
      "Tämä saattaa kestää hetken. Ole hyvä ja odota kärsivällisesti.",
  },
  Profile: {
    vote: "Äänestä",
    askVote: "Pyydä Ääntä",
    inviteText: `Hei! 😊 Tue minua äänelläsi TON Stars -kilpailussa! Jokainen ääni on ratkaiseva ja auttaa minua pääsemään lähemmäksi arvokkaiden palkintojen voittamista.

🌟 +{{coins}} kolikkoa ensimmäisenä lahjana
🔥 +{{coinsPremium}} kolikkoa, jos sinulla on Telegram Premium

Äänestä profiiliani täällä: {{url}}

Kiitos tuestasi! 🌟`,
    joinContest: "Osallistu kilpailuun",
    gender: {
      male: "Mies",
      female: "Nainen",
    },
    photos: {
      inReview:
        "Uudet kuvasi ovat tarkistettavana.\nTämä voi viedä jonkin aikaa.",
      rejected:
        "Yksi tai useampi kuva on hylätty seuraavasta syystä:\n{{reason}}.\n\nLataa eri kuvia ja yritä uudelleen.",
    },
  },
  ProfileEdit: {
    title: "Profiilisi",
    name: {
      label: "Nimesi",
      placeholder: "Anna nimesi",
    },
    instagram: {
      description: "Instagram-profiilin on oltava julkinen",
    },
    photos: {
      verificationPhotoUploaded:
        "Vahvistuskuvasi on ladattu, kukaan ei näe sitä.",
      label: "Kuvasi",
      mainPhoto: "Pääkuva",
      error: "Yksi tai useampi kuva ei ole sopiva, tee toinen valinta.",
      hint: "Valitse vaikuttavimmat kuvasi, joissa ei ole muita ihmisiä ja kasvosi ovat selkeästi ja ilmeikkäästi näkyvissä.",
      inReview:
        "Uudet kuvasi ovat tarkistettavana.\nTämä voi viedä jonkin aikaa.",
      rejected:
        "Yksi tai useampi kuva on hylätty seuraavasta syystä:\n{{reason}}.\n\nLataa eri kuvia ja yritä uudelleen.",
    },
    submit: "Osallistu kilpailuun",
    photosUploading: "Ladataan kuvaa...",
    success: "Profiilisi on päivitetty",
    errors: {
      name: "Anna nimesi",
      photos: "Valitse 3 kuvaa",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Pääkuva",
    noFace: "Kasvojasi ei havaittu",
    otherPeople: "Kuvan pitäisi sisältää vain kasvosi",
  },
};
