export default {
  g: {
    flag: "🇷🇺",
    langName: "Russian",
    langNameNative: "Русский",
    continue: "Продолжить",
    save: "Сохранить",
    retry: "Повторить",
    loading: "Загрузка...",
    genderMany: {
      all: "Все",
      male: "Мужчины",
      female: "Женщины",
    },
  },
  CreateAccount: {
    slogan: "Место, где мечты превращаются в победы!",
    gender: {
      title: "Привет {{name}}, Вы...",
      male: "Мужчина",
      female: "Женщина",
    },
  },
  Settings: {
    title: "Настройки",
    language: "Язык",
    auto: "Автоматически",
    country: "Страна",
    hideProfile: {
      label: "Скрыть профиль",
      description:
        "Ваш профиль будет скрыт от всех, как если бы вы его удалили.",
    },
    profileHidden: "Ваш профиль скрыт и не виден другим пользователям.",
  },
  Tabs: {
    vote: "Голосовать",
    contest: "Конкурс",
    friends: "Друзья",
    earn: "Заработать",
    wallet: "Кошелек",
  },
  Vote: {
    whoIsBetter: "Кто вам нравится больше?",
    votingForLeader: "Голосование за лидера",
    noUsers: {
      title: "🚀 На сегодня всё",
      description:
        "Пригласите друзей, чтобы продолжить голосовать и получать монеты",
      inviteFriends: "Пригласить друзей",
    },
  },
  Leaders: {
    rewards: "Награды",
    comingSoon: "Скоро",
    prizePool: "Призовой фонд",
    moreInfo: "Подробнее",
    contest: "Конкурс",
    richest: "Богатые",
    sponsors: "Спонсоры",
    description: {
      likes:
        "Приглашайте друзей и делитесь своей анкетой, чтобы собрать больше голосов и повысить свои шансы на победу.",
      coins:
        "Выполняйте задания, голосуйте за других участников и приглашайте друзей, чтобы зарабатывать больше монет и усиливать свои позиции.",
      stars:
        "Голосуйте за анкеты на странице профиля, чтобы получать звезды и подниматься в рейтинге.",
    },
  },
  PrizePool: {
    text: `<b>Призовые места</b>:
<b>1 место</b>: 50% USD и 10 000 монет
<b>2 место</b>: 30% USD и 3 000 монет
<b>3 место</b>: 20% USD и 2 000 монет
<b>4-10 места</b>: по 1 000 монет
<b>11-50 места</b>: по 500 монет
<b>51-100 места</b>: по 250 монет

<b>Призовой фонд</b> увеличивается, когда кто-то голосует за вас на странице вашего профиля.

<b>Условия</b>:
В конкурсе участвуют все анкеты, загрузившие фото и выполнившие задания.

<b>Окончание</b>:
{{endDate}}

<b>Выплата вознаграждения</b>:
Вознаграждение вы сможете получить в <b>TON</b> — криптовалюте, разработанной Telegram.`,
  },
  LeadersItem: {
    join: "Присоед.",
  },
  Friends: {
    title: "Пригласите друзей!",
    description: "Вы и ваш друг получите бонусы",
    bonus: {
      profit: "<coins>+{{count}}</coins> для вас и друга",
      invite: {
        title: "Пригласить друга",
      },
      inviteWithPremium: {
        title: "Пригласить друга",
        description: "с Telegram Premium",
      },
    },
    list: {
      title: "Список ваших друзей",
    },
    inviteFriend: "Пригласить друга",
    inviteText: `Присоединяйся ко мне, участвуй в голосовании за самых красивых парней и девушек и получай ценные призы!

🌟 +{{coins}} монет в качестве первого подарка
🔥 +{{coinsPremium}} монет, если у тебя есть Telegram Premium

🏆 Получай монеты за каждый голос и выбирай самых привлекательных участников. Хочешь больше? Участвуй сам и борись за ценные призы!

Не упусти шанс, становись частью нашего звездного сообщества и получай призы!`,
    linkCopied: "Ссылка скопирована",
  },
  Earn: {
    title: "Заработайте больше монет",
    tasksList: "Список заданий",
    check: "Проверить",
    inReview: "На проверке",
    subscribe: "Подписаться",
    participate: "Учавствовать",
    invite: "Пригласить друзей",
    verify: "Верифицировать",
    taskCompleted: "Задание выполнено",
  },
  Wallet: {
    title: "Получайте награды в TON",
    description:
      "Выполняйте задания, чтобы зарабатывать TON, криптовалюту, разработанную Telegram",
    rewards: "Награды",
    withdrawal: "Вывод средств",
    walletConnected: "Ваш кошелек TON подключен",
    walletDescription: "Скоро будет доступно больше возможностей",
  },
  VerificationRequired: {
    title: "Требуется верификация",
    description:
      "Чтобы принять участие в конкурсе, необходимо пройти верификацию вашего аккаунта.",
    bdate: {
      title: "Дата вашего рождения",
    },
  },
  Verification: {
    title: "Верификация",
    hint: "Держите лицо внутри овала",
    secureHint: "Никто не увидит это фото",
    verification: "Верификация...",
    cameraAccessRequired:
      "Пожалуйста, предоставьте доступ к камере, чтобы продолжить",
    gettingAccessToCamer: "Получение доступа к камере...",
    cameraAccessNotAllowed: "Доступ к камере запрещен пользователем.",
    cameraAccessError: "Ошибка доступа к камере",
    loadingHint:
      "Это может занять некоторое время. Пожалуйста, наберитесь терпения.",
  },
  Profile: {
    vote: "Проголосовать",
    askVote: "Попросить проголосовать",
    inviteText: `Привет! 😊 Пожалуйста, поддержи меня своим голосом в конкурсе TON Stars! Каждый голос очень важен и помогает мне приблизиться к победе и ценным призам.

🌟 +{{coins}} монет в качестве первого подарка
🔥 +{{coinsPremium}} монет, если у тебя есть Telegram Premium

Голосуй за мой профиль здесь: {{url}}

Спасибо за поддержку! 🌟`,
    joinContest: "Присоединиться к конкурсу",
    gender: {
      male: "Мужчина",
      female: "Женщина",
    },
    photos: {
      inReview:
        "Ваши новые фотографии находятся на рассмотрении.\nЭто может занять некоторое время.",
      rejected:
        "Одна или несколько фотографий были отклонены по следующей причине:\n{{reason}}.\n\nПожалуйста, загрузите другие фотографии и попробуйте снова.",
    },
  },
  ProfileEdit: {
    title: "Ваш профиль",
    name: {
      label: "Ваше имя",
      placeholder: "Введите ваше имя",
    },
    instagram: {
      description: "Профиль в Instagram должен быть открытым",
    },
    photos: {
      verificationPhotoUploaded:
        "Ваше фото для верификации загружено, никто его не увидит.",
      label: "Ваши фотографии",
      mainPhoto: "Основное фото",
      error:
        "Одна или несколько фотографий не подходят, пожалуйста, выберите другие.",
      hint: "Выберите ваши самые впечатляющие фотографии, где нет других людей, и ваше лицо четко и выразительно видно.",
      inReview:
        "Ваши новые фотографии находятся на рассмотрении.\nЭто может занять некоторое время.",
      rejected:
        "Одна или несколько фотографий были отклонены по следующей причине:\n{{reason}}.\n\nПожалуйста, загрузите другие фотографии и попробуйте снова.",
    },
    submit: "Присоединиться к конкурсу",
    photosUploading: "Загрузка фото...",
    success: "Ваш профиль был обновлен",
    errors: {
      name: "Укажите ваше имя",
      photos: "Пожалуйста, выберите 3 фотографии",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Основное фото",
    noFace: "Ваше лицо не обнаружено",
    otherPeople: "На фото должно быть только ваше лицо",
  },
};
