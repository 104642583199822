export default {
  g: {
    flag: "🇮🇳",
    langName: "Telugu",
    langNameNative: "తెలుగు",
    continue: "కొనసాగించు",
    save: "సేవ్ చేయి",
    retry: "మళ్లీ ప్రయత్నించు",
    loading: "లోడ్ అవుతోంది...",
    genderMany: {
      all: "అందరు",
      male: "మగవారు",
      female: "ఆడవారు",
    },
  },
  CreateAccount: {
    slogan: "కలలు విజయాలుగా మారే స్థలం!",
    gender: {
      title: "హలో {{name}}, మీరు...",
      male: "పురుషుడు",
      female: "మహిళ",
    },
  },
  Settings: {
    title: "సెట్టింగ్స్",
    language: "భాష",
    auto: "ఆటోమేటిక్",
    country: "దేశం",
    hideProfile: {
      label: "ప్రొఫైల్ దాచు",
      description:
        "మీ ప్రొఫైల్‌ను మీరు తీసివేసినట్లుగా అందరికీ దాచిపెట్టబడుతుంది.",
    },
    profileHidden: "మీ ప్రొఫైల్ దాచబడింది మరియు ఇతర వినియోగదారులకు కనిపించదు.",
  },
  Tabs: {
    vote: "ఓటు వేయండి",
    contest: "పోటీ",
    friends: "స్నేహితులు",
    earn: "సంపాదించండి",
    wallet: "వాలెట్",
  },
  Vote: {
    whoIsBetter: "మీకు ఎవరు ఎక్కువగా ఇష్టం?",
    votingForLeader: "నాయకుడికి ఓటు వేయడం",
    noUsers: {
      title: "🚀 ఇవాళికి అంతే",
      description:
        "వోటింగ్ మరియు నాణేలు సంపాదించడం కొనసాగించడానికి స్నేహితులను ఆహ్వానించండి",
      inviteFriends: "స్నేహితులను ఆహ్వానించండి",
    },
  },
  Leaders: {
    rewards: "బహుమతులు",
    comingSoon: "త్వరలో",
    prizePool: "బహుమతి నిధి",
    moreInfo: "మరింత సమాచారం",
    contest: "పోటీ",
    richest: "అత్యంత సంపన్నులు",
    sponsors: "స్పాన్సర్లు",
    description: {
      likes:
        "మరిన్ని ఓట్లు పొందేందుకు మరియు గెలిచే అవకాశాలను పెంచుకోవడానికి స్నేహితులను ఆహ్వానించండి మరియు మీ ప్రొఫైల్‌ను పంచుకోండి.",
      coins:
        "పనులను పూర్తి చేయండి, ఇతర పాల్గొనేవారికి ఓటు వేయండి మరియు మరిన్ని నాణేలను సంపాదించడానికి మరియు మీ స్థానం బలపడేందుకు స్నేహితులను ఆహ్వానించండి.",
      stars:
        "స్టార్స్ పొందేందుకు మరియు ర్యాంకింగ్‌లో ఎక్కేందుకు ప్రొఫైల్ పేజీలో ప్రొఫైల్‌ల కోసం ఓటు వేయండి.",
    },
  },
  PrizePool: {
    text: `<b>బహుమతి గెలిచే స్థలాలు</b>:
<b>1వ స్థానం</b>: 50% USD మరియు 10,000 నాణేలు
<b>2వ స్థానం</b>: 30% USD మరియు 3,000 నాణేలు
<b>3వ స్థానం</b>: 20% USD మరియు 2,000 నాణేలు
<b>4వ-10వ స్థానాలు</b>: ప్రతి ఒక్కటి 1,000 నాణేలు
<b>11వ-50వ స్థానాలు</b>: ప్రతి ఒక్కటి 500 నాణేలు
<b>51వ-100వ స్థానాలు</b>: ప్రతి ఒక్కటి 250 నాణేలు

<b>బహుమతి నిధి</b> మీ ప్రొఫైల్ పేజీలో ఎవరైనా మీకు ఓటు వేస్తే పెరుగుతుంది.

<b>నిబందనలు</b>:
ఫోటోను అప్‌లోడ్ చేసే మరియు పనులను పూర్తి చేసే అన్ని ప్రొఫైల్‌లు పోటీలో పాల్గొనే అర్హత కలిగి ఉంటాయి.

<b>ముగింపు తేదీ</b>:
{{endDate}}

<b>బహుమతి చెల్లింపు</b>:
మీరు మీ బహుమతిని <b>TON</b> లో పొందవచ్చు — ఇది Telegram అభివృద్ధి చేసిన క్రిప్టోకరెన్సీ.`,
  },
  LeadersItem: {
    join: "చేరండి",
  },
  Friends: {
    title: "స్నేహితులను ఆహ్వానించండి!",
    description: "మీరు మరియు మీ స్నేహితుడు బోనస్ లను అందుకుంటారు",
    bonus: {
      profit: "<coins>+{{count}}</coins> మీకు మరియు మీ స్నేహితుడికి",
      invite: {
        title: "స్నేహితున్ని ఆహ్వానించండి",
      },
      inviteWithPremium: {
        title: "స్నేహితున్ని ఆహ్వానించండి",
        description: "టెలిగ్రామ్ ప్రీమియం తో",
      },
    },
    list: {
      title: "మీ స్నేహితుల జాబితా",
    },
    inviteFriend: "స్నేహితున్ని ఆహ్వానించండి",
    inviteText: `నాతో చేరండి, అందమైన అబ్బాయిలు మరియు అమ్మాయిలకు ఓటు వేయండి మరియు విలువైన బహుమతులు గెలుచుకోండి!
  
  🌟 మొదటి బహుమతిగా +{{coins}} నాణేలు
  🔥 మీకు టెలిగ్రామ్ ప్రీమియం ఉంటే +{{coinsPremium}} నాణేలు
  
  🏆 ప్రతి ఓటుకు నాణేలు సంపాదించండి మరియు అత్యంత ఆకర్షణీయమైన పాల్గొనేవారిని ఎంచుకోండి. మరిన్ని కావాలా? మీరు స్వయంగా పాల్గొని విలువైన బహుమతుల కోసం పోటీ చేయండి!
  
  మీ అవకాసాన్ని కోల్పోకండి, మా నక్షత్రాల సమాజంలో భాగం అవ్వండి మరియు గెలుచుకోండి!`,
    linkCopied: "లింక్ కాపీ చేయబడింది",
  },
  Earn: {
    title: "మరింత నాణేలు సంపాదించండి",
    tasksList: "టాస్క్ ల జాబితా",
    check: "తనిఖీ చేయండి",
    inReview: "సమీక్షలో ఉంది",
    subscribe: "సబ్ స్క్రైబ్ చేయండి",
    participate: "పాల్గొనండి",
    invite: "ఆహ్వానించండి",
    verify: "వెరిఫై చేయండి",
    taskCompleted: "టాస్క్ పూర్తి",
  },
  Wallet: {
    title: "TON లో రివార్డులను పొందండి",
    description:
      "టెలిగ్రామ్ అభివృద్ధి చేసిన క్రిప్టో నాణెంగా TONని సంపాదించడానికి సవాళ్లను పూర్తి చేయండి",
    rewards: "రివార్డులు",
    withdrawal: "విత్‌డ్రా",
    walletConnected: "మీ TON వాలెట్ కనెక్ట్ చేయబడింది",
    walletDescription: "మరిన్ని అవకాశాలు త్వరలో అందుబాటులోకి వస్తాయి",
  },
  VerificationRequired: {
    title: "వెరిఫికేషన్ అవసరం",
    description: "పోటీలో పాల్గొనటానికి, మీరు మీ అకౌంట్ ను వెరిఫై చేయాలి.",
    bdate: {
      title: "మీ పుట్టిన తేది",
    },
  },
  Verification: {
    title: "వెరిఫికేషన్",
    hint: "మీ ముఖాన్ని ఓవల్‌లో ఉంచండి",
    secureHint: "ఈ ఫోటోను ఎవ్వరూ చూడరు",
    success: "మీరు వెరిఫికేషన్ ను విజయవంతంగా పూర్తి చేసారు",
    goToApp: "అనువర్తనానికి వెళ్ళండి",
    verification: "వెరిఫికేషన్ చేస్తోంది...",
    cameraAccessRequired: "కొనసాగడానికి కెమేరా యాక్సెస్ ను అనుమతించండి",
    gettingAccessToCamer: "కెమేరా యాక్సెస్ పొందుతోంది...",
    cameraAccessNotAllowed: "యూజర్ కెమేరా యాక్సెస్ ను నిరాకరించారు.",
    cameraAccessError: "కెమేరా యాక్సెస్ లో లోపం",
    loadingHint: "ఇది కొంత సమయం పట్టవచ్చు. దయచేసి సహనంగా ఉండండి.",
  },
  Profile: {
    vote: "వోటు",
    askVote: "వోటు అడగండి",
    inviteText: `హలో! 😊 TON Stars పోటీలో మీ వోటుతో నాకు మద్దతు ఇవ్వండి! ప్రతి వోటు కీలకం మరియు విలువైన బహుమతులను గెలవడంలో నాకు సహాయపడుతుంది.

🌟 మొదటి బహుమతిగా +{{coins}} నాణేలు
🔥 మీకు Telegram Premium ఉన్నట్లయితే +{{coinsPremium}} నాణేలు

నా ప్రొఫైల్‌కు ఇక్కడ వోటు చేయండి: {{url}}

మీ మద్దతుకు ధన్యవాదాలు! 🌟`,
    joinContest: "కాంపిటీషన్ లో పాల్గొనండి",
    gender: {
      male: "పురుషుడు",
      female: "స్త్రీ",
    },
    photos: {
      inReview: "మీ కొత్త ఫోటోలు సమీక్షలో ఉన్నాయి.\nఇది కొంత సమయం పట్టవచ్చు.",
      rejected:
        "ఒకటి లేదా ఎక్కువ ఫోటోలు ఈ కారణం వల్ల తిరస్కరించబడ్డాయి:\n{{reason}}.\n\nదయచేసి వేరే ఫోటోలను అప్‌లోడ్ చేయండి మరియు మరల ప్రయత్నించండి.",
    },
  },
  ProfileEdit: {
    title: "మీ ప్రొఫైల్",
    name: {
      label: "మీ పేరు",
      placeholder: "మీ పేరు నమోదు చేయండి",
    },
    instagram: {
      description: "Instagram ప్రొఫైల్ పబ్లిక్ గా ఉండాలి",
    },
    photos: {
      verificationPhotoUploaded:
        "మీ ధృవీకరణ ఫోటో అప్‌లోడ్ చేయబడింది, దాన్ని ఎవరూ చూడరు.",
      label: "మీ ఫోటోలు",
      mainPhoto: "ప్రధాన ఫోటో",
      error: "ఒకటి లేదా ఎక్కువ ఫోటోలు అనుకూలం కాదు, దయచేసి వేరే ఎంపిక చేయండి.",
      hint: "మరెవరూ లేని, మీ ముఖం స్పష్టంగా మరియు స్పష్టంగా కనిపించే మీ అత్యంత ప్రభావవంతమైన ఫోటోలను ఎంచుకోండి.",
      inReview: "మీ కొత్త ఫోటోలు సమీక్షలో ఉన్నాయి.\nఇది కొంత సమయం పట్టవచ్చు.",
      rejected:
        "ఒకటి లేదా ఎక్కువ ఫోటోలు ఈ కారణం వల్ల తిరస్కరించబడ్డాయి:\n{{reason}}.\n\nదయచేసి వేరే ఫోటోలను అప్‌లోడ్ చేయండి మరియు మరల ప్రయత్నించండి.",
    },
    submit: "కాంపిటీషన్ లో పాల్గొనండి",
    photosUploading: "ఫోటో అప్‌లోడ్ అవుతోంది...",
    success: "మీ ప్రొఫైల్ అప్‌డేట్ చేయబడింది",
    errors: {
      name: "మీ పేరు నమోదు చేయండి",
      photos: "దయచేసి 3 ఫోటోలు ఎంచుకోండి",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "ప్రధాన ఫోటో",
    noFace: "మీ ముఖం గుర్తించబడలేదు",
    otherPeople: "ఫోటోలో మీ ముఖం మాత్రమే ఉండాలి",
  },
};
