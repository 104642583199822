export default {
  g: {
    flag: "🇹🇭",
    langName: "Thai",
    langNameNative: "ไทย",
    continue: "ดำเนินการต่อ",
    save: "บันทึก",
    retry: "ลองอีกครั้ง",
    loading: "กำลังโหลด...",
    genderMany: {
      all: "ทั้งหมด",
      male: "ผู้ชาย",
      female: "ผู้หญิง",
    },
  },
  CreateAccount: {
    slogan: "สถานที่ที่ความฝันกลายเป็นชัยชนะ!",
    gender: {
      title: "สวัสดี {{name}}, คุณ...",
      male: "ชาย",
      female: "หญิง",
    },
  },
  Settings: {
    title: "การตั้งค่า",
    language: "ภาษา",
    auto: "อัตโนมัติ",
    country: "ประเทศ",
    hideProfile: {
      label: "ซ่อนโปรไฟล์",
      description: "โปรไฟล์ของคุณจะถูกซ่อนไว้จากทุกคน ราวกับว่าคุณลบมันไปแล้ว",
    },
    profileHidden: "โปรไฟล์ของคุณถูกซ่อนและไม่ปรากฏให้ผู้ใช้คนอื่นเห็น",
  },
  Tabs: {
    vote: "โหวต",
    contest: "การแข่งขัน",
    friends: "เพื่อน",
    earn: "หารายได้",
    wallet: "กระเป๋าสตางค์",
  },
  Vote: {
    whoIsBetter: "คุณชอบใครมากกว่า?",
    votingForLeader: "โหวตให้ผู้นำ",
    noUsers: {
      title: "🚀 นั่นคือทั้งหมดสำหรับวันนี้",
      description: "เชิญเพื่อนเพื่อโหวตและรับเหรียญต่อไป",
      inviteFriends: "เชิญเพื่อน",
    },
  },
  Leaders: {
    rewards: "รางวัล",
    comingSoon: "เร็วๆ นี้",
    prizePool: "เงินรางวัล",
    moreInfo: "ข้อมูลเพิ่มเติม",
    contest: "การแข่งขัน",
    richest: "ผู้ที่ร่ำรวยที่สุด",
    sponsors: "ผู้สนับสนุน",
    description: {
      likes:
        "เชิญเพื่อนและแบ่งปันโปรไฟล์ของคุณเพื่อรับคะแนนโหวตมากขึ้นและเพิ่มโอกาสในการชนะ",
      coins:
        "ทำภารกิจให้สำเร็จ ลงคะแนนให้ผู้เข้าร่วมคนอื่น และเชิญเพื่อนเพื่อรับเหรียญเพิ่มและเสริมความแข็งแกร่งให้กับตำแหน่งของคุณ",
      stars: "โหวตให้โปรไฟล์ในหน้าของโปรไฟล์เพื่อรับดาวและไต่ขึ้นในอันดับ",
    },
  },
  PrizePool: {
    text: `<b>อันดับที่ได้รับรางวัล</b>:
<b>อันดับที่ 1</b>: 50% USD และ 10,000 เหรียญ
<b>อันดับที่ 2</b>: 30% USD และ 3,000 เหรียญ
<b>อันดับที่ 3</b>: 20% USD และ 2,000 เหรียญ
<b>อันดับที่ 4-10</b>: อันดับละ 1,000 เหรียญ
<b>อันดับที่ 11-50</b>: อันดับละ 500 เหรียญ
<b>อันดับที่ 51-100</b>: อันดับละ 250 เหรียญ

<b>เงินรางวัล</b> จะเพิ่มขึ้นเมื่อมีคนโหวตให้คุณในหน้าของโปรไฟล์คุณ

<b>เงื่อนไข</b>:
โปรไฟล์ทั้งหมดที่อัปโหลดรูปภาพและทำภารกิจเสร็จสมบูรณ์มีสิทธิ์เข้าร่วมการแข่งขัน

<b>วันที่สิ้นสุด</b>:
{{endDate}}

<b>การจ่ายรางวัล</b>:
คุณสามารถรับรางวัลของคุณเป็น <b>TON</b> — สกุลเงินดิจิทัลที่พัฒนาโดย Telegram.`,
  },
  LeadersItem: {
    join: "เข้าร่วม",
  },
  Friends: {
    title: "เชิญเพื่อน!",
    description: "คุณและเพื่อนของคุณจะได้รับโบนัส",
    bonus: {
      profit: "<coins>+{{count}}</coins> สำหรับคุณและเพื่อนของคุณ",
      invite: {
        title: "เชิญเพื่อน",
      },
      inviteWithPremium: {
        title: "เชิญเพื่อน",
        description: "ด้วย Telegram Premium",
      },
    },
    list: {
      title: "รายชื่อเพื่อนของคุณ",
    },
    inviteFriend: "เชิญเพื่อน",
    inviteText: `เข้าร่วมกับฉัน โหวตให้กับหนุ่มสาวที่สวยที่สุดและรับรางวัลมีค่า!
  
  🌟 +{{coins}} เหรียญเป็นของขวัญแรก
  🔥 +{{coinsPremium}} เหรียญหากคุณมี Telegram Premium
  
  🏆 หารายได้เหรียญสำหรับทุกการโหวตและเลือกผู้เข้าร่วมที่มีเสน่ห์ที่สุด ต้องการมากกว่านี้? เข้าร่วมด้วยตัวเองและแข่งขันเพื่อรับรางวัลมีค่า!
  
  อย่าพลาดโอกาสของคุณ เข้าร่วมชุมชนดาวของเราและชนะ!`,
    linkCopied: "คัดลอกลิงก์แล้ว",
  },
  Earn: {
    title: "หารายได้เหรียญมากขึ้น",
    tasksList: "รายการงาน",
    check: "ตรวจสอบ",
    inReview: "อยู่ระหว่างการตรวจสอบ",
    subscribe: "สมัครสมาชิก",
    participate: "เข้าร่วม",
    invite: "เชิญ",
    verify: "ยืนยัน",
    taskCompleted: "งานเสร็จสิ้น",
  },
  Wallet: {
    title: "รับรางวัลใน TON",
    description:
      "ทำภารกิจให้สำเร็จเพื่อรับ TON ซึ่งเป็นเหรียญคริปโตที่พัฒนาโดย Telegram",
    rewards: "รางวัล",
    withdrawal: "การถอนเงิน",
    walletConnected: "กระเป๋าเงิน TON ของคุณเชื่อมต่อแล้ว",
    walletDescription: "โอกาสเพิ่มเติมจะพร้อมใช้งานเร็ว ๆ นี้",
  },
  VerificationRequired: {
    title: "ต้องการการยืนยัน",
    description: "เพื่อเข้าร่วมการแข่งขัน คุณต้องยืนยันบัญชีของคุณ",
    bdate: {
      title: "วันเกิดของคุณ",
    },
  },
  Verification: {
    title: "การยืนยัน",
    hint: "เก็บใบหน้าของคุณไว้ในวงรี",
    secureHint: "ไม่มีใครเห็นรูปนี้",
    success: "คุณได้ทำการยืนยันสำเร็จแล้ว",
    goToApp: "ไปที่แอปพลิเคชัน",
    verification: "กำลังยืนยัน...",
    cameraAccessRequired: "กรุณาอนุญาตการเข้าถึงกล้องเพื่อดำเนินการต่อ",
    gettingAccessToCamer: "กำลังเข้าถึงกล้อง...",
    cameraAccessNotAllowed: "การเข้าถึงกล้องถูกปฏิเสธโดยผู้ใช้",
    cameraAccessError: "ข้อผิดพลาดในการเข้าถึงกล้อง",
    loadingHint: "อาจใช้เวลาสักครู่ กรุณารอสักครู่",
  },
  Profile: {
    vote: "โหวต",
    askVote: "ขอโหวต",
    inviteText: `สวัสดี! 😊 โปรดสนับสนุนฉันด้วยการโหวตของคุณในการประกวด TON Stars! ทุกคะแนนเสียงมีความสำคัญและช่วยให้ฉันเข้าใกล้การชนะรางวัลอันมีค่ามากขึ้น

🌟 +{{coins}} เหรียญเป็นของขวัญแรก
🔥 +{{coinsPremium}} เหรียญหากคุณมี Telegram Premium

โหวตให้โปรไฟล์ของฉันที่นี่: {{url}}

ขอบคุณสำหรับการสนับสนุนของคุณ! 🌟`,
    joinContest: "เข้าร่วมการแข่งขัน",
    gender: {
      male: "ชาย",
      female: "หญิง",
    },
    photos: {
      inReview: "รูปถ่ายใหม่ของคุณอยู่ระหว่างการตรวจสอบ\nอาจใช้เวลาสักครู่",
      rejected:
        "หนึ่งหรือมากกว่าหนึ่งรูปถ่ายถูกปฏิเสธเนื่องจากเหตุผลดังต่อไปนี้:\n{{reason}}.\n\nโปรดอัปโหลดรูปถ่ายอื่นและลองใหม่อีกครั้ง",
    },
  },
  ProfileEdit: {
    title: "โปรไฟล์ของคุณ",
    name: {
      label: "ชื่อของคุณ",
      placeholder: "ใส่ชื่อของคุณ",
    },
    instagram: {
      description: "โปรไฟล์ Instagram ต้องเป็นสาธารณะ",
    },
    photos: {
      verificationPhotoUploaded:
        "รูปภาพการยืนยันของคุณถูกอัปโหลดแล้ว ไม่มีใครเห็นมัน",
      label: "รูปถ่ายของคุณ",
      mainPhoto: "รูปถ่ายหลัก",
      error: "หนึ่งหรือมากกว่าหนึ่งรูปถ่ายไม่เหมาะสม โปรดเลือกอีกครั้ง",
      hint: "เลือกภาพที่น่าประทับใจที่สุดของคุณ ซึ่งไม่มีคนอื่น และใบหน้าของคุณชัดเจนและมองเห็นได้อย่างชัดเจน",
      inReview: "รูปถ่ายใหม่ของคุณอยู่ระหว่างการตรวจสอบ\nอาจใช้เวลาสักครู่",
      rejected:
        "หนึ่งหรือมากกว่าหนึ่งรูปถ่ายถูกปฏิเสธเนื่องจากเหตุผลดังต่อไปนี้:\n{{reason}}.\n\nโปรดอัปโหลดรูปถ่ายอื่นและลองใหม่อีกครั้ง",
    },
    submit: "เข้าร่วมการแข่งขัน",
    photosUploading: "กำลังอัปโหลดรูปภาพ...",
    success: "โปรไฟล์ของคุณได้รับการอัปเดตแล้ว",
    errors: {
      name: "กรุณากรอกชื่อของคุณ",
      photos: "กรุณาเลือก 3 รูป",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "รูปถ่ายหลัก",
    noFace: "ไม่พบใบหน้าของคุณ",
    otherPeople: "รูปถ่ายควรแสดงเฉพาะใบหน้าของคุณ",
  },
};
