export default {
  g: {
    flag: "🇮🇳",
    langName: "Tamil",
    langNameNative: "தமிழ்",
    continue: "தொடரவும்",
    save: "சேமிக்கவும்",
    retry: "மீண்டும் முயற்சி செய்யவும்",
    loading: "ஏற்றுகிறது...",
    genderMany: {
      all: "அனைத்து",
      male: "ஆண்கள்",
      female: "பெண்கள்",
    },
  },
  CreateAccount: {
    slogan: "கனவுகள் வெற்றிகளாக மாறும் இடம்!",
    gender: {
      title: "வணக்கம் {{name}}, நீ...",
      male: "ஆண்",
      female: "பெண்",
    },
  },
  Settings: {
    title: "அமைப்புகள்",
    language: "மொழி",
    auto: "தானாக",
    country: "நாடு",
    hideProfile: {
      label: "சுயவிவரத்தை மறைக்கவும்",
      description:
        "நீங்கள் அதை நீக்கியது போல், உங்கள் சுயவிவரம் அனைவரிடமிருந்தும் மறைக்கப்படும்.",
    },
    profileHidden:
      "உங்கள் சுயவிவரம் மறைக்கப்பட்டுள்ளது மற்றும் பிற பயனர்களுக்கு தெரியாது.",
  },
  Tabs: {
    vote: "வாக்களிக்கவும்",
    contest: "போட்டி",
    friends: "நண்பர்கள்",
    earn: "சம்பாதிக்கவும்",
    wallet: "வாலட்",
  },
  Vote: {
    whoIsBetter: "உங்களுக்கு அதிகமாக யாரை விரும்புகிறீர்கள்?",
    votingForLeader: "முதன்மையாளர் க்காக வாக்களித்தல்",
    noUsers: {
      title: "🚀 இன்று இதுவரை",
      description: "வாக்களிக்கவும் நாணயங்களைப் பெறவும் நண்பர்களை அழைக்கவும்",
      inviteFriends: "நண்பர்களை அழைக்கவும்",
    },
  },
  Leaders: {
    rewards: "பரிசுகள்",
    comingSoon: "விரைவில்",
    prizePool: "பரிசு நிதி",
    moreInfo: "மேலும் தகவல்",
    contest: "போட்டி",
    richest: "மிகவும் பணக்காரர்கள்",
    sponsors: "மூலதனம்கொடுப்பவர்கள்",
    description: {
      likes:
        "அனைவரையும் அழைக்கவும், உங்கள் சுயவிவரத்தைப் பகிரவும், மேலும் ஓட்டுகளைப் பெறவும், வெற்றி பெறும் வாய்ப்புகளை அதிகரிக்கவும்.",
      coins:
        "பணிகளை முடிக்கவும், பிற போட்டியாளர்களுக்காக ஓட்டளிக்கவும், மேலும் நாணயங்களைப் பெறவும், உங்கள் நிலையை உறுதிப்படுத்தவும் நண்பர்களை அழைக்கவும்.",
      stars:
        "சுயவிவரப் பக்கத்தில் சுயவிவரங்களுக்காக ஓட்டளிக்கவும், நட்சத்திரங்களைப் பெறவும் மற்றும் தரவரிசையில் ஏறவும்.",
    },
  },
  PrizePool: {
    text: `<b>பரிசு வெல்லும் இடங்கள்</b>:
<b>1வது இடம்</b>: 50% USD மற்றும் 10,000 நாணயங்கள்
<b>2வது இடம்</b>: 30% USD மற்றும் 3,000 நாணயங்கள்
<b>3வது இடம்</b>: 20% USD மற்றும் 2,000 நாணயங்கள்
<b>4வது-10வது இடங்கள்</b>: ஒவ்வொன்றும் 1,000 நாணயங்கள்
<b>11வது-50வது இடங்கள்</b>: ஒவ்வொன்றும் 500 நாணயங்கள்
<b>51வது-100வது இடங்கள்</b>: ஒவ்வொன்றும் 250 நாணயங்கள்

<b>பரிசு நிதி</b> உங்கள் சுயவிவரப் பக்கத்தில் யாரோ ஒருவர் உங்களுக்காக ஓட்டளிக்கும் போது அதிகரிக்கிறது.

<b>நிபந்தனைகள்</b>:
புகைப்படத்தைப் பதிவேற்றும் மற்றும் பணிகளை முடிக்கும் அனைத்து சுயவிவரங்களும் போட்டியில் பங்கேற்க தகுதியானவை.

<b>முடிவு தேதி</b>:
{{endDate}}

<b>பரிசு வழங்கல்</b>:
உங்கள் பரிசு <b>TON</b> இல் கிடைக்கக் கூடியது — இது Telegram இல் உருவாக்கப்பட்ட கிரிப்டோகரன்சி.`,
  },
  LeadersItem: {
    join: "சேரவும்",
  },
  Friends: {
    title: "நண்பர்களை அழைக்கவும்!",
    description: "நீங்கள் மற்றும் உங்கள் நண்பர் பானஸ்களைப் பெறுவீர்கள்",
    bonus: {
      profit: "<coins>+{{count}}</coins> உங்களுக்கும் உங்கள் நண்பருக்கும்",
      invite: {
        title: "நண்பரை அழைக்கவும்",
      },
      inviteWithPremium: {
        title: "நண்பரை அழைக்கவும்",
        description: "டெலிகிராம் பிரீமியத்துடன்",
      },
    },
    list: {
      title: "உங்கள் நண்பர்களின் பட்டியல்",
    },
    inviteFriend: "நண்பரை அழைக்கவும்",
    inviteText: `என்னுடன் சேரவும், அழகான ஆண்கள் மற்றும் பெண்களுக்கு வாக்களிக்கவும் மற்றும் மதிப்புள்ள பரிசுகளைப் பெறவும்!
  
  🌟 முதல் பரிசாக +{{coins}} நாணயங்கள்
  🔥 உங்களிடம் டெலிகிராம் பிரீமியம் இருந்தால் +{{coinsPremium}} நாணயங்கள்
  
  🏆 ஒவ்வொரு வாக்குக்கும் நாணயங்களை சம்பாதிக்கவும் மற்றும் மிகவும் கவர்ச்சிகரமான பங்கேற்பாளர்களைத் தேர்வு செய்யவும். மேலும் விரும்புகிறீர்களா? நீங்களே பங்கேற்று மதிப்புள்ள பரிசுகளுக்காகப் போட்டியிடுங்கள்!
  
  உங்கள் வாய்ப்பைத் தவறவிடாதீர்கள், எங்கள் நட்சத்திரக் சமூகத்தின் ஒரு பகுதியாகவும் மற்றும் வெற்றி பெறுங்கள்!`,
    linkCopied: "இணைப்பு நகலெடுக்கப்பட்டது",
  },
  Earn: {
    title: "மேலும் நாணயங்களைப் பெறுங்கள்",
    tasksList: "பணிகள் பட்டியல்",
    check: "சரிபார்க்கவும்",
    inReview: "மதிப்பீட்டில்",
    subscribe: "சந்தா",
    participate: "பங்கேற்கவும்",
    invite: "அழைக்கவும்",
    verify: "சரிபார்க்கவும்",
    taskCompleted: "பணி முடிந்தது",
  },
  Wallet: {
    title: "TON இல் பரிசுகள் பெறுங்கள்",
    description:
      "டெலிகிராம் உருவாக்கிய கிரிப்டோ நாணயமான TON ஐப் பெற சவால்களை நிறைவேற்றுங்கள்",
    rewards: "பரிசுகள்",
    withdrawal: "திரும்பப்பெறுதல்",
    walletConnected: "உங்கள் TON வாலட் இணைக்கப்பட்டுள்ளது",
    walletDescription: "மேலும் அதிக வாய்ப்புகள் விரைவில் கிடைக்கும்",
  },
  VerificationRequired: {
    title: "சரிபார்ப்பு தேவை",
    description:
      "போட்டியில் பங்கேற்பதற்கு, நீங்கள் உங்கள் கணக்கை சரிபார்க்க வேண்டும்.",
    bdate: {
      title: "உங்கள் பிறந்த தேதி",
    },
  },
  Verification: {
    title: "சரிபார்ப்பு",
    hint: "உங்கள் முகத்தை ஓவலில் வைத்திருக்கவும்",
    secureHint: "இந்த புகைப்படத்தை யாரும் பார்க்கமாட்டார்கள்",
    success: "நீங்கள் சரிபார்ப்பை வெற்றிகரமாக முடித்தீர்கள்",
    goToApp: "பயன்பாட்டுக்கு செல்லவும்",
    verification: "சரிபார்க்கிறது...",
    cameraAccessRequired: "தொடர கேமரா அணுகலை அனுமதிக்கவும்",
    gettingAccessToCamer: "கேமரா அணுகலைப் பெறுகிறது...",
    cameraAccessNotAllowed: "பயனர் கேமரா அணுகலை நிராகரித்தார்.",
    cameraAccessError: "கேமரா அணுகல் பிழை",
    loadingHint: "இது சில நேரம் ஆகக்கூடும். தயவுசெய்து பொறுமையாக இருங்கள்.",
  },
  Profile: {
    vote: "வாக்களிக்கவும்",
    askVote: "வாக்கு கேட்கவும்",
    inviteText: `வணக்கம்! 😊 TON Stars போட்டியில் உங்கள் வாக்குடன் என்னை ஆதரிக்கவும்! ஒவ்வொரு வாக்கும் முக்கியமானது மற்றும் மதிப்புமிக்க பரிசுகளை வெல்ல எனக்கு உதவுகிறது.

🌟 முதல் பரிசாக +{{coins}} நாணயங்கள்
🔥 உங்கள் விலக்கப்பட்டால் +{{coinsPremium}} நாணயங்கள்

எனது சுயவிவரத்திற்கு இங்கே வாக்களிக்கவும்: {{url}}

உங்கள் ஆதரவுக்கு நன்றி! 🌟`,
    joinContest: "போட்டியில் சேரவும்",
    gender: {
      male: "ஆண்",
      female: "பெண்",
    },
    photos: {
      inReview:
        "உங்கள் புதிய புகைப்படங்கள் மதிப்பீட்டில் உள்ளன.\nஇது சிறிது நேரம் எடுத்துக் கொள்ளலாம்.",
      rejected:
        "ஒரு அல்லது அதற்கு மேற்பட்ட புகைப்படங்கள் கீழ்க்கண்ட காரணத்திற்காக நிராகரிக்கப்பட்டுள்ளன:\n{{reason}}.\n\nவேறுபட்ட புகைப்படங்களைப் பதிவேற்றவும் மீண்டும் முயற்சிக்கவும்.",
    },
  },
  ProfileEdit: {
    title: "உங்கள் சுயவிவரம்",
    name: {
      label: "உங்கள் பெயர்",
      placeholder: "உங்கள் பெயரை உள்ளிடவும்",
    },
    instagram: {
      description: "Instagram சுயவிவரம் பொது இருக்க வேண்டும்",
    },
    photos: {
      verificationPhotoUploaded:
        "உங்கள் சரிபார்ப்பு புகைப்படம் பதிவேற்றப்பட்டுள்ளது, யாரும் அதை பார்க்கமாட்டார்கள்.",
      label: "உங்கள் புகைப்படங்கள்",
      mainPhoto: "முக்கிய புகைப்படம்",
      error:
        "ஒரு அல்லது அதற்கு மேற்பட்ட புகைப்படங்கள் பொருத்தமற்றவை, தயவுசெய்து வேறு தேர்வு செய்யவும்.",
      hint: "உங்கள் முகம் தெளிவாகவும் வெளிப்படையாகவும் தெரியும், உங்கள் மிகக் குறிப்பிடத்தக்க புகைப்படங்களைத் தேர்வு செய்யவும்.",
      inReview:
        "உங்கள் புதிய புகைப்படங்கள் மதிப்பீட்டில் உள்ளன.\nஇது சிறிது நேரம் எடுத்துக் கொள்ளலாம்.",
      rejected:
        "ஒரு அல்லது அதற்கு மேற்பட்ட புகைப்படங்கள் கீழ்க்கண்ட காரணத்திற்காக நிராகரிக்கப்பட்டுள்ளன:\n{{reason}}.\n\nவேறுபட்ட புகைப்படங்களைப் பதிவேற்றவும் மீண்டும் முயற்சிக்கவும்.",
    },
    submit: "போட்டியில் சேரவும்",
    photosUploading: "புகைப்படத்தைப் பதிவேற்றுகிறது...",
    success: "உங்கள் சுயவிவரம் புதுப்பிக்கப்பட்டுள்ளது",
    errors: {
      name: "உங்கள் பெயரைச் சேர்க்கவும்",
      photos: "3 புகைப்படங்களைத் தேர்ந்தெடுக்கவும்",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "முக்கிய புகைப்படம்",
    noFace: "உங்கள் முகம் கண்டறியப்படவில்லை",
    otherPeople: "புகைப்படத்தில் உங்கள் முகம் மட்டுமே இருக்க வேண்டும்",
  },
};
