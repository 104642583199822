export default {
  g: {
    flag: "🇰🇿",
    langName: "Kazakh",
    langNameNative: "Қазақ",
    continue: "Жалғастыру",
    save: "Сақтау",
    retry: "Қайталап көріңіз",
    loading: "Жүктелуде...",
    genderMany: {
      all: "Барлығы",
      male: "Ерлер",
      female: "Әйелдер",
    },
  },
  CreateAccount: {
    slogan: "Армандар жеңіске айналатын орын!",
    gender: {
      title: "Сәлем {{name}}, Сіз...",
      male: "Ер адам",
      female: "Әйел",
    },
  },
  Settings: {
    title: "Параметрлер",
    language: "Тіл",
    auto: "Автоматты",
    country: "Ел",
    hideProfile: {
      label: "Профильді жасыру",
      description:
        "Сіздің профиліңіз бәрінен жасырылған болады, оны жойған сияқты.",
    },
    profileHidden:
      "Сіздің профиліңіз жасырылған және басқа пайдаланушыларға көрінбейді.",
  },
  Tabs: {
    vote: "Дауыс беру",
    contest: "Байқау",
    friends: "Достар",
    earn: "Табыс",
    wallet: "Әмиян",
  },
  Vote: {
    whoIsBetter: "Сізге кім көбірек ұнайды?",
    votingForLeader: "Көшбасшыға дауыс беру",
    noUsers: {
      title: "🚀 Бүгінгі күнге осы жеткілікті",
      description:
        "Достарыңызды дауыс беруді жалғастыруға және монеталар табуға шақырыңыз",
      inviteFriends: "Достарыңызды шақырыңыз",
    },
  },
  Leaders: {
    rewards: "Марапаттар",
    comingSoon: "Жақында",
    prizePool: "Жүлде қоры",
    moreInfo: "Толығырақ ақпарат",
    contest: "Байқау",
    richest: "Ең байлар",
    sponsors: "Демеушілер",
    description: {
      likes:
        "Достарыңызды шақырыңыз және көбірек дауыс жинау және жеңіске жету мүмкіндігін арттыру үшін профиліңізді бөлісіңіз.",
      coins:
        "Тапсырмаларды орындаңыз, басқа қатысушыларға дауыс беріңіз және достарыңызды шақырыңыз, көбірек монеталар табу және позицияңызды нығайту үшін.",
      stars:
        "Жұлдыздар алу және рейтингтерде жоғары көтерілу үшін профиль бетінде профильдерге дауыс беріңіз.",
    },
  },
  PrizePool: {
    text: `<b>Жүлделі орындар</b>:
<b>1-ші орын</b>: 50% USD және 10,000 монета
<b>2-ші орын</b>: 30% USD және 3,000 монета
<b>3-ші орын</b>: 20% USD және 2,000 монета
<b>4-10-ші орындар</b>: әрқайсысы 1,000 монета
<b>11-50-ші орындар</b>: әрқайсысы 500 монета
<b>51-100-ші орындар</b>: әрқайсысы 250 монета

<b>Жүлде қоры</b> біреу сіздің профиль бетіңізде сізге дауыс бергенде артады.

<b>Шарттар</b>:
Фотосурет жүктеп, тапсырмаларды орындаған барлық профильдер байқауға қатысуға құқылы.

<b>Аяқталу мерзімі</b>:
{{endDate}}

<b>Марапаттарды төлеу</b>:
Сіз марапатты <b>TON</b> арқылы ала аласыз — бұл Telegram әзірлеген криптовалюта.`,
  },
  LeadersItem: {
    join: "Қосылу",
  },
  Friends: {
    title: "Достарыңызды шақырыңыз!",
    description: "Сіз және досыңыз бонус аласыз",
    bonus: {
      profit: "<coins>+{{count}}</coins> сізге және досыңызға",
      invite: {
        title: "Досыңызды шақырыңыз",
      },
      inviteWithPremium: {
        title: "Досыңызды шақырыңыз",
        description: "Telegram Premium-пен",
      },
    },
    list: {
      title: "Сіздің достарыңыздың тізімі",
    },
    inviteFriend: "Досыңызды шақырыңыз",
    inviteText: `Маған қосылыңыз, ең әдемі жігіттер мен қыздарға дауыс беріп, құнды сыйлықтар алыңыз!
  
  🌟 Бірінші сыйлық ретінде +{{coins}} монеталар
  🔥 Егер сізде Telegram Premium болса, +{{coinsPremium}} монеталар
  
  🏆 Әр дауыс үшін монеталар табыңыз және ең тартымды қатысушыларды таңдаңыз. Көбірек қалайсыз ба? Өз бетіңізше қатысып, құнды сыйлықтар үшін жарысыңыз!
  
  Мүмкіндігіңізді жіберіп алмаңыз, біздің жұлдыздар қауымдастығымыздың бір бөлігі болыңыз және жеңіңіз!`,
    linkCopied: "Сілтеме көшірілді",
  },
  Earn: {
    title: "Көбірек монеталар табыңыз",
    tasksList: "Тапсырмалар тізімі",
    check: "Тексеру",
    inReview: "Қаралуда",
    subscribe: "Жазылыңыз",
    participate: "Қатысу",
    invite: "Шақыру",
    verify: "Растау",
    taskCompleted: "Тапсырма орындалды",
  },
  Wallet: {
    title: "TON-да сыйақы алыңыз",
    description:
      "TON, Telegram әзірлеген крипто монетасын табу үшін тапсырмаларды орындаңыз",
    rewards: "Сыйақылар",
    withdrawal: "Шығару",
    walletConnected: "Сіздің TON әмияныңыз қосылған",
    walletDescription: "Жақында көбірек мүмкіндіктер қолжетімді болады",
  },
  VerificationRequired: {
    title: "Растау қажет",
    description: "Байқауға қатысу үшін сіз өз аккаунтыңызды растауыңыз керек.",
    bdate: {
      title: "Туған күніңіз",
    },
  },
  Verification: {
    title: "Растау",
    hint: "Бетіңізді сопақша ішіне орналастырыңыз",
    secureHint: "Бұл фотосуретті ешкім көрмейді",
    success: "Сіз растауды сәтті аяқтадыңыз",
    goToApp: "Қосымшаға өту",
    verification: "Растау...",
    cameraAccessRequired:
      "Жалғастыру үшін камераға қол жеткізуді рұқсат етіңіз",
    gettingAccessToCamer: "Камераға қол жеткізу алынуда...",
    cameraAccessNotAllowed: "Пайдаланушы камераға қол жеткізуден бас тартты.",
    cameraAccessError: "Камераға қол жеткізу қатесі",
    loadingHint: "Бұл біраз уақыт алуы мүмкін. Сабыр етіңіз.",
  },
  Profile: {
    vote: "Дауыс беру",
    askVote: "Дауыс сұрау",
    inviteText: `Сәлем! 😊 TON Stars байқауында маған дауыс беру арқылы қолдау көрсетіңізші! Әр дауыс маңызды және құнды жүлделерге жақындауға көмектеседі.

🌟 Алғашқы сыйлық ретінде +{{coins}} монеталар
🔥 Telegram Premium болса +{{coinsPremium}} монеталар

Менің профиліме осы жерден дауыс беріңіз: {{url}}

Қолдауыңыз үшін рақмет! 🌟`,
    joinContest: "Байқауға қатысу",
    gender: {
      male: "Ер",
      female: "Әйел",
    },
    photos: {
      inReview:
        "Сіздің жаңа фотосуреттеріңіз қаралуда.\nБұл біраз уақыт алуы мүмкін.",
      rejected:
        "Бір немесе бірнеше фотосуреттер келесі себеппен қабылданбады:\n{{reason}}.\n\nӨзге фотосуреттерді жүктеп, қайтадан көріңіз.",
    },
  },
  ProfileEdit: {
    title: "Сіздің профиль",
    name: {
      label: "Сіздің аты-жөніңіз",
      placeholder: "Аты-жөніңізді енгізіңіз",
    },
    instagram: {
      description: "Instagram профилі ашық болуы керек",
    },
    photos: {
      verificationPhotoUploaded:
        "Сіздің верификациялау фотосуретіңіз жүктелді, оны ешкім көрмейді.",
      label: "Сіздің фотосуреттеріңіз",
      mainPhoto: "Негізгі фото",
      error:
        "Бір немесе бірнеше фотосуреттер қолайлы емес, басқа таңдау жасаңыз.",
      hint: "Ең әсерлі фотосуреттеріңізді таңдаңыз, онда басқа адамдар жоқ, және сіздің бетіңіз анық көрініп тұр.",
      inReview:
        "Сіздің жаңа фотосуреттеріңіз қаралуда.\nБұл біраз уақыт алуы мүмкін.",
      rejected:
        "Бір немесе бірнеше фотосуреттер келесі себеппен қабылданбады:\n{{reason}}.\n\nӨзге фотосуреттерді жүктеп, қайтадан көріңіз.",
    },
    submit: "Байқауға қатысу",
    photosUploading: "Фото жүктелуде...",
    success: "Сіздің профиліңіз жаңартылды",
    errors: {
      name: "Атыңызды енгізіңіз",
      photos: "3 фотосуретті таңдаңыз",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Негізгі фото",
    noFace: "Бетіңіз анықталмады",
    otherPeople: "Фотода тек сіздің бетіңіз болуы керек",
  },
};
