import { Modal } from "components/Modal/Modal";
import { useTranslation } from "react-i18next";
import FemaleImage from "./icons/female.png";
import MaleImage from "./icons/male.png";
import "./VerificationRequired.css";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { UserGender } from "__generated__/graphql";
import { Caption, Input, Text, Title } from "@telegram-apps/telegram-ui";
import { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { Verification } from "./Verification";
import { useMainButton } from "hooks/useMainButton";
import toast from "react-hot-toast";

const VerificationRequired_Mutation = gql(`
  mutation VerificationRequired_Mutation(
    $data: UserSaveInput!
  ) {
    userSave(data: $data) {
      id
      gender
      bdate
    }
  }
`);

const VerificationRequiredUser_QueryFragment = gql(`
  fragment VerificationRequiredUser_QueryFragment on User {
      id
      gender
      bdate
  }
`);

type VerificationRequiredProps = {
  user?: FragmentType<typeof VerificationRequiredUser_QueryFragment> | null;
  onClose?: () => void;
  onComplete?: () => void;
};

export const VerificationRequired = (props: VerificationRequiredProps) => {
  const user = getFragmentData(
    VerificationRequiredUser_QueryFragment,
    props.user
  );
  const [saveUser, { loading }] = useMutation(VerificationRequired_Mutation);
  const { t } = useTranslation("VerificationRequired");
  const { t: g } = useTranslation();

  const [bdate, setBdate] = useState(
    moment(user?.bdate || undefined)
      .toDate()
      .toISOString()
      .split("T")[0]
  );

  const [verificationVisible, setVerificationVisible] = useState(false);

  useMainButton({
    text: g("continue"),
    loading,
    onClick: useCallback(() => {
      saveUser({ variables: { data: { bdate } } })
        .then(({ data }) => {
          if (!data?.userSave) return;

          setVerificationVisible(true);
        })
        .catch((e) => {
          toast.error(e.message);
        });
    }, [bdate, saveUser]),
  });

  return (
    <Modal onClose={props.onClose} fullscreen>
      <div className="verification-required">
        {verificationVisible && (
          <Verification
            onComplete={() => {
              props.onClose?.();
              props.onComplete?.();
            }}
          />
        )}
        <img
          className="modal-icon"
          src={user?.gender === UserGender.Female ? FemaleImage : MaleImage}
        />
        <Title className="modal-title" level="1" weight="2">
          {t("title")}
        </Title>
        <Text className="verification-required-description">
          {t("description")}
        </Text>
        <div className="verification-required-body">
          <Caption
            className="verification-required-caption"
            caps
            level="1"
            weight="3"
          >
            {t("bdate.title")}
          </Caption>
          <Input
            type="date"
            required
            value={bdate}
            onChange={(e) => setBdate(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};
