import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Uploads } from "./Uploads";
import { FragmentType, getFragmentData } from "__generated__";
import { Upload_QueryFragment } from "./gql";
import { Upload } from "./Upload";

export type UploadItem = {
  id?: string;
  uploadId?: string;
  url?: string | null;
  state?: "uploading" | "done" | "error";
  progress?: number;
  uploadRef?: Upload;
};

export const useUploads = (
  initialUploadsFragment?: FragmentType<typeof Upload_QueryFragment>[]
) => {
  const initialUploads = getFragmentData(
    Upload_QueryFragment,
    initialUploadsFragment
  );

  const uploadsRef = useRef(new Uploads());
  const [uploads, setUploads] = useState<UploadItem[]>(
    initialUploads?.map((_) => ({
      id: _.id,
      uploadId: _.id,
      url: _.previewUrl,
      state: "done",
    })) || []
  );

  const uploadCreate = useCallback((file: File) => {
    const uploadRef = uploadsRef.current.create(file);

    setUploads((uploads) => [
      ...uploads,
      {
        id: uploadRef.id,
        url: uploadRef.objectUrl,
        uploadRef,
      },
    ]);
  }, []);

  useEffect(() => {
    const ref = uploadsRef.current;
    const update = (upload: Upload) => {
      setUploads((uploads) => {
        const _upload = uploads.find((_) => _.id === upload.id);
        if (_upload) {
          _upload.state =
            upload.state === "error"
              ? "error"
              : upload.state === "done"
              ? "done"
              : "uploading";
          _upload.progress = upload.progress;
          if (_upload.state === "done") {
            _upload.uploadId = upload._upload?.id;
            _upload.url = upload._upload?.previewUrl;
          }
          return [...uploads];
        }
        return uploads;
      });
    };
    ref.on("update", update);

    return () => {
      ref.off("update", update);
      ref.abortAll();
    };
  }, []);

  return useMemo(
    () => [uploads, setUploads, uploadCreate] as const,
    [uploadCreate, uploads]
  );
};
