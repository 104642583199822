import { persistReducer } from "redux-persist";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SupportedLanguages } from "utils/i18n/resources";
import { RootState } from "store";
import { getInitialLang } from "utils/i18n";
import storage from "redux-persist/lib/storage";

export type SettingsState = Readonly<{
  lang: SupportedLanguages;
  tabId: string;
  modalStack: string[];
  mainButtonStack: string[];
}>;

const initialState: SettingsState = {
  tabId: "vote",
  lang: getInitialLang(),
  modalStack: [],
  mainButtonStack: [],
};

export const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<SettingsState["lang"]>) => {
      state.lang = action.payload;
    },
    setTabId: (state, action: PayloadAction<SettingsState["tabId"]>) => {
      state.tabId = action.payload;
    },
    addModal: (state, action: PayloadAction<string>) => {
      !state.modalStack.includes(action.payload) &&
        state.modalStack.push(action.payload);
    },
    removeModal: (state, action: PayloadAction<string>) => {
      state.modalStack = state.modalStack.filter((_) => _ !== action.payload);
    },
    addMainButton: (state, action: PayloadAction<string>) => {
      !state.mainButtonStack.includes(action.payload) &&
        state.mainButtonStack.push(action.payload);
    },
    removeMainButton: (state, action: PayloadAction<string>) => {
      state.mainButtonStack = state.mainButtonStack.filter(
        (_) => _ !== action.payload
      );
    },
  },
});

export const {
  setLang,
  setTabId,
  addModal,
  removeModal,
  addMainButton,
  removeMainButton,
} = settings.actions;

export const selectTabId = (state: RootState) => state.settings.tabId;
export const selectLang = (state: RootState) => state.settings.lang;
export const selectModalStack = (state: RootState) => state.settings.modalStack;
export const selectMainButtonStack = (state: RootState) =>
  state.settings.mainButtonStack;

export default {
  reducer: persistReducer(
    {
      key: "settings",
      storage,
      blacklist: ["tabId", "modalStack", "mainButtonStack"],
    },
    settings.reducer
  ),
};
