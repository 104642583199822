import Emittery from "emittery";
import { Upload } from "./Upload";

export class Uploads extends Emittery {
  uploads: Upload[] = [];

  create(file: File) {
    const upload = new Upload(file);
    this.uploads.push(upload);

    upload.start();
    upload.on("update", () => {
      this.emit("update", upload);
    });

    return upload;
  }

  abortAll() {
    this.uploads.forEach((upload) => upload.abort());
  }
}
