export default {
  g: {
    flag: "🇵🇭",
    langName: "Filipino",
    langNameNative: "Filipino",
    continue: "Magpatuloy",
    save: "I-save",
    retry: "Subukang muli",
    loading: "Naglo-load...",
    genderMany: {
      all: "Lahat",
      male: "Mga Lalaki",
      female: "Mga Babae",
    },
  },
  CreateAccount: {
    slogan: "Lugar kung saan ang mga pangarap ay nagiging mga panalo!",
    gender: {
      title: "Kamusta {{name}}, Ikaw ay...",
      male: "Lalaki",
      female: "Babae",
    },
  },
  Settings: {
    title: "Mga Setting",
    language: "Wika",
    auto: "Awtomatik",
    country: "Bansa",
    hideProfile: {
      label: "Itago ang Profile",
      description:
        "Ang iyong profile ay itatago sa lahat, na parang tinanggal mo na ito.",
    },
    profileHidden:
      "Ang iyong profile ay nakatago at hindi nakikita ng ibang mga gumagamit.",
  },
  Tabs: {
    vote: "Bumoto",
    contest: "Kumpetisyon",
    friends: "Mga Kaibigan",
    earn: "Kumita",
    wallet: "Wallet",
  },
  Vote: {
    whoIsBetter: "Sino ang mas gusto mo?",
    votingForLeader: "Pagboto para sa lider",
    noUsers: {
      title: "🚀 Iyan na para sa ngayon",
      description:
        "Imbitahan ang mga kaibigan na ipagpatuloy ang pagboto at pagkita ng mga barya",
      inviteFriends: "Imbitahan ang mga Kaibigan",
    },
  },
  Leaders: {
    rewards: "Mga Gantimpala",
    comingSoon: "Paparating na",
    prizePool: "Premyo na Pondo",
    moreInfo: "Karagdagang impormasyon",
    contest: "Paligsahan",
    richest: "Pinakamayaman",
    sponsors: "Mga Sponsor",
    description: {
      likes:
        "Imbitahan ang mga kaibigan at ibahagi ang iyong profile upang makalikom ng mas maraming boto at pataasin ang iyong tsansa ng pagkapanalo.",
      coins:
        "Kumpletuhin ang mga gawain, bumoto para sa ibang mga kalahok, at imbitahan ang mga kaibigan upang makakuha ng mas maraming mga barya at palakasin ang iyong posisyon.",
      stars:
        "Bumoto para sa mga profile sa profile page upang makatanggap ng mga bituin at umangat sa rankings.",
    },
  },
  PrizePool: {
    text: `<b>Mga nanalo ng premyo</b>:
<b>1st place</b>: 50% USD at 10,000 coins
<b>2nd place</b>: 30% USD at 3,000 coins
<b>3rd place</b>: 20% USD at 2,000 coins
<b>4th-10th places</b>: 1,000 coins bawat isa
<b>11th-50th places</b>: 500 coins bawat isa
<b>51st-100th places</b>: 250 coins bawat isa

<b>Ang premyo na pondo</b> ay tumataas kapag may bumoto para sa iyo sa iyong profile page.

<b>Mga kondisyon</b>:
Lahat ng mga profile na nag-upload ng larawan at kumpletuhin ang mga gawain ay karapat-dapat na lumahok sa paligsahan.

<b>Petsa ng pagtatapos</b>:
{{endDate}}

<b>Pagbabayad ng gantimpala</b>:
Maaari mong matanggap ang iyong gantimpala sa <b>TON</b> — ang cryptocurrency na binuo ng Telegram.`,
  },
  LeadersItem: {
    join: "Sumali",
  },
  Friends: {
    title: "Mag-imbita ng mga kaibigan!",
    description: "Ikaw at ang iyong kaibigan ay makakatanggap ng mga bonus",
    bonus: {
      profit: "<coins>+{{count}}</coins> para sa iyo at sa iyong kaibigan",
      invite: {
        title: "Mag-imbita ng kaibigan",
      },
      inviteWithPremium: {
        title: "Mag-imbita ng kaibigan",
        description: "kasama ang Telegram Premium",
      },
    },
    list: {
      title: "Listahan ng iyong mga kaibigan",
    },
    inviteFriend: "Mag-imbita ng kaibigan",
    inviteText: `Sumali sa akin, bumoto para sa pinakamagagandang lalaki at babae, at makatanggap ng mga mahalagang premyo!
  
  🌟 +{{coins}} barya bilang unang regalo
  🔥 +{{coinsPremium}} barya kung mayroon kang Telegram Premium
  
  🏆 Kumita ng barya sa bawat boto at piliin ang pinaka-kaakit-akit na mga kalahok. Gusto mo ba ng higit pa? Sumali mismo at makipagkumpitensya para sa mahalagang mga premyo!
  
  Huwag palampasin ang iyong pagkakataon, maging bahagi ng aming komunidad ng mga bituin at manalo!`,
    linkCopied: "Kinopya ang link",
  },
  Earn: {
    title: "Kumita ng mas maraming barya",
    tasksList: "Listahan ng mga gawain",
    check: "Suriin",
    inReview: "Sa pagsusuri",
    subscribe: "Mag-subscribe",
    participate: "Sumali",
    invite: "Mag-imbita",
    verify: "I-verify",
    taskCompleted: "Natapos na ang gawain",
  },
  Wallet: {
    title: "Kumuha ng mga gantimpala sa TON",
    description:
      "Kumpletuhin ang mga hamon upang kumita ng TON, isang crypto coin na binuo ng Telegram",
    rewards: "Mga Gantimpala",
    withdrawal: "Pag-withdraw",
    walletConnected: "Nakakonekta ang iyong TON wallet",
    walletDescription:
      "Mas maraming mga pagkakataon ang magiging available sa lalong madaling panahon",
  },
  VerificationRequired: {
    title: "Kailangan ng Pag-verify",
    description:
      "Upang sumali sa kumpetisyon, kailangan mong i-verify ang iyong account.",
    bdate: {
      title: "Araw ng kapanganakan",
    },
  },
  Verification: {
    title: "Pag-verify",
    hint: "Panatilihin ang iyong mukha sa loob ng hugis-ovalo",
    secureHint: "Walang makakakita sa larawang ito",
    success: "Matagumpay mong nakumpleto ang pag-verify",
    goToApp: "Pumunta sa app",
    verification: "Pag-verify...",
    cameraAccessRequired:
      "Mangyaring payagan ang pag-access sa camera upang magpatuloy",
    gettingAccessToCamer: "Kinukuha ang pag-access sa camera...",
    cameraAccessNotAllowed:
      "Tinanggihan ng gumagamit ang pag-access sa camera.",
    cameraAccessError: "Error sa pag-access sa camera",
    loadingHint: "Maaaring magtagal ito. Mangyaring maghintay.",
  },
  Profile: {
    vote: "Bumoto",
    askVote: "Hilingin na Bumoto",
    inviteText: `Hi! 😊 Pakisuportahan po ako sa pamamagitan ng iyong boto sa TON Stars contest! Mahalaga ang bawat boto at makakatulong ito sa akin na makalapit sa pagwawagi ng mahahalagang premyo.

🌟 +{{coins}} coins bilang unang regalo
🔥 +{{coinsPremium}} coins kung mayroon kang Telegram Premium

Bumoto para sa aking profile dito: {{url}}

Salamat sa iyong suporta! 🌟`,
    joinContest: "Sumali sa contest",
    gender: {
      male: "Lalaki",
      female: "Babae",
    },
    photos: {
      inReview:
        "Ang iyong mga bagong larawan ay sinusuri.\nIto ay maaaring tumagal ng ilang oras.",
      rejected:
        "Isa o higit pang mga larawan ay tinanggihan sa sumusunod na dahilan:\n{{reason}}.\n\nMangyaring mag-upload ng iba pang mga larawan at subukang muli.",
    },
  },
  ProfileEdit: {
    title: "Iyong Profile",
    name: {
      label: "Iyong Pangalan",
      placeholder: "Ilagay ang iyong pangalan",
    },
    instagram: {
      description: "Ang Instagram profile ay dapat pampubliko",
    },
    photos: {
      verificationPhotoUploaded:
        "Na-upload na ang iyong verification photo, walang makakakita nito.",
      label: "Iyong mga Larawan",
      mainPhoto: "Pangunahing Larawan",
      error:
        "Isa o higit pang mga larawan ay hindi angkop, mangyaring gumawa ng ibang pagpipilian.",
      hint: "Pumili ng iyong pinaka-nakakamanghang mga larawan, kung saan walang ibang tao, at ang iyong mukha ay malinaw at malinaw na nakikita.",
      inReview:
        "Ang iyong mga bagong larawan ay sinusuri.\nIto ay maaaring tumagal ng ilang oras.",
      rejected:
        "Isa o higit pang mga larawan ay tinanggihan sa sumusunod na dahilan:\n{{reason}}.\n\nMangyaring mag-upload ng iba pang mga larawan at subukang muli.",
    },
    submit: "Sumali sa contest",
    photosUploading: "Nag-a-upload ng larawan...",
    success: "Ang iyong profile ay na-update",
    errors: {
      name: "Pakilagay ang iyong pangalan",
      photos: "Pakitagilid ang 3 larawan",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Pangunahing larawan",
    noFace: "Hindi nakita ang iyong mukha",
    otherPeople: "Ang larawan ay dapat magpakita lamang ng iyong mukha",
  },
};
