import "./CreateAccount.css";
import Icon from "../../assets/icon.svg?react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { UserGender } from "__generated__/graphql";
import MaleIcon from "./icons/male.svg?react";
import FemaleIcon from "./icons/female.svg?react";
import { Spinner } from "@telegram-apps/telegram-ui";

const CreateAccount_Mutation = gql(`
  mutation CreateAccount_Mutation(
    $data: UserSaveInput!
  ) {
    userSave(data: $data) {
      id
      gender
    }
  }
`);

const CreateAccount_QueryFragment = gql(`
  fragment CreateAccount_QueryFragment on Query {
    user {
      id
      first_name
      gender
    }
  }
`);

type CreateAccountProps = {
  query: FragmentType<typeof CreateAccount_QueryFragment>;
};

export const CreateAccount = (props: CreateAccountProps) => {
  const query = getFragmentData(CreateAccount_QueryFragment, props.query);
  const [save, { loading }] = useMutation(CreateAccount_Mutation);

  const { t } = useTranslation("CreateAccount");

  const submit = useCallback(
    (gender: UserGender) => {
      save({
        variables: {
          data: { gender },
        },
      });
    },
    [save]
  );

  return (
    <div className="create-account-container">
      <div className="create-account-header">
        <div className="create-account-logo-container">
          <Icon className="create-account-logo" />
          <div className="crete-account-app-name">TON STARS</div>
        </div>
        <div className="create-account-slogan">{t("slogan")}</div>
      </div>
      <div className="create-account-body">
        <div className="create-account-text">
          {t("gender.title", { name: query.user?.first_name })}
        </div>
        <div className="create-account-gender">
          {loading && (
            <Spinner className="create-account-gender-spinner" size="m" />
          )}
          <div
            className="create-account-gender-item create-account-gender-item-male"
            onClick={() => submit(UserGender.Male)}
          >
            <div className="create-account-gender-item-icon">
              <MaleIcon />
            </div>
            <div className="create-account-gender-item-text">
              {t("gender.male")}
            </div>
          </div>
          <div
            className="create-account-gender-item create-account-gender-item-female"
            onClick={() => submit(UserGender.Female)}
          >
            <div className="create-account-gender-item-icon">
              <FemaleIcon />
            </div>
            <div className="create-account-gender-item-text">
              {t("gender.female")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
