import moment from "moment";

export function formatTimePeriodFromNow(date: Date, lang = "en"): string {
  const now = moment();
  const targetDate = moment(date);

  const s = targetDate.diff(now, "s") % 60;
  const m = targetDate.diff(now, "m") % 60;
  const h = targetDate.diff(now, "h") % 24;
  const d = targetDate.diff(now, "d");

  if (s < 0) return "-";

  return (
    (d
      ? Intl.NumberFormat(lang, {
          style: "unit",
          unit: "day",
          compactDisplay: "long",
        }).format(d) + " "
      : "") +
    `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s
      .toString()
      .padStart(2, "0")}`
  );
}
