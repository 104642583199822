export default {
  g: {
    flag: "🇰🇷",
    langName: "Korean",
    langNameNative: "한국어",
    continue: "계속",
    save: "저장",
    retry: "다시 시도",
    loading: "로딩 중...",
    genderMany: {
      all: "모두",
      male: "남성",
      female: "여성",
    },
  },
  CreateAccount: {
    slogan: "꿈이 승리로 바뀌는 곳!",
    gender: {
      title: "안녕하세요 {{name}}, 당신은...",
      male: "남성",
      female: "여성",
    },
  },
  Settings: {
    title: "설정",
    language: "언어",
    auto: "자동",
    country: "국가",
    hideProfile: {
      label: "프로필 숨기기",
      description: "프로필이 삭제된 것처럼 모든 사용자에게 숨겨집니다.",
    },
    profileHidden: "프로필이 숨겨져 있으며 다른 사용자에게 표시되지 않습니다.",
  },
  Tabs: {
    vote: "투표",
    contest: "대회",
    friends: "친구",
    earn: "수익",
    wallet: "지갑",
  },
  Vote: {
    whoIsBetter: "누가 더 마음에 드나요?",
    votingForLeader: "리더를 위한 투표",
    noUsers: {
      title: "🚀 오늘은 여기까지입니다",
      description: "친구를 초대하여 투표를 계속하고 동전을 얻으세요",
      inviteFriends: "친구 초대",
    },
  },
  Leaders: {
    rewards: "보상",
    comingSoon: "곧 공개",
    prizePool: "상금 풀",
    moreInfo: "자세히 보기",
    contest: "대회",
    richest: "가장 부유한 사람들",
    sponsors: "스폰서",
    description: {
      likes:
        "친구를 초대하고 프로필을 공유하여 더 많은 투표를 받고 승리할 가능성을 높이세요.",
      coins:
        "작업을 완료하고 다른 참가자에게 투표하며 친구를 초대하여 더 많은 코인을 획득하고 위치를 강화하세요.",
      stars:
        "프로필 페이지에서 프로필에 투표하여 별을 받고 순위에서 상승하세요.",
    },
  },
  PrizePool: {
    text: `<b>상금을 받을 수 있는 위치</b>:
<b>1위</b>: 50% USD 및 10,000 코인
<b>2위</b>: 30% USD 및 3,000 코인
<b>3위</b>: 20% USD 및 2,000 코인
<b>4위-10위</b>: 각 1,000 코인
<b>11위-50위</b>: 각 500 코인
<b>51위-100위</b>: 각 250 코인

<b>상금 풀</b>은 누군가가 프로필 페이지에서 귀하에게 투표할 때 증가합니다.

<b>조건</b>:
사진을 업로드하고 작업을 완료한 모든 프로필은 대회에 참여할 자격이 있습니다.

<b>종료 날짜</b>:
{{endDate}}

<b>보상 지급</b>:
보상은 Telegram이 개발한 암호화폐인 <b>TON</b>으로 받을 수 있습니다.`,
  },
  LeadersItem: {
    join: "참여",
  },
  Friends: {
    title: "친구를 초대하세요!",
    description: "당신과 친구가 보너스를 받을 것입니다",
    bonus: {
      profit: "<coins>+{{count}}</coins> 당신과 친구에게",
      invite: {
        title: "친구 초대",
      },
      inviteWithPremium: {
        title: "친구 초대",
        description: "Telegram 프리미엄과 함께",
      },
    },
    list: {
      title: "당신의 친구 목록",
    },
    inviteFriend: "친구 초대",
    inviteText: `저와 함께하고, 가장 아름다운 남자와 여자에게 투표하고 귀중한 상품을 받으세요!
  
  🌟 첫 번째 선물로 +{{coins}} 코인
  🔥 Telegram 프리미엄을 가지고 있다면 +{{coinsPremium}} 코인
  
  🏆 각 투표마다 코인을 벌고 가장 매력적인 참가자를 선택하세요. 더 원하세요? 직접 참여하고 귀중한 상품을 경쟁하세요!
  
  기회를 놓치지 마세요, 저희 별 커뮤니티의 일원이 되어 이기세요!`,
    linkCopied: "링크가 복사되었습니다",
  },
  Earn: {
    title: "더 많은 코인을 벌기",
    tasksList: "작업 목록",
    check: "확인",
    inReview: "검토 중",
    subscribe: "구독",
    participate: "참여",
    invite: "초대",
    verify: "확인",
    taskCompleted: "작업 완료",
  },
  Wallet: {
    title: "TON으로 보상 받기",
    description:
      "Telegram에서 개발한 암호화폐 TON을 획득하려면 도전을 완료하세요",
    rewards: "보상",
    withdrawal: "출금",
    walletConnected: "TON 지갑이 연결되었습니다",
    walletDescription: "곧 더 많은 기회가 제공될 예정입니다",
  },
  VerificationRequired: {
    title: "확인 필요",
    description: "대회에 참가하려면 계정을 확인해야 합니다.",
    bdate: {
      title: "생년월일",
    },
  },
  Verification: {
    title: "확인",
    hint: "얼굴을 타원 안에 맞추세요",
    secureHint: "이 사진은 아무도 보지 못합니다",
    success: "확인이 성공적으로 완료되었습니다",
    goToApp: "앱으로 이동",
    verification: "확인 중...",
    cameraAccessRequired: "계속하려면 카메라 접근을 허용하세요",
    gettingAccessToCamer: "카메라 접근을 얻고 있습니다...",
    cameraAccessNotAllowed: "사용자가 카메라 접근을 거부했습니다.",
    cameraAccessError: "카메라 접근 오류",
    loadingHint: "이 작업은 시간이 걸릴 수 있습니다. 잠시만 기다려주세요.",
  },
  Profile: {
    vote: "투표",
    askVote: "투표 요청",
    inviteText: `안녕하세요! 😊 TON Stars 콘테스트에서 귀하의 투표로 저를 지원해 주세요! 모든 투표는 중요하며 귀중한 상품을 얻는 데 도움이 됩니다.

🌟 첫 번째 선물로 +{{coins}} 코인
🔥 Telegram Premium이 있으면 +{{coinsPremium}} 코인

여기에서 내 프로필에 투표해 주세요: {{url}}

지원해 주셔서 감사합니다! 🌟`,
    joinContest: "콘테스트에 참가하기",
    gender: {
      male: "남성",
      female: "여성",
    },
    photos: {
      inReview: "새 사진이 검토 중입니다.\n시간이 조금 걸릴 수 있습니다.",
      rejected:
        "한 장 이상의 사진이 다음 이유로 거부되었습니다:\n{{reason}}.\n\n다른 사진을 업로드하고 다시 시도해 주세요.",
    },
  },
  ProfileEdit: {
    title: "프로필",
    name: {
      label: "이름",
      placeholder: "이름을 입력하세요",
    },
    instagram: {
      description: "Instagram 프로필은 공개되어야 합니다",
    },
    photos: {
      verificationPhotoUploaded:
        "귀하의 인증 사진이 업로드되었습니다. 아무도 볼 수 없습니다.",
      label: "사진",
      mainPhoto: "메인 사진",
      error: "한 장 이상의 사진이 적합하지 않습니다. 다른 사진을 선택하세요.",
      hint: "다른 사람이 없고 얼굴이 명확하고 표현이 잘 보이는 가장 인상적인 사진을 선택하세요.",
      inReview: "새 사진이 검토 중입니다.\n시간이 조금 걸릴 수 있습니다.",
      rejected:
        "한 장 이상의 사진이 다음 이유로 거부되었습니다:\n{{reason}}.\n\n다른 사진을 업로드하고 다시 시도해 주세요.",
    },
    submit: "콘테스트에 참가하기",
    photosUploading: "사진 업로드 중...",
    success: "프로필이 업데이트되었습니다",
    errors: {
      name: "이름을 입력하세요",
      photos: "사진 3장을 선택하세요",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "메인 사진",
    noFace: "얼굴이 감지되지 않았습니다",
    otherPeople: "사진에는 얼굴만 나와야 합니다",
  },
};
