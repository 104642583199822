export default {
  g: {
    flag: "🇲🇾",
    langName: "Malay",
    langNameNative: "Bahasa Melayu",
    continue: "Teruskan",
    save: "Simpan",
    retry: "Cuba lagi",
    loading: "Memuatkan...",
    genderMany: {
      all: "Semua",
      male: "Lelaki",
      female: "Perempuan",
    },
  },
  CreateAccount: {
    slogan: "Tempat di mana Impian Menjadi Kemenangan!",
    gender: {
      title: "Helo {{name}}, Anda adalah...",
      male: "Lelaki",
      female: "Perempuan",
    },
  },
  Settings: {
    title: "Tetapan",
    language: "Bahasa",
    auto: "Automatik",
    country: "Negara",
    hideProfile: {
      label: "Sembunyikan Profil",
      description:
        "Profil anda akan disembunyikan daripada semua orang, seolah-olah anda telah memadamkannya.",
    },
    profileHidden:
      "Profil anda disembunyikan dan tidak kelihatan kepada pengguna lain.",
  },
  Tabs: {
    vote: "Undi",
    contest: "Pertandingan",
    friends: "Kawan",
    earn: "Dapatkan",
    wallet: "Dompet",
  },
  Vote: {
    whoIsBetter: "Siapa yang anda suka lebih?",
    votingForLeader: "Mengundi untuk pemimpin",
    noUsers: {
      title: "🚀 Itu sahaja untuk hari ini",
      description:
        "Jemput rakan untuk meneruskan mengundi dan mendapatkan syiling",
      inviteFriends: "Jemput Rakan",
    },
  },
  Leaders: {
    rewards: "Ganjaran",
    comingSoon: "Akan datang",
    prizePool: "Kolam Hadiah",
    moreInfo: "Maklumat lanjut",
    contest: "Peraduan",
    richest: "Terkaya",
    sponsors: "Penaja",
    description: {
      likes:
        "Jemput rakan-rakan dan kongsi profil anda untuk mengumpul lebih banyak undi dan meningkatkan peluang anda untuk menang.",
      coins:
        "Selesaikan tugasan, undi untuk peserta lain, dan jemput rakan-rakan untuk mendapatkan lebih banyak syiling dan memperkukuhkan kedudukan anda.",
      stars:
        "Undi untuk profil di halaman profil untuk menerima bintang dan naik dalam kedudukan.",
    },
  },
  PrizePool: {
    text: `<b>Tempat memenangi hadiah</b>:
<b>Tempat pertama</b>: 50% USD dan 10,000 syiling
<b>Tempat kedua</b>: 30% USD dan 3,000 syiling
<b>Tempat ketiga</b>: 20% USD dan 2,000 syiling
<b>Tempat ke-4-10</b>: 1,000 syiling setiap satu
<b>Tempat ke-11-50</b>: 500 syiling setiap satu
<b>Tempat ke-51-100</b>: 250 syiling setiap satu

<b>Kolam hadiah</b> meningkat apabila seseorang mengundi untuk anda di halaman profil anda.

<b>Syarat</b>:
Semua profil yang memuat naik gambar dan menyelesaikan tugasan layak untuk menyertai peraduan.

<b>Tarikh akhir</b>:
{{endDate}}

<b>Pembayaran ganjaran</b>:
Anda boleh menerima ganjaran anda dalam <b>TON</b> — mata wang kripto yang dibangunkan oleh Telegram.`,
  },
  LeadersItem: {
    join: "Sertai",
  },
  Friends: {
    title: "Jemput kawan-kawan!",
    description: "Anda dan kawan anda akan menerima bonus",
    bonus: {
      profit: "<coins>+{{count}}</coins> untuk anda dan kawan anda",
      invite: {
        title: "Jemput kawan",
      },
      inviteWithPremium: {
        title: "Jemput kawan",
        description: "dengan Telegram Premium",
      },
    },
    list: {
      title: "Senarai kawan anda",
    },
    inviteFriend: "Jemput kawan",
    inviteText: `Sertai saya, undi untuk lelaki dan wanita yang paling cantik dan dapatkan hadiah yang berharga!
  
  🌟 +{{coins}} syiling sebagai hadiah pertama
  🔥 +{{coinsPremium}} syiling jika anda mempunyai Telegram Premium
  
  🏆 Dapatkan syiling untuk setiap undian dan pilih peserta yang paling menarik. Mahu lebih? Sertai sendiri dan bersaing untuk hadiah yang berharga!
  
  Jangan lepaskan peluang anda, sertai komuniti bintang kami dan menang!`,
    linkCopied: "Pautan disalin",
  },
  Earn: {
    title: "Dapatkan lebih banyak syiling",
    tasksList: "Senarai tugas",
    check: "Periksa",
    inReview: "Dalam semakan",
    subscribe: "Langgan",
    participate: "Sertai",
    invite: "Jemput",
    verify: "Sahkan",
    taskCompleted: "Tugas selesai",
  },
  Wallet: {
    title: "Dapatkan ganjaran dalam TON",
    description:
      "Lengkapkan cabaran untuk mendapatkan TON, syiling kripto yang dibangunkan oleh Telegram",
    rewards: "Ganjaran",
    withdrawal: "Pengeluaran",
    walletConnected: "Dompet TON anda disambungkan",
    walletDescription: "Lebih banyak peluang akan tersedia tidak lama lagi",
  },
  VerificationRequired: {
    title: "Pengesahan Diperlukan",
    description:
      "Untuk menyertai pertandingan, anda perlu mengesahkan akaun anda.",
    bdate: {
      title: "Tarikh lahir anda",
    },
  },
  Verification: {
    title: "Pengesahan",
    hint: "Kekalkan muka anda di dalam bujur",
    secureHint: "Tiada siapa akan melihat foto ini",
    success: "Anda telah berjaya menyelesaikan pengesahan",
    goToApp: "Pergi ke aplikasi",
    verification: "Pengesahan...",
    cameraAccessRequired: "Sila benarkan akses kamera untuk meneruskan",
    gettingAccessToCamer: "Mendapatkan akses ke kamera...",
    cameraAccessNotAllowed: "Akses kamera telah ditolak oleh pengguna.",
    cameraAccessError: "Ralat akses kamera",
    loadingHint: "Ini mungkin mengambil sedikit masa. Sila bersabar.",
  },
  Profile: {
    vote: "Undi",
    askVote: "Minta Undi",
    inviteText: `Hai! 😊 Sila sokong saya dengan undian anda dalam pertandingan TON Stars! Setiap undi amat penting dan membantu saya lebih dekat untuk memenangi hadiah berharga.

🌟 +{{coins}} syiling sebagai hadiah pertama
🔥 +{{coinsPremium}} syiling jika anda mempunyai Telegram Premium

Undi profil saya di sini: {{url}}

Terima kasih atas sokongan anda! 🌟`,
    joinContest: "Sertai peraduan",
    gender: {
      male: "Lelaki",
      female: "Perempuan",
    },
    photos: {
      inReview: "Foto baru anda sedang disemak.\nIni mungkin mengambil masa.",
      rejected:
        "Satu atau lebih foto telah ditolak atas sebab berikut:\n{{reason}}.\n\nSila muat naik foto yang berbeza dan cuba lagi.",
    },
  },
  ProfileEdit: {
    title: "Profil Anda",
    name: {
      label: "Nama Anda",
      placeholder: "Masukkan nama anda",
    },
    instagram: {
      description: "Profil Instagram mesti awam",
    },
    photos: {
      verificationPhotoUploaded:
        "Foto pengesahan anda telah dimuat naik, tiada siapa yang akan melihatnya.",
      label: "Foto Anda",
      mainPhoto: "Foto Utama",
      error: "Satu atau lebih foto tidak sesuai, sila buat pilihan lain.",
      hint: "Pilih foto anda yang paling mengesankan, di mana tiada orang lain, dan wajah anda jelas dan ekspresif kelihatan.",
      inReview: "Foto baru anda sedang disemak.\nIni mungkin mengambil masa.",
      rejected:
        "Satu atau lebih foto telah ditolak atas sebab berikut:\n{{reason}}.\n\nSila muat naik foto yang berbeza dan cuba lagi.",
    },
    submit: "Sertai Peraduan",
    photosUploading: "Memuat naik foto...",
    success: "Profil anda telah dikemas kini",
    errors: {
      name: "Sila masukkan nama anda",
      photos: "Sila pilih 3 foto",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto Utama",
    noFace: "Wajah anda tidak dikesan",
    otherPeople: "Foto hanya harus menampilkan wajah anda",
  },
};
