export default {
  g: {
    flag: "🇮🇩",
    langName: "Indonesian",
    langNameNative: "Bahasa Indonesia",
    continue: "Lanjutkan",
    save: "Simpan",
    retry: "Coba lagi",
    loading: "Memuat...",
    genderMany: {
      all: "Semua",
      male: "Pria",
      female: "Wanita",
    },
  },
  CreateAccount: {
    slogan: "Tempat di mana Mimpi Menjadi Kemenangan!",
    gender: {
      title: "Halo {{name}}, Kamu adalah...",
      male: "Pria",
      female: "Wanita",
    },
  },
  Settings: {
    title: "Pengaturan",
    language: "Bahasa",
    auto: "Otomatis",
    country: "Negara",
    hideProfile: {
      label: "Sembunyikan Profil",
      description:
        "Profil Anda akan disembunyikan dari semua orang, seolah-olah Anda telah menghapusnya.",
    },
    profileHidden:
      "Profil Anda disembunyikan dan tidak terlihat oleh pengguna lain.",
  },
  Tabs: {
    vote: "Vote",
    contest: "Kontes",
    friends: "Teman",
    earn: "Dapatkan",
    wallet: "Dompet",
  },
  Vote: {
    whoIsBetter: "Siapa yang lebih kamu suka?",
    votingForLeader: "Memilih pemimpin",
    noUsers: {
      title: "🚀 Itu saja untuk hari ini",
      description:
        "Undang teman untuk melanjutkan pemungutan suara dan mendapatkan koin",
      inviteFriends: "Undang Teman",
    },
  },
  Leaders: {
    rewards: "Hadiah",
    comingSoon: "Segera hadir",
    prizePool: "Kolam Hadiah",
    moreInfo: "Info lebih lanjut",
    contest: "Kontes",
    richest: "Terkaya",
    sponsors: "Sponsor",
    description: {
      likes:
        "Undang teman dan bagikan profil Anda untuk mengumpulkan lebih banyak suara dan meningkatkan peluang Anda untuk menang.",
      coins:
        "Selesaikan tugas, beri suara untuk peserta lain, dan undang teman untuk mendapatkan lebih banyak koin dan memperkuat posisi Anda.",
      stars:
        "Beri suara untuk profil di halaman profil untuk menerima bintang dan naik peringkat.",
    },
  },
  PrizePool: {
    text: `<b>Tempat pemenang hadiah</b>:
<b>Tempat pertama</b>: 50% USD dan 10,000 koin
<b>Tempat kedua</b>: 30% USD dan 3,000 koin
<b>Tempat ketiga</b>: 20% USD dan 2,000 koin
<b>Tempat ke-4-10</b>: 1,000 koin masing-masing
<b>Tempat ke-11-50</b>: 500 koin masing-masing
<b>Tempat ke-51-100</b>: 250 koin masing-masing

<b>Kolam hadiah</b> meningkat ketika seseorang memberi suara untuk Anda di halaman profil Anda.

<b>Persyaratan</b>:
Semua profil yang mengunggah foto dan menyelesaikan tugas memenuhi syarat untuk berpartisipasi dalam kontes.

<b>Tanggal Akhir</b>:
{{endDate}}

<b>Pembayaran Hadiah</b>:
Anda dapat menerima hadiah Anda dalam <b>TON</b> — cryptocurrency yang dikembangkan oleh Telegram.`,
  },
  LeadersItem: {
    join: "Bergabung",
  },
  Friends: {
    title: "Undang teman-teman!",
    description: "Kamu dan temanmu akan menerima bonus",
    bonus: {
      profit: "<coins>+{{count}}</coins> untuk kamu dan temanmu",
      invite: {
        title: "Undang teman",
      },
      inviteWithPremium: {
        title: "Undang teman",
        description: "dengan Telegram Premium",
      },
    },
    list: {
      title: "Daftar temanmu",
    },
    inviteFriend: "Undang teman",
    inviteText: `Bergabunglah denganku, ikut voting untuk pria dan wanita paling cantik, dan dapatkan hadiah berharga!
  
  🌟 +{{coins}} koin sebagai hadiah pertama
  🔥 +{{coinsPremium}} koin jika kamu memiliki Telegram Premium
  
  🏆 Dapatkan koin untuk setiap vote dan pilih peserta yang paling menarik. Ingin lebih? Ikut serta dan bersaing untuk hadiah berharga!
  
  Jangan lewatkan kesempatanmu, bergabunglah dengan komunitas bintang kami dan menang!`,
    linkCopied: "Tautan disalin",
  },
  Earn: {
    title: "Dapatkan lebih banyak koin",
    tasksList: "Daftar tugas",
    check: "Periksa",
    inReview: "Sedang ditinjau",
    subscribe: "Berlangganan",
    participate: "Ikut serta",
    invite: "Undang",
    verify: "Verifikasi",
    taskCompleted: "Tugas selesai",
  },
  Wallet: {
    title: "Dapatkan hadiah di TON",
    description:
      "Selesaikan tantangan untuk mendapatkan TON, koin kripto yang dikembangkan oleh Telegram",
    rewards: "Hadiah",
    withdrawal: "Penarikan",
    walletConnected: "Dompet TON Anda terhubung",
    walletDescription: "Lebih banyak peluang akan segera tersedia",
  },
  VerificationRequired: {
    title: "Verifikasi Diperlukan",
    description:
      "Untuk ikut serta dalam kontes, kamu perlu memverifikasi akunmu.",
    bdate: {
      title: "Tanggal lahirmu",
    },
  },
  Verification: {
    title: "Verifikasi",
    hint: "Jaga wajah Anda di dalam oval",
    secureHint: "Tidak ada yang akan melihat foto ini",
    success: "Kamu berhasil menyelesaikan verifikasi",
    goToApp: "Pergi ke aplikasi",
    verification: "Memverifikasi...",
    cameraAccessRequired: "Harap izinkan akses kamera untuk melanjutkan",
    gettingAccessToCamer: "Mendapatkan akses ke kamera...",
    cameraAccessNotAllowed: "Akses kamera ditolak oleh pengguna.",
    cameraAccessError: "Kesalahan akses kamera",
    loadingHint: "Ini mungkin memakan waktu. Harap bersabar.",
  },
  Profile: {
    vote: "Pilih",
    askVote: "Minta Suara",
    inviteText: `Hai! 😊 Mohon dukung saya dengan suara Anda dalam kontes TON Stars! Setiap suara sangat penting dan membantu saya mendekati kemenangan hadiah berharga.

🌟 +{{coins}} koin sebagai hadiah pertama
🔥 +{{coinsPremium}} koin jika Anda memiliki Telegram Premium

Pilih profil saya di sini: {{url}}

Terima kasih atas dukungan Anda! 🌟`,
    joinContest: "Ikuti kontes",
    gender: {
      male: "Laki-laki",
      female: "Perempuan",
    },
    photos: {
      inReview: "Foto baru Anda sedang ditinjau.\nIni mungkin memakan waktu.",
      rejected:
        "Satu atau lebih foto telah ditolak dengan alasan berikut:\n{{reason}}.\n\nSilakan unggah foto yang berbeda dan coba lagi.",
    },
  },
  ProfileEdit: {
    title: "Profil Anda",
    name: {
      label: "Nama Anda",
      placeholder: "Masukkan nama Anda",
    },
    instagram: {
      description: "Profil Instagram harus publik",
    },
    photos: {
      verificationPhotoUploaded:
        "Foto verifikasi Anda telah diunggah, tidak ada yang akan melihatnya.",
      label: "Foto Anda",
      mainPhoto: "Foto Utama",
      error: "Satu atau lebih foto tidak cocok, silakan buat pilihan lain.",
      hint: "Pilih foto Anda yang paling mengesankan, di mana tidak ada orang lain, dan wajah Anda terlihat jelas dan ekspresif.",
      inReview: "Foto baru Anda sedang ditinjau.\nIni mungkin memakan waktu.",
      rejected:
        "Satu atau lebih foto telah ditolak dengan alasan berikut:\n{{reason}}.\n\nSilakan unggah foto yang berbeda dan coba lagi.",
    },
    submit: "Ikuti Kontes",
    photosUploading: "Mengunggah foto...",
    success: "Profil Anda telah diperbarui",
    errors: {
      name: "Masukkan nama Anda",
      photos: "Pilih 3 foto",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Foto Utama",
    noFace: "Wajah Anda tidak terdeteksi",
    otherPeople: "Foto harus hanya menampilkan wajah Anda",
  },
};
