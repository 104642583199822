import {
  Button,
  Cell,
  IconButton,
  List,
  Placeholder,
  Section,
  Subheadline,
} from "@telegram-apps/telegram-ui";
import CopyIcon from "./copy.svg?react";
import InviteIcon from "./invite.svg?react";
import CoinIcon from "../../assets/coin.svg?react";
import GiftIcon from "./gift.svg?react";
import GiftPremiumIcon from "./gift.premium.svg?react";
import "./Friends.css";
import WebApp from "@twa-dev/sdk";
import { Trans, useTranslation } from "react-i18next";
import { useMemo } from "react";
import { FragmentType, getFragmentData, gql } from "__generated__";
import { useQuery } from "@apollo/client";
import { LoadingPlaceholder } from "components/LoadingPlaceholder/LoadingPlaceholder";
import { ProfileBonus_QueryFragment } from "components/Profile/Profile";
import { FriendsUser } from "./FriendsUser";
import { ProfileVoteProps } from "components/ProfileVote/ProfileVote";
import config from "config";
import toast from "react-hot-toast";

const Friends_Query = gql(`
  query Friends_Query {
    friends {
      ...ProfileUser_QueryFragment
      ...FriendsUser_QueryFragment
      id
    }
  }
`);

export type FriendsProps = {
  bonus: FragmentType<typeof ProfileBonus_QueryFragment> | null;
  voteConfig: ProfileVoteProps["voteConfig"];
};

export const Friends = (props: FriendsProps) => {
  const { t, i18n } = useTranslation("Friends");
  const bonus = getFragmentData(ProfileBonus_QueryFragment, props.bonus);

  const query = useQuery(Friends_Query);

  const bonuses = useMemo(() => {
    return [
      {
        id: "invite",
        Icon: GiftIcon,
        title: t("bonus.invite.title"),
        reward: bonus?.invite,
      },
      {
        id: "inviteWithPremium",
        Icon: GiftPremiumIcon,
        title: t("bonus.inviteWithPremium.title"),
        subtitle: t("bonus.inviteWithPremium.description"),
        reward: bonus?.invitePremium,
      },
    ];
  }, [bonus?.invite, bonus?.invitePremium, t]);

  const shareUrl =
    `https://t.me/share/url?url=${config.refUrl}&text=` +
    encodeURIComponent(
      t("inviteText", {
        coins: Intl.NumberFormat(i18n.language, {
          notation: "compact",
        }).format(bonuses[0].reward),
        coinsPremium: Intl.NumberFormat(i18n.language, {
          notation: "compact",
        }).format(bonuses[1].reward),
      })
    );

  return (
    <div className="friends-wrap">
      <List className="friends-container scroll">
        <Placeholder
          className="friends-placeholder"
          header={t("title")}
          description={t("description")}
        />
        <Section>
          {bonuses.map((_) => (
            <Cell
              key={_.id}
              className="friends-cell"
              style={{
                padding: "8px 16px",
              }}
              before={<_.Icon width={48} height={48} />}
              subtitle={_.subtitle}
              description={
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    color: "var(--tgui--text_color)",
                  }}
                >
                  <CoinIcon width={18} height={18} />
                  <Trans
                    i18nKey="bonus.profit"
                    t={t}
                    components={{
                      coins: <b style={{ color: "#fcd227" }} />,
                    }}
                    values={{
                      count: _.reward
                        ? Intl.NumberFormat(i18n.language).format(_.reward)
                        : "",
                    }}
                  />
                </div>
              }
              onClick={() => WebApp.openTelegramLink(shareUrl)}
            >
              {_.title}
            </Cell>
          ))}
        </Section>
        <Subheadline style={{ padding: "0 16px" }} level="1" weight="1">
          {t("list.title")} {query.data ? `(${query.data.friends.length})` : ""}
        </Subheadline>
        <LoadingPlaceholder {...query}>
          <Section>
            {query.data?.friends.map((_) => (
              <FriendsUser
                key={_.id}
                user={_}
                shareUrl={shareUrl}
                voteConfig={props.voteConfig}
                bonus={props.bonus}
              />
            ))}
          </Section>
        </LoadingPlaceholder>
      </List>
      <div className="friends-footer">
        <Button
          className="friends-invite-button"
          size="l"
          stretched
          before={<InviteIcon width={18} height={18} />}
          onClick={() => WebApp.openTelegramLink(shareUrl)}
        >
          {t("inviteFriend")}
        </Button>
        <IconButton
          style={{
            aspectRatio: 1,
            height: 50,
            justifyContent: "center",
            alignItems: "center",
            background: "var(--tgui--button_color)",
          }}
          mode="gray"
          size="l"
          onClick={() => {
            navigator.clipboard.writeText(config.refUrl);
            toast.success(t("linkCopied"));
          }}
        >
          <CopyIcon
            style={{
              color: "var(--tgui--button_text_color)",
            }}
            width={18}
            height={18}
          />
        </IconButton>
      </div>
    </div>
  );
};
