export default {
  g: {
    flag: "🇻🇳",
    langName: "Vietnamese",
    langNameNative: "Tiếng Việt",
    continue: "Tiếp tục",
    save: "Lưu",
    retry: "Thử lại",
    loading: "Đang tải...",
    genderMany: {
      all: "Tất cả",
      male: "Nam",
      female: "Nữ",
    },
  },
  CreateAccount: {
    slogan: "Nơi những giấc mơ trở thành chiến thắng!",
    gender: {
      title: "Xin chào {{name}}, Bạn...",
      male: "Nam",
      female: "Nữ",
    },
  },
  Settings: {
    title: "Cài đặt",
    language: "Ngôn ngữ",
    auto: "Tự động",
    country: "Quốc gia",
    hideProfile: {
      label: "Ẩn hồ sơ",
      description:
        "Hồ sơ của bạn sẽ bị ẩn với tất cả mọi người, như thể bạn đã xóa nó.",
    },
    profileHidden:
      "Hồ sơ của bạn đã được ẩn và không hiển thị cho người dùng khác.",
  },
  Tabs: {
    vote: "Bỏ phiếu",
    contest: "Cuộc thi",
    friends: "Bạn bè",
    earn: "Kiếm tiền",
    wallet: "Ví",
  },
  Vote: {
    whoIsBetter: "Bạn thích ai hơn?",
    votingForLeader: "Bình chọn cho lãnh đạo",
    noUsers: {
      title: "🚀 Đó là tất cả cho ngày hôm nay",
      description: "Mời bạn bè để tiếp tục bình chọn và kiếm tiền xu",
      inviteFriends: "Mời bạn bè",
    },
  },
  Leaders: {
    rewards: "Phần thưởng",
    comingSoon: "Sắp có",
    prizePool: "Quỹ giải thưởng",
    moreInfo: "Thêm thông tin",
    contest: "Cuộc thi",
    richest: "Giàu nhất",
    sponsors: "Nhà tài trợ",
    description: {
      likes:
        "Mời bạn bè và chia sẻ hồ sơ của bạn để thu thập nhiều phiếu bầu hơn và tăng cơ hội chiến thắng.",
      coins:
        "Hoàn thành nhiệm vụ, bỏ phiếu cho những người tham gia khác và mời bạn bè để kiếm thêm xu và củng cố vị trí của bạn.",
      stars:
        "Bỏ phiếu cho hồ sơ trên trang hồ sơ để nhận sao và leo lên bảng xếp hạng.",
    },
  },
  PrizePool: {
    text: `<b>Vị trí nhận thưởng</b>:
<b>Hạng 1</b>: 50% USD và 10,000 xu
<b>Hạng 2</b>: 30% USD và 3,000 xu
<b>Hạng 3</b>: 20% USD và 2,000 xu
<b>Hạng 4-10</b>: mỗi hạng 1,000 xu
<b>Hạng 11-50</b>: mỗi hạng 500 xu
<b>Hạng 51-100</b>: mỗi hạng 250 xu

<b>Quỹ giải thưởng</b> tăng lên khi có người bỏ phiếu cho bạn trên trang hồ sơ của bạn.

<b>Điều kiện</b>:
Tất cả hồ sơ tải lên ảnh và hoàn thành nhiệm vụ đều đủ điều kiện tham gia cuộc thi.

<b>Ngày kết thúc</b>:
{{endDate}}

<b>Thanh toán phần thưởng</b>:
Bạn có thể nhận phần thưởng của mình bằng <b>TON</b> — tiền điện tử được phát triển bởi Telegram.`,
  },
  LeadersItem: {
    join: "Tham gia",
  },
  Friends: {
    title: "Mời bạn bè!",
    description: "Bạn và bạn của bạn sẽ nhận được tiền thưởng",
    bonus: {
      profit: "<coins>+{{count}}</coins> cho bạn và bạn của bạn",
      invite: {
        title: "Mời bạn bè",
      },
      inviteWithPremium: {
        title: "Mời bạn bè",
        description: "với Telegram Premium",
      },
    },
    list: {
      title: "Danh sách bạn bè của bạn",
    },
    inviteFriend: "Mời bạn bè",
    inviteText: `Tham gia cùng tôi, bỏ phiếu cho những chàng trai và cô gái đẹp nhất và nhận được những phần thưởng giá trị!
  
  🌟 +{{coins}} xu làm quà tặng đầu tiên
  🔥 +{{coinsPremium}} xu nếu bạn có Telegram Premium
  
  🏆 Kiếm xu cho mỗi lần bỏ phiếu và chọn những người tham gia hấp dẫn nhất. Muốn nhiều hơn? Tham gia và cạnh tranh để giành những phần thưởng giá trị!
  
  Đừng bỏ lỡ cơ hội của bạn, trở thành một phần của cộng đồng ngôi sao của chúng tôi và chiến thắng!`,
    linkCopied: "Liên kết đã được sao chép",
  },
  Earn: {
    title: "Kiếm thêm xu",
    tasksList: "Danh sách nhiệm vụ",
    check: "Kiểm tra",
    inReview: "Đang xem xét",
    subscribe: "Theo dõi",
    participate: "Tham gia",
    invite: "Mời",
    verify: "Xác minh",
    taskCompleted: "Nhiệm vụ đã hoàn thành",
  },
  Wallet: {
    title: "Nhận phần thưởng bằng TON",
    description:
      "Hoàn thành thử thách để kiếm TON, một đồng tiền mã hóa được phát triển bởi Telegram",
    rewards: "Phần thưởng",
    withdrawal: "Rút tiền",
    walletConnected: "Ví TON của bạn đã được kết nối",
    walletDescription: "Sẽ sớm có nhiều cơ hội hơn",
  },
  VerificationRequired: {
    title: "Yêu cầu Xác minh",
    description: "Để tham gia cuộc thi, bạn cần xác minh tài khoản của mình.",
    bdate: {
      title: "Ngày sinh của bạn",
    },
  },
  Verification: {
    title: "Xác minh",
    hint: "Giữ khuôn mặt của bạn trong hình bầu dục",
    secureHint: "Không ai sẽ nhìn thấy bức ảnh này",
    success: "Bạn đã hoàn thành xác minh thành công",
    goToApp: "Đi đến ứng dụng",
    verification: "Đang xác minh...",
    cameraAccessRequired: "Vui lòng cho phép truy cập camera để tiếp tục",
    gettingAccessToCamer: "Đang lấy quyền truy cập vào camera...",
    cameraAccessNotAllowed: "Người dùng từ chối truy cập camera.",
    cameraAccessError: "Lỗi truy cập camera",
    loadingHint:
      "Điều này có thể mất một chút thời gian. Vui lòng kiên nhẫn chờ đợi.",
  },
  Profile: {
    vote: "Bình chọn",
    askVote: "Yêu cầu Bình chọn",
    inviteText: `Xin chào! 😊 Hãy ủng hộ tôi bằng phiếu bầu của bạn trong cuộc thi TON Stars! Mỗi phiếu bầu đều rất quan trọng và giúp tôi đến gần hơn với việc giành được những giải thưởng quý giá.

🌟 +{{coins}} xu như một món quà đầu tiên
🔥 +{{coinsPremium}} xu nếu bạn có Telegram Premium

Bình chọn cho hồ sơ của tôi tại đây: {{url}}

Cảm ơn bạn đã ủng hộ! 🌟`,
    joinContest: "Tham gia cuộc thi",
    gender: {
      male: "Nam",
      female: "Nữ",
    },
    photos: {
      inReview:
        "Ảnh mới của bạn đang được xem xét.\nĐiều này có thể mất một thời gian.",
      rejected:
        "Một hoặc nhiều ảnh đã bị từ chối vì lý do sau:\n{{reason}}.\n\nVui lòng tải lên ảnh khác và thử lại.",
    },
  },
  ProfileEdit: {
    title: "Hồ sơ của bạn",
    name: {
      label: "Tên của bạn",
      placeholder: "Nhập tên của bạn",
    },
    instagram: {
      description: "Hồ sơ Instagram phải ở chế độ công khai",
    },
    photos: {
      verificationPhotoUploaded:
        "Ảnh xác minh của bạn đã được tải lên, không ai sẽ thấy nó.",
      label: "Ảnh của bạn",
      mainPhoto: "Ảnh chính",
      error: "Một hoặc nhiều ảnh không phù hợp, vui lòng chọn ảnh khác.",
      hint: "Chọn những bức ảnh ấn tượng nhất của bạn, nơi không có người khác và khuôn mặt của bạn rõ ràng và biểu cảm.",
      inReview:
        "Ảnh mới của bạn đang được xem xét.\nĐiều này có thể mất một thời gian.",
      rejected:
        "Một hoặc nhiều ảnh đã bị từ chối vì lý do sau:\n{{reason}}.\n\nVui lòng tải lên ảnh khác và thử lại.",
    },
    submit: "Tham gia cuộc thi",
    photosUploading: "Đang tải ảnh lên...",
    success: "Hồ sơ của bạn đã được cập nhật",
    errors: {
      name: "Vui lòng nhập tên của bạn",
      photos: "Vui lòng chọn 3 ảnh",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Ảnh chính",
    noFace: "Khuôn mặt của bạn không được phát hiện",
    otherPeople: "Ảnh chỉ nên hiển thị khuôn mặt của bạn",
  },
};
