export default {
  g: {
    flag: "🇭🇷",
    langName: "Croatian",
    langNameNative: "Hrvatski",
    continue: "Nastavi",
    save: "Spremi",
    retry: "Pokušaj ponovno",
    loading: "Učitavanje...",
    genderMany: {
      all: "Svi",
      male: "Muškarci",
      female: "Žene",
    },
  },
  CreateAccount: {
    slogan: "Mjesto gdje se snovi pretvaraju u pobjede!",
    gender: {
      title: "Pozdrav {{name}}, Ti si...",
      male: "Muško",
      female: "Žensko",
    },
  },
  Settings: {
    title: "Postavke",
    language: "Jezik",
    auto: "Automatski",
    country: "Zemlja",
    hideProfile: {
      label: "Sakrij profil",
      description:
        "Vaš profil će biti skriven od svih, kao da ste ga izbrisali.",
    },
    profileHidden: "Vaš profil je skriven i nije vidljiv drugim korisnicima.",
  },
  Tabs: {
    vote: "Glasaj",
    contest: "Natjecanje",
    friends: "Prijatelji",
    earn: "Zaradi",
    wallet: "Novčanik",
  },
  Vote: {
    whoIsBetter: "Tko vam se više sviđa?",
    votingForLeader: "Glasovanje za vođu",
    noUsers: {
      title: "🚀 To je sve za danas",
      description:
        "Pozovite prijatelje da nastave glasovati i zaraditi novčiće",
      inviteFriends: "Pozovite prijatelje",
    },
  },
  Leaders: {
    rewards: "Nagrade",
    comingSoon: "Uskoro",
    prizePool: "Nagradni fond",
    moreInfo: "Više informacija",
    contest: "Natječaj",
    richest: "Najbogatiji",
    sponsors: "Sponzori",
    description: {
      likes:
        "Pozovite prijatelje i podijelite svoj profil kako biste prikupili više glasova i povećali šanse za pobjedu.",
      coins:
        "Izvršite zadatke, glasajte za druge sudionike i pozovite prijatelje kako biste zaradili više kovanica i ojačali svoju poziciju.",
      stars:
        "Glasujte za profile na stranici profila kako biste dobili zvjezdice i napredovali na ljestvicama.",
    },
  },
  PrizePool: {
    text: `<b>Nagradna mjesta</b>:
<b>1. mjesto</b>: 50% USD i 10,000 kovanica
<b>2. mjesto</b>: 30% USD i 3,000 kovanica
<b>3. mjesto</b>: 20% USD i 2,000 kovanica
<b>4.-10. mjesta</b>: svaki po 1,000 kovanica
<b>11.-50. mjesta</b>: svaki po 500 kovanica
<b>51.-100. mjesta</b>: svaki po 250 kovanica

<b>Nagradni fond</b> se povećava kada netko glasa za vas na vašoj stranici profila.

<b>Uvjeti</b>:
Svi profili koji učitaju fotografiju i izvrše zadatke imaju pravo sudjelovati u natjecanju.

<b>Datum završetka</b>:
{{endDate}}

<b>Isplata nagrada</b>:
Nagradu možete primiti u <b>TON</b> — kriptovaluti koju je razvio Telegram.`,
  },
  LeadersItem: {
    join: "Pridruži se",
  },
  Friends: {
    title: "Pozovite prijatelje!",
    description: "Vi i vaš prijatelj dobit ćete bonuse",
    bonus: {
      profit: "<coins>+{{count}}</coins> za vas i vašeg prijatelja",
      invite: {
        title: "Pozovi prijatelja",
      },
      inviteWithPremium: {
        title: "Pozovi prijatelja",
        description: "s Telegram Premium",
      },
    },
    list: {
      title: "Popis vaših prijatelja",
    },
    inviteFriend: "Pozovi prijatelja",
    inviteText: `Pridružite mi se, sudjelujte u glasanju za najljepše dečke i djevojke i osvojite vrijedne nagrade!
  
  🌟 +{{coins}} kovanica kao prvi dar
  🔥 +{{coinsPremium}} kovanica ako imate Telegram Premium
  
  🏆 Zaradite kovanice za svaki glas i odaberite najatraktivnije sudionike. Želite više? Sudjelujte sami i natječite se za vrijedne nagrade!
  
  Ne propustite svoju priliku, postanite dio naše zvjezdane zajednice i pobijedite!`,
    linkCopied: "Veza kopirana",
  },
  Earn: {
    title: "Zaradite više kovanica",
    tasksList: "Popis zadataka",
    check: "Provjeri",
    inReview: "U pregledu",
    subscribe: "Pretplati se",
    participate: "Sudjeluj",
    invite: "Pozovi",
    verify: "Potvrdi",
    taskCompleted: "Zadatak završen",
  },
  Wallet: {
    title: "Osvojite nagrade u TON-u",
    description:
      "Dovršite izazove kako biste zaradili TON, kripto novčić koji je razvio Telegram",
    rewards: "Nagrade",
    withdrawal: "Povlačenje",
    walletConnected: "Vaš TON novčanik je povezan",
    walletDescription: "Uskoro će biti dostupno više mogućnosti",
  },
  VerificationRequired: {
    title: "Potrebna Verifikacija",
    description:
      "Da biste sudjelovali u natjecanju, morate potvrditi svoj račun.",
    bdate: {
      title: "Datum rođenja",
    },
  },
  Verification: {
    title: "Verifikacija",
    hint: "Držite lice unutar ovala",
    secureHint: "Nitko neće vidjeti ovu fotografiju",
    success: "Uspješno ste završili verifikaciju",
    goToApp: "Idite u aplikaciju",
    verification: "Verifikacija...",
    cameraAccessRequired: "Molimo omogućite pristup kameri za nastavak",
    gettingAccessToCamer: "Dobivanje pristupa kameri...",
    cameraAccessNotAllowed: "Pristup kameri je odbijen od strane korisnika.",
    cameraAccessError: "Greška u pristupu kameri",
    loadingHint: "Ovo može potrajati neko vrijeme. Molimo budite strpljivi.",
  },
  Profile: {
    vote: "Glasaj",
    askVote: "Zatraži glas",
    inviteText: `Bok! 😊 Molim vas, podržite me svojim glasom na natjecanju TON Stars! Svaki glas je presudan i pomaže mi da se približim osvajanje vrijednih nagrada.

🌟 +{{coins}} kovanica kao prvi poklon
🔥 +{{coinsPremium}} kovanica ako imate Telegram Premium

Glasajte za moj profil ovdje: {{url}}

Hvala vam na podršci! 🌟`,
    joinContest: "Pridružite se natjecanju",
    gender: {
      male: "Muški",
      female: "Ženski",
    },
    photos: {
      inReview:
        "Vaše nove fotografije su na pregledu.\nTo može potrajati neko vrijeme.",
      rejected:
        "Jedna ili više fotografija je odbijeno iz sljedećeg razloga:\n{{reason}}.\n\nMolimo vas, prenesite različite fotografije i pokušajte ponovno.",
    },
  },
  ProfileEdit: {
    title: "Vaš profil",
    name: {
      label: "Vaše ime",
      placeholder: "Unesite svoje ime",
    },
    instagram: {
      description: "Instagram profil mora biti javan",
    },
    photos: {
      verificationPhotoUploaded:
        "Vaša potvrda fotografije je prenesena, nitko je neće vidjeti.",
      label: "Vaše fotografije",
      mainPhoto: "Glavna fotografija",
      error:
        "Jedna ili više fotografija nije prikladno, molimo vas da napravite drugi izbor.",
      hint: "Odaberite svoje najimpresivnije fotografije, na kojima nema drugih ljudi, a vaše lice je jasno i izražajno vidljivo.",
      inReview:
        "Vaše nove fotografije su na pregledu.\nTo može potrajati neko vrijeme.",
      rejected:
        "Jedna ili više fotografija je odbijeno iz sljedećeg razloga:\n{{reason}}.\n\nMolimo vas, prenesite različite fotografije i pokušajte ponovno.",
    },
    submit: "Pridružite se natjecanju",
    photosUploading: "Učitavanje fotografije...",
    success: "Vaš profil je ažuriran",
    errors: {
      name: "Unesite svoje ime",
      photos: "Odaberite 3 fotografije",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "Glavna fotografija",
    noFace: "Vaše lice nije otkriveno",
    otherPeople: "Fotografija bi trebala prikazivati samo vaše lice",
  },
};
