export default {
  g: {
    flag: "🇮🇳",
    langName: "Odia",
    langNameNative: "ଓଡ଼ିଆ",
    continue: "ଜାରି ରଖନ୍ତୁ",
    save: "ସଂରକ୍ଷଣ କରନ୍ତୁ",
    retry: "ପୁନଃଚେଷ୍ଟା କରନ୍ତୁ",
    loading: "ଲୋଡ଼ ହେଉଛି...",
    genderMany: {
      all: "ସମସ୍ତ",
      male: "ପୁରୁଷ",
      female: "ମହିଳା",
    },
  },
  CreateAccount: {
    slogan: "ଯେଉଁଠାରେ ସ୍ବପ୍ନ ଜିତରେ ପରିବର୍ତ୍ତିତ ହୁଏ!",
    gender: {
      title: "ନମସ୍କାର {{name}}, ଆପଣ...",
      male: "ପୁରୁଷ",
      female: "ମହିଳା",
    },
  },
  Settings: {
    title: "ସେଟିଂସ୍",
    language: "ଭାଷା",
    auto: "ସ୍ୱୟଂଚାଳିତ",
    country: "ଦେଶ",
    hideProfile: {
      label: "ପ୍ରୋଫାଇଲ୍ ଲୁଚାନ୍ତୁ",
      description:
        "ଆପଣଙ୍କ ପ୍ରୋଫାଇଲ୍ ସବୁଠାରୁ ଲୁଚାଯିବ, ଯେପର୍ଯ୍ୟନ୍ତ ଆପଣ ଏହାକୁ ଡିଲିଟ୍ କରିନାହାନ୍ତି।",
    },
    profileHidden:
      "ଆପଣଙ୍କ ପ୍ରୋଫାଇଲ୍ ଲୁଚାଯାଇଛି ଏବଂ ଅନ୍ୟ ଉପଯୋଗକାରୀଙ୍କୁ ଦେଖିବାକୁ ମିଳିନାହିଁ।",
  },
  Tabs: {
    vote: "ଭୋଟ୍",
    contest: "ପ୍ରତିଯୋଗିତା",
    friends: "ମିତ୍ର",
    earn: "ଅର୍ଜନ କରନ୍ତୁ",
    wallet: "ୱାଲେଟ୍",
  },
  Vote: {
    whoIsBetter: "ଆପଣ କାହାକୁ ଅଧିକ ପସନ୍ଦ କରନ୍ତି?",
    votingForLeader: "ନେତା ପାଇଁ ଭୋଟ ଦେବା",
    noUsers: {
      title: "🚀 ଆଜି ପାଇଁ ଏତେଇ",
      description:
        "ଭୋଟିଂ ଜାରି ରଖିବା ଓ କଏନ୍ ଉପାର୍ଜନ କରିବା ପାଇଁ ବନ୍ଧୁମାନଙ୍କୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ",
      inviteFriends: "ବନ୍ଧୁମାନଙ୍କୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ",
    },
  },
  Leaders: {
    rewards: "ପୁରସ୍କାର",
    comingSoon: "ଶୀଘ୍ର ଆସିବାକୁ",
    prizePool: "ପୁରସ୍କାର ପୁଲ୍",
    moreInfo: "ଅଧିକ ସୂଚନା",
    contest: "ପ୍ରତିଯୋଗିତା",
    richest: "ସବୁଠାରୁ ଧନୀ",
    sponsors: "ସ୍ପଂସର୍ସ",
    description: {
      likes:
        "ଅଧିକ ଭୋଟ ପାଇବାକୁ ଏବଂ ଜିତିବାର ସମ୍ଭାବନା ବୃଦ୍ଧି ପାଇବାକୁ ସାଙ୍ଗକୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ ଏବଂ ଆପଣଙ୍କର ପ୍ରୋଫାଇଲ୍ ସେୟାର କରନ୍ତୁ।",
      coins:
        "କାର୍ଯ୍ୟ ସମ୍ପୂର୍ଣ୍ଣ କରନ୍ତୁ, ଅନ୍ୟ ପ୍ରତିଭାଗୀଙ୍କ ପାଇଁ ଭୋଟ୍ କରନ୍ତୁ, ଏବଂ ଅଧିକ କଏନ୍ ଉପାର୍ଜନ କରିବାକୁ ଏବଂ ଆପଣଙ୍କର ସ୍ଥିତିକୁ ଶକ୍ତିଶାଳୀ କରିବାକୁ ସାଙ୍ଗମାନଙ୍କୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ।",
      stars:
        "ସ୍ଟାର୍ ପାଇବା ଏବଂ ରାଙ୍କିଙ୍ଗ୍ରେ ବଢିବା ପାଇଁ ପ୍ରୋଫାଇଲ୍ ପୃଷ୍ଠାରେ ପ୍ରୋଫାଇଲ୍ ପାଇଁ ଭୋଟ୍ କରନ୍ତୁ।",
    },
  },
  PrizePool: {
    text: `<b>ପୁରସ୍କାର ଜିତିବା ସ୍ଥାନଗୁଡ଼ିକ</b>:
<b>1st place</b>: 50% USD ଏବଂ 10,000 କଏନ୍ |
<b>2nd place</b>: 30% USD ଏବଂ 3,000 କଏନ୍ |
<b>3rd place</b>: 20% USD ଏବଂ 2,000 କଏନ୍ |
<b>4th-10th places</b>: ପ୍ରତ୍ୟେକ 1,000 କଏନ୍ |
<b>11th-50th places</b>: ପ୍ରତ୍ୟେକ 500 କଏନ୍ |
<b>51st-100th places</b>: ପ୍ରତ୍ୟେକ 250 କଏନ୍ |

<b>ପୁରସ୍କାର ପୁଲ୍</b> ଆପଣଙ୍କ ପ୍ରୋଫାଇଲ୍ ପୃଷ୍ଠାରେ କେହି ଆପଣଙ୍କ ପାଇଁ ଭୋଟ୍ କଲେ ବୃଦ୍ଧି ପାଉଛି।

<b>ସର୍ତ୍ତ</b>:
ଯେଉଁସମସ୍ତ ପ୍ରୋଫାଇଲ୍ ଏକ ଫୋଟୋ ଅପଲୋଡ କରିବେ ଏବଂ କାର୍ଯ୍ୟଗୁଡ଼ିକ ସମ୍ପୂର୍ଣ୍ଣ କରିବେ ସେମାନେ ପ୍ରତିଯୋଗିତାରେ ଅଂଶଗ୍ରହଣ କରିବାକୁ ଯୋଗ୍ୟ |

<b>ସମାପ୍ତି ତାରିଖ</b>:
{{endDate}}

<b>ପୁରସ୍କାର ପାଇବା</b>:
ଆପଣ <b>TON</b> ଭିତରେ ଆପଣଙ୍କ ପୁରସ୍କାର ପାଇପାରିବେ — ଯାହା Telegram ଦ୍ୱାରା ବିକାଶ ହୋଇଛି।`,
  },
  LeadersItem: {
    join: "ଯୋଗଦିଅ",
  },
  Friends: {
    title: "ମିତ୍ରମାନଙ୍କୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ!",
    description: "ଆପଣ ଏବଂ ଆପଣଙ୍କର ମିତ୍ର ବୋନାସ୍ ପାଇବେ",
    bonus: {
      profit: "<coins>+{{count}}</coins> ଆପଣ ଏବଂ ଆପଣଙ୍କର ମିତ୍ରଙ୍କ ପାଇଁ",
      invite: {
        title: "ମିତ୍ରକୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ",
      },
      inviteWithPremium: {
        title: "ମିତ୍ରକୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ",
        description: "Telegram Premium ସହ",
      },
    },
    list: {
      title: "ଆପଣଙ୍କର ମିତ୍ରଙ୍କ ତାଲିକା",
    },
    inviteFriend: "ମିତ୍ରକୁ ଆମନ୍ତ୍ରଣ କରନ୍ତୁ",
    inviteText: `ମୋ ସହ ଯୋଗ ଦିଅନ୍ତୁ, ସବୁଠାରୁ ସୁନ୍ଦର ଝିଅ ଏବଂ ପୁଆମାନଙ୍କ ପାଇଁ ଭୋଟ୍ କରନ୍ତୁ ଏବଂ ମୂଲ୍ୟବାନ ପୁରସ୍କାର ଜିତନ୍ତୁ!
  
  🌟 ପ୍ରଥମ ଉପହାର ଭାବେ +{{coins}} କୋଇନ୍ସ୍
  🔥 Telegram Premium ଥିଲେ +{{coinsPremium}} କୋଇନ୍ସ୍
  
  🏆 ପ୍ରତ୍ୟେକ ଭୋଟ୍ ପାଇଁ କୋଇନ୍ସ୍ ଅର୍ଜନ କରନ୍ତୁ ଏବଂ ସବୁଠାରୁ ଆକର୍ଷକ ଭାଗଗ୍ରାହୀମାନଙ୍କୁ ଚୟନ କରନ୍ତୁ। ଅଧିକ ଚାହୁଁଛନ୍ତି କି? ନିଜେ ଭାଗ ନିଅନ୍ତୁ ଏବଂ ମୂଲ୍ୟବାନ ପୁରସ୍କାର ପାଇଁ ମଲ୍ୟାଂକନ କରନ୍ତୁ!
  
  ଆପଣଙ୍କର ଅବସରକୁ ହାରିବେନି, ଆମର ତାରା ସମୁଦାୟର ଅଂଶ ହୋନ୍ତୁ ଏବଂ ଜିତନ୍ତୁ!`,
    linkCopied: "ଲିଙ୍କ କପି ହୋଇଯାଇଛି",
  },
  Earn: {
    title: "ଅଧିକ କୋଇନ୍ସ୍ ଅର୍ଜନ କରନ୍ତୁ",
    tasksList: "କାର୍ଯ୍ୟ ସୂଚୀ",
    check: "ପରିଖ୍ୟା କରନ୍ତୁ",
    inReview: "ପୁନଃପରିକ୍ଷାରେ",
    subscribe: "ସବସ୍କ୍ରାଇବ୍ କରନ୍ତୁ",
    participate: "ଭାଗ ନିଅନ୍ତୁ",
    invite: "ଆମନ୍ତ୍ରଣ କରନ୍ତୁ",
    verify: "ସତ୍ୟାପନ କରନ୍ତୁ",
    taskCompleted: "କାର୍ଯ୍ୟ ସମାପ୍ତ ହେଲା",
  },
  Wallet: {
    title: "TON ରେ ପୁରସ୍କାର ପାଆନ୍ତୁ",
    description:
      "TON, ଏକ କ୍ରିପ୍ଟୋ କୋଇନ୍ ଯାହା Telegram ଦ୍ୱାରା ବିକଶିତ ହୋଇଛି, ହାସଲ କରିବା ପାଇଁ ଚ୍ୟାଲେଞ୍ଜକୁ ସମ୍ପୂର୍ଣ୍ଣ କରନ୍ତୁ",
    rewards: "ପୁରସ୍କାର",
    withdrawal: "ଉତ୍ତୋଳନ",
    walletConnected: "ଆପଣଙ୍କର TON ୱାଲେଟ୍ ସଂଯୁକ୍ତ ହୋଇଛି",
    walletDescription: "ଶୀଘ୍ର ଅଧିକ ସୁଯୋଗ ଉପଲବ୍ଧ ହେବ",
  },
  VerificationRequired: {
    title: "ସତ୍ୟାପନ ଆବଶ୍ୟକ",
    description:
      "ପ୍ରତିଯୋଗିତାରେ ଭାଗ ନେବା ପାଇଁଆପଣଙ୍କୁ ଆପଣଙ୍କର ଖାତା ସତ୍ୟାପନ କରିବାକୁ ପଡିବ।",
    bdate: {
      title: "ଆପଣଙ୍କର ଜନ୍ମ ତାରିଖ",
    },
  },
  Verification: {
    title: "ସତ୍ୟାପନ",
    hint: "ଆପଣଙ୍କ ମୁହାଁକୁ ଓଭାଲରେ ରଖନ୍ତୁ",
    secureHint: "ଏହି ଫଟୋଟି କେହି ଦେଖିବେ ନାହିଁ",
    success: "ଆପଣ ସଫଳତାର ସହ ସତ୍ୟାପନ ସମାପ୍ତ କରିଛନ୍ତି",
    goToApp: "ଆପ୍ ଦିଗରେ ଯାଆନ୍ତୁ",
    verification: "ସତ୍ୟାପନ କରାଯାଉଛି…",
    cameraAccessRequired: "ଅଗ୍ରଗତି ପାଇଁ କ୍ୟାମେରା ପ୍ରବେଶକୁ ଅନୁମତି ଦିଅନ୍ତୁ",
    gettingAccessToCamer: "କ୍ୟାମେରା ପ୍ରବେଶ ପାଇଁ ଚେଷ୍ଟା କରୁଛି…",
    cameraAccessNotAllowed:
      "ବ୍ୟବହାରକାରୀ କ୍ୟାମେରା ପ୍ରବେଶକୁ ଅନୁମତି ଦିଅନ୍ତି ନାହିଁ।",
    cameraAccessError: "କ୍ୟାମେରା ପ୍ରବେଶ ତ୍ରୁଟି",
    loadingHint: "ଏହା କିଛି ସମୟ ନେବ। ଦୟାକରି ଧୈର୍ଯ୍ୟ ଧରନ୍ତୁ।",
  },
  Profile: {
    vote: "ଭୋଟ୍",
    askVote: "ଭୋଟ୍ ମାଗିବେ",
    inviteText: `ନମସ୍କାର! 😊 TON Stars ପ୍ରତିଯୋଗିତାରେ ଆପଣଙ୍କର ଭୋଟ୍ ସହ ମୋତେ ସମର୍ଥନ କରନ୍ତୁ! ପ୍ରତ୍ୟେକ ଭୋଟ୍ ଅତ୍ୟନ୍ତ ଦରକାରୀ ଏବଂ ମୋତେ ମୂଲ୍ୟବାନ ପୁରସ୍କାର ଜିତିବାରେ ନିକଟ ଯିବାରେ ସାହାଯ୍ୟ କରେ।

🌟 ପ୍ରଥମ ଉପହାର ଭାବରେ +{{coins}} କଏନ୍
🔥 ଯଦି ଆପଣଙ୍କର ଠାରେ Telegram Premium ଅଛି ତେବେ +{{coinsPremium}} କଏନ୍

ମୋ ପ୍ରୋଫାଇଲ୍ ପାଇଁ ଏଠାରେ ଭୋଟ୍ ଦିଅନ୍ତୁ: {{url}}

ଆପଣଙ୍କର ସମର୍ଥନ ପାଇଁ ଧନ୍ୟବାଦ! 🌟`,
    joinContest: "ପ୍ରତିଯୋଗିତାରେ ଯୋଗ ଦିଅନ୍ତୁ",
    gender: {
      male: "ପୁରୁଷ",
      female: "ମହିଳା",
    },
    photos: {
      inReview: "ଆପଣଙ୍କ ନୂଆ ଫଟୋଗୁଡ଼ିକ ସମୀକ୍ଷାରେ ଅଛି।\nଏହା କିଛି ସମୟ ନିଅବ।",
      rejected:
        "ନିମ୍ନଲିଖିତ କାରଣରେ ଗୋଟିଏ କିମ୍ବା ଅଧିକ ଫଟୋକୁ ପ୍ରତ୍ଖ୍ୟାନ କରାଯାଇଛି:\n{{reason}}।\n\nଦୟାକରି ଭିନ୍ନ ଫଟୋଗୁଡ଼ିକ ଅପଲୋଡ୍ କରନ୍ତୁ ଏବଂ ପୁଣିଥରେ ଚେଷ୍ଟା କରନ୍ତୁ।",
    },
  },
  ProfileEdit: {
    title: "ଆପଣଙ୍କର ପ୍ରୋଫାଇଲ୍",
    name: {
      label: "ଆପଣଙ୍କର ନାମ",
      placeholder: "ଆପଣଙ୍କର ନାମ ଲେଖନ୍ତୁ",
    },
    instagram: {
      description: "Instagram ପ୍ରୋଫାଇଲ୍ ସାର୍ବଜନିକ ହେବା ଆବଶ୍ୟକ",
    },
    photos: {
      verificationPhotoUploaded:
        "ଆପଣଙ୍କ ଭେରିଫିକେସନ୍ ଫଟୋ ଅପଲୋଡ୍ ହୋଇଛି, କେହି ଏହାକୁ ଦେଖିବେ ନାହିଁ।",
      label: "ଆପଣଙ୍କର ଫଟୋଗୁଡ଼ିକ",
      mainPhoto: "ପ୍ରଧାନ ଫଟୋ",
      error: "ଗୋଟିଏ କିମ୍ବା ଅଧିକ ଫଟୋ ଉପଯୋଗୀ ନୁହେଁ, ଦୟାକରି ଅନ୍ୟ ଏକ ଚୟନ କରନ୍ତୁ।",
      hint: "ଆପଣଙ୍କର ସବୁଠୁ ପ୍ରଭାବଶାଳୀ ଫଟୋଗୁଡ଼ିକ ଚୟନ କରନ୍ତୁ, ଯେଉଁଠାରେ ଅନ୍ୟ କିଛି ଲୋକ ନାହିଁ, ଏବଂ ଆପଣଙ୍କର ମୁହଁ ସ୍ପଷ୍ଟ ଏବଂ ସ୍ପଷ୍ଟ ଭାବରେ ଦୃଶ୍ୟମାନ ଅଛି।",
      inReview: "ଆପଣଙ୍କ ନୂଆ ଫଟୋଗୁଡ଼ିକ ସମୀକ୍ଷାରେ ଅଛି।\nଏହା କିଛି ସମୟ ନିଅବ।",
      rejected:
        "ନିମ୍ନଲିଖିତ କାରଣରେ ଗୋଟିଏ କିମ୍ବା ଅଧିକ ଫଟୋକୁ ପ୍ରତ୍ଖ୍ୟାନ କରାଯାଇଛି:\n{{reason}}।\n\nଦୟାକରି ଭିନ୍ନ ଫଟୋଗୁଡ଼ିକ ଅପଲୋଡ୍ କରନ୍ତୁ ଏବଂ ପୁଣିଥରେ ଚେଷ୍ଟା କରନ୍ତୁ।",
    },
    submit: "ପ୍ରତିଯୋଗିତାରେ ଯୋଗ ଦିଅନ୍ତୁ",
    photosUploading: "ଫଟୋ ଅପଲୋଡ୍ ହେଉଛି...",
    success: "ଆପଣଙ୍କର ପ୍ରୋଫାଇଲ୍ ଅଦ୍ୟତିତ ହୋଇଯାଇଛି",
    errors: {
      name: "ଆପଣଙ୍କ ନାମ ଦାୟକ କରନ୍ତୁ",
      photos: "ଦୟାକରି 3ଟି ଫୋଟୋ ଚୟନ କରନ୍ତୁ",
    },
  },
  ProfileEditPhoto: {
    mainPhoto: "ପ୍ରଧାନ ଫଟୋ",
    noFace: "ଆପଣଙ୍କର ମୁହଁ ଚିହ୍ନଟ ହୋଇନାହିଁ",
    otherPeople: "ଫଟୋରେ କେବଳ ଆପଣଙ୍କର ମୁହଁ ଦେଖାଯିବା ଉଚିତ",
  },
};
